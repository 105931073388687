import React from 'react';
import './App.css';
import { withSplash } from './pages/Splash';
import AnimatedDashboards from "./pages/AnimatedDashboards";

function App(props) {
  const { store } = props;
  return (
      <>
        <AnimatedDashboards store={store}/>
      </>
  )
}

export default withSplash(App);
