import React from 'react';
import './DisplayStatus.css'
import { useStore } from "../../../store";

export function DisplayStatus(props) {
    const store = useStore();
    const {displayStatus: displays} = store;

    return (
        <>
            {displays.size === 0
                ? <div className="display-status">No queue times available</div>
                : <div className="display-status">
                    <div className="row display-status-title">
                      Security Time
                    </div>
                    <div className="row">
                      <div className="col auto">T1: {displays.get('T1_Security_E2E').time_interval_in_minutes_max} mins</div>
                      <div className="col auto">T2: {displays.get('T2_Security_E2E').time_interval_in_minutes_max} mins</div>
                    </div>
                </div>
            }

        </>
    )
}
