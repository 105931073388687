import {flow, makeAutoObservable} from "mobx";
import moment from "moment";

export class FlightStore {
  totalPageNum = 0;
  pages = []
  state = "initial";
  displayStatus = new Map();
  lastCall = null;

  INITIAL = "initial"
  LOADING = "loading"
  LOADED = "loaded"

  constructor() {
    makeAutoObservable(this, {
      fetchFlights: flow,
      fetchDisplay: flow,
      INITIAL: false,
      LOADING: false,
      LOADED: false,
      lastCall: false
    }, { proxy: false });
  }

  *fetchDisplay() {
    const displayObj = new Map();
    const response = yield fetch(`/api/displays`);
    const displays = yield response.json();

    displays.forEach((display) => {
      displayObj.set(display.id, display);
    })
    this.displayStatus = displayObj;
  }

  *fetchFlights() {
    const newApiCall = yield this.fetchApiCall();
    if(this.lastCall && this.lastCall.latest_mod_time === newApiCall.latest_mod_time)
      return;
    else
      this.lastCall = newApiCall;

    const ROWS_PER_PAGE = 18;
    if(this.state !== this.INITIAL) {
      this.state = this.LOADING;
    }

    function isSameDay(dateA, dateB) {
      return dateA.getFullYear() === dateB.getFullYear() &&
        dateA.getMonth() === dateB.getMonth() &&
        dateA.getDate() === dateB.getDate();
    }

    const response = yield fetch(`/api/flights?per_page=54`);
    const pageData = yield response.json();
    const rows = [];

    let lastDate = null;
    pageData.data.forEach((item, index) => {
      const itemDate = new Date(item.scheduled_date_time);

      if(lastDate === null || !isSameDay(lastDate, itemDate)) {
        rows.push({
          type: 'separator',
          date: moment(itemDate).format('ddd Do MMMM YYYY')
        })
        lastDate = itemDate;
      }

      item.time = moment(itemDate).format('HH:mm');

      const iata_codes = item.port_of_call_iata_code.split(',');

      iata_codes.forEach((iata_code) => {
        rows.push({
          type: 'item',
          item: Object.assign({}, item, {
            port_of_call_iata_code: iata_code
          })
        });
      });

    });


    const numEmptyRows = ROWS_PER_PAGE - rows.length % ROWS_PER_PAGE;
    for(let i = 0; i < numEmptyRows; i++) {
      rows.push({
        type: 'empty'
      })
    }

    const pages = [];
    rows.forEach(function(row, index) {
      let pageIdx = Math.floor(index / ROWS_PER_PAGE);
      if(pageIdx >= pages.length) {
        pages.push([]);
      }
      pages[pageIdx].push(row);
    });

    this.totalPageNum = pages.length;
    this.pages = pages;
    this.state = this.LOADED;
  }

  async fetchApiCall() {
    const response = await fetch(`/api/api_calls/latest`);
    return await response.json();
  }

  startFetch() {
    this.fetchFlights();
    this.fetchDisplay();

    setInterval(() => {
      this.fetchFlights();
      this.fetchDisplay();
    }, 60000);
  }
}
