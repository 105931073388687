import React, {useEffect, useRef, useState} from 'react';
import { CSSTransition } from "react-transition-group";
import Dashboard from "./Dashboard";
import '../slideTransition.css';
import { useStore } from "../store";

function SlideDashboard(props) {
  const ref = useRef(null);
  const { page } = props;
  return <CSSTransition
    {...props}
    nodeRef={ref}
  >
    <div ref={ref} className="pageSlider left">
      <Dashboard activePage={page} />
    </div>
  </CSSTransition>
}

export default function AnimatedDashboards(props) {
  const store = useStore();
  const [currentPage, setCurrentPage] = useState(1);

  useEffect(() => {
    setTimeout(() => {
      if(store.totalPageNum > 0) {
        setCurrentPage(currentPage % store.totalPageNum + 1);
      }
    }, 5000);
  }, [currentPage]);

  const pageNumbers = [];
  for(let i = 0; i < store.totalPageNum; i++) {
    pageNumbers.push(i + 1);
  }

  return (
    <>
      {
        pageNumbers.map((pageNumber) =>
          <SlideDashboard
            in={pageNumber === currentPage}
            key={pageNumber}
            timeout={1000}
            unmountOnExit
            page={pageNumber}
            classNames="pageSlider"
          >
          </SlideDashboard>
        )
      }
    </>
  )

}
