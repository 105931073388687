import { observer } from 'mobx-react-lite';

import logo from "../../assets/DA Logo White.png"
import './splash.css';
import React from 'react';
import { useStore } from "../../store";

export function Splash(props) {
  return <div className="splash-back">
    <img src={logo} className="splash-logo" />
  </div>
}

export function withSplash(WrappedComponent) {
  return observer((props) => {
    const store = useStore();

    if(store.state === store.INITIAL) {
      return <Splash />
    } else {
      return <WrappedComponent {...props} />
    }
  })
}
