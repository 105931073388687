const codes = {
    "A01": [
        {
            "B": "LOXZ",
            "C": "ZELTWEG   MIL",
            "D": "Zeltweg",
            "E": "Austria",
            "F": "AT",
            "G": "E",
            "H": "",
            "I": "",
            "J": "EUR"
        }
    ],
    "AAA": [
        {
            "B": "AAAA",
            "C": "DUBLIN TO DUBLIN",
            "D": "Local",
            "E": "Republic of Ireland",
            "F": "IE",
            "G": "D",
            "H": "",
            "I": "",
            "J": "EUR"
        }
    ],
    "AAE": [
        {
            "B": "DABB",
            "C": "Les Salines",
            "D": "Rabah Bitat",
            "E": "Algeria",
            "F": "DZ",
            "G": "O",
            "H": "",
            "I": "",
            "J": "AFR"
        }
    ],
    "AAH": [
        {
            "B": "EDKA",
            "C": "AACHEN",
            "D": "Aachen",
            "E": "Germany",
            "F": "DE",
            "G": "E",
            "H": "",
            "I": "",
            "J": "EUR"
        }
    ],
    "AAL": [
        {
            "B": "EKYT",
            "C": "AALBORG",
            "D": "Aalborg",
            "E": "Denmark",
            "F": "DK",
            "G": "E",
            "H": "",
            "I": "",
            "J": "EUR"
        }
    ],
    "AAN": [
        {
            "B": "OMAL",
            "C": "AL AIN INTERNATIONAL",
            "D": "Al ain",
            "E": "United Arab Emirates",
            "F": "AE",
            "G": "O",
            "H": "",
            "I": "",
            "J": "MDE"
        }
    ],
    "AAQ": [
        {
            "B": "URKA",
            "C": "Anapa",
            "D": "Vityazevo",
            "E": "Russian Federation",
            "F": "RU",
            "G": "E",
            "H": "",
            "I": "",
            "J": "EUR"
        }
    ],
    "AAR": [
        {
            "B": "EKAH",
            "C": "AARHUS LUFTHAVN TIRS",
            "D": "Aarhus",
            "E": "Denmark",
            "F": "DK",
            "G": "E",
            "H": "",
            "I": "",
            "J": "EUR"
        }
    ],
    "AAX": [
        {
            "B": "SBAX",
            "C": "ARAXA",
            "D": "Araxa romeu zema",
            "E": "Brazil",
            "F": "BR",
            "G": "T",
            "H": "",
            "I": "",
            "J": "SOA"
        }
    ],
    "ABA": [
        {
            "B": "UNAA",
            "C": "Abakan",
            "D": "Abakan",
            "E": "Russian Federation",
            "F": "RU",
            "G": "E",
            "H": "",
            "I": "",
            "J": "EUR"
        }
    ],
    "ABC": [
        {
            "B": "LEAB",
            "C": "LOS LANOS ALBACETE",
            "D": "Albacete",
            "E": "Spain",
            "F": "ES",
            "G": "E",
            "H": "",
            "I": "",
            "J": "EUR"
        }
    ],
    "ABD": [
        {
            "B": "OIAA",
            "C": "ABADAN",
            "D": "Abadan",
            "E": "Iran",
            "F": "IR",
            "G": "O",
            "H": "",
            "I": "",
            "J": "MDE"
        }
    ],
    "ABE": [
        {
            "B": "KABE",
            "C": "ALLENTOWN BETHLEHEM EASTON",
            "D": "Allentown",
            "E": "USA",
            "F": "US",
            "G": "T",
            "H": "",
            "I": "",
            "J": "NOA"
        }
    ],
    "ABI": [
        {
            "B": "KABI",
            "C": "Municipal",
            "D": "Abilene Regional Airport",
            "E": "USA",
            "F": "US",
            "G": "T",
            "H": "",
            "I": "",
            "J": "NOA"
        }
    ],
    "ABJ": [
        {
            "B": "DIAP",
            "C": "ABIDJAN",
            "D": "Abidjan",
            "E": "Cote d'Ivoire",
            "F": "CI",
            "G": "O",
            "H": "",
            "I": "",
            "J": "AFR"
        }
    ],
    "ABK": [
        {
            "B": "HAKD",
            "C": "KABRI DAHAR",
            "D": "Kabri Dahar",
            "E": "Ethiopia",
            "F": "ET",
            "G": "O",
            "H": "",
            "I": "",
            "J": "AFR"
        }
    ],
    "ABQ": [
        {
            "B": "KABQ",
            "C": "ALBUQUERQUE",
            "D": "Albuquerque",
            "E": "USA",
            "F": "US",
            "G": "T",
            "H": "",
            "I": "",
            "J": "NOA"
        }
    ],
    "ABR": [
        {
            "B": "KABR",
            "C": "Municipal",
            "D": "Aberdeen Regional",
            "E": "USA",
            "F": "US",
            "G": "T",
            "H": "",
            "I": "",
            "J": "NOA"
        }
    ],
    "ABS": [
        {
            "B": "HEBL",
            "C": "ABU SIMBEL",
            "D": "Abu Simbel",
            "E": "Egypt",
            "F": "EG",
            "G": "O",
            "H": "",
            "I": "",
            "J": "MDE"
        }
    ],
    "ABT": [
        {
            "B": "OEBA",
            "C": "Al-Aqiq",
            "D": "Al-Aqiq",
            "E": "Saudi Arabia",
            "F": "SA",
            "G": "O",
            "H": "",
            "I": "",
            "J": "MDE"
        }
    ],
    "ABU": [
        {
            "B": "WRKA",
            "C": "HALIWEN AIRPORT",
            "D": "HALIWEN AIRPORT",
            "E": "Indonesia",
            "F": "ID",
            "G": "O",
            "H": "",
            "I": "",
            "J": "SEA"
        }
    ],
    "ABV": [
        {
            "B": "DNAA",
            "C": "ABUJA",
            "D": "Abuja",
            "E": "Nigeria",
            "F": "NG",
            "G": "O",
            "H": "",
            "I": "",
            "J": "AFR"
        }
    ],
    "ABX": [
        {
            "B": "YMAY",
            "C": "Albury",
            "D": "Albury",
            "E": "Australia",
            "F": "AU",
            "G": "O",
            "H": "",
            "I": "",
            "J": "SWP"
        }
    ],
    "ABY": [
        {
            "B": "KABY",
            "C": "Dougherty County",
            "D": "Southwest Georgia Rgnl",
            "E": "USA",
            "F": "US",
            "G": "T",
            "H": "",
            "I": "",
            "J": "NOA"
        }
    ],
    "ABZ": [
        {
            "B": "EGPD",
            "C": "ABERDEEN",
            "D": "Aberdeen",
            "E": "United Kingdom",
            "F": "GB",
            "G": "U",
            "H": "",
            "I": "",
            "J": "EUR"
        }
    ],
    "ACA": [
        {
            "B": "MMAA",
            "C": "ACAPULCO",
            "D": "Acapulco",
            "E": "Mexico",
            "F": "MX",
            "G": "T",
            "H": "",
            "I": "",
            "J": "CEM"
        }
    ],
    "ACC": [
        {
            "B": "DGAA",
            "C": "ACCRA",
            "D": "Accra",
            "E": "Ghana",
            "F": "GH",
            "G": "O",
            "H": "",
            "I": "",
            "J": "AFR"
        }
    ],
    "ACE": [
        {
            "B": "GCRR",
            "C": "LANZAROTE",
            "D": "Lanzarote",
            "E": "Spain",
            "F": "ES",
            "G": "E",
            "H": "",
            "I": "",
            "J": "EUR"
        }
    ],
    "ACH": [
        {
            "B": "LSZR",
            "C": "ALTENRHEIN",
            "D": "Altenrhein",
            "E": "Switzerland",
            "F": "CH",
            "G": "E",
            "H": "",
            "I": "",
            "J": "EUR"
        }
    ],
    "ACI": [
        {
            "B": "EGJA",
            "C": "ALDERNEY",
            "D": "Alderney",
            "E": "United Kingdom",
            "F": "GB",
            "G": "U",
            "H": "",
            "I": "",
            "J": "EUR"
        }
    ],
    "ACK": [
        {
            "B": "KACK",
            "C": "NANTUCKET",
            "D": "Nantucket",
            "E": "USA",
            "F": "US",
            "G": "T",
            "H": "",
            "I": "",
            "J": "NOA"
        }
    ],
    "ACO": [
        {
            "B": "LSZD",
            "C": "ASCONA",
            "D": "Ascona",
            "E": "Switzerland",
            "F": "CH",
            "G": "E",
            "H": "",
            "I": "",
            "J": "EUR"
        }
    ],
    "ACT": [
        {
            "B": "KACT",
            "C": "Municipal",
            "D": "Waco Municipal",
            "E": "USA",
            "F": "US",
            "G": "T",
            "H": "",
            "I": "",
            "J": "NOA"
        }
    ],
    "ACV": [
        {
            "B": "KACV",
            "C": "Arcata",
            "D": "Arcata",
            "E": "USA",
            "F": "US",
            "G": "T",
            "H": "",
            "I": "",
            "J": "NOA"
        }
    ],
    "ACY": [
        {
            "B": "KACY",
            "C": "ATLANTIC CITY",
            "D": "Atlantic City",
            "E": "USA",
            "F": "US",
            "G": "T",
            "H": "",
            "I": "",
            "J": "NOA"
        }
    ],
    "ADA": [
        {
            "B": "LTAF",
            "C": "ADANA",
            "D": "Adana",
            "E": "Turkey",
            "F": "TR",
            "G": "E",
            "H": "",
            "I": "",
            "J": "EUR"
        }
    ],
    "ADB": [
        {
            "B": "LTBJ",
            "C": "IZMIR",
            "D": "Izmir",
            "E": "Turkey",
            "F": "TR",
            "G": "E",
            "H": "",
            "I": "",
            "J": "EUR"
        }
    ],
    "ADD": [
        {
            "B": "HAAB",
            "C": "ADDIS ABABA",
            "D": "Addis Ababa",
            "E": "Ethiopia",
            "F": "ET",
            "G": "O",
            "H": "",
            "I": "",
            "J": "AFR"
        }
    ],
    "ADJ": [
        {
            "B": "OJAM",
            "C": "AMMAN MARKA",
            "D": "Amman",
            "E": "Jordan",
            "F": "JO",
            "G": "O",
            "H": "",
            "I": "",
            "J": "MDE"
        }
    ],
    "ADL": [
        {
            "B": "YPAX",
            "C": "ADELAIDE",
            "D": "Adelaide",
            "E": "Australia",
            "F": "AU",
            "G": "O",
            "H": "",
            "I": "",
            "J": "SWP"
        }
    ],
    "ADQ": [
        {
            "B": "PADQ",
            "C": "Kodiak, Alaska",
            "D": "Kodiak Airport",
            "E": "USA",
            "F": "US",
            "G": "T",
            "H": "",
            "I": "",
            "J": "NOA"
        }
    ],
    "ADS": [
        {
            "B": "KADS",
            "C": "ADDISON AIRPORT",
            "D": "Addison Airport, Dallas",
            "E": "USA",
            "F": "US",
            "G": "T",
            "H": "",
            "I": "",
            "J": "NOA"
        }
    ],
    "ADV": [
        {
            "B": "EGVP",
            "C": "ANDOVER",
            "D": "Andover",
            "E": "United Kingdom",
            "F": "GB",
            "G": "U",
            "H": "",
            "I": "",
            "J": "EUR"
        }
    ],
    "ADW": [
        {
            "B": "KADW",
            "C": "ANDREWS AFB",
            "D": "Andrews",
            "E": "USA",
            "F": "US",
            "G": "T",
            "H": "",
            "I": "",
            "J": "NOA"
        }
    ],
    "ADX": [
        {
            "B": "EGQL",
            "C": "LEUCHARS / ST. ANDREWS",
            "D": "Leuchars",
            "E": "United Kingdom",
            "F": "GB",
            "G": "U",
            "H": "",
            "I": "",
            "J": "EUR"
        }
    ],
    "ADZ": [
        {
            "B": "SKSP",
            "C": "San Andres Island",
            "D": "Gustavo R. Pinilla Int",
            "E": "Colombia",
            "F": "CO",
            "G": "T",
            "H": "",
            "I": "",
            "J": "SOA"
        }
    ],
    "AED": [
        {
            "B": "PAED",
            "C": "ELMENDORF AFB",
            "D": "ELMENDORF",
            "E": "USA",
            "F": "US",
            "G": "T",
            "H": "",
            "I": "",
            "J": "NOA"
        }
    ],
    "AEP": [
        {
            "B": "SABE",
            "C": "Buenos Aires",
            "D": "Jorge Newbery",
            "E": "Argentina",
            "F": "AR",
            "G": "T",
            "H": "",
            "I": "",
            "J": "SOA"
        }
    ],
    "AER": [
        {
            "B": "URSS",
            "C": "ADLER-SOCHI",
            "D": "Adler-sochi",
            "E": "Russian Federation",
            "F": "RU",
            "G": "E",
            "H": "",
            "I": "",
            "J": "EUR"
        }
    ],
    "AES": [
        {
            "B": "ENAL",
            "C": "ALESUND",
            "D": "Alesund",
            "E": "Norway",
            "F": "NO",
            "G": "E",
            "H": "",
            "I": "",
            "J": "EUR"
        }
    ],
    "AEY": [
        {
            "B": "BIAR",
            "C": "AKUREYRI",
            "D": "Akureyri",
            "E": "Iceland",
            "F": "IS",
            "G": "E",
            "H": "",
            "I": "",
            "J": "EUR"
        }
    ],
    "AFW": [
        {
            "B": "KAFW",
            "C": "FORT WORTH",
            "D": "Fort Worth",
            "E": "USA",
            "F": "US",
            "G": "T",
            "H": "",
            "I": "",
            "J": "NOA"
        }
    ],
    "AFY": [
        {
            "B": "LTAH",
            "C": "AFYON",
            "D": "Afyon",
            "E": "Turkey",
            "F": "TR",
            "G": "E",
            "H": "",
            "I": "",
            "J": "EUR"
        }
    ],
    "AGA": [
        {
            "B": "GMAD",
            "C": "AGADIR",
            "D": "Agadir",
            "E": "Morocco",
            "F": "MA",
            "G": "O",
            "H": "",
            "I": "",
            "J": "AFR"
        }
    ],
    "AGB": [
        {
            "B": "EDMA",
            "C": "AUGSBURG",
            "D": "Augsburg",
            "E": "Germany",
            "F": "DE",
            "G": "E",
            "H": "",
            "I": "",
            "J": "EUR"
        }
    ],
    "AGC": [
        {
            "B": "KAGC",
            "C": "PITTSBURGH / ALLEGHENY",
            "D": "Pittsburgh",
            "E": "USA",
            "F": "US",
            "G": "T",
            "H": "",
            "I": "",
            "J": "NOA"
        }
    ],
    "AGE": [
        {
            "B": "EDWG",
            "C": "WANGEROOGE",
            "D": "Wangerooge",
            "E": "Germany",
            "F": "DE",
            "G": "E",
            "H": "",
            "I": "",
            "J": "EUR"
        }
    ],
    "AGF": [
        {
            "B": "LFBA",
            "C": "AGEN",
            "D": "Agen",
            "E": "France",
            "F": "FR",
            "G": "E",
            "H": "",
            "I": "",
            "J": "EUR"
        }
    ],
    "AGH": [
        {
            "B": "ESTA",
            "C": "HELSINGBORG ANGELHOLM",
            "D": "Helsingborg",
            "E": "Sweden",
            "F": "SE",
            "G": "E",
            "H": "",
            "I": "",
            "J": "EUR"
        }
    ],
    "AGP": [
        {
            "B": "LEMG",
            "C": "MALAGA",
            "D": "Malaga",
            "E": "Spain",
            "F": "ES",
            "G": "E",
            "H": "",
            "I": "",
            "J": "EUR"
        }
    ],
    "AGQ": [
        {
            "B": "LGAG",
            "C": "AGRINION",
            "D": "Agrinion",
            "E": "Greece",
            "F": "GR",
            "G": "E",
            "H": "",
            "I": "",
            "J": "EUR"
        }
    ],
    "AGS": [
        {
            "B": "KAGS",
            "C": "AUGUSTA BUSH FIELD",
            "D": "Augusta",
            "E": "USA",
            "F": "US",
            "G": "T",
            "H": "",
            "I": "",
            "J": "NOA"
        }
    ],
    "AGT": [
        {
            "B": "SGES",
            "C": "Alejo Garcia",
            "D": "Guarani International Airport",
            "E": "Paraguay",
            "F": "PY",
            "G": "T",
            "H": "",
            "I": "",
            "J": "SOA"
        }
    ],
    "AGU": [
        {
            "B": "MMAS",
            "C": "Aguascalients",
            "D": "Aguascalientes International Airport",
            "E": "Mexico",
            "F": "MX",
            "G": "T",
            "H": "",
            "I": "",
            "J": "CEM"
        }
    ],
    "AHB": [
        {
            "B": "OEAB",
            "C": "Abha",
            "D": "Abha",
            "E": "Saudi Arabia",
            "F": "SA",
            "G": "O",
            "H": "",
            "I": "",
            "J": "MDE"
        }
    ],
    "AHO": [
        {
            "B": "LIEA",
            "C": "ALGHERO",
            "D": "Alghero",
            "E": "Italy",
            "F": "IT",
            "G": "E",
            "H": "",
            "I": "",
            "J": "EUR"
        }
    ],
    "AIY": [
        {
            "B": "KAIY",
            "C": "ATLANTIC CITY - BADER",
            "D": "Atlantic City",
            "E": "USA",
            "F": "US",
            "G": "T",
            "H": "",
            "I": "",
            "J": "NOA"
        }
    ],
    "AJA": [
        {
            "B": "LFKJ",
            "C": "AJACCIO",
            "D": "Ajaccio",
            "E": "France",
            "F": "FR",
            "G": "E",
            "H": "",
            "I": "",
            "J": "EUR"
        }
    ],
    "AJF": [
        {
            "B": "OESK",
            "C": "al-Jouf",
            "D": "Al Jouf",
            "E": "Saudi Arabia",
            "F": "SA",
            "G": "O",
            "H": "",
            "I": "",
            "J": "MDE"
        }
    ],
    "AJL": [
        {
            "B": "VELP",
            "C": "LENGPUI",
            "D": "Lengpui",
            "E": "India",
            "F": "IN",
            "G": "O",
            "H": "",
            "I": "",
            "J": "SAS"
        }
    ],
    "AJR": [
        {
            "B": "ESNX",
            "C": "ARVIDSJAUR",
            "D": "Arvidsjaur",
            "E": "Sweden",
            "F": "SE",
            "G": "E",
            "H": "",
            "I": "",
            "J": "EUR"
        }
    ],
    "AJU": [
        {
            "B": "SBAR",
            "C": "Aracaju",
            "D": "Santa Maria",
            "E": "Brazil",
            "F": "BR",
            "G": "T",
            "H": "",
            "I": "",
            "J": "SOA"
        }
    ],
    "AKJ": [
        {
            "B": "RJEC",
            "C": "Asahikawa",
            "D": "Asahikawa",
            "E": "Japan",
            "F": "JP",
            "G": "O",
            "H": "",
            "I": "",
            "J": "JAK"
        }
    ],
    "AKL": [
        {
            "B": "NZAA",
            "C": "AUCKLAND INTERNATIONAL",
            "D": "Auckland",
            "E": "New Zealand",
            "F": "NZ",
            "G": "O",
            "H": "",
            "I": "",
            "J": "SWP"
        }
    ],
    "AKT": [
        {
            "B": "LCRA",
            "C": "AKROTIRI",
            "D": "Akrotiri",
            "E": "Cyprus",
            "F": "CY",
            "G": "E",
            "H": "",
            "I": "",
            "J": "EUR"
        }
    ],
    "AKU": [
        {
            "B": "ZWAK",
            "C": "AKSU",
            "D": "Aksu",
            "E": "Peoples Republic of China",
            "F": "CN",
            "G": "O",
            "H": "",
            "I": "",
            "J": "SEA"
        }
    ],
    "AKX": [
        {
            "B": "UATT",
            "C": "Aktyubinsk",
            "D": "Aktobe",
            "E": "Kazakhstan",
            "F": "KZ",
            "G": "O",
            "H": "",
            "I": "",
            "J": "SEA"
        }
    ],
    "ALA": [
        {
            "B": "UAAA",
            "C": "ALMATY",
            "D": "Almaty",
            "E": "Kazakhstan",
            "F": "KZ",
            "G": "O",
            "H": "",
            "I": "",
            "J": "SEA"
        }
    ],
    "ALB": [
        {
            "B": "KALB",
            "C": "ALBANY COUNTY",
            "D": "Albany",
            "E": "USA",
            "F": "US",
            "G": "T",
            "H": "",
            "I": "",
            "J": "NOA"
        }
    ],
    "ALC": [
        {
            "B": "LEAL",
            "C": "ALICANTE",
            "D": "Alicante",
            "E": "Spain",
            "F": "ES",
            "G": "E",
            "H": "",
            "I": "",
            "J": "EUR"
        }
    ],
    "ALF": [
        {
            "B": "ENAT",
            "C": "ALTA",
            "D": "ALTA",
            "E": "Norway",
            "F": "NO",
            "G": "E",
            "H": "",
            "I": "",
            "J": "EUR"
        }
    ],
    "ALG": [
        {
            "B": "DAAG",
            "C": "ALGERS BOUMEDIENE",
            "D": "Algiers",
            "E": "Algeria",
            "F": "DZ",
            "G": "O",
            "H": "",
            "I": "",
            "J": "AFR"
        }
    ],
    "ALH": [
        {
            "B": "YABA",
            "C": "Albany",
            "D": "Albany",
            "E": "Australia",
            "F": "AU",
            "G": "O",
            "H": "",
            "I": "",
            "J": "SWP"
        }
    ],
    "ALL": [
        {
            "B": "LIMG",
            "C": "ALBENGA",
            "D": "Albenga",
            "E": "Italy",
            "F": "IT",
            "G": "E",
            "H": "",
            "I": "",
            "J": "EUR"
        }
    ],
    "ALM": [
        {
            "B": "KALM",
            "C": "ALAMOGORDO AIRPORT",
            "D": "ALAMOGORDO WHITE SANDS AIRPORT",
            "E": "USA",
            "F": "US",
            "G": "T",
            "H": "",
            "I": "",
            "J": "NOA"
        }
    ],
    "ALO": [
        {
            "B": "KALO",
            "C": "Waterloo",
            "D": "Waterloo Regional",
            "E": "USA",
            "F": "US",
            "G": "T",
            "H": "",
            "I": "",
            "J": "NOA"
        }
    ],
    "ALP": [
        {
            "B": "OSAP",
            "C": "ALEPPO",
            "D": "Aleppo",
            "E": "Syrian Arab Republic",
            "F": "SY",
            "G": "O",
            "H": "",
            "I": "",
            "J": "MDE"
        }
    ],
    "ALT": [
        {
            "B": "SDWQ",
            "C": "ALENQUER",
            "D": "Alenquer",
            "E": "Brazil",
            "F": "BR",
            "G": "T",
            "H": "",
            "I": "",
            "J": "SOA"
        }
    ],
    "ALW": [
        {
            "B": "KALW",
            "C": "Walla Walla",
            "D": "Walla Walla Regional",
            "E": "USA",
            "F": "US",
            "G": "T",
            "H": "",
            "I": "",
            "J": "NOA"
        }
    ],
    "ALY": [
        {
            "B": "HEAX",
            "C": "ALEXANDRIA",
            "D": "Alexandria",
            "E": "Egypt",
            "F": "EG",
            "G": "O",
            "H": "",
            "I": "",
            "J": "MDE"
        }
    ],
    "AMA": [
        {
            "B": "KAMA",
            "C": "International",
            "D": "Rick Husband Intl",
            "E": "USA",
            "F": "US",
            "G": "T",
            "H": "",
            "I": "",
            "J": "NOA"
        }
    ],
    "AMD": [
        {
            "B": "VAAH",
            "C": "AHMADABAD",
            "D": "Ahmedabad",
            "E": "India",
            "F": "IN",
            "G": "O",
            "H": "",
            "I": "",
            "J": "SAS"
        }
    ],
    "AMH": [
        {
            "B": "HAAM",
            "C": "Arba Mintch",
            "D": "Arba Mintch",
            "E": "Ethiopia",
            "F": "ET",
            "G": "O",
            "H": "",
            "I": "",
            "J": "AFR"
        }
    ],
    "AMM": [
        {
            "B": "OJAI",
            "C": "QUEEN ALI INT AIRPORT",
            "D": "Amman",
            "E": "Jordan",
            "F": "JO",
            "G": "O",
            "H": "",
            "I": "",
            "J": "MDE"
        }
    ],
    "AMQ": [
        {
            "B": "WAPP",
            "C": "AMBON/PATTIMURA",
            "D": "Ambon/Pattimura",
            "E": "Indonesia",
            "F": "ID",
            "G": "O",
            "H": "",
            "I": "",
            "J": "SEA"
        }
    ],
    "AMS": [
        {
            "B": "EHAM",
            "C": "AMSTERDAM",
            "D": "Amsterdam",
            "E": "Netherlands",
            "F": "NL",
            "G": "E",
            "H": "",
            "I": "",
            "J": "EUR"
        }
    ],
    "ANC": [
        {
            "B": "PANC",
            "C": "ANCHORAGE",
            "D": "Anchorage",
            "E": "USA",
            "F": "US",
            "G": "T",
            "H": "",
            "I": "",
            "J": "NOA"
        }
    ],
    "ANE": [
        {
            "B": "LFJR",
            "C": "ANGERS - MARCE",
            "D": "Angers",
            "E": "France",
            "F": "FR",
            "G": "E",
            "H": "",
            "I": "",
            "J": "EUR"
        }
    ],
    "ANF": [
        {
            "B": "SCFA",
            "C": "Cerro Moreno",
            "D": "Cerro Moreno",
            "E": "Chile",
            "F": "CL",
            "G": "T",
            "H": "",
            "I": "",
            "J": "SOA"
        }
    ],
    "ANG": [
        {
            "B": "LFBU",
            "C": "ANGOULEME",
            "D": "Angouleme",
            "E": "France",
            "F": "FR",
            "G": "E",
            "H": "",
            "I": "",
            "J": "EUR"
        }
    ],
    "ANK": [
        {
            "B": "LTAD",
            "C": "ANKARA - ETIMESGUT",
            "D": "Ankara",
            "E": "Turkey",
            "F": "TR",
            "G": "E",
            "H": "",
            "I": "",
            "J": "EUR"
        }
    ],
    "ANR": [
        {
            "B": "EBAW",
            "C": "ANTWERP DEURNE AIRPORT",
            "D": "Antwerp",
            "E": "Belgium",
            "F": "BE",
            "G": "E",
            "H": "",
            "I": "",
            "J": "EUR"
        }
    ],
    "ANU": [
        {
            "B": "TAPA",
            "C": "V C BIRD INTERNATIONAL",
            "D": "Antiga",
            "E": "Antigua and Barbuda",
            "F": "AG",
            "G": "T",
            "H": "",
            "I": "",
            "J": "CAR"
        }
    ],
    "ANW": [
        {
            "B": "",
            "C": "",
            "D": "",
            "E": "UNKNOWN",
            "F": "",
            "G": "A",
            "H": "",
            "I": "",
            "J": ""
        }
    ],
    "ANX": [
        {
            "B": "ENAN",
            "C": "ANDENES",
            "D": "Andenes",
            "E": "Norway",
            "F": "NO",
            "G": "E",
            "H": "",
            "I": "",
            "J": "EUR"
        }
    ],
    "AOC": [
        {
            "B": "EDAC",
            "C": "ALTENBURG-NOBITZ",
            "D": "Altenburg",
            "E": "Germany",
            "F": "DE",
            "G": "E",
            "H": "",
            "I": "",
            "J": "EUR"
        }
    ],
    "AOG": [
        {
            "B": "ZYAS",
            "C": "ANSHAN",
            "D": "Anshan",
            "E": "Peoples Republic of China",
            "F": "CN",
            "G": "O",
            "H": "",
            "I": "",
            "J": "SEA"
        }
    ],
    "AOI": [
        {
            "B": "LIPY",
            "C": "ANCONA",
            "D": "Ancona",
            "E": "Italy",
            "F": "IT",
            "G": "E",
            "H": "",
            "I": "",
            "J": "EUR"
        }
    ],
    "AOJ": [
        {
            "B": "RJSA",
            "C": "Aomori",
            "D": "Aomori",
            "E": "Japan",
            "F": "JP",
            "G": "O",
            "H": "",
            "I": "",
            "J": "JAK"
        }
    ],
    "AOK": [
        {
            "B": "LGKP",
            "C": "KARPATHOS",
            "D": "Karpathos",
            "E": "Greece",
            "F": "GR",
            "G": "E",
            "H": "",
            "I": "",
            "J": "EUR"
        }
    ],
    "AOO": [
        {
            "B": "KAOO",
            "C": "Altoona",
            "D": "Blair County",
            "E": "USA",
            "F": "US",
            "G": "T",
            "H": "",
            "I": "",
            "J": "NOA"
        }
    ],
    "AOR": [
        {
            "B": "WMKA",
            "C": "Alor Setar",
            "D": "Sultan Abdul Halim",
            "E": "Malaysia",
            "F": "MY",
            "G": "O",
            "H": "",
            "I": "",
            "J": "SEA"
        }
    ],
    "AOT": [
        {
            "B": "LIMW",
            "C": "AOSTA",
            "D": "Aosta",
            "E": "Italy",
            "F": "IT",
            "G": "E",
            "H": "",
            "I": "",
            "J": "EUR"
        }
    ],
    "APA": [
        {
            "B": "KAPA",
            "C": "Dove Valley",
            "D": "Dove valley centennial",
            "E": "USA",
            "F": "US",
            "G": "T",
            "H": "",
            "I": "",
            "J": "NOA"
        }
    ],
    "APF": [
        {
            "B": "KAPF",
            "C": "NAPLES MUNICIPAL",
            "D": "NAPLES MUNICIPAL",
            "E": "USA",
            "F": "US",
            "G": "T",
            "H": "",
            "I": "",
            "J": "NOA"
        }
    ],
    "APL": [
        {
            "B": "FQNP",
            "C": "Nampula",
            "D": "Nampula",
            "E": "Mozambique",
            "F": "MZ",
            "G": "O",
            "H": "",
            "I": "",
            "J": "AFR"
        }
    ],
    "APN": [
        {
            "B": "KAPN",
            "C": "Alpena County Regional",
            "D": "Alpena County Regional",
            "E": "USA",
            "F": "US",
            "G": "T",
            "H": "",
            "I": "",
            "J": "NOA"
        }
    ],
    "APW": [
        {
            "B": "NSAP",
            "C": "Faleolo",
            "D": "Faleolo Intl",
            "E": "Samoa",
            "F": "WS",
            "G": "O",
            "H": "",
            "I": "",
            "J": "SWP"
        }
    ],
    "AQI": [
        {
            "B": "OEPA",
            "C": "Qaisumah",
            "D": "Hafar al Batin Airport",
            "E": "Saudi Arabia",
            "F": "SA",
            "G": "O",
            "H": "",
            "I": "",
            "J": "MDE"
        }
    ],
    "AQJ": [
        {
            "B": "OJAQ",
            "C": "KING HUSSAIN INT",
            "D": "Aqaba",
            "E": "Jordan",
            "F": "JO",
            "G": "O",
            "H": "",
            "I": "",
            "J": "MDE"
        }
    ],
    "AQP": [
        {
            "B": "SPQU",
            "C": "Rodriguez Ballon",
            "D": "Rodriguez Ballon Intl",
            "E": "Peru",
            "F": "PE",
            "G": "T",
            "H": "",
            "I": "",
            "J": "SOA"
        }
    ],
    "ARH": [
        {
            "B": "ULAA",
            "C": "TALAGI AIRPORT",
            "D": "TALAGI AIRPORT",
            "E": "RUSSIAN FEDERATION",
            "F": "RU1",
            "G": "O",
            "H": "",
            "I": "",
            "J": ""
        }
    ],
    "ARI": [
        {
            "B": "SCAR",
            "C": "ARICA/AP CHACALLUTA",
            "D": "Arica/Ap Chacalluta",
            "E": "Chile",
            "F": "CL",
            "G": "T",
            "H": "",
            "I": "",
            "J": "SOA"
        }
    ],
    "ARK": [
        {
            "B": "HTAR",
            "C": "ARUSHA",
            "D": "Arusha",
            "E": "Tanzania",
            "F": "TZ",
            "G": "O",
            "H": "",
            "I": "",
            "J": "AFR"
        }
    ],
    "ARM": [
        {
            "B": "YARM",
            "C": "Armidale",
            "D": "Armidale",
            "E": "Australia",
            "F": "AU",
            "G": "O",
            "H": "",
            "I": "",
            "J": "SWP"
        }
    ],
    "ARN": [
        {
            "B": "ESSA",
            "C": "STOCKHOLM - ARLANDA",
            "D": "Stockholm",
            "E": "Sweden",
            "F": "SE",
            "G": "E",
            "H": "",
            "I": "",
            "J": "EUR"
        }
    ],
    "ARR": [
        {
            "B": "SAVR",
            "C": "ALTO RIO SENGUERR",
            "D": "Alto Rio Senguerr",
            "E": "Argentina",
            "F": "AR",
            "G": "T",
            "H": "",
            "I": "",
            "J": "SOA"
        }
    ],
    "ART": [
        {
            "B": "KART",
            "C": "Watertown",
            "D": "Watertown International",
            "E": "USA",
            "F": "US",
            "G": "T",
            "H": "",
            "I": "",
            "J": "NOA"
        }
    ],
    "ARU": [
        {
            "B": "SBAU",
            "C": "ARACATUBA/ARACATUBA",
            "D": "Aracatuba/Aracatuba",
            "E": "Brazil",
            "F": "BR",
            "G": "T",
            "H": "",
            "I": "",
            "J": "SOA"
        }
    ],
    "ARW": [
        {
            "B": "LRAR",
            "C": "ARAD",
            "D": "Arad",
            "E": "Romania",
            "F": "RO",
            "G": "E",
            "H": "",
            "I": "",
            "J": "EUR"
        }
    ],
    "ASB": [
        {
            "B": "UTAA",
            "C": "ASHGABAT",
            "D": "Ashgabat",
            "E": "Turkmenistan",
            "F": "TM",
            "G": "O",
            "H": "",
            "I": "",
            "J": "SEA"
        }
    ],
    "ASE": [
        {
            "B": "KASE",
            "C": "ASPEN",
            "D": "Aspen",
            "E": "USA",
            "F": "US",
            "G": "T",
            "H": "",
            "I": "",
            "J": "NOA"
        }
    ],
    "ASF": [
        {
            "B": "URWA",
            "C": "ASTRAKHAN",
            "D": "Astrakhan",
            "E": "Russian Federation",
            "F": "RU",
            "G": "E",
            "H": "",
            "I": "",
            "J": "EUR"
        }
    ],
    "ASJ": [
        {
            "B": "RJKA",
            "C": "AMAMI",
            "D": "Amami",
            "E": "Japan",
            "F": "JP",
            "G": "O",
            "H": "",
            "I": "",
            "J": "JAK"
        }
    ],
    "ASM": [
        {
            "B": "HHAS",
            "C": "ASMARA",
            "D": "Asmara",
            "E": "Eritrea",
            "F": "ER",
            "G": "O",
            "H": "",
            "I": "",
            "J": "AFR"
        }
    ],
    "ASP": [
        {
            "B": "YBAS",
            "C": "ALICE SPRINGS",
            "D": "Alice Springs",
            "E": "Australia",
            "F": "AU",
            "G": "O",
            "H": "",
            "I": "",
            "J": "SWP"
        }
    ],
    "ASR": [
        {
            "B": "LTAU",
            "C": "KAYSERI",
            "D": "Kayseri",
            "E": "Turkey",
            "F": "TR",
            "G": "E",
            "H": "",
            "I": "",
            "J": "EUR"
        }
    ],
    "ASU": [
        {
            "B": "SGAS",
            "C": "Silvio Pettirossi",
            "D": "Silvio Pettirossi Intl",
            "E": "Paraguay",
            "F": "PY",
            "G": "T",
            "H": "",
            "I": "",
            "J": "SOA"
        }
    ],
    "ASW": [
        {
            "B": "HESN",
            "C": "ASWAN",
            "D": "Aswan",
            "E": "Egypt",
            "F": "EG",
            "G": "O",
            "H": "",
            "I": "",
            "J": "MDE"
        }
    ],
    "ATC": [
        {
            "B": "LSMF",
            "C": "MOLLIS AIRPORT",
            "D": "MOLLIS AIRPORT",
            "E": "Switzerland",
            "F": "CH",
            "G": "E",
            "H": "",
            "I": "",
            "J": "EUR"
        }
    ],
    "ATH": [
        {
            "B": "LGAV",
            "C": "ATHENS VENEZELOS",
            "D": "Athens",
            "E": "Greece",
            "F": "GR",
            "G": "E",
            "H": "",
            "I": "",
            "J": "EUR"
        }
    ],
    "ATL": [
        {
            "B": "KATL",
            "C": "ATLANTA",
            "D": "Atlanta",
            "E": "USA",
            "F": "US",
            "G": "T",
            "H": "",
            "I": "",
            "J": "NOA"
        }
    ],
    "ATQ": [
        {
            "B": "VIAR",
            "C": "RAJA SANSI",
            "D": "Raja sansi",
            "E": "India",
            "F": "IN",
            "G": "O",
            "H": "",
            "I": "",
            "J": "SAS"
        }
    ],
    "ATW": [
        {
            "B": "KATW",
            "C": "Appleton, Wisconsin",
            "D": "Outagamie County Regional",
            "E": "USA",
            "F": "US",
            "G": "T",
            "H": "",
            "I": "",
            "J": "NOA"
        }
    ],
    "ATZ": [
        {
            "B": "HEAT",
            "C": "ASSIUT",
            "D": "Assiut",
            "E": "Egypt",
            "F": "EG",
            "G": "O",
            "H": "",
            "I": "",
            "J": "MDE"
        }
    ],
    "AUA": [
        {
            "B": "TNCA",
            "C": "ARUBA REINA BEATRIX",
            "D": "Aruba",
            "E": "Aruba",
            "F": "AW",
            "G": "T",
            "H": "",
            "I": "",
            "J": "CAR"
        }
    ],
    "AUF": [
        {
            "B": "LFLA",
            "C": "AUXERRE-MONETEAU",
            "D": "Auxerre",
            "E": "France",
            "F": "FR",
            "G": "E",
            "H": "",
            "I": "",
            "J": "EUR"
        }
    ],
    "AUG": [
        {
            "B": "KAUG",
            "C": "Augusta State",
            "D": "Augusta State",
            "E": "USA",
            "F": "US",
            "G": "T",
            "H": "",
            "I": "",
            "J": "NOA"
        }
    ],
    "AUH": [
        {
            "B": "OMAA",
            "C": "ABU DHABI",
            "D": "Abu Dhabi",
            "E": "United Arab Emirates",
            "F": "AE",
            "G": "O",
            "H": "",
            "I": "",
            "J": "MDE"
        }
    ],
    "AUR": [
        {
            "B": "LFLW",
            "C": "AURILLAC",
            "D": "Aurillac",
            "E": "France",
            "F": "FR",
            "G": "E",
            "H": "",
            "I": "",
            "J": "EUR"
        }
    ],
    "AUS": [
        {
            "B": "KAUS",
            "C": "AUSTIN",
            "D": "Austin",
            "E": "USA",
            "F": "US",
            "G": "T",
            "H": "",
            "I": "",
            "J": "NOA"
        }
    ],
    "AVB": [
        {
            "B": "LIPA",
            "C": "AVIANO",
            "D": "Aviano",
            "E": "Italy",
            "F": "IT",
            "G": "E",
            "H": "",
            "I": "",
            "J": "EUR"
        }
    ],
    "AVL": [
        {
            "B": "KAVL",
            "C": "ASHEVILLE REGIONAL",
            "D": "ASHEVILLE",
            "E": "USA",
            "F": "US",
            "G": "T",
            "H": "",
            "I": "",
            "J": "NOA"
        }
    ],
    "AVN": [
        {
            "B": "LFMV",
            "C": "AVIGNON",
            "D": "Avignon",
            "E": "France",
            "F": "FR",
            "G": "E",
            "H": "",
            "I": "",
            "J": "EUR"
        }
    ],
    "AVP": [
        {
            "B": "KAVP",
            "C": "WILKES-BARRE/SCRANTON",
            "D": "WILKES-BARRE/SCRANTON",
            "E": "USA",
            "F": "US",
            "G": "T",
            "H": "",
            "I": "",
            "J": "NOA"
        }
    ],
    "AVW": [
        {
            "B": "KAVQ",
            "C": "MARANA",
            "D": "Marana",
            "E": "USA",
            "F": "US",
            "G": "T",
            "H": "",
            "I": "",
            "J": "NOA"
        }
    ],
    "AWZ": [
        {
            "B": "OIAW",
            "C": "Ahwaz",
            "D": "Ahwaz",
            "E": "Iran",
            "F": "IR",
            "G": "O",
            "H": "",
            "I": "",
            "J": "MDE"
        }
    ],
    "AXA": [
        {
            "B": "TQPF",
            "C": "WALLBLAKE",
            "D": "Wallblake",
            "E": "Anguilla",
            "F": "AI",
            "G": "T",
            "H": "",
            "I": "",
            "J": "CAR"
        }
    ],
    "AXD": [
        {
            "B": "LGAL",
            "C": "ALEXANDROUPOLIS",
            "D": "Alexandroupolis",
            "E": "Greece",
            "F": "GR",
            "G": "E",
            "H": "",
            "I": "",
            "J": "EUR"
        }
    ],
    "AXM": [
        {
            "B": "SKAR",
            "C": "Armenia",
            "D": "El Eden International",
            "E": "Colombia",
            "F": "CO",
            "G": "T",
            "H": "",
            "I": "",
            "J": "SOA"
        }
    ],
    "AXT": [
        {
            "B": "RJSK",
            "C": "AKITA",
            "D": "Akita",
            "E": "Japan",
            "F": "JP",
            "G": "O",
            "H": "",
            "I": "",
            "J": "JAK"
        }
    ],
    "AXU": [
        {
            "B": "HAAX",
            "C": "Axum",
            "D": "Axum",
            "E": "Ethiopia",
            "F": "ET",
            "G": "O",
            "H": "",
            "I": "",
            "J": "AFR"
        }
    ],
    "AYQ": [
        {
            "B": "YAYE",
            "C": "AYERS ROCK",
            "D": "Ayers Rock",
            "E": "Australia",
            "F": "AU",
            "G": "O",
            "H": "",
            "I": "",
            "J": "SWP"
        }
    ],
    "AYT": [
        {
            "B": "LTAI",
            "C": "ANTALYA",
            "D": "Antalya",
            "E": "Turkey",
            "F": "TR",
            "G": "E",
            "H": "",
            "I": "",
            "J": "EUR"
        }
    ],
    "AZI": [
        {
            "B": "OMAD",
            "C": "ABU DHABI BATEEN  MIL",
            "D": "Abu dhabi",
            "E": "United Arab Emirates",
            "F": "AE",
            "G": "O",
            "H": "",
            "I": "",
            "J": "MDE"
        }
    ],
    "AZO": [
        {
            "B": "KAZO",
            "C": "KALAMAZOO",
            "D": "Kalamazoo",
            "E": "USA",
            "F": "US",
            "G": "T",
            "H": "",
            "I": "",
            "J": "NOA"
        }
    ],
    "AZP": [
        {
            "B": "MMJC",
            "C": "ATIZAPAN",
            "D": "Atizapan",
            "E": "Mexico",
            "F": "MX",
            "G": "T",
            "H": "",
            "I": "",
            "J": "CEM"
        }
    ],
    "AZR": [
        {
            "B": "DAUA",
            "C": "Adrar Airport",
            "D": "Adrar Airport",
            "E": "Algeria",
            "F": "DZ",
            "G": "O",
            "H": "",
            "I": "",
            "J": "AFR"
        }
    ],
    "AZS": [
        {
            "B": "MDCY",
            "C": "SAMANÁ EL CATEY",
            "D": "Samaná El Catey",
            "E": "Dominican Republic",
            "F": "DO",
            "G": "T",
            "H": "",
            "I": "",
            "J": "CAR"
        }
    ],
    "B01": [
        {
            "B": "EBCV",
            "C": "CHIEVRES",
            "D": "Chievres",
            "E": "Belgium",
            "F": "BE",
            "G": "E",
            "H": "",
            "I": "",
            "J": "EUR"
        }
    ],
    "B02": [
        {
            "B": "EBMB",
            "C": "BRUSSELS MELSBROEK",
            "D": "Brussells Melsbroek",
            "E": "Belgium",
            "F": "BE",
            "G": "E",
            "H": "",
            "I": "",
            "J": "EUR"
        }
    ],
    "B03": [
        {
            "B": "EBBT",
            "C": "BRASSCHAAT MIL",
            "D": "Brasschaat mil",
            "E": "Belgium",
            "F": "BE",
            "G": "E",
            "H": "",
            "I": "",
            "J": "EUR"
        }
    ],
    "B04": [
        {
            "B": "EBFS",
            "C": "FLORENNES MIL",
            "D": "Florennes mil",
            "E": "Belgium",
            "F": "BE",
            "G": "E",
            "H": "",
            "I": "",
            "J": "EUR"
        }
    ],
    "B05": [
        {
            "B": "EBGB",
            "C": "GRIMBERGEN",
            "D": "Grimbergen",
            "E": "Belgium",
            "F": "BE",
            "G": "E",
            "H": "",
            "I": "",
            "J": "EUR"
        }
    ],
    "B06": [
        {
            "B": "EBMI",
            "C": "BRUSSELS MIL",
            "D": "Brussels mil",
            "E": "Belgium",
            "F": "BE",
            "G": "E",
            "H": "",
            "I": "",
            "J": "EUR"
        }
    ],
    "B07": [
        {
            "B": "EBZW",
            "C": "GENK",
            "D": "Genk",
            "E": "Belgium",
            "F": "BE",
            "G": "E",
            "H": "",
            "I": "",
            "J": "EUR"
        }
    ],
    "B65": [
        {
            "B": "EBBE",
            "C": "BEAUVECHAINE",
            "D": "Beauvechaine",
            "E": "Belgium",
            "F": "BE",
            "G": "E",
            "H": "",
            "I": "",
            "J": "EUR"
        }
    ],
    "BAE": [
        {
            "B": "LFMR",
            "C": "BARCELONNETTE",
            "D": "Barcelonnette",
            "E": "France",
            "F": "FR",
            "G": "E",
            "H": "",
            "I": "",
            "J": "EUR"
        }
    ],
    "BAF": [
        {
            "B": "KBAF",
            "C": "WESTFIELD / BARNES MUNICIPAL",
            "D": "Westfield",
            "E": "USA",
            "F": "US",
            "G": "T",
            "H": "",
            "I": "",
            "J": "NOA"
        }
    ],
    "BAH": [
        {
            "B": "OBBI",
            "C": "BAHRAIN INTERNATIONAL",
            "D": "Bahrain",
            "E": "Bahrain",
            "F": "BH",
            "G": "O",
            "H": "",
            "I": "",
            "J": "MDE"
        }
    ],
    "BAL": [
        {
            "B": "LTCJ",
            "C": "BATMAN AIRPORT",
            "D": "BATMAN AIRPORT",
            "E": "Turkey",
            "F": "TR",
            "G": "E",
            "H": "",
            "I": "",
            "J": "EUR"
        }
    ],
    "BAQ": [
        {
            "B": "SKEC",
            "C": "E Cortissoz",
            "D": "Ernesto Cortissoz International",
            "E": "Colombia",
            "F": "CO",
            "G": "T",
            "H": "",
            "I": "",
            "J": "SOA"
        }
    ],
    "BAV": [
        {
            "B": "ZBOW",
            "C": "Baotou",
            "D": "Erliban",
            "E": "Peoples Republic of China",
            "F": "CN",
            "G": "O",
            "H": "",
            "I": "",
            "J": "SEA"
        }
    ],
    "BAX": [
        {
            "B": "UNBB",
            "C": "BARNAUL",
            "D": "Barnaul",
            "E": "Russian Federation",
            "F": "RU",
            "G": "E",
            "H": "",
            "I": "",
            "J": "EUR"
        }
    ],
    "BAY": [
        {
            "B": "LRBM",
            "C": "BAIA MARE",
            "D": "Baia Mare",
            "E": "Romania",
            "F": "RO",
            "G": "E",
            "H": "",
            "I": "",
            "J": "EUR"
        }
    ],
    "BBI": [
        {
            "B": "VEBS",
            "C": "Bhubaneswar",
            "D": "Biju Patnaik",
            "E": "India",
            "F": "IN",
            "G": "O",
            "H": "",
            "I": "",
            "J": "SAS"
        }
    ],
    "BBJ": [
        {
            "B": "EDRB",
            "C": "BITBURG",
            "D": "Bitburg",
            "E": "Germany",
            "F": "DE",
            "G": "E",
            "H": "",
            "I": "",
            "J": "EUR"
        }
    ],
    "BBK": [
        {
            "B": "FBKE",
            "C": "Kasane",
            "D": "Kasane",
            "E": "Botswana",
            "F": "BW",
            "G": "O",
            "H": "",
            "I": "",
            "J": "AFR"
        }
    ],
    "BBP": [
        {
            "B": "EGHJ",
            "C": "BEMBRIDGE",
            "D": "Bembridge",
            "E": "United Kingdom",
            "F": "GB",
            "G": "U",
            "H": "",
            "I": "",
            "J": "EUR"
        }
    ],
    "BBS": [
        {
            "B": "EGLK",
            "C": "BLACKBUSHE",
            "D": "Blackbushe",
            "E": "United Kingdom",
            "F": "GB",
            "G": "U",
            "H": "",
            "I": "",
            "J": "EUR"
        }
    ],
    "BBU": [
        {
            "B": "LRBS",
            "C": "BUCHAREST - BANEASA",
            "D": "Bucharest",
            "E": "Romania",
            "F": "RO",
            "G": "E",
            "H": "",
            "I": "",
            "J": "EUR"
        }
    ],
    "BCD": [
        {
            "B": "RPVB",
            "C": "Bacolod",
            "D": "Silay International",
            "E": "Philippines",
            "F": "PH",
            "G": "O",
            "H": "",
            "I": "",
            "J": "SEA"
        }
    ],
    "BCI": [
        {
            "B": "YBAR",
            "C": "Barcaldine",
            "D": "Barcaldine",
            "E": "Australia",
            "F": "AU",
            "G": "O",
            "H": "",
            "I": "",
            "J": "SWP"
        }
    ],
    "BCM": [
        {
            "B": "LRBC",
            "C": "BACAU",
            "D": "Bacau",
            "E": "Romania",
            "F": "RO",
            "G": "E",
            "H": "",
            "I": "",
            "J": "EUR"
        }
    ],
    "BCN": [
        {
            "B": "LEBL",
            "C": "BARCELONA",
            "D": "Barcelona",
            "E": "Spain",
            "F": "ES",
            "G": "E",
            "H": "",
            "I": "",
            "J": "EUR"
        }
    ],
    "BCO": [
        {
            "B": "HABC",
            "C": "BACO",
            "D": "Baco",
            "E": "Ethiopia",
            "F": "ET",
            "G": "O",
            "H": "",
            "I": "",
            "J": "AFR"
        }
    ],
    "BCT": [
        {
            "B": "KBCT",
            "C": "BOCA RATON",
            "D": "Boca raton",
            "E": "USA",
            "F": "US",
            "G": "T",
            "H": "",
            "I": "",
            "J": "NOA"
        }
    ],
    "BD1": [
        {
            "B": "TBPO",
            "C": "BRIDGETOWN",
            "D": "Bridgetown",
            "E": "Barbados",
            "F": "BB",
            "G": "T",
            "H": "",
            "I": "",
            "J": "CAR"
        }
    ],
    "BDA": [
        {
            "B": "TXKF",
            "C": "BERMUDA INTERNATIONAL",
            "D": "Bermuda",
            "E": "Bermuda",
            "F": "BM",
            "G": "T",
            "H": "",
            "I": "",
            "J": "CAR"
        }
    ],
    "BDB": [
        {
            "B": "YBUD",
            "C": "Bundaberg",
            "D": "Bundaberg",
            "E": "Australia",
            "F": "AU",
            "G": "O",
            "H": "",
            "I": "",
            "J": "SWP"
        }
    ],
    "BDH": [
        {
            "B": "KBDH",
            "C": "Willmar Municipal",
            "D": "Willmar Municipal",
            "E": "USA",
            "F": "US",
            "G": "T",
            "H": "",
            "I": "",
            "J": "NOA"
        }
    ],
    "BDL": [
        {
            "B": "KBDL",
            "C": "HARTFORD",
            "D": "Hartford",
            "E": "USA",
            "F": "US",
            "G": "T",
            "H": "",
            "I": "",
            "J": "NOA"
        }
    ],
    "BDM": [
        {
            "B": "LTBG",
            "C": "BALIKESIR - BANDIRMA",
            "D": "Balikesir Bandirma",
            "E": "Turkey",
            "F": "TR",
            "G": "E",
            "H": "",
            "I": "",
            "J": "EUR"
        }
    ],
    "BDO": [
        {
            "B": "WICC",
            "C": "Husein Sastranegara",
            "D": "Husein Sastranegara",
            "E": "Indonesia",
            "F": "ID",
            "G": "O",
            "H": "",
            "I": "",
            "J": "SEA"
        }
    ],
    "BDQ": [
        {
            "B": "VABO",
            "C": "Vadodara",
            "D": "Vadodara(Baroda)",
            "E": "India",
            "F": "IN",
            "G": "O",
            "H": "",
            "I": "",
            "J": "SAS"
        }
    ],
    "BDS": [
        {
            "B": "LIBR",
            "C": "BRINDISI",
            "D": "Brindisi",
            "E": "Italy",
            "F": "IT",
            "G": "E",
            "H": "",
            "I": "",
            "J": "EUR"
        }
    ],
    "BDU": [
        {
            "B": "ENDU",
            "C": "BARDUFOSS",
            "D": "Bardufoss",
            "E": "Norway",
            "F": "NO",
            "G": "E",
            "H": "",
            "I": "",
            "J": "EUR"
        }
    ],
    "BEB": [
        {
            "B": "EGPL",
            "C": "BENBECULA",
            "D": "Benbecula",
            "E": "United Kingdom",
            "F": "GB",
            "G": "U",
            "H": "",
            "I": "",
            "J": "EUR"
        }
    ],
    "BED": [
        {
            "B": "KBED",
            "C": "BEDFORD HANSCOM FIELD",
            "D": "Bedford",
            "E": "USA",
            "F": "US",
            "G": "T",
            "H": "",
            "I": "",
            "J": "NOA"
        }
    ],
    "BEG": [
        {
            "B": "LYBE",
            "C": "BELGRADE",
            "D": "Belgrade",
            "E": "SERBIA",
            "F": "CS",
            "G": "E",
            "H": "",
            "I": "",
            "J": "EUR"
        }
    ],
    "BEH": [
        {
            "B": "KBEH",
            "C": "SW MICHIGAN REGIONAL",
            "D": "SW MICHIGAN REGIONAL",
            "E": "USA",
            "F": "US",
            "G": "T",
            "H": "",
            "I": "",
            "J": "NOA"
        }
    ],
    "BEI": [
        {
            "B": "HABE",
            "C": "BEICA",
            "D": "Beica",
            "E": "Ethiopia",
            "F": "ET",
            "G": "O",
            "H": "",
            "I": "",
            "J": "AFR"
        }
    ],
    "BEL": [
        {
            "B": "SBBE",
            "C": "VAL DE CAN - BELEM",
            "D": "Belem",
            "E": "Brazil",
            "F": "BR",
            "G": "T",
            "H": "",
            "I": "",
            "J": "SOA"
        }
    ],
    "BEN": [
        {
            "B": "HLLB",
            "C": "BENGHAZI BENINA INTERNATIONAL",
            "D": "Benghazi",
            "E": "Libya",
            "F": "LY",
            "G": "O",
            "H": "",
            "I": "",
            "J": "AFR"
        }
    ],
    "BEQ": [
        {
            "B": "EGXH",
            "C": "HONINGTON",
            "D": "Honington",
            "E": "United Kingdom",
            "F": "GB",
            "G": "U",
            "H": "",
            "I": "",
            "J": "EUR"
        }
    ],
    "BER": [
        {
            "B": "EDDB",
            "C": "BERLIN BRANDENBURG",
            "D": "Berlin",
            "E": "Germany",
            "F": "DE",
            "G": "E",
            "H": "",
            "I": "Europe/Berlin",
            "J": "EUR"
        }
    ],
    "BES": [
        {
            "B": "LFRB",
            "C": "BREST",
            "D": "Brest",
            "E": "France",
            "F": "FR",
            "G": "E",
            "H": "",
            "I": "",
            "J": "EUR"
        }
    ],
    "BEV": [
        {
            "B": "LLBS",
            "C": "BEER-SHEBA - TEYMAN AI",
            "D": "Beer Sheba",
            "E": "Israel",
            "F": "IL",
            "G": "O",
            "H": "",
            "I": "",
            "J": "MDE"
        }
    ],
    "BEW": [
        {
            "B": "FQBR",
            "C": "BEIRA",
            "D": "Beira",
            "E": "Mozambique",
            "F": "MZ",
            "G": "O",
            "H": "",
            "I": "",
            "J": "AFR"
        }
    ],
    "BEX": [
        {
            "B": "EGUB",
            "C": "BENSON",
            "D": "Benson",
            "E": "United Kingdom",
            "F": "GB",
            "G": "U",
            "H": "",
            "I": "",
            "J": "EUR"
        }
    ],
    "BEY": [
        {
            "B": "OLBA",
            "C": "BEIRUT",
            "D": "Beirut",
            "E": "Lebanon",
            "F": "LB",
            "G": "O",
            "H": "",
            "I": "",
            "J": "MDE"
        }
    ],
    "BFD": [
        {
            "B": "KBFD",
            "C": "BRADFORD",
            "D": "Bradford",
            "E": "USA",
            "F": "US",
            "G": "T",
            "H": "",
            "I": "",
            "J": "NOA"
        }
    ],
    "BFE": [
        {
            "B": "EDLI",
            "C": "BIELEFELD",
            "D": "Bielefeld",
            "E": "Germany",
            "F": "DE",
            "G": "E",
            "H": "",
            "I": "",
            "J": "EUR"
        }
    ],
    "BFF": [
        {
            "B": "KBFF",
            "C": "SCOTTSBLUFF",
            "D": "Scottsbluff/William B.Heilig Field",
            "E": "USA",
            "F": "US",
            "G": "T",
            "H": "",
            "I": "",
            "J": "NOA"
        }
    ],
    "BFI": [
        {
            "B": "KBFI",
            "C": "BOEING FIELD INTERNATIONAL",
            "D": "Boeing field",
            "E": "USA",
            "F": "US",
            "G": "T",
            "H": "",
            "I": "",
            "J": "NOA"
        }
    ],
    "BFL": [
        {
            "B": "KBFL",
            "C": "Meadows Field",
            "D": "Meadows Field",
            "E": "USA",
            "F": "US",
            "G": "T",
            "H": "",
            "I": "",
            "J": "NOA"
        }
    ],
    "BFN": [
        {
            "B": "FABL",
            "C": "BLOEMFONTEIN",
            "D": "Bloemfontein",
            "E": "South Africa",
            "F": "ZA",
            "G": "O",
            "H": "",
            "I": "",
            "J": "AFR"
        }
    ],
    "BFS": [
        {
            "B": "EGAA",
            "C": "BELFAST - ALDERGROVE",
            "D": "Belfast",
            "E": "United Kingdom",
            "F": "GB",
            "G": "U",
            "H": "",
            "I": "",
            "J": "EUR"
        }
    ],
    "BG1": [
        {
            "B": "BGDH",
            "C": "DANMARKSHAVN",
            "D": "Danmarkshavn",
            "E": "Greenland",
            "F": "GL",
            "G": "E",
            "H": "",
            "I": "",
            "J": "EUR"
        }
    ],
    "BGA": [
        {
            "B": "SKBG",
            "C": "Bucaramanga",
            "D": "Palonegro International",
            "E": "Colombia",
            "F": "CO",
            "G": "T",
            "H": "",
            "I": "",
            "J": "SOA"
        }
    ],
    "BGC": [
        {
            "B": "LPBG",
            "C": "BRAGANCA",
            "D": "Braganca",
            "E": "Portugal",
            "F": "PT",
            "G": "E",
            "H": "",
            "I": "",
            "J": "EUR"
        }
    ],
    "BGF": [
        {
            "B": "FEFF",
            "C": "Bangui",
            "D": "Mpoko Intl",
            "E": "Central African Republic",
            "F": "CF",
            "G": "O",
            "H": "",
            "I": "",
            "J": "AFR"
        }
    ],
    "BGI": [
        {
            "B": "TBPB",
            "C": "BRIDGETOWN GRANTLY ADAMS",
            "D": "Barbados",
            "E": "Barbados",
            "F": "BB",
            "G": "T",
            "H": "",
            "I": "",
            "J": "CAR"
        }
    ],
    "BGM": [
        {
            "B": "KBGM",
            "C": "Binghamton",
            "D": "Greater Binghamton",
            "E": "USA",
            "F": "US",
            "G": "T",
            "H": "",
            "I": "",
            "J": "NOA"
        }
    ],
    "BGN": [
        {
            "B": "UESG",
            "C": "BELAYA GORA",
            "D": "Belaya Gora",
            "E": "Russian Federation",
            "F": "RU",
            "G": "E",
            "H": "",
            "I": "",
            "J": "EUR"
        }
    ],
    "BGO": [
        {
            "B": "ENBR",
            "C": "BERGEN FLESLAND",
            "D": "Bergen",
            "E": "Norway",
            "F": "NO",
            "G": "E",
            "H": "",
            "I": "",
            "J": "EUR"
        }
    ],
    "BGR": [
        {
            "B": "KBGR",
            "C": "BANGOR",
            "D": "Bangor",
            "E": "USA",
            "F": "US",
            "G": "T",
            "H": "",
            "I": "",
            "J": "NOA"
        }
    ],
    "BGY": [
        {
            "B": "LIME",
            "C": "BERGAMO - ORIO AL SERI",
            "D": "Milan BGY",
            "E": "Italy",
            "F": "IT",
            "G": "E",
            "H": "",
            "I": "",
            "J": "EUR"
        }
    ],
    "BGZ": [
        {
            "B": "LPBR",
            "C": "BRAGA",
            "D": "Braga",
            "E": "Portugal",
            "F": "PT",
            "G": "E",
            "H": "",
            "I": "",
            "J": "EUR"
        }
    ],
    "BHB": [
        {
            "B": "KBHB",
            "C": "Bar Harbor, Maine",
            "D": "Hancock County",
            "E": "USA",
            "F": "US",
            "G": "T",
            "H": "",
            "I": "",
            "J": "NOA"
        }
    ],
    "BHD": [
        {
            "B": "EGAC",
            "C": "BELFAST CITY",
            "D": "Belfast City",
            "E": "United Kingdom",
            "F": "GB",
            "G": "U",
            "H": "",
            "I": "",
            "J": "EUR"
        }
    ],
    "BHE": [
        {
            "B": "NZBM",
            "C": "Blenheim",
            "D": "Woodbourne",
            "E": "New Zealand",
            "F": "NZ",
            "G": "O",
            "H": "",
            "I": "",
            "J": "SWP"
        }
    ],
    "BHI": [
        {
            "B": "SAZB",
            "C": "Comandante",
            "D": "Comandante Espora",
            "E": "Argentina",
            "F": "AR",
            "G": "T",
            "H": "",
            "I": "",
            "J": "SOA"
        }
    ],
    "BHJ": [
        {
            "B": "VABJ",
            "C": "Rudra Mata",
            "D": "Shyamji Krishna Verma",
            "E": "India",
            "F": "IN",
            "G": "O",
            "H": "",
            "I": "",
            "J": "SAS"
        }
    ],
    "BHK": [
        {
            "B": "UTSB",
            "C": "BUKHARA INTL",
            "D": "BUKHARA INTERNATIONAL AIRPORT",
            "E": "Uzbekistan",
            "F": "UZ",
            "G": "O",
            "H": "",
            "I": "",
            "J": "SEA"
        }
    ],
    "BHM": [
        {
            "B": "KBHM",
            "C": "BIRMINGHAM - US",
            "D": "Birmingham",
            "E": "USA",
            "F": "US",
            "G": "T",
            "H": "",
            "I": "",
            "J": "NOA"
        }
    ],
    "BHO": [
        {
            "B": "VABP",
            "C": "Bhopal",
            "D": "Raja Bhoj",
            "E": "India",
            "F": "IN",
            "G": "O",
            "H": "",
            "I": "",
            "J": "SAS"
        }
    ],
    "BHR": [
        {
            "B": "VNBP",
            "C": "BHARATPUR",
            "D": "Bharatpur",
            "E": "Nepal",
            "F": "NP",
            "G": "O",
            "H": "",
            "I": "",
            "J": "SEA"
        }
    ],
    "BHU": [
        {
            "B": "VABV",
            "C": "Bhavnagar",
            "D": "Bhavnagar",
            "E": "India",
            "F": "IN",
            "G": "O",
            "H": "",
            "I": "",
            "J": "SAS"
        }
    ],
    "BHX": [
        {
            "B": "EGBB",
            "C": "BIRMINGHAM - UK",
            "D": "Birmingham",
            "E": "United Kingdom",
            "F": "GB",
            "G": "U",
            "H": "",
            "I": "",
            "J": "EUR"
        }
    ],
    "BHY": [
        {
            "B": "ZGBH",
            "C": "Beihai",
            "D": "Fucheng",
            "E": "Peoples Republic of China",
            "F": "CN",
            "G": "O",
            "H": "",
            "I": "",
            "J": "SEA"
        }
    ],
    "BIA": [
        {
            "B": "LFKB",
            "C": "BASTIA",
            "D": "Bastia",
            "E": "France",
            "F": "FR",
            "G": "E",
            "H": "",
            "I": "",
            "J": "EUR"
        }
    ],
    "BIF": [
        {
            "B": "KBIF",
            "C": "EL PASO",
            "D": "Biggs army airfield fort bliss",
            "E": "USA",
            "F": "US",
            "G": "T",
            "H": "",
            "I": "",
            "J": "NOA"
        }
    ],
    "BIK": [
        {
            "B": "WABB",
            "C": "FRANS KAISEPO AIRPORT",
            "D": "FRANS KAISEPO AIRPORT",
            "E": "Indonesia",
            "F": "",
            "G": "O",
            "H": "",
            "I": "",
            "J": ""
        }
    ],
    "BIL": [
        {
            "B": "KBIL",
            "C": "BILLINGS LOGAN INTERNATIONAL",
            "D": "Billings",
            "E": "USA",
            "F": "US",
            "G": "T",
            "H": "",
            "I": "",
            "J": "NOA"
        }
    ],
    "BIO": [
        {
            "B": "LEBB",
            "C": "BILBAO",
            "D": "Bilbao",
            "E": "Spain",
            "F": "ES",
            "G": "E",
            "H": "",
            "I": "",
            "J": "EUR"
        }
    ],
    "BIQ": [
        {
            "B": "LFBZ",
            "C": "BIARRITZ - BAYONNE - ANGLET",
            "D": "Biarritz",
            "E": "France",
            "F": "FR",
            "G": "E",
            "H": "",
            "I": "",
            "J": "EUR"
        }
    ],
    "BIS": [
        {
            "B": "KBIS",
            "C": "Bismarck",
            "D": "Bismarck Municipal",
            "E": "USA",
            "F": "US",
            "G": "T",
            "H": "",
            "I": "",
            "J": "NOA"
        }
    ],
    "BJF": [
        {
            "B": "ENBS",
            "C": "Batsfjord",
            "D": "Batsfjord",
            "E": "Norway",
            "F": "NO",
            "G": "E",
            "H": "",
            "I": "",
            "J": "EUR"
        }
    ],
    "BJI": [
        {
            "B": "KBJI",
            "C": "Bemidji, Minnesota",
            "D": "Bemidji Municipal",
            "E": "USA",
            "F": "US",
            "G": "T",
            "H": "",
            "I": "",
            "J": "NOA"
        }
    ],
    "BJL": [
        {
            "B": "GBYD",
            "C": "BANJUL YUNDUM INTL",
            "D": "Banjul",
            "E": "Gambia",
            "F": "GM",
            "G": "O",
            "H": "",
            "I": "",
            "J": "AFR"
        }
    ],
    "BJM": [
        {
            "B": "HBBA",
            "C": "International",
            "D": "Bujumbura Intl",
            "E": "Burundi",
            "F": "BI",
            "G": "O",
            "H": "",
            "I": "",
            "J": "AFR"
        }
    ],
    "BJR": [
        {
            "B": "HABD",
            "C": "Bahar Dar",
            "D": "Bahar Dar",
            "E": "Ethiopia",
            "F": "ET",
            "G": "O",
            "H": "",
            "I": "",
            "J": "AFR"
        }
    ],
    "BJS": [
        {
            "B": "ZBBB",
            "C": "BEIJING CITY",
            "D": "Beijing",
            "E": "Peoples Republic of China",
            "F": "CN",
            "G": "O",
            "H": "",
            "I": "",
            "J": "SEA"
        }
    ],
    "BJV": [
        {
            "B": "LTFE",
            "C": "MILAS / BODRUM",
            "D": "Bodrum",
            "E": "Turkey",
            "F": "TR",
            "G": "E",
            "H": "",
            "I": "",
            "J": "EUR"
        }
    ],
    "BJX": [
        {
            "B": "MMLO",
            "C": "Del Bajio",
            "D": "Del Bajio International",
            "E": "Mexico",
            "F": "MX",
            "G": "T",
            "H": "",
            "I": "",
            "J": "CEM"
        }
    ],
    "BJZ": [
        {
            "B": "LEBZ",
            "C": "BADAJOZ",
            "D": "Badajoz",
            "E": "Spain",
            "F": "ES",
            "G": "E",
            "H": "",
            "I": "",
            "J": "EUR"
        }
    ],
    "BKI": [
        {
            "B": "WBFC",
            "C": "Kota Kinabalu",
            "D": "Kota Kinabalu International",
            "E": "Malaysia",
            "F": "MY",
            "G": "O",
            "H": "",
            "I": "",
            "J": "SEA"
        }
    ],
    "BKK": [
        {
            "B": "VTBS",
            "C": "BANGKOK-INTERNATIONAL",
            "D": "Bangkok",
            "E": "Thailand",
            "F": "TH",
            "G": "O",
            "H": "",
            "I": "",
            "J": "SEA"
        }
    ],
    "BKL": [
        {
            "B": "KBKL",
            "C": "CLEVELAND / BURKE LAKEFRONT",
            "D": "Cleveland",
            "E": "USA",
            "F": "US",
            "G": "T",
            "H": "",
            "I": "",
            "J": "NOA"
        }
    ],
    "BKO": [
        {
            "B": "GABS",
            "C": "Bamako",
            "D": "Senou International",
            "E": "Mali",
            "F": "ML",
            "G": "O",
            "H": "",
            "I": "",
            "J": "AFR"
        }
    ],
    "BKW": [
        {
            "B": "KBKW",
            "C": "Beckley",
            "D": "Raleigh County Meml",
            "E": "USA",
            "F": "US",
            "G": "T",
            "H": "",
            "I": "",
            "J": "NOA"
        }
    ],
    "BLB": [
        {
            "B": "MPHO",
            "C": "PANAMA PACIFICO",
            "D": "PANAMA PACIFICO INTERNATIONAL AIRPORT",
            "E": "Panama",
            "F": "PA",
            "G": "T",
            "H": "",
            "I": "",
            "J": "CEM"
        }
    ],
    "BLD": [
        {
            "B": "ORBD",
            "C": "BALAD SOUTHEAST",
            "D": "Balad",
            "E": "Iraq",
            "F": "IQ",
            "G": "O",
            "H": "",
            "I": "",
            "J": "MDE"
        }
    ],
    "BLE": [
        {
            "B": "ESSD",
            "C": "BORLANGE",
            "D": "Borlange",
            "E": "Sweden",
            "F": "SE",
            "G": "E",
            "H": "",
            "I": "",
            "J": "EUR"
        }
    ],
    "BLI": [
        {
            "B": "KBLI",
            "C": "Bellingham",
            "D": "Bellingham International",
            "E": "USA",
            "F": "US",
            "G": "T",
            "H": "",
            "I": "",
            "J": "NOA"
        }
    ],
    "BLK": [
        {
            "B": "EGNH",
            "C": "BLACKPOOL",
            "D": "Blackpool",
            "E": "United Kingdom",
            "F": "GB",
            "G": "U",
            "H": "",
            "I": "",
            "J": "EUR"
        }
    ],
    "BLL": [
        {
            "B": "EKBI",
            "C": "BILLUND",
            "D": "Billund",
            "E": "Denmark",
            "F": "DK",
            "G": "E",
            "H": "",
            "I": "",
            "J": "EUR"
        }
    ],
    "BLQ": [
        {
            "B": "LIPE",
            "C": "BOLOGNA",
            "D": "Bologna",
            "E": "Italy",
            "F": "IT",
            "G": "E",
            "H": "",
            "I": "",
            "J": "EUR"
        }
    ],
    "BLR": [
        {
            "B": "VOBG",
            "C": "BANGALORE HINDUSTAN",
            "D": "Bangalore",
            "E": "India",
            "F": "IN",
            "G": "O",
            "H": "",
            "I": "",
            "J": "SAS"
        }
    ],
    "BLX": [
        {
            "B": "LIDB",
            "C": "BELLUNO",
            "D": "Belluno",
            "E": "Italy",
            "F": "IT",
            "G": "E",
            "H": "",
            "I": "",
            "J": "EUR"
        }
    ],
    "BLY": [
        {
            "B": "EIBT",
            "C": "BELMULLET",
            "D": "Belmullet",
            "E": "Republic of Ireland",
            "F": "IE",
            "G": "D",
            "H": "",
            "I": "",
            "J": "EUR"
        }
    ],
    "BLZ": [
        {
            "B": "FWCL",
            "C": "Chileka",
            "D": "Chileka Intl",
            "E": "Malawi",
            "F": "MW",
            "G": "O",
            "H": "",
            "I": "",
            "J": "AFR"
        }
    ],
    "BMA": [
        {
            "B": "ESSB",
            "C": "STOCKHOLM - BROMMA",
            "D": "Stockholm",
            "E": "Sweden",
            "F": "SE",
            "G": "E",
            "H": "",
            "I": "",
            "J": "EUR"
        }
    ],
    "BME": [
        {
            "B": "YBRM",
            "C": "Broome",
            "D": "Broome International",
            "E": "Australia",
            "F": "AU",
            "G": "O",
            "H": "",
            "I": "",
            "J": "SWP"
        }
    ],
    "BMI": [
        {
            "B": "KBMI",
            "C": "Bloomington-Normal",
            "D": "Central Illinois Regional",
            "E": "USA",
            "F": "US",
            "G": "T",
            "H": "",
            "I": "",
            "J": "NOA"
        }
    ],
    "BMK": [
        {
            "B": "EDWR",
            "C": "BORKUM",
            "D": "Borkum",
            "E": "Germany",
            "F": "DE",
            "G": "E",
            "H": "",
            "I": "",
            "J": "EUR"
        }
    ],
    "BMO": [
        {
            "B": "VYBM",
            "C": "BHAMO",
            "D": "Bhamo",
            "E": "Myanmar",
            "F": "MM",
            "G": "O",
            "H": "",
            "I": "",
            "J": "SEA"
        }
    ],
    "BMR": [
        {
            "B": "EDWZ",
            "C": "BALTRUM",
            "D": "Baltrum",
            "E": "Germany",
            "F": "DE",
            "G": "E",
            "H": "",
            "I": "",
            "J": "EUR"
        }
    ],
    "BNA": [
        {
            "B": "KBNA",
            "C": "NASHVILLE",
            "D": "Nashville",
            "E": "USA",
            "F": "US",
            "G": "T",
            "H": "",
            "I": "",
            "J": "NOA"
        }
    ],
    "BND": [
        {
            "B": "OIKB",
            "C": "Bandar Abbas",
            "D": "Bandar Abbas International Airport",
            "E": "Iran",
            "F": "IR",
            "G": "O",
            "H": "",
            "I": "",
            "J": "MDE"
        }
    ],
    "BNE": [
        {
            "B": "YBBN",
            "C": "BRISBANE",
            "D": "Brisbane",
            "E": "Australia",
            "F": "AU",
            "G": "O",
            "H": "",
            "I": "",
            "J": "SWP"
        }
    ],
    "BNK": [
        {
            "B": "YBNA",
            "C": "Ballina",
            "D": "Byron Gateway",
            "E": "Australia",
            "F": "AU",
            "G": "O",
            "H": "",
            "I": "",
            "J": "SWP"
        }
    ],
    "BNN": [
        {
            "B": "ENBN",
            "C": "BRONNOYSUND/BRONNOY",
            "D": "Bronnoy",
            "E": "Norway",
            "F": "NO",
            "G": "E",
            "H": "",
            "I": "",
            "J": "EUR"
        }
    ],
    "BNX": [
        {
            "B": "LQBK",
            "C": "BANJA LUKA",
            "D": "Banja Luka",
            "E": "Bosnia and Herzegovina",
            "F": "BA",
            "G": "E",
            "H": "",
            "I": "",
            "J": "EUR"
        }
    ],
    "BOD": [
        {
            "B": "LFBD",
            "C": "BORDEAUX - MERIGNAC",
            "D": "Bordeaux",
            "E": "France",
            "F": "FR",
            "G": "E",
            "H": "",
            "I": "",
            "J": "EUR"
        }
    ],
    "BOF": [
        {
            "B": "KBOF",
            "C": "WASHINGTON - BOLLING",
            "D": "Washington",
            "E": "USA",
            "F": "US",
            "G": "T",
            "H": "",
            "I": "",
            "J": "NOA"
        }
    ],
    "BOG": [
        {
            "B": "SKBO",
            "C": "BOGOTA",
            "D": "BOGOTA",
            "E": "Colombia",
            "F": "CO",
            "G": "T",
            "H": "",
            "I": "",
            "J": "SOA"
        }
    ],
    "BOH": [
        {
            "B": "EGHH",
            "C": "BOURNEMOUTH",
            "D": "Bournemouth",
            "E": "United Kingdom",
            "F": "GB",
            "G": "U",
            "H": "",
            "I": "",
            "J": "EUR"
        }
    ],
    "BOI": [
        {
            "B": "KBOI",
            "C": "BOISE",
            "D": "Boise",
            "E": "USA",
            "F": "US",
            "G": "T",
            "H": "",
            "I": "",
            "J": "NOA"
        }
    ],
    "BOJ": [
        {
            "B": "LBBG",
            "C": "BOURGAS",
            "D": "Bourgas",
            "E": "Bulgaria",
            "F": "BG",
            "G": "E",
            "H": "",
            "I": "",
            "J": "EUR"
        }
    ],
    "BOL": [
        {
            "B": "EGQB",
            "C": "BALLYKELLY",
            "D": "Ballykelly",
            "E": "United Kingdom",
            "F": "GB",
            "G": "U",
            "H": "",
            "I": "",
            "J": "EUR"
        }
    ],
    "BOM": [
        {
            "B": "VABB",
            "C": "MUMBAI",
            "D": "Mumbai",
            "E": "India",
            "F": "IN",
            "G": "O",
            "H": "",
            "I": "",
            "J": "SAS"
        }
    ],
    "BON": [
        {
            "B": "TNCB",
            "C": "FLAMINGO",
            "D": "Bonaire",
            "E": "Netherlands Antilles",
            "F": "AN",
            "G": "T",
            "H": "",
            "I": "",
            "J": "CAR"
        }
    ],
    "BOO": [
        {
            "B": "ENBO",
            "C": "BODO",
            "D": "Bodo",
            "E": "Norway",
            "F": "NO",
            "G": "E",
            "H": "",
            "I": "",
            "J": "EUR"
        }
    ],
    "BOR": [
        {
            "B": "LFGG",
            "C": "BELFORT - CHAUX",
            "D": "Belfort Chaux",
            "E": "France",
            "F": "FR",
            "G": "E",
            "H": "",
            "I": "",
            "J": "EUR"
        }
    ],
    "BOS": [
        {
            "B": "KBOS",
            "C": "BOSTON",
            "D": "Boston",
            "E": "USA",
            "F": "US",
            "G": "T",
            "H": "",
            "I": "",
            "J": "NOA"
        }
    ],
    "BOU": [
        {
            "B": "LFLD",
            "C": "BOURGES",
            "D": "Bourges",
            "E": "France",
            "F": "FR",
            "G": "E",
            "H": "",
            "I": "",
            "J": "EUR"
        }
    ],
    "BOY": [
        {
            "B": "DFOO",
            "C": "BOBO DIOULASSO",
            "D": "Bobo Dioulasso",
            "E": "Burkina Faso",
            "F": "BF",
            "G": "O",
            "H": "",
            "I": "",
            "J": "AFR"
        }
    ],
    "BPN": [
        {
            "B": "WALZ",
            "C": "Sepingan",
            "D": "Sepinggan",
            "E": "Indonesia",
            "F": "ID",
            "G": "O",
            "H": "",
            "I": "",
            "J": "SEA"
        }
    ],
    "BPS": [
        {
            "B": "SBPS",
            "C": "Porto Seguro",
            "D": "Porto Seguro",
            "E": "Brazil",
            "F": "BR",
            "G": "T",
            "H": "",
            "I": "",
            "J": "SOA"
        }
    ],
    "BPT": [
        {
            "B": "KBPT",
            "C": "Jefferson County",
            "D": "Jack Brooks Regional",
            "E": "USA",
            "F": "US",
            "G": "T",
            "H": "",
            "I": "",
            "J": "NOA"
        }
    ],
    "BQC": [
        {
            "B": "",
            "C": "SAINTE-FOY BUS STATION",
            "D": "Sainte-Foy Bus Station",
            "E": "France",
            "F": "FR",
            "G": "E",
            "H": "",
            "I": "",
            "J": "EUR"
        }
    ],
    "BQH": [
        {
            "B": "EGKB",
            "C": "BIGGIN HILL",
            "D": "Biggin Hill",
            "E": "United Kingdom",
            "F": "GB",
            "G": "U",
            "H": "",
            "I": "",
            "J": "EUR"
        }
    ],
    "BQK": [
        {
            "B": "KBQK",
            "C": "BRUNSWICK, USA",
            "D": "BRUNSWICK GOLDEN ISLES AIRPORT",
            "E": "USA",
            "F": "US",
            "G": "T",
            "H": "",
            "I": "",
            "J": "NOA"
        }
    ],
    "BQN": [
        {
            "B": "TJBQ",
            "C": "RAFAEL HERNADEZ AIRPORT",
            "D": "Aguadilla",
            "E": "Puerto Rico",
            "F": "PR",
            "G": "T",
            "H": "",
            "I": "",
            "J": "CAR"
        }
    ],
    "BQS": [
        {
            "B": "UHBB",
            "C": "BLAGOVESHCHENSK/IGNATYEVO",
            "D": "Blagoveshchensk/Ignatyevo",
            "E": "Russian Federation",
            "F": "RU",
            "G": "E",
            "H": "",
            "I": "",
            "J": "EUR"
        }
    ],
    "BRC": [
        {
            "B": "SAZS",
            "C": "International",
            "D": "San Carlos Bariloche International",
            "E": "Argentina",
            "F": "AR",
            "G": "T",
            "H": "",
            "I": "",
            "J": "SOA"
        }
    ],
    "BRD": [
        {
            "B": "KBRD",
            "C": "Brainerd, Minnesota",
            "D": "Brainerd Lakes Rgnl",
            "E": "USA",
            "F": "US",
            "G": "T",
            "H": "",
            "I": "",
            "J": "NOA"
        }
    ],
    "BRE": [
        {
            "B": "EDDW",
            "C": "BREMEN",
            "D": "Bremen",
            "E": "Germany",
            "F": "DE",
            "G": "E",
            "H": "",
            "I": "",
            "J": "EUR"
        }
    ],
    "BRI": [
        {
            "B": "LIBD",
            "C": "BARI",
            "D": "Bari",
            "E": "Italy",
            "F": "IT",
            "G": "E",
            "H": "",
            "I": "",
            "J": "EUR"
        }
    ],
    "BRL": [
        {
            "B": "KBRL",
            "C": "BURLINGTON MUNI",
            "D": "Burlington Muni",
            "E": "USA",
            "F": "US",
            "G": "T",
            "H": "",
            "I": "",
            "J": "NOA"
        }
    ],
    "BRM": [
        {
            "B": "SVBM",
            "C": "Barquisimeto",
            "D": "Jacinto Lara Intl",
            "E": "Venezuela",
            "F": "VE",
            "G": "T",
            "H": "",
            "I": "",
            "J": "SOA"
        }
    ],
    "BRN": [
        {
            "B": "LSZB",
            "C": "BERNE BELP",
            "D": "Berne",
            "E": "Switzerland",
            "F": "CH",
            "G": "E",
            "H": "",
            "I": "",
            "J": "EUR"
        }
    ],
    "BRO": [
        {
            "B": "KBRO",
            "C": "South Padre Is. Intl",
            "D": "South Padre Isl Intl",
            "E": "USA",
            "F": "US",
            "G": "T",
            "H": "",
            "I": "",
            "J": "NOA"
        }
    ],
    "BRQ": [
        {
            "B": "LKTB",
            "C": "BRNO",
            "D": "Brno",
            "E": "Czech Republic",
            "F": "CZ",
            "G": "E",
            "H": "",
            "I": "",
            "J": "EUR"
        }
    ],
    "BRR": [
        {
            "B": "EGPR",
            "C": "BARRA",
            "D": "Barra",
            "E": "United Kingdom",
            "F": "GB",
            "G": "U",
            "H": "",
            "I": "",
            "J": "EUR"
        }
    ],
    "BRS": [
        {
            "B": "EGGD",
            "C": "BRISTOL",
            "D": "Bristol",
            "E": "United Kingdom",
            "F": "GB",
            "G": "U",
            "H": "",
            "I": "",
            "J": "EUR"
        }
    ],
    "BRU": [
        {
            "B": "EBBR",
            "C": "BRUSSELS",
            "D": "Brussels",
            "E": "Belgium",
            "F": "BE",
            "G": "E",
            "H": "",
            "I": "",
            "J": "EUR"
        }
    ],
    "BRV": [
        {
            "B": "EDWB",
            "C": "BREMERHAVEN",
            "D": "BREMERHAVEN AIRPORT",
            "E": "Germany",
            "F": "DE",
            "G": "E",
            "H": "",
            "I": "",
            "J": "EUR"
        }
    ],
    "BSB": [
        {
            "B": "SBBR",
            "C": "BRASILIA",
            "D": "Brasilia",
            "E": "Brazil",
            "F": "BR",
            "G": "T",
            "H": "",
            "I": "",
            "J": "SOA"
        }
    ],
    "BSK": [
        {
            "B": "DAUB",
            "C": "Biskra",
            "D": "Mohamed Khider",
            "E": "Algeria",
            "F": "DZ",
            "G": "O",
            "H": "",
            "I": "",
            "J": "AFR"
        }
    ],
    "BSL": [
        {
            "B": "LFSB",
            "C": "BASEL MULHOUSE",
            "D": "Basel",
            "E": "Switzerland",
            "F": "CH",
            "G": "E",
            "H": "",
            "I": "",
            "J": "EUR"
        }
    ],
    "BSP": [
        {
            "B": "AYBH",
            "C": "BENSBACH",
            "D": "Bensbach",
            "E": "Papua New Guinea",
            "F": "PG",
            "G": "O",
            "H": "",
            "I": "",
            "J": "SEA"
        }
    ],
    "BSR": [
        {
            "B": "ORMM",
            "C": "BASRAH INTERNATIONAL",
            "D": "Basrah",
            "E": "Iraq",
            "F": "IQ",
            "G": "O",
            "H": "",
            "I": "",
            "J": "MDE"
        }
    ],
    "BTJ": [
        {
            "B": "WITT",
            "C": "BANDA ACEH",
            "D": "Banda Aceh/Sltn Iskandr Muda",
            "E": "Indonesia",
            "F": "ID",
            "G": "O",
            "H": "",
            "I": "",
            "J": "SEA"
        }
    ],
    "BTK": [
        {
            "B": "UIBB",
            "C": "BRATSK",
            "D": "Bratsk",
            "E": "Russian Federation",
            "F": "RU",
            "G": "E",
            "H": "",
            "I": "",
            "J": "EUR"
        }
    ],
    "BTL": [
        {
            "B": "KBTL",
            "C": "BATTLE CREEK - W K KELLOG",
            "D": "Wk kellogg regional",
            "E": "USA",
            "F": "US",
            "G": "T",
            "H": "",
            "I": "",
            "J": "NOA"
        }
    ],
    "BTM": [
        {
            "B": "KBTM",
            "C": "BUTTE",
            "D": "Butte",
            "E": "USA",
            "F": "US",
            "G": "T",
            "H": "",
            "I": "",
            "J": "NOA"
        }
    ],
    "BTR": [
        {
            "B": "KBTR",
            "C": "Ryan",
            "D": "Metropolitan -Ryan Field",
            "E": "USA",
            "F": "US",
            "G": "T",
            "H": "",
            "I": "",
            "J": "NOA"
        }
    ],
    "BTS": [
        {
            "B": "LZIB",
            "C": "BRATISLAVA - M.R STEFA",
            "D": "Bratislava",
            "E": "Slovakia",
            "F": "SK",
            "G": "E",
            "H": "",
            "I": "",
            "J": "EUR"
        }
    ],
    "BTU": [
        {
            "B": "WBGB",
            "C": "Bintulu",
            "D": "Bintulu Airport",
            "E": "Malaysia",
            "F": "MY",
            "G": "O",
            "H": "",
            "I": "",
            "J": "SEA"
        }
    ],
    "BTV": [
        {
            "B": "KBTV",
            "C": "BURLINGTON VERMONT",
            "D": "Burlington",
            "E": "USA",
            "F": "US",
            "G": "T",
            "H": "",
            "I": "",
            "J": "NOA"
        }
    ],
    "BTZ": [
        {
            "B": "",
            "C": "BURSA - YENISEHIR",
            "D": "Bursa",
            "E": "Turkey",
            "F": "TR",
            "G": "E",
            "H": "",
            "I": "",
            "J": "EUR"
        }
    ],
    "BUB": [
        {
            "B": "KBUB",
            "C": "CRAM FIELD",
            "D": "Cram field burwell municipal airport",
            "E": "USA",
            "F": "US",
            "G": "T",
            "H": "",
            "I": "",
            "J": "NOA"
        }
    ],
    "BUC": [
        {
            "B": "",
            "C": "",
            "D": "",
            "E": "UNKNOWN",
            "F": "",
            "G": "A",
            "H": "",
            "I": "",
            "J": ""
        }
    ],
    "BUD": [
        {
            "B": "LHBP",
            "C": "BUDAPEST",
            "D": "Budapest",
            "E": "Hungary",
            "F": "HU",
            "G": "E",
            "H": "",
            "I": "",
            "J": "EUR"
        }
    ],
    "BUF": [
        {
            "B": "KBUF",
            "C": "BUFFALO",
            "D": "Buffalo",
            "E": "USA",
            "F": "US",
            "G": "T",
            "H": "",
            "I": "",
            "J": "NOA"
        }
    ],
    "BUQ": [
        {
            "B": "FVBU",
            "C": "Bulawayo",
            "D": "Joshua M. Nkomo Intl",
            "E": "Zimbabwe",
            "F": "ZW",
            "G": "O",
            "H": "",
            "I": "",
            "J": "AFR"
        }
    ],
    "BUR": [
        {
            "B": "KBUR",
            "C": "BURBANK - GLENDALE-PASSADENA",
            "D": "Burbank",
            "E": "USA",
            "F": "US",
            "G": "T",
            "H": "",
            "I": "",
            "J": "NOA"
        }
    ],
    "BUS": [
        {
            "B": "UGSB",
            "C": "BATUMI INTERNATIONAL",
            "D": "BATUMI INTERNATIONAL AIRPORT",
            "E": "Georgia",
            "F": "GE",
            "G": "E",
            "H": "",
            "I": "",
            "J": "EUR"
        }
    ],
    "BVA": [
        {
            "B": "LFOB",
            "C": "PARIS - BEAUVAIS",
            "D": "Paris BVA",
            "E": "France",
            "F": "FR",
            "G": "E",
            "H": "",
            "I": "",
            "J": "EUR"
        }
    ],
    "BVB": [
        {
            "B": "SBBV",
            "C": "Boa Vista",
            "D": "A.B. Cantanhede Intl",
            "E": "Brazil",
            "F": "BR",
            "G": "T",
            "H": "",
            "I": "",
            "J": "SOA"
        }
    ],
    "BVC": [
        {
            "B": "GVBA",
            "C": "ARISTIDES PEREIRA",
            "D": "Aristides Pereira",
            "E": "Cape Verde",
            "F": "CV",
            "G": "O",
            "H": "",
            "I": "",
            "J": "AFR"
        }
    ],
    "BVE": [
        {
            "B": "LFBV",
            "C": "BRIVE-LA-GAILLARDE",
            "D": "Brive La Gaillarde",
            "E": "France",
            "F": "FR",
            "G": "E",
            "H": "",
            "I": "",
            "J": "EUR"
        }
    ],
    "BVY": [
        {
            "B": "KBVY",
            "C": "BEVERLY REGIONAL AIRPORT",
            "D": "BEVERLY REGIONAL AIRPORT",
            "E": "USA",
            "F": "US",
            "G": "T",
            "H": "",
            "I": "",
            "J": "NOA"
        }
    ],
    "BWE": [
        {
            "B": "EDVE",
            "C": "BRAUNSCHWEIG",
            "D": "Braunschweig",
            "E": "Germany",
            "F": "DE",
            "G": "E",
            "H": "",
            "I": "",
            "J": "EUR"
        }
    ],
    "BWF": [
        {
            "B": "EGNL",
            "C": "BARROW / WALNEY ISLAND",
            "D": "Barrow",
            "E": "United Kingdom",
            "F": "GB",
            "G": "U",
            "H": "",
            "I": "",
            "J": "EUR"
        }
    ],
    "BWI": [
        {
            "B": "KBWI",
            "C": "BALTIMORE  - WASHINGTON",
            "D": "Washington",
            "E": "USA",
            "F": "US",
            "G": "T",
            "H": "",
            "I": "",
            "J": "NOA"
        }
    ],
    "BWK": [
        {
            "B": "LDSB",
            "C": "BRAC ISLAND",
            "D": "Brae",
            "E": "Croatia",
            "F": "HR",
            "G": "E",
            "H": "",
            "I": "",
            "J": "EUR"
        }
    ],
    "BWN": [
        {
            "B": "WBSB",
            "C": "BRUNEI INTERNATIONAL AIRPORT",
            "D": "BRUNEI INTERNATIONAL AIRPORT",
            "E": "Brunei Darussalam",
            "F": "BN",
            "G": "O",
            "H": "",
            "I": "",
            "J": "SEA"
        }
    ],
    "BWT": [
        {
            "B": "YBLN",
            "C": "BUSSELTON",
            "D": "Busselton",
            "E": "Australia",
            "F": "AU",
            "G": "O",
            "H": "",
            "I": "",
            "J": "SWP"
        }
    ],
    "BXM": [
        {
            "B": "KBXM",
            "C": "BRUNSWICK EXECUTIVE AIRPORT",
            "D": "BRUNSWICK EXECUTIVE AIRPORT",
            "E": "USA",
            "F": "",
            "G": "T",
            "H": "",
            "I": "",
            "J": ""
        }
    ],
    "BXO": [
        {
            "B": "LSMU",
            "C": "BUOCHS",
            "D": "Buochs",
            "E": "Switzerland",
            "F": "CH",
            "G": "E",
            "H": "",
            "I": "",
            "J": "EUR"
        }
    ],
    "BXU": [
        {
            "B": "RPME",
            "C": "Butuan",
            "D": "Bancasi",
            "E": "Philippines",
            "F": "PH",
            "G": "O",
            "H": "",
            "I": "",
            "J": "SEA"
        }
    ],
    "BYF": [
        {
            "B": "LFAQ",
            "C": "PICARDIE",
            "D": "PICARDIE",
            "E": "France",
            "F": "FR",
            "G": "E",
            "H": "",
            "I": "",
            "J": "EUR"
        }
    ],
    "BYJ": [
        {
            "B": "LPBJ",
            "C": "BEJA AIRPORT",
            "D": "BEJA AIRPORT",
            "E": "Portugal",
            "F": "PT",
            "G": "E",
            "H": "",
            "I": "",
            "J": "EUR"
        }
    ],
    "BYT": [
        {
            "B": "EIBN",
            "C": "BANTRY",
            "D": "Bantry",
            "E": "Republic of Ireland",
            "F": "IE",
            "G": "D",
            "H": "",
            "I": "",
            "J": "EUR"
        }
    ],
    "BYU": [
        {
            "B": "EDQD",
            "C": "BAYREUTH",
            "D": "Bayreuth",
            "E": "Germany",
            "F": "DE",
            "G": "E",
            "H": "",
            "I": "",
            "J": "EUR"
        }
    ],
    "BZE": [
        {
            "B": "MZBZ",
            "C": "Philip S.W.Goldson Int",
            "D": "Belize International",
            "E": "Belize",
            "F": "BZ",
            "G": "T",
            "H": "",
            "I": "",
            "J": "CEM"
        }
    ],
    "BZG": [
        {
            "B": "EPBY",
            "C": "BYDGOSZCZ-SZWEDEROWO",
            "D": "Bydgoszcz",
            "E": "Poland",
            "F": "PL",
            "G": "E",
            "H": "",
            "I": "",
            "J": "EUR"
        }
    ],
    "BZI": [
        {
            "B": "LTBF",
            "C": "BALIKESIR-EDREMIT-KO",
            "D": "Balikesir Edremit",
            "E": "Turkey",
            "F": "TR",
            "G": "E",
            "H": "",
            "I": "",
            "J": "EUR"
        }
    ],
    "BZM": [
        {
            "B": "",
            "C": "",
            "D": "",
            "E": "UNKNOWN",
            "F": "",
            "G": "O",
            "H": "",
            "I": "",
            "J": ""
        }
    ],
    "BZN": [
        {
            "B": "KBZN",
            "C": "Gallatin Field",
            "D": "Yellowstone Intl",
            "E": "USA",
            "F": "US",
            "G": "T",
            "H": "",
            "I": "",
            "J": "NOA"
        }
    ],
    "BZO": [
        {
            "B": "LIPB",
            "C": "BOLZANO",
            "D": "Bolzano",
            "E": "Italy",
            "F": "IT",
            "G": "E",
            "H": "",
            "I": "",
            "J": "EUR"
        }
    ],
    "BZR": [
        {
            "B": "LFMU",
            "C": "BEZIERS",
            "D": "Beziers",
            "E": "France",
            "F": "FR",
            "G": "E",
            "H": "",
            "I": "",
            "J": "EUR"
        }
    ],
    "BZV": [
        {
            "B": "FCBB",
            "C": "MAYA MAYA AIRPORT",
            "D": "MAYA MAYA AIRPORT",
            "E": "Congo",
            "F": "CG",
            "G": "O",
            "H": "",
            "I": "",
            "J": "AFR"
        }
    ],
    "BZZ": [
        {
            "B": "EGVN",
            "C": "BRIZE NORTON",
            "D": "Brize Norton",
            "E": "United Kingdom",
            "F": "GB",
            "G": "U",
            "H": "",
            "I": "",
            "J": "EUR"
        }
    ],
    "C01": [
        {
            "B": "LSGK",
            "C": "SAANEN",
            "D": "Saanen",
            "E": "Switzerland",
            "F": "CH",
            "G": "E",
            "H": "",
            "I": "",
            "J": "EUR"
        }
    ],
    "C02": [
        {
            "B": "LSGC",
            "C": "LES EPLATURES",
            "D": "Les eeplatures",
            "E": "Switzerland",
            "F": "CH",
            "G": "E",
            "H": "",
            "I": "",
            "J": "EUR"
        }
    ],
    "C22": [
        {
            "B": "LSGR",
            "C": "REICHENBACH",
            "D": "Reichenbach",
            "E": "Switzerland",
            "F": "CH",
            "G": "E",
            "H": "",
            "I": "",
            "J": "EUR"
        }
    ],
    "CAC": [
        {
            "B": "SBCA",
            "C": "Cascavel",
            "D": "A.Mendes da Silva",
            "E": "Brazil",
            "F": "BR",
            "G": "T",
            "H": "",
            "I": "",
            "J": "SOA"
        }
    ],
    "CAE": [
        {
            "B": "KCAE",
            "C": "COLUMBIA METROPOLITAN",
            "D": "Columbia",
            "E": "USA",
            "F": "US",
            "G": "T",
            "H": "",
            "I": "",
            "J": "NOA"
        }
    ],
    "CAG": [
        {
            "B": "LIEE",
            "C": "CAGLIARI",
            "D": "Cagliari",
            "E": "Italy",
            "F": "IT",
            "G": "E",
            "H": "",
            "I": "",
            "J": "EUR"
        }
    ],
    "CAI": [
        {
            "B": "HECA",
            "C": "CAIRO",
            "D": "Kairo",
            "E": "Egypt",
            "F": "EG",
            "G": "O",
            "H": "",
            "I": "",
            "J": "MDE"
        }
    ],
    "CAK": [
        {
            "B": "KCAK",
            "C": "AKRON-CANTON REGIONAL",
            "D": "Akron-canton",
            "E": "USA",
            "F": "US",
            "G": "T",
            "H": "",
            "I": "",
            "J": "NOA"
        }
    ],
    "CAL": [
        {
            "B": "EGEC",
            "C": "CAMPBELTOWN MACRIHANISH",
            "D": "Campbeltown",
            "E": "United Kingdom",
            "F": "GB",
            "G": "U",
            "H": "",
            "I": "",
            "J": "EUR"
        }
    ],
    "CAN": [
        {
            "B": "ZGGG",
            "C": "BAIYUN",
            "D": "Baiyun",
            "E": "Peoples Republic of China",
            "F": "CN",
            "G": "O",
            "H": "",
            "I": "",
            "J": "SEA"
        }
    ],
    "CAP": [
        {
            "B": "MTCH",
            "C": "CAP-HAÏTIEN",
            "D": "Cap-Haïtien",
            "E": "Haiti",
            "F": "HT",
            "G": "T",
            "H": "",
            "I": "",
            "J": "CAR"
        }
    ],
    "CAR": [
        {
            "B": "",
            "C": "",
            "D": "",
            "E": "UNKNOWN",
            "F": "",
            "G": "A",
            "H": "",
            "I": "",
            "J": ""
        }
    ],
    "CAS": [
        {
            "B": "GMMC",
            "C": "CASABLANCA - ANFA",
            "D": "Casablanca",
            "E": "Morocco",
            "F": "MA",
            "G": "O",
            "H": "",
            "I": "",
            "J": "AFR"
        }
    ],
    "CAT": [
        {
            "B": "LPCS",
            "C": "Cascais Aerodrome",
            "D": "Lisboa Cascais-Tejo Regional Airport",
            "E": "Portugal",
            "F": "PT",
            "G": "E",
            "H": "",
            "I": "",
            "J": ""
        }
    ],
    "CAX": [
        {
            "B": "EGNC",
            "C": "CARLISLE",
            "D": "Carlisle",
            "E": "United Kingdom",
            "F": "GB",
            "G": "U",
            "H": "",
            "I": "",
            "J": "EUR"
        }
    ],
    "CAZ": [
        {
            "B": "YCBA",
            "C": "Cobar",
            "D": "Cobar",
            "E": "Australia",
            "F": "AU",
            "G": "O",
            "H": "",
            "I": "",
            "J": "SWP"
        }
    ],
    "CBB": [
        {
            "B": "SLCB",
            "C": "J Wilsterman",
            "D": "Jorge Wilstermann Intl",
            "E": "Bolivia",
            "F": "BO",
            "G": "T",
            "H": "",
            "I": "",
            "J": "SOA"
        }
    ],
    "CBG": [
        {
            "B": "EGSC",
            "C": "CAMBRIDGE",
            "D": "Cambridge",
            "E": "United Kingdom",
            "F": "GB",
            "G": "U",
            "H": "",
            "I": "",
            "J": "EUR"
        }
    ],
    "CBP": [
        {
            "B": "LPCO",
            "C": "COIMBRA",
            "D": "Coimbra",
            "E": "Portugal",
            "F": "PT",
            "G": "E",
            "H": "",
            "I": "",
            "J": "EUR"
        }
    ],
    "CBQ": [
        {
            "B": "DNCA",
            "C": "Calabar",
            "D": "Margaret Ekpo International Airport",
            "E": "Nigeria",
            "F": "NG",
            "G": "O",
            "H": "",
            "I": "",
            "J": "AFR"
        }
    ],
    "CBR": [
        {
            "B": "YSCB",
            "C": "CANBERRA",
            "D": "Canberra",
            "E": "Australia",
            "F": "AU",
            "G": "O",
            "H": "",
            "I": "",
            "J": "SWP"
        }
    ],
    "CBU": [
        {
            "B": "EDCD",
            "C": "COTTBUS",
            "D": "Cottbus",
            "E": "Germany",
            "F": "DE",
            "G": "E",
            "H": "",
            "I": "",
            "J": "EUR"
        }
    ],
    "CCC": [
        {
            "B": "MUCC",
            "C": "JARDINES DEL REY",
            "D": "Cayo Coco",
            "E": "Cuba",
            "F": "CU",
            "G": "T",
            "H": "",
            "I": "",
            "J": "CAR"
        }
    ],
    "CCF": [
        {
            "B": "LFMK",
            "C": "CARCASSONNE",
            "D": "Carcassonne",
            "E": "France",
            "F": "FR",
            "G": "E",
            "H": "",
            "I": "",
            "J": "EUR"
        }
    ],
    "CCJ": [
        {
            "B": "",
            "C": "",
            "D": "",
            "E": "UNKNOWN",
            "F": "",
            "G": "A",
            "H": "",
            "I": "",
            "J": ""
        }
    ],
    "CCP": [
        {
            "B": "SCIE",
            "C": "Carriel Sur",
            "D": "Carriel Sur",
            "E": "Chile",
            "F": "CL",
            "G": "T",
            "H": "",
            "I": "",
            "J": "SOA"
        }
    ],
    "CCS": [
        {
            "B": "SVMI",
            "C": "CARACAS",
            "D": "Caracas",
            "E": "Venezuela",
            "F": "VE",
            "G": "T",
            "H": "",
            "I": "",
            "J": "SOA"
        }
    ],
    "CCU": [
        {
            "B": "VECF",
            "C": "Netaji Subhas Chandra",
            "D": "Subhas Chandra Bose Airport",
            "E": "India",
            "F": "IN",
            "G": "O",
            "H": "",
            "I": "",
            "J": "SAS"
        }
    ],
    "CDC": [
        {
            "B": "KCDC",
            "C": "CEDAR CITY ",
            "D": "Cedar City ",
            "E": "USA",
            "F": "US",
            "G": "T",
            "H": "",
            "I": "",
            "J": "NOA"
        }
    ],
    "CDF": [
        {
            "B": "LIDI",
            "C": "CORTINA",
            "D": "Cortina",
            "E": "Italy",
            "F": "IT",
            "G": "E",
            "H": "",
            "I": "",
            "J": "EUR"
        }
    ],
    "CDG": [
        {
            "B": "LFPG",
            "C": "PARIS - CHARLES DE GAULLE",
            "D": "Paris CDG",
            "E": "France",
            "F": "FR",
            "G": "E",
            "H": "",
            "I": "",
            "J": "EUR"
        }
    ],
    "CDT": [
        {
            "B": "LECH",
            "C": "CASTELLON",
            "D": "CASTELLON-COSTA AZAHAR",
            "E": "Spain",
            "F": "ES",
            "G": "E",
            "H": "",
            "I": "",
            "J": "EUR"
        }
    ],
    "CEB": [
        {
            "B": "RPVM",
            "C": "MACTAN-CEBU INTERNATIONAL",
            "D": "Cebu",
            "E": "Philippines",
            "F": "PH",
            "G": "O",
            "H": "",
            "I": "",
            "J": "SEA"
        }
    ],
    "CEC": [
        {
            "B": "KCEC",
            "C": "Mc Namara Fld",
            "D": "Jack Mc Namara Field",
            "E": "USA",
            "F": "US",
            "G": "T",
            "H": "",
            "I": "",
            "J": "NOA"
        }
    ],
    "CEG": [
        {
            "B": "EGNR",
            "C": "CHESTER",
            "D": "Chester",
            "E": "United Kingdom",
            "F": "GB",
            "G": "U",
            "H": "",
            "I": "",
            "J": "EUR"
        }
    ],
    "CEI": [
        {
            "B": "VTCT",
            "C": "CHIANG RAI",
            "D": "Chiang rai",
            "E": "Thailand",
            "F": "TH",
            "G": "O",
            "H": "",
            "I": "",
            "J": "SEA"
        }
    ],
    "CEK": [
        {
            "B": "USCC",
            "C": "Chelyabinsk",
            "D": "Balandino Airport",
            "E": "Russian Federation",
            "F": "RU",
            "G": "E",
            "H": "",
            "I": "",
            "J": "EUR"
        }
    ],
    "CEN": [
        {
            "B": "MMCN",
            "C": "Ciudad Obregon",
            "D": "Ciudad Obregon International",
            "E": "Mexico",
            "F": "MX",
            "G": "T",
            "H": "",
            "I": "",
            "J": "CEM"
        }
    ],
    "CEQ": [
        {
            "B": "LFMD",
            "C": "CANNES MANDELIEU",
            "D": "Cannes",
            "E": "France",
            "F": "FR",
            "G": "E",
            "H": "",
            "I": "",
            "J": "EUR"
        }
    ],
    "CER": [
        {
            "B": "LFRC",
            "C": "CHERBOURG",
            "D": "Cherbourg",
            "E": "France",
            "F": "FR",
            "G": "E",
            "H": "",
            "I": "",
            "J": "EUR"
        }
    ],
    "CET": [
        {
            "B": "LFOU",
            "C": "CHOLET-LE PONTREAU",
            "D": "Cholet Le Pontreau",
            "E": "France",
            "F": "FR",
            "G": "E",
            "H": "",
            "I": "",
            "J": "EUR"
        }
    ],
    "CEZ": [
        {
            "B": "KCEZ",
            "C": "Cortez Municipal",
            "D": "Cortez Municipal Aiport",
            "E": "USA",
            "F": "US",
            "G": "T",
            "H": "",
            "I": "",
            "J": "NOA"
        }
    ],
    "CFB": [
        {
            "B": "SBCB",
            "C": "CABO FRIO INTL",
            "D": "CABO FRIO INTERNATIONAL AIRPORT",
            "E": "Brazil",
            "F": "BR",
            "G": "T",
            "H": "",
            "I": "",
            "J": "SOA"
        }
    ],
    "CFE": [
        {
            "B": "LFLC",
            "C": "CLERMONT-FERRAND",
            "D": "Clermont",
            "E": "France",
            "F": "FR",
            "G": "E",
            "H": "",
            "I": "",
            "J": "EUR"
        }
    ],
    "CFN": [
        {
            "B": "EIDL",
            "C": "DONEGAL",
            "D": "Donegal",
            "E": "Republic of Ireland",
            "F": "IE",
            "G": "D",
            "H": "",
            "I": "",
            "J": "EUR"
        }
    ],
    "CFR": [
        {
            "B": "LFRK",
            "C": "CAEN",
            "D": "Caen",
            "E": "France",
            "F": "FR",
            "G": "E",
            "H": "",
            "I": "",
            "J": "EUR"
        }
    ],
    "CFS": [
        {
            "B": "YSCH",
            "C": "Coffs Harbour",
            "D": "Coffs Harbour",
            "E": "Australia",
            "F": "AU",
            "G": "O",
            "H": "",
            "I": "",
            "J": "SWP"
        }
    ],
    "CFU": [
        {
            "B": "LGKR",
            "C": "CORFU / KERKIRA",
            "D": "Corfu",
            "E": "Greece",
            "F": "GR",
            "G": "E",
            "H": "",
            "I": "",
            "J": "EUR"
        }
    ],
    "CGB": [
        {
            "B": "SBCY",
            "C": "M. Rondon",
            "D": "Marechal Rondon Intl",
            "E": "Brazil",
            "F": "BR",
            "G": "T",
            "H": "",
            "I": "",
            "J": "SOA"
        }
    ],
    "CGF": [
        {
            "B": "KCGF",
            "C": "Cuyahoga County Airport",
            "D": "Cuyahoga County Airport",
            "E": "USA",
            "F": "US",
            "G": "T",
            "H": "",
            "I": "",
            "J": "NOA"
        }
    ],
    "CGH": [
        {
            "B": "SBSP",
            "C": "Congonhas",
            "D": "Congonhas",
            "E": "Brazil",
            "F": "BR",
            "G": "T",
            "H": "",
            "I": "",
            "J": "SOA"
        }
    ],
    "CGI": [
        {
            "B": "KCGI",
            "C": "CAPE GIRARDEAU MUNICIPAL",
            "D": "Cape girardeau",
            "E": "USA",
            "F": "US",
            "G": "T",
            "H": "",
            "I": "",
            "J": "NOA"
        }
    ],
    "CGK": [
        {
            "B": "WIII",
            "C": "JAKARTA",
            "D": "Jakarta",
            "E": "Indonesia",
            "F": "ID",
            "G": "O",
            "H": "",
            "I": "",
            "J": "SEA"
        }
    ],
    "CGM": [
        {
            "B": "RPMH",
            "C": "CAMIGUIN, CAMIGUIN",
            "D": "Camiguin, Camiguin",
            "E": "Philippines",
            "F": "PH",
            "G": "O",
            "H": "",
            "I": "",
            "J": "SEA"
        }
    ],
    "CGN": [
        {
            "B": "EDDK",
            "C": "COLOGNE / BONN K.A.",
            "D": "Cologne Bonn",
            "E": "Germany",
            "F": "DE",
            "G": "E",
            "H": "",
            "I": "",
            "J": "EUR"
        }
    ],
    "CGO": [
        {
            "B": "ZHCC",
            "C": "ZHENGZHOU XINZHENG",
            "D": "ZHENGZHOU XINZHENG INTL AIRPORT",
            "E": "Peoples Republic of China",
            "F": "CN",
            "G": "O",
            "H": "",
            "I": "",
            "J": "SEA"
        }
    ],
    "CGP": [
        {
            "B": "VGEG",
            "C": "Chittagong",
            "D": "Shah Amanat International Airport",
            "E": "Bangladesh",
            "F": "BD",
            "G": "O",
            "H": "",
            "I": "",
            "J": "SAS"
        }
    ],
    "CGQ": [
        {
            "B": "ZYCC",
            "C": "Changchun",
            "D": "Longjia International",
            "E": "Peoples Republic of China",
            "F": "CN",
            "G": "O",
            "H": "",
            "I": "",
            "J": "SEA"
        }
    ],
    "CGR": [
        {
            "B": "SBCG",
            "C": "Internacional",
            "D": "Campo Grande International",
            "E": "Brazil",
            "F": "BR",
            "G": "T",
            "H": "",
            "I": "",
            "J": "SOA"
        }
    ],
    "CGX": [
        {
            "B": "KCGX",
            "C": "CHICAGO - MERRILL",
            "D": "Chicago",
            "E": "USA",
            "F": "US",
            "G": "T",
            "H": "",
            "I": "",
            "J": "NOA"
        }
    ],
    "CGY": [
        {
            "B": "RPUC",
            "C": "Lumbia",
            "D": "Laguindingan Intl",
            "E": "Philippines",
            "F": "PH",
            "G": "O",
            "H": "",
            "I": "",
            "J": "SEA"
        }
    ],
    "CGZ": [
        {
            "B": "KCGZ",
            "C": "CASA GRANDE MUNICIPAL",
            "D": "Casa grande",
            "E": "USA",
            "F": "US",
            "G": "T",
            "H": "",
            "I": "",
            "J": "NOA"
        }
    ],
    "CHA": [
        {
            "B": "KCHA",
            "C": "CHATTANOOGA",
            "D": "Chattanooga",
            "E": "USA",
            "F": "US",
            "G": "T",
            "H": "",
            "I": "",
            "J": "NOA"
        }
    ],
    "CHC": [
        {
            "B": "NZCH",
            "C": "CHRISTCHURCH",
            "D": "Christchurch",
            "E": "New Zealand",
            "F": "NZ",
            "G": "O",
            "H": "",
            "I": "",
            "J": "SWP"
        }
    ],
    "CHG": [
        {
            "B": "ZYCY",
            "C": "CHAOYANG",
            "D": "Chaoyang",
            "E": "Peoples Republic of China",
            "F": "CN",
            "G": "O",
            "H": "",
            "I": "",
            "J": "SEA"
        }
    ],
    "CHO": [
        {
            "B": "KCHO",
            "C": "Albemarle",
            "D": "Albemarle",
            "E": "USA",
            "F": "US",
            "G": "T",
            "H": "",
            "I": "",
            "J": "NOA"
        }
    ],
    "CHQ": [
        {
            "B": "LGSA",
            "C": "CHANIA - SOUDA",
            "D": "Chania Souda",
            "E": "Greece",
            "F": "GR",
            "G": "E",
            "H": "",
            "I": "",
            "J": "EUR"
        }
    ],
    "CHR": [
        {
            "B": "LFLX",
            "C": "CHATEAUROUX - DEOLS",
            "D": "Chateauroux",
            "E": "France",
            "F": "FR",
            "G": "E",
            "H": "",
            "I": "",
            "J": "EUR"
        }
    ],
    "CHS": [
        {
            "B": "KCHS",
            "C": "CHARLESTON AFB MUNICIPAL",
            "D": "Charleston",
            "E": "USA",
            "F": "US",
            "G": "T",
            "H": "",
            "I": "",
            "J": "NOA"
        }
    ],
    "CHV": [
        {
            "B": "LPCH",
            "C": "CHAVES",
            "D": "Chaves",
            "E": "Portugal",
            "F": "PT",
            "G": "E",
            "H": "",
            "I": "",
            "J": "EUR"
        }
    ],
    "CIA": [
        {
            "B": "LIRA",
            "C": "ROMA - CIAMPINO",
            "D": "Rome CIA",
            "E": "Italy",
            "F": "IT",
            "G": "E",
            "H": "",
            "I": "",
            "J": "EUR"
        }
    ],
    "CIC": [
        {
            "B": "KCIC",
            "C": "Chico, California",
            "D": "Chico Muni",
            "E": "USA",
            "F": "US",
            "G": "T",
            "H": "",
            "I": "",
            "J": "NOA"
        }
    ],
    "CIF": [
        {
            "B": "ZBCF",
            "C": "Chifeng",
            "D": "Yulong",
            "E": "Peoples Republic of China",
            "F": "CN",
            "G": "O",
            "H": "",
            "I": "",
            "J": "SEA"
        }
    ],
    "CIU": [
        {
            "B": "KCIU",
            "C": "Chippewa County",
            "D": "Chippewa County International",
            "E": "USA",
            "F": "US",
            "G": "T",
            "H": "",
            "I": "",
            "J": "NOA"
        }
    ],
    "CIW": [
        {
            "B": "TVSC",
            "C": "CANOUAN ISLAND",
            "D": "CANOUAN ISLAND",
            "E": "St Vincent and Grenadines",
            "F": "VC",
            "G": "T",
            "H": "",
            "I": "",
            "J": "CAR"
        }
    ],
    "CIX": [
        {
            "B": "SPHI",
            "C": "QUIÑONES GONZÁLEZ",
            "D": "Quiñones González",
            "E": "Peru",
            "F": "PE",
            "G": "T",
            "H": "",
            "I": "",
            "J": "SOA"
        }
    ],
    "CIY": [
        {
            "B": "LICB",
            "C": "COMISO AIRPORT",
            "D": "Comiso Airport",
            "E": "Italy",
            "F": "IT",
            "G": "E",
            "H": "",
            "I": "",
            "J": "EUR"
        }
    ],
    "CJA": [
        {
            "B": "SPJR",
            "C": "Cajamarca",
            "D": "Armando R. Iglesias",
            "E": "Peru",
            "F": "PE",
            "G": "T",
            "H": "",
            "I": "",
            "J": "SOA"
        }
    ],
    "CJB": [
        {
            "B": "VOCB",
            "C": "Peelamedu",
            "D": "Coimbatore International",
            "E": "India",
            "F": "IN",
            "G": "O",
            "H": "",
            "I": "",
            "J": "SAS"
        }
    ],
    "CJC": [
        {
            "B": "SCCF",
            "C": "El Loa",
            "D": "El Loa",
            "E": "Chile",
            "F": "CL",
            "G": "T",
            "H": "",
            "I": "",
            "J": "SOA"
        }
    ],
    "CJU": [
        {
            "B": "RKPC",
            "C": "Cheju",
            "D": "Jeju International",
            "E": "Republic of Korea",
            "F": "KR",
            "G": "O",
            "H": "",
            "I": "",
            "J": "JAK"
        }
    ],
    "CKG": [
        {
            "B": "ZUCK",
            "C": "Chongqing",
            "D": "Jiangbei International",
            "E": "Peoples Republic of China",
            "F": "CN",
            "G": "O",
            "H": "",
            "I": "",
            "J": "SEA"
        }
    ],
    "CKY": [
        {
            "B": "GUCY",
            "C": "CONAKRY",
            "D": "Conakry",
            "E": "Guinea",
            "F": "GN",
            "G": "O",
            "H": "",
            "I": "",
            "J": "AFR"
        }
    ],
    "CKZ": [
        {
            "B": "LTBH",
            "C": "CANAKKALE",
            "D": "Canakkale",
            "E": "Turkey",
            "F": "TR",
            "G": "E",
            "H": "",
            "I": "",
            "J": "EUR"
        }
    ],
    "CLB": [
        {
            "B": "EICB",
            "C": "CASTLEBAR",
            "D": "Castlebar",
            "E": "Republic of Ireland",
            "F": "IE",
            "G": "D",
            "H": "",
            "I": "",
            "J": "EUR"
        }
    ],
    "CLD": [
        {
            "B": "KCRQ",
            "C": "PALOMAR",
            "D": "Palomar",
            "E": "USA",
            "F": "US",
            "G": "T",
            "H": "",
            "I": "",
            "J": "NOA"
        }
    ],
    "CLE": [
        {
            "B": "KCLE",
            "C": "HOPKINS INTERNATIONAL",
            "D": "Cleveland",
            "E": "USA",
            "F": "US",
            "G": "T",
            "H": "",
            "I": "",
            "J": "NOA"
        }
    ],
    "CLF": [
        {
            "B": "EGYC",
            "C": "COLTISHALL",
            "D": "Coltishall",
            "E": "United Kingdom",
            "F": "GB",
            "G": "U",
            "H": "",
            "I": "",
            "J": "EUR"
        }
    ],
    "CLJ": [
        {
            "B": "LRCL",
            "C": "CLUJ NAPOCA",
            "D": "Cluj",
            "E": "Romania",
            "F": "RO",
            "G": "E",
            "H": "",
            "I": "",
            "J": "EUR"
        }
    ],
    "CLL": [
        {
            "B": "KCLL",
            "C": "Easterwood Field",
            "D": "Easterwood Field",
            "E": "USA",
            "F": "US",
            "G": "T",
            "H": "",
            "I": "",
            "J": "NOA"
        }
    ],
    "CLO": [
        {
            "B": "SKCL",
            "C": "Alfonso B. Aragon",
            "D": "Alfonso B Aragon International",
            "E": "Colombia",
            "F": "CO",
            "G": "T",
            "H": "",
            "I": "",
            "J": "SOA"
        }
    ],
    "CLQ": [
        {
            "B": "MMIA",
            "C": "Colima",
            "D": "Miguel de la Madrid",
            "E": "Mexico",
            "F": "MX",
            "G": "T",
            "H": "",
            "I": "",
            "J": "CEM"
        }
    ],
    "CLT": [
        {
            "B": "KCLT",
            "C": "CHARLOTTE/DOUGLAS INTERNATIONA",
            "D": "Charlotte",
            "E": "USA",
            "F": "US",
            "G": "T",
            "H": "",
            "I": "",
            "J": "NOA"
        }
    ],
    "CLY": [
        {
            "B": "LFKC",
            "C": "CALVI",
            "D": "Calvi",
            "E": "France",
            "F": "FR",
            "G": "E",
            "H": "",
            "I": "",
            "J": "EUR"
        }
    ],
    "CMA": [
        {
            "B": "KCMA",
            "C": "CAMARILLO",
            "D": "Camarillo Airport",
            "E": "USA",
            "F": "US",
            "G": "T",
            "H": "",
            "I": "",
            "J": "NOA"
        }
    ],
    "CMB": [
        {
            "B": "VCBI",
            "C": "BANDARANAYAKE INTERNATIONAL",
            "D": "Colombo",
            "E": "Sri Lanka",
            "F": "LK",
            "G": "O",
            "H": "",
            "I": "",
            "J": "SAS"
        }
    ],
    "CME": [
        {
            "B": "MMCE",
            "C": "Ciudad Del Carmen",
            "D": "Ciudad del Carmen International",
            "E": "Mexico",
            "F": "MX",
            "G": "T",
            "H": "",
            "I": "",
            "J": "CEM"
        }
    ],
    "CMF": [
        {
            "B": "LFLB",
            "C": "CHAMBERY",
            "D": "Chambery",
            "E": "France",
            "F": "FR",
            "G": "E",
            "H": "",
            "I": "",
            "J": "EUR"
        }
    ],
    "CMH": [
        {
            "B": "KCMH",
            "C": "COLUMBUS/PORT COLUMBUS INTL",
            "D": "Columbus",
            "E": "USA",
            "F": "US",
            "G": "T",
            "H": "",
            "I": "",
            "J": "NOA"
        }
    ],
    "CMI": [
        {
            "B": "KCMI",
            "C": "WILLARD AIRPORT",
            "D": "WILLARD AIRPORT",
            "E": "USA",
            "F": "US",
            "G": "T",
            "H": "",
            "I": "",
            "J": "NOA"
        }
    ],
    "CMN": [
        {
            "B": "GMMN",
            "C": "CASABLANCA - (MOH. V)",
            "D": "Casablanca",
            "E": "Morocco",
            "F": "MA",
            "G": "O",
            "H": "",
            "I": "",
            "J": "AFR"
        }
    ],
    "CMR": [
        {
            "B": "LFGA",
            "C": "COLMAR - HOUSSEN",
            "D": "Colmar Houssen",
            "E": "France",
            "F": "FR",
            "G": "E",
            "H": "",
            "I": "",
            "J": "EUR"
        }
    ],
    "CMX": [
        {
            "B": "KCMX",
            "C": "Houghton County",
            "D": "Houghton County Memorial",
            "E": "USA",
            "F": "US",
            "G": "T",
            "H": "",
            "I": "",
            "J": "NOA"
        }
    ],
    "CND": [
        {
            "B": "LRCK",
            "C": "CONSTANTA - M.KOGALINC",
            "D": "Constanta MKogalinc",
            "E": "Romania",
            "F": "RO",
            "G": "E",
            "H": "",
            "I": "",
            "J": "EUR"
        }
    ],
    "CNF": [
        {
            "B": "SBCF",
            "C": "Tancredo Neves Int'l.",
            "D": "Tancredo Neves International",
            "E": "Brazil",
            "F": "BR",
            "G": "T",
            "H": "",
            "I": "",
            "J": "SOA"
        }
    ],
    "CNG": [
        {
            "B": "LFBG",
            "C": "COGNAC - CHATEAU BERNARD",
            "D": "Cognac",
            "E": "France",
            "F": "FR",
            "G": "E",
            "H": "",
            "I": "",
            "J": "EUR"
        }
    ],
    "CNJ": [
        {
            "B": "YCCY",
            "C": "Cloncurry",
            "D": "Cloncurry",
            "E": "Australia",
            "F": "AU",
            "G": "O",
            "H": "",
            "I": "",
            "J": "SWP"
        }
    ],
    "CNL": [
        {
            "B": "EKSN",
            "C": "SINDAL",
            "D": "Sindal",
            "E": "Denmark",
            "F": "DK",
            "G": "E",
            "H": "",
            "I": "",
            "J": "EUR"
        }
    ],
    "CNR": [
        {
            "B": "SCRA",
            "C": "CHANARAL",
            "D": "Chanaral",
            "E": "Chile",
            "F": "CL",
            "G": "T",
            "H": "",
            "I": "",
            "J": "SOA"
        }
    ],
    "CNS": [
        {
            "B": "YBCS",
            "C": "Cairns",
            "D": "Cairns International",
            "E": "Australia",
            "F": "AU",
            "G": "O",
            "H": "",
            "I": "",
            "J": "SWP"
        }
    ],
    "CNX": [
        {
            "B": "VTCC",
            "C": "International",
            "D": "Chiang Mai Intl",
            "E": "Thailand",
            "F": "TH",
            "G": "O",
            "H": "",
            "I": "",
            "J": "SEA"
        }
    ],
    "CNY": [
        {
            "B": "KCNY",
            "C": "CANYONLANDS FIELD",
            "D": "Canyonlands Field",
            "E": "USA",
            "F": "US",
            "G": "T",
            "H": "",
            "I": "",
            "J": "NOA"
        }
    ],
    "COD": [
        {
            "B": "KCOD",
            "C": "Cody, Wyoming",
            "D": "Yellowstone Regional",
            "E": "USA",
            "F": "US",
            "G": "T",
            "H": "",
            "I": "",
            "J": "NOA"
        }
    ],
    "COF": [
        {
            "B": "KCOF",
            "C": "COCOA BEACH/PATRICK AFB",
            "D": "Cocoa Beach/Patrick Afb",
            "E": "USA",
            "F": "US",
            "G": "T",
            "H": "",
            "I": "",
            "J": "NOA"
        }
    ],
    "COK": [
        {
            "B": "VOCI",
            "C": "Cochin",
            "D": "Kochi International Airport",
            "E": "India",
            "F": "IN",
            "G": "O",
            "H": "",
            "I": "",
            "J": "SAS"
        }
    ],
    "COO": [
        {
            "B": "DBBB",
            "C": "Cotonou",
            "D": "Cadjehoun",
            "E": "Benin",
            "F": "BJ",
            "G": "O",
            "H": "",
            "I": "",
            "J": "AFR"
        }
    ],
    "COR": [
        {
            "B": "SACO",
            "C": "Pajas Blancas",
            "D": "Pajas Blancas",
            "E": "Argentina",
            "F": "AR",
            "G": "T",
            "H": "",
            "I": "",
            "J": "SOA"
        }
    ],
    "COV": [
        {
            "B": "LPCV",
            "C": "COVILHA",
            "D": "Covilha",
            "E": "Portugal",
            "F": "PT",
            "G": "E",
            "H": "",
            "I": "",
            "J": "EUR"
        }
    ],
    "CPC": [
        {
            "B": "SAZY",
            "C": "Chapelco",
            "D": "Aviador Carlos Campos",
            "E": "Argentina",
            "F": "AR",
            "G": "T",
            "H": "",
            "I": "",
            "J": "SOA"
        }
    ],
    "CPE": [
        {
            "B": "MMCP",
            "C": "CAMPECHE",
            "D": "Campeche",
            "E": "Mexico",
            "F": "MX",
            "G": "T",
            "H": "",
            "I": "",
            "J": "CEM"
        }
    ],
    "CPH": [
        {
            "B": "EKCH",
            "C": "COPENHAGEN",
            "D": "Copenhagen",
            "E": "Denmark",
            "F": "DK",
            "G": "E",
            "H": "",
            "I": "",
            "J": "EUR"
        }
    ],
    "CPJ": [
        {
            "B": "",
            "C": "",
            "D": "",
            "E": "UNKNOWN",
            "F": "",
            "G": "A",
            "H": "",
            "I": "",
            "J": ""
        }
    ],
    "CPR": [
        {
            "B": "KCPR",
            "C": "Casper",
            "D": "Casper",
            "E": "USA",
            "F": "US",
            "G": "T",
            "H": "",
            "I": "",
            "J": "NOA"
        }
    ],
    "CPT": [
        {
            "B": "FACT",
            "C": "CAPE TOWN",
            "D": "Cape Town",
            "E": "South Africa",
            "F": "ZA",
            "G": "O",
            "H": "",
            "I": "",
            "J": "AFR"
        }
    ],
    "CPV": [
        {
            "B": "SBKG",
            "C": "CAMPINA GRANDE",
            "D": "Campina Grande/Joao Suassuna",
            "E": "Brazil",
            "F": "BR",
            "G": "T",
            "H": "",
            "I": "",
            "J": "SOA"
        }
    ],
    "CQF": [
        {
            "B": "LFAC",
            "C": "CALAIS / DUNKERQUE",
            "D": "Calais",
            "E": "France",
            "F": "FR",
            "G": "E",
            "H": "",
            "I": "",
            "J": "EUR"
        }
    ],
    "CQM": [
        {
            "B": "LERL",
            "C": "CIUDAD REAL",
            "D": "Ciudad Real",
            "E": "Spain",
            "F": "ES",
            "G": "E",
            "H": "",
            "I": "",
            "J": "EUR"
        }
    ],
    "CRA": [
        {
            "B": "LRCV",
            "C": "CRAIOVA",
            "D": "Craiova",
            "E": "Romania",
            "F": "RO",
            "G": "E",
            "H": "",
            "I": "",
            "J": "EUR"
        }
    ],
    "CRK": [
        {
            "B": "RPLC",
            "C": "CLARK INTERNATIONAL",
            "D": "Clark int",
            "E": "Philippines",
            "F": "PH",
            "G": "O",
            "H": "",
            "I": "",
            "J": "SEA"
        }
    ],
    "CRL": [
        {
            "B": "EBCI",
            "C": "BRUSSELS - CHARLEROI",
            "D": "Brussels CRL",
            "E": "Belgium",
            "F": "BE",
            "G": "E",
            "H": "",
            "I": "",
            "J": "EUR"
        }
    ],
    "CRN": [
        {
            "B": "",
            "C": "CROMARTY",
            "D": "Cromarty",
            "E": "United Kingdom",
            "F": "GB",
            "G": "U",
            "H": "",
            "I": "",
            "J": "EUR"
        }
    ],
    "CRP": [
        {
            "B": "KCRP",
            "C": "International",
            "D": "Corpus Christi Intl",
            "E": "USA",
            "F": "US",
            "G": "T",
            "H": "",
            "I": "",
            "J": "NOA"
        }
    ],
    "CRV": [
        {
            "B": "LIBC",
            "C": "CROTONE",
            "D": "Crotone",
            "E": "Italy",
            "F": "IT",
            "G": "E",
            "H": "",
            "I": "",
            "J": "EUR"
        }
    ],
    "CRW": [
        {
            "B": "KCRW",
            "C": "CHARLESTON",
            "D": "Charleston",
            "E": "USA",
            "F": "US",
            "G": "T",
            "H": "",
            "I": "",
            "J": "NOA"
        }
    ],
    "CSB": [
        {
            "B": "LRCS",
            "C": "CARANSEBES",
            "D": "Caransebes",
            "E": "Romania",
            "F": "RO",
            "G": "E",
            "H": "",
            "I": "",
            "J": "EUR"
        }
    ],
    "CSF": [
        {
            "B": "LFPC",
            "C": "CREIL",
            "D": "Creil",
            "E": "France",
            "F": "FR",
            "G": "E",
            "H": "",
            "I": "",
            "J": "EUR"
        }
    ],
    "CSG": [
        {
            "B": "KCSG",
            "C": "Columbus, Georgia",
            "D": "Columbus Airport",
            "E": "USA",
            "F": "US",
            "G": "T",
            "H": "",
            "I": "",
            "J": "NOA"
        }
    ],
    "CSI": [
        {
            "B": "YCAS",
            "C": "CASINO",
            "D": "Casino",
            "E": "Australia",
            "F": "AU",
            "G": "O",
            "H": "",
            "I": "",
            "J": "SWP"
        }
    ],
    "CSO": [
        {
            "B": "EDBC",
            "C": "MAGDEBURG-COCHSTEDT",
            "D": "MAGDEBURG-COCHSTEDT AIRPORT",
            "E": "Germany",
            "F": "DE",
            "G": "E",
            "H": "",
            "I": "",
            "J": "EUR"
        }
    ],
    "CSX": [
        {
            "B": "ZGCS",
            "C": "Changsha",
            "D": "Huanghua International Airport",
            "E": "Peoples Republic of China",
            "F": "CN",
            "G": "O",
            "H": "",
            "I": "",
            "J": "SEA"
        }
    ],
    "CTA": [
        {
            "B": "LICC",
            "C": "CATANIA",
            "D": "Catania",
            "E": "Italy",
            "F": "IT",
            "G": "E",
            "H": "",
            "I": "",
            "J": "EUR"
        }
    ],
    "CTG": [
        {
            "B": "SKCG",
            "C": "RAFAEL NUNEZ INTL",
            "D": "RAFAEL NUNEZ INTERNATIONAL AIRPORT",
            "E": "Colombia",
            "F": "CO",
            "G": "T",
            "H": "",
            "I": "",
            "J": "SOA"
        }
    ],
    "CTS": [
        {
            "B": "RJCC",
            "C": "SAPPORA - CHITOSE",
            "D": "Sappora",
            "E": "Japan",
            "F": "JP",
            "G": "O",
            "H": "",
            "I": "",
            "J": "JAK"
        }
    ],
    "CTT": [
        {
            "B": "LFMQ",
            "C": "LE CASTELLET",
            "D": "Le Castellet",
            "E": "France",
            "F": "FR",
            "G": "E",
            "H": "",
            "I": "",
            "J": "EUR"
        }
    ],
    "CTU": [
        {
            "B": "ZUUU",
            "C": "CHENGDU",
            "D": "Chengdu Shuanglia International",
            "E": "Peoples Republic of China",
            "F": "CN",
            "G": "O",
            "H": "",
            "I": "",
            "J": "SEA"
        }
    ],
    "CUC": [
        {
            "B": "SKCC",
            "C": "Camilo Dazo",
            "D": "Camilo Daza",
            "E": "Colombia",
            "F": "CO",
            "G": "T",
            "H": "",
            "I": "",
            "J": "SOA"
        }
    ],
    "CUE": [
        {
            "B": "SECU",
            "C": "Cuenca",
            "D": "Mariscal Lamar Intl",
            "E": "Ecuador",
            "F": "EC",
            "G": "T",
            "H": "",
            "I": "",
            "J": "SOA"
        }
    ],
    "CUF": [
        {
            "B": "LIMZ",
            "C": "CUNEO - LEVALDIGI",
            "D": "Cuneo Levaldigi",
            "E": "Italy",
            "F": "IT",
            "G": "E",
            "H": "",
            "I": "",
            "J": "EUR"
        }
    ],
    "CUL": [
        {
            "B": "MMCL",
            "C": "Fedl De Bachigualato",
            "D": "Fed. De Bachigualato",
            "E": "Mexico",
            "F": "MX",
            "G": "T",
            "H": "",
            "I": "",
            "J": "CEM"
        }
    ],
    "CUN": [
        {
            "B": "MMUN",
            "C": "CANCUN",
            "D": "Cancun",
            "E": "Mexico",
            "F": "MX",
            "G": "T",
            "H": "",
            "I": "",
            "J": "CEM"
        }
    ],
    "CUR": [
        {
            "B": "TNCC",
            "C": "CURACAO-HATO",
            "D": "Curacao",
            "E": "Netherlands Antilles",
            "F": "AN",
            "G": "T",
            "H": "",
            "I": "",
            "J": "CAR"
        }
    ],
    "CUU": [
        {
            "B": "MMCU",
            "C": "CHIHUAHUA",
            "D": "Chihuahua",
            "E": "Mexico",
            "F": "MX",
            "G": "T",
            "H": "",
            "I": "",
            "J": "CEM"
        }
    ],
    "CUZ": [
        {
            "B": "SPZO",
            "C": "Velazco Astete",
            "D": "A.Velasco Astete Intl",
            "E": "Peru",
            "F": "PE",
            "G": "T",
            "H": "",
            "I": "",
            "J": "SOA"
        }
    ],
    "CVF": [
        {
            "B": "LFLJ",
            "C": "COURCHEVEL",
            "D": "Courchevel",
            "E": "France",
            "F": "FR",
            "G": "E",
            "H": "",
            "I": "",
            "J": "EUR"
        }
    ],
    "CVG": [
        {
            "B": "KCVG",
            "C": "CINCINNATI COVINGTON",
            "D": "Covington",
            "E": "USA",
            "F": "US",
            "G": "T",
            "H": "",
            "I": "",
            "J": "NOA"
        }
    ],
    "CVM": [
        {
            "B": "MMCV",
            "C": "CIUDAD VICTORIA",
            "D": "Ciudad Victoria",
            "E": "Mexico",
            "F": "MX",
            "G": "T",
            "H": "",
            "I": "",
            "J": "CEM"
        }
    ],
    "CVT": [
        {
            "B": "EGBE",
            "C": "COVENTRY",
            "D": "Coventry",
            "E": "United Kingdom",
            "F": "GB",
            "G": "U",
            "H": "",
            "I": "",
            "J": "EUR"
        }
    ],
    "CVU": [
        {
            "B": "LPCR",
            "C": "CORVO",
            "D": "Corvo",
            "E": "Portugal",
            "F": "PT",
            "G": "E",
            "H": "",
            "I": "",
            "J": "EUR"
        }
    ],
    "CWA": [
        {
            "B": "KCWA",
            "C": "Mosinee, Wisconsin",
            "D": "Central Wisconsin",
            "E": "USA",
            "F": "US",
            "G": "T",
            "H": "",
            "I": "",
            "J": "NOA"
        }
    ],
    "CWB": [
        {
            "B": "SBCT",
            "C": "Afonso Pena",
            "D": "Afonso Pena International",
            "E": "Brazil",
            "F": "BR",
            "G": "T",
            "H": "",
            "I": "",
            "J": "SOA"
        }
    ],
    "CWC": [
        {
            "B": "UKLN",
            "C": "CHERNIVTSI",
            "D": "Chernitsi",
            "E": "Ukraine",
            "F": "UA",
            "G": "E",
            "H": "",
            "I": "",
            "J": "EUR"
        }
    ],
    "CWF": [
        {
            "B": "KCWF",
            "C": "CHENNAULT INT",
            "D": "CHENNAULT INT",
            "E": "USA",
            "F": "US",
            "G": "T",
            "H": "",
            "I": "",
            "J": "NOA"
        }
    ],
    "CWL": [
        {
            "B": "EGFF",
            "C": "CARDIFF",
            "D": "Cardiff",
            "E": "United Kingdom",
            "F": "GB",
            "G": "U",
            "H": "",
            "I": "",
            "J": "EUR"
        }
    ],
    "CXB": [
        {
            "B": "VGCB",
            "C": "COX'S BAZAR",
            "D": "Cox'S Bazar",
            "E": "Bangladesh",
            "F": "BD",
            "G": "O",
            "H": "",
            "I": "",
            "J": "SAS"
        }
    ],
    "CYB": [
        {
            "B": "MWCB",
            "C": "Gerrard-Smith",
            "D": "C. Kirkconnell Intl",
            "E": "Cayman Islands",
            "F": "KY",
            "G": "T",
            "H": "",
            "I": "",
            "J": "CAR"
        }
    ],
    "CYS": [
        {
            "B": "KCYS",
            "C": "CHEYENNE/CHEYENNE",
            "D": "Cheyenne/Cheyenne",
            "E": "USA",
            "F": "US",
            "G": "T",
            "H": "",
            "I": "",
            "J": "NOA"
        }
    ],
    "CZ1": [
        {
            "B": "LKKB",
            "C": "KBELY PRAHA",
            "D": "KBELY PRAHA",
            "E": "Czech Republic",
            "F": "CZ",
            "G": "E",
            "H": "",
            "I": "",
            "J": "EUR"
        }
    ],
    "CZ2": [
        {
            "B": "LKHK",
            "C": "Hradec Kralove Airport",
            "D": "Hradec Kralove Airport",
            "E": "Czech Republic",
            "F": "CZ",
            "G": "E",
            "H": "",
            "I": "",
            "J": "EUR"
        }
    ],
    "CZL": [
        {
            "B": "DABC",
            "C": "Ain El Bey",
            "D": "Mohamed Boudiaf",
            "E": "Algeria",
            "F": "DZ",
            "G": "O",
            "H": "",
            "I": "",
            "J": "AFR"
        }
    ],
    "CZM": [
        {
            "B": "MMCZ",
            "C": "Cozumel",
            "D": "Cozumel International",
            "E": "Mexico",
            "F": "MX",
            "G": "T",
            "H": "",
            "I": "",
            "J": "CEM"
        }
    ],
    "CZX": [
        {
            "B": "ZSCG",
            "C": "CHANGZHOU/BENNIU",
            "D": "Changzhou/Benniu",
            "E": "Peoples Republic of China",
            "F": "CN",
            "G": "O",
            "H": "",
            "I": "",
            "J": "SEA"
        }
    ],
    "D01": [
        {
            "B": "ETNH",
            "C": "HOHN",
            "D": "Hohn",
            "E": "Germany",
            "F": "DE",
            "G": "E",
            "H": "",
            "I": "",
            "J": "EUR"
        }
    ],
    "D02": [
        {
            "B": "ETNW",
            "C": "WUNSTORF",
            "D": "Wunstorf",
            "E": "Germany",
            "F": "DE",
            "G": "E",
            "H": "",
            "I": "",
            "J": "EUR"
        }
    ],
    "D03": [
        {
            "B": "EDTY",
            "C": "SCHWABISH HALL - HESSENTAL",
            "D": "Schwabish hall",
            "E": "Germany",
            "F": "DE",
            "G": "E",
            "H": "",
            "I": "",
            "J": "EUR"
        }
    ],
    "D04": [
        {
            "B": "EDMY",
            "C": "MUHLDORF",
            "D": "Muhldorf",
            "E": "Germany",
            "F": "DE",
            "G": "E",
            "H": "",
            "I": "",
            "J": "EUR"
        }
    ],
    "D05": [
        {
            "B": "EDTD",
            "C": "DONAUESCHINGEN",
            "D": "Donaueschingen",
            "E": "Germany",
            "F": "DE",
            "G": "E",
            "H": "",
            "I": "",
            "J": "EUR"
        }
    ],
    "D06": [
        {
            "B": "EDVY",
            "C": "PORTA WESTFALIC",
            "D": "Porta westfalic",
            "E": "Germany",
            "F": "DE",
            "G": "E",
            "H": "",
            "I": "",
            "J": "EUR"
        }
    ],
    "D07": [
        {
            "B": "EDAR",
            "C": "PIRNA-PRATSCHWITZ",
            "D": "Pirna",
            "E": "Germany",
            "F": "DE",
            "G": "E",
            "H": "",
            "I": "",
            "J": "EUR"
        }
    ],
    "D08": [
        {
            "B": "EDBR",
            "C": "ROTHEBURG / GORLITZ",
            "D": "Rothenburg",
            "E": "Germany",
            "F": "DE",
            "G": "E",
            "H": "",
            "I": "",
            "J": "EUR"
        }
    ],
    "D09": [
        {
            "B": "ETND",
            "C": "DIEPHOLZ",
            "D": "Diepholz",
            "E": "Germany",
            "F": "DE",
            "G": "E",
            "H": "",
            "I": "",
            "J": "EUR"
        }
    ],
    "D10": [
        {
            "B": "EDFB",
            "C": "REICHELSHEIM",
            "D": "Reichelsheim",
            "E": "Germany",
            "F": "DE",
            "G": "E",
            "H": "",
            "I": "",
            "J": "EUR"
        }
    ],
    "D11": [
        {
            "B": "EDBM",
            "C": "MAGDEBURG",
            "D": "Magdeburg",
            "E": "Germany",
            "F": "DE",
            "G": "E",
            "H": "",
            "I": "",
            "J": "EUR"
        }
    ],
    "D12": [
        {
            "B": "ETSA",
            "C": "LANDSBERG AB",
            "D": "LANDSBERG AB",
            "E": "Germany",
            "F": "DE",
            "G": "E",
            "H": "",
            "I": "",
            "J": "EUR"
        }
    ],
    "D13": [
        {
            "B": "ETNT",
            "C": "WITTMUNDHAFEN AB",
            "D": "WITTMUNDHAFEN AB",
            "E": "Germany",
            "F": "DE",
            "G": "E",
            "H": "",
            "I": "",
            "J": "EUR"
        }
    ],
    "D14": [
        {
            "B": "EDFU",
            "C": "MAINBULLAU",
            "D": "MAINBULLAU",
            "E": "Germany",
            "F": "DE",
            "G": "E",
            "H": "",
            "I": "",
            "J": "EUR"
        }
    ],
    "D15": [
        {
            "B": "EDXR",
            "C": "RENDSBURG",
            "D": "RENDSBURG SCHACHTHOLM",
            "E": "Germany",
            "F": "DE",
            "G": "E",
            "H": "",
            "I": "",
            "J": "EUR"
        }
    ],
    "D16": [
        {
            "B": "EDCO",
            "C": "OBERMEHLER",
            "D": "OBERMEHLER",
            "E": "Germany",
            "F": "DE",
            "G": "E",
            "H": "",
            "I": "",
            "J": "EUR"
        }
    ],
    "D32": [
        {
            "B": "EDAD",
            "C": "DESSAU",
            "D": "Dessau",
            "E": "Germany",
            "F": "DE",
            "G": "E",
            "H": "",
            "I": "",
            "J": "EUR"
        }
    ],
    "DAB": [
        {
            "B": "KDAB",
            "C": "Regional",
            "D": "Daytona Beach International",
            "E": "USA",
            "F": "US",
            "G": "T",
            "H": "",
            "I": "",
            "J": "NOA"
        }
    ],
    "DAC": [
        {
            "B": "VGHS",
            "C": "Zia International",
            "D": "Hazrat Shahjalal International",
            "E": "Bangladesh",
            "F": "BD",
            "G": "O",
            "H": "",
            "I": "",
            "J": "SAS"
        }
    ],
    "DAD": [
        {
            "B": "VVDN",
            "C": "DA NANG",
            "D": "DA NANG INTERNATIONAL AIRPORT",
            "E": "Vietnam",
            "F": "VN",
            "G": "O",
            "H": "",
            "I": "",
            "J": "SEA"
        }
    ],
    "DAL": [
        {
            "B": "KDAL",
            "C": "DALLAS - LOVE FIELD",
            "D": "Dallas",
            "E": "USA",
            "F": "US",
            "G": "T",
            "H": "",
            "I": "",
            "J": "NOA"
        }
    ],
    "DAM": [
        {
            "B": "OSDI",
            "C": "DAMASCUS",
            "D": "Damascus",
            "E": "Syrian Arab Republic",
            "F": "SY",
            "G": "O",
            "H": "",
            "I": "",
            "J": "MDE"
        }
    ],
    "DAN": [
        {
            "B": "KDAN",
            "C": "DANVILLE",
            "D": "Danville",
            "E": "USA",
            "F": "US",
            "G": "T",
            "H": "",
            "I": "",
            "J": "NOA"
        }
    ],
    "DAR": [
        {
            "B": "HTDA",
            "C": "DAR ES SALAAM",
            "D": "Dar Es Salaam",
            "E": "Tanzania",
            "F": "TZ",
            "G": "O",
            "H": "",
            "I": "",
            "J": "AFR"
        }
    ],
    "DAX": [
        {
            "B": "ZUDX",
            "C": "Daxian",
            "D": "Heshi",
            "E": "Peoples Republic of China",
            "F": "CN",
            "G": "O",
            "H": "",
            "I": "",
            "J": "SEA"
        }
    ],
    "DAY": [
        {
            "B": "KDAY",
            "C": "DAYTON INTERNATIONAL",
            "D": "Dayton",
            "E": "USA",
            "F": "US",
            "G": "T",
            "H": "",
            "I": "",
            "J": "NOA"
        }
    ],
    "DBO": [
        {
            "B": "YSDU",
            "C": "Dubbo",
            "D": "Dubbo City Airport",
            "E": "Australia",
            "F": "AU",
            "G": "O",
            "H": "",
            "I": "",
            "J": "SWP"
        }
    ],
    "DBQ": [
        {
            "B": "KDBQ",
            "C": "DUBUQUE",
            "D": "Dubuque",
            "E": "USA",
            "F": "US",
            "G": "T",
            "H": "",
            "I": "",
            "J": "NOA"
        }
    ],
    "DBU": [
        {
            "B": "",
            "C": "",
            "D": "",
            "E": "UNKNOWN",
            "F": "",
            "G": "D",
            "H": "",
            "I": "",
            "J": ""
        }
    ],
    "DBV": [
        {
            "B": "LDDU",
            "C": "DUBROVNIK",
            "D": "Dubrovnik",
            "E": "Croatia",
            "F": "HR",
            "G": "E",
            "H": "",
            "I": "",
            "J": "EUR"
        }
    ],
    "DCA": [
        {
            "B": "KDCA",
            "C": "WASHINGTON DC - NATIONAL",
            "D": "Washington",
            "E": "USA",
            "F": "US",
            "G": "T",
            "H": "",
            "I": "",
            "J": "NOA"
        }
    ],
    "DCI": [
        {
            "B": "LIED",
            "C": "DECIMOMANNU",
            "D": "Decimomannu",
            "E": "Italy",
            "F": "IT",
            "G": "E",
            "H": "",
            "I": "",
            "J": "EUR"
        }
    ],
    "DCM": [
        {
            "B": "LFCK",
            "C": "CASTRES",
            "D": "Castres",
            "E": "France",
            "F": "FR",
            "G": "E",
            "H": "",
            "I": "",
            "J": "EUR"
        }
    ],
    "DCS": [
        {
            "B": "EGXX",
            "C": "DONCASTER FINNINGLY",
            "D": "Doncaster",
            "E": "United Kingdom",
            "F": "GB",
            "G": "U",
            "H": "",
            "I": "",
            "J": "EUR"
        }
    ],
    "DDC": [
        {
            "B": "KDDC",
            "C": "DODGE CITY",
            "D": "Dodge City",
            "E": "USA",
            "F": "US",
            "G": "T",
            "H": "",
            "I": "",
            "J": "NOA"
        }
    ],
    "DEB": [
        {
            "B": "LHDC",
            "C": "DEBRECEN",
            "D": "Debrecen",
            "E": "Hungary",
            "F": "HU",
            "G": "E",
            "H": "",
            "I": "",
            "J": "EUR"
        }
    ],
    "DEC": [
        {
            "B": "KDEC",
            "C": "DECATUR",
            "D": "Decatur",
            "E": "USA",
            "F": "US",
            "G": "T",
            "H": "",
            "I": "",
            "J": "NOA"
        }
    ],
    "DED": [
        {
            "B": "VIDN",
            "C": "Dehra Dun",
            "D": "Jolly Grant",
            "E": "India",
            "F": "IN",
            "G": "O",
            "H": "",
            "I": "",
            "J": "SAS"
        }
    ],
    "DEL": [
        {
            "B": "VIDP",
            "C": "DELHI",
            "D": "Delhi",
            "E": "India",
            "F": "IN",
            "G": "O",
            "H": "",
            "I": "",
            "J": "SAS"
        }
    ],
    "DEN": [
        {
            "B": "KDEN",
            "C": "DENVER INTERNATIONAL",
            "D": "Denver",
            "E": "USA",
            "F": "US",
            "G": "T",
            "H": "",
            "I": "",
            "J": "NOA"
        }
    ],
    "DET": [
        {
            "B": "KDET",
            "C": "DETROIT CITY",
            "D": "Detroit",
            "E": "USA",
            "F": "US",
            "G": "T",
            "H": "",
            "I": "",
            "J": "NOA"
        }
    ],
    "DFW": [
        {
            "B": "KDFW",
            "C": "DALLAS - FORT WORTH",
            "D": "Dallas",
            "E": "USA",
            "F": "US",
            "G": "T",
            "H": "",
            "I": "",
            "J": "NOA"
        }
    ],
    "DGO": [
        {
            "B": "MMDO",
            "C": "Guadalupe Victoria",
            "D": "Guadalupe Victoria Intl",
            "E": "Mexico",
            "F": "MX",
            "G": "T",
            "H": "",
            "I": "",
            "J": "CEM"
        }
    ],
    "DGT": [
        {
            "B": "RPVD",
            "C": "Dumaguete",
            "D": "Sibulan",
            "E": "Philippines",
            "F": "PH",
            "G": "O",
            "H": "",
            "I": "",
            "J": "SEA"
        }
    ],
    "DGX": [
        {
            "B": "EGDX",
            "C": "ST. ATHAN MIL",
            "D": "St Athan",
            "E": "United Kingdom",
            "F": "GB",
            "G": "U",
            "H": "",
            "I": "",
            "J": "EUR"
        }
    ],
    "DHA": [
        {
            "B": "OEDR",
            "C": "King Abdulaziz AB",
            "D": "King Abdulaziz Air Base",
            "E": "Saudi Arabia",
            "F": "SA",
            "G": "O",
            "H": "",
            "I": "",
            "J": "MDE"
        }
    ],
    "DHM": [
        {
            "B": "VIGG",
            "C": "Kangra (Himachal Pradesh)",
            "D": "Kangra Airport",
            "E": "India",
            "F": "IN",
            "G": "O",
            "H": "",
            "I": "",
            "J": "SAS"
        }
    ],
    "DHR": [
        {
            "B": "EHKD",
            "C": "Den Helder Airport",
            "D": "Den Helder",
            "E": "Netherlands",
            "F": "NL",
            "G": "E",
            "H": "",
            "I": "",
            "J": "EUR"
        }
    ],
    "DIA": [
        {
            "B": "OTBD",
            "C": "DOHA",
            "D": "DOHA",
            "E": "Qatar",
            "F": "QA",
            "G": "O",
            "H": "",
            "I": "",
            "J": "MDE"
        }
    ],
    "DIB": [
        {
            "B": "VEMN",
            "C": "DIBRUGARH",
            "D": "Dibrugarh",
            "E": "India",
            "F": "IN",
            "G": "O",
            "H": "",
            "I": "",
            "J": "SAS"
        }
    ],
    "DIE": [
        {
            "B": "FMNA",
            "C": "ANTSIRANANA",
            "D": "Antsiranana",
            "E": "Madagascar",
            "F": "MG",
            "G": "O",
            "H": "",
            "I": "",
            "J": "AFR"
        }
    ],
    "DIJ": [
        {
            "B": "LFSD",
            "C": "DIJON",
            "D": "Dijon",
            "E": "France",
            "F": "FR",
            "G": "E",
            "H": "",
            "I": "",
            "J": "EUR"
        }
    ],
    "DIK": [
        {
            "B": "KDIK",
            "C": "DICKINSON",
            "D": "Dickinson",
            "E": "USA",
            "F": "US",
            "G": "T",
            "H": "",
            "I": "",
            "J": "NOA"
        }
    ],
    "DIL": [
        {
            "B": "WPDL",
            "C": "Comoro",
            "D": "Pres. Nicolau Lobato",
            "E": "East Timor",
            "F": "TL",
            "G": "O",
            "H": "",
            "I": "",
            "J": "SEA"
        }
    ],
    "DIR": [
        {
            "B": "HADR",
            "C": "Aba Tenna D Yilma",
            "D": "Aba Tenna D Yilma International",
            "E": "Ethiopia",
            "F": "ET",
            "G": "O",
            "H": "",
            "I": "",
            "J": "AFR"
        }
    ],
    "DIU": [
        {
            "B": "VA1P",
            "C": "Diu (Daman and Diu)",
            "D": "Diu",
            "E": "India",
            "F": "IN",
            "G": "O",
            "H": "",
            "I": "",
            "J": "SAS"
        }
    ],
    "DIY": [
        {
            "B": "LTCC",
            "C": "DIYARBAKIR",
            "D": "Diyarbakir",
            "E": "Turkey",
            "F": "TR",
            "G": "E",
            "H": "",
            "I": "",
            "J": "EUR"
        }
    ],
    "DJE": [
        {
            "B": "DTTJ",
            "C": "DJERBA",
            "D": "Djerba",
            "E": "Tunisia",
            "F": "TN",
            "G": "O",
            "H": "",
            "I": "",
            "J": "AFR"
        }
    ],
    "DJJ": [
        {
            "B": "WAJJ",
            "C": "JAYAPURA/SENTANI",
            "D": "Jayapura/Sentani",
            "E": "Indonesia",
            "F": "ID",
            "G": "O",
            "H": "",
            "I": "",
            "J": "SEA"
        }
    ],
    "DKR": [
        {
            "B": "GOOY",
            "C": "YOFF",
            "D": "Dakar",
            "E": "Senegal",
            "F": "SN",
            "G": "O",
            "H": "",
            "I": "",
            "J": "AFR"
        }
    ],
    "DLA": [
        {
            "B": "FKKD",
            "C": "DOUALA",
            "D": "Douala international airport",
            "E": "Cameroon",
            "F": "CM",
            "G": "O",
            "H": "",
            "I": "",
            "J": "AFR"
        }
    ],
    "DLC": [
        {
            "B": "ZYTL",
            "C": "Dalian",
            "D": "Zhoushuizi International",
            "E": "Peoples Republic of China",
            "F": "CN",
            "G": "O",
            "H": "",
            "I": "",
            "J": "SEA"
        }
    ],
    "DLE": [
        {
            "B": "LFGJ",
            "C": "DOLE - TAVAUX",
            "D": "Dole Tavaux",
            "E": "France",
            "F": "FR",
            "G": "E",
            "H": "",
            "I": "",
            "J": "EUR"
        }
    ],
    "DLG": [
        {
            "B": "PADL",
            "C": "DILLINGHAM APRT,.",
            "D": "Dillingham Aprt,.",
            "E": "USA",
            "F": "US",
            "G": "T",
            "H": "",
            "I": "",
            "J": "NOA"
        }
    ],
    "DLH": [
        {
            "B": "KDLH",
            "C": "DULUTH INTERNATIONAL",
            "D": "Deluth",
            "E": "USA",
            "F": "US",
            "G": "T",
            "H": "",
            "I": "",
            "J": "NOA"
        }
    ],
    "DLI": [
        {
            "B": "VVDL",
            "C": "LIEN KHUONG",
            "D": "Lien Khuong",
            "E": "Vietnam",
            "F": "VN",
            "G": "O",
            "H": "",
            "I": "",
            "J": "SEA"
        }
    ],
    "DLM": [
        {
            "B": "LTBS",
            "C": "DALAMAN - MULGA",
            "D": "Dalaman",
            "E": "Turkey",
            "F": "TR",
            "G": "E",
            "H": "",
            "I": "",
            "J": "EUR"
        }
    ],
    "DLU": [
        {
            "B": "ZPDL",
            "C": "DALI",
            "D": "Dali",
            "E": "Peoples Republic of China",
            "F": "CN",
            "G": "O",
            "H": "",
            "I": "",
            "J": "SEA"
        }
    ],
    "DMA": [
        {
            "B": "KDMA",
            "C": "TUCSON",
            "D": "Davis monthan air force base",
            "E": "USA",
            "F": "US",
            "G": "T",
            "H": "",
            "I": "",
            "J": "NOA"
        }
    ],
    "DME": [
        {
            "B": "UUDD",
            "C": "MOSKVA - DOMODEDOVO",
            "D": "Moscow",
            "E": "Russian Federation",
            "F": "RU",
            "G": "E",
            "H": "",
            "I": "",
            "J": "EUR"
        }
    ],
    "DMK": [
        {
            "B": "VTBD",
            "C": "BANGKOK-DON MUANG",
            "D": "Bangkok",
            "E": "Thailand",
            "F": "TH",
            "G": "O",
            "H": "",
            "I": "",
            "J": "SEA"
        }
    ],
    "DMM": [
        {
            "B": "OEDF",
            "C": "KING FAHD",
            "D": "KING FAHD INTL AIRPORT",
            "E": "Saudi Arabia",
            "F": "SA",
            "G": "O",
            "H": "",
            "I": "",
            "J": "MDE"
        }
    ],
    "DMN": [
        {
            "B": "KDMN",
            "C": "Deming Municipal Airport",
            "D": "Deming Municipal Airport",
            "E": "USA",
            "F": "US",
            "G": "T",
            "H": "",
            "I": "",
            "J": "NOA"
        }
    ],
    "DMS": [
        {
            "B": "",
            "C": "Bus Station Dammam Airport",
            "D": "SABTCO Bus Station",
            "E": "Saudi Arabia",
            "F": "SA",
            "G": "O",
            "H": "",
            "I": "",
            "J": "MDE"
        }
    ],
    "DND": [
        {
            "B": "EGPN",
            "C": "DUNDEE",
            "D": "Dundee",
            "E": "United Kingdom",
            "F": "GB",
            "G": "U",
            "H": "",
            "I": "",
            "J": "EUR"
        }
    ],
    "DNK": [
        {
            "B": "UKDD",
            "C": "DNEPROPERTROVSK",
            "D": "Dnepropertrovsk",
            "E": "Ukraine",
            "F": "UA",
            "G": "E",
            "H": "",
            "I": "",
            "J": "EUR"
        }
    ],
    "DNR": [
        {
            "B": "LFRD",
            "C": "DINARD",
            "D": "Dinard",
            "E": "France",
            "F": "FR",
            "G": "E",
            "H": "",
            "I": "",
            "J": "EUR"
        }
    ],
    "DNZ": [
        {
            "B": "LTAY",
            "C": "DENIZLI",
            "D": "Denizli",
            "E": "Turkey",
            "F": "TR",
            "G": "E",
            "H": "",
            "I": "",
            "J": "EUR"
        }
    ],
    "DOH": [
        {
            "B": "OTHH",
            "C": "DOHA INTERNATIONAL",
            "D": "Doha",
            "E": "Qatar",
            "F": "QA",
            "G": "O",
            "H": "",
            "I": "",
            "J": "MDE"
        }
    ],
    "DOK": [
        {
            "B": "UKCC",
            "C": "DONETSK",
            "D": "",
            "E": "Ukraine",
            "F": "UA",
            "G": "E",
            "H": "",
            "I": "",
            "J": ""
        }
    ],
    "DOL": [
        {
            "B": "LFRG",
            "C": "DEAUVILLE",
            "D": "Deauville",
            "E": "France",
            "F": "FR",
            "G": "E",
            "H": "",
            "I": "",
            "J": "EUR"
        }
    ],
    "DOM": [
        {
            "B": "TDPD",
            "C": "Melville Hall",
            "D": "Melville Hall",
            "E": "Dominica",
            "F": "DM",
            "G": "T",
            "H": "",
            "I": "",
            "J": "CAR"
        }
    ],
    "DOV": [
        {
            "B": "KDOV",
            "C": "DOVER AIR FORCE BASE",
            "D": "Denver",
            "E": "USA",
            "F": "US",
            "G": "T",
            "H": "",
            "I": "",
            "J": "NOA"
        }
    ],
    "DOY": [
        {
            "B": "ZSDY",
            "C": "Dongying",
            "D": "Shengli",
            "E": "Peoples Republic of China",
            "F": "CN",
            "G": "O",
            "H": "",
            "I": "",
            "J": "SEA"
        }
    ],
    "DPA": [
        {
            "B": "KDPA",
            "C": "DUPAGE COUNTY AIRPORT",
            "D": "Chicago,du page",
            "E": "USA",
            "F": "US",
            "G": "T",
            "H": "",
            "I": "",
            "J": "NOA"
        }
    ],
    "DPE": [
        {
            "B": "LFAB",
            "C": "DIEPPE / SAINT-AUBIN",
            "D": "Dieppe",
            "E": "France",
            "F": "FR",
            "G": "E",
            "H": "",
            "I": "",
            "J": "EUR"
        }
    ],
    "DPL": [
        {
            "B": "RPMG",
            "C": "Dipolog",
            "D": "Dipolog",
            "E": "Philippines",
            "F": "PH",
            "G": "O",
            "H": "",
            "I": "",
            "J": "SEA"
        }
    ],
    "DPO": [
        {
            "B": "YDPO",
            "C": "Devonport",
            "D": "Devonport",
            "E": "Australia",
            "F": "AU",
            "G": "O",
            "H": "",
            "I": "",
            "J": "SWP"
        }
    ],
    "DPS": [
        {
            "B": "",
            "C": "",
            "D": "",
            "E": "UNKNOWN",
            "F": "",
            "G": "A",
            "H": "",
            "I": "",
            "J": ""
        }
    ],
    "DQA": [
        {
            "B": "ZYDQ",
            "C": "Daqing Sartu",
            "D": "Sartu",
            "E": "Peoples Republic of China",
            "F": "CN",
            "G": "O",
            "H": "",
            "I": "",
            "J": "SEA"
        }
    ],
    "DQM": [
        {
            "B": "OODQ",
            "C": "DUQM",
            "D": "Duqm",
            "E": "Oman",
            "F": "OM",
            "G": "O",
            "H": "",
            "I": "",
            "J": "MDE"
        }
    ],
    "DRO": [
        {
            "B": "KDRO",
            "C": "Durango, Colorado",
            "D": "La Plata",
            "E": "USA",
            "F": "US",
            "G": "T",
            "H": "",
            "I": "",
            "J": "NOA"
        }
    ],
    "DRS": [
        {
            "B": "EDDC",
            "C": "DRESDEN",
            "D": "Dresden",
            "E": "Germany",
            "F": "DE",
            "G": "E",
            "H": "",
            "I": "",
            "J": "EUR"
        }
    ],
    "DRW": [
        {
            "B": "YPDN",
            "C": "DARWIN",
            "D": "Darwin",
            "E": "Australia",
            "F": "AU",
            "G": "O",
            "H": "",
            "I": "",
            "J": "SWP"
        }
    ],
    "DSA": [
        {
            "B": "EGCN",
            "C": "DONCASTER ROBIN HOOD",
            "D": "Doncaster",
            "E": "United Kingdom",
            "F": "GB",
            "G": "U",
            "H": "",
            "I": "",
            "J": "EUR"
        }
    ],
    "DSE": [
        {
            "B": "HADC",
            "C": "Combolcha",
            "D": "Combolcha",
            "E": "Ethiopia",
            "F": "ET",
            "G": "O",
            "H": "",
            "I": "",
            "J": "AFR"
        }
    ],
    "DSM": [
        {
            "B": "KDSM",
            "C": "DES MOINES",
            "D": "DES MOINES INTERNATIONAL",
            "E": "USA",
            "F": "US",
            "G": "T",
            "H": "",
            "I": "",
            "J": "NOA"
        }
    ],
    "DSS": [
        {
            "B": "GOBD",
            "C": "BLAISE DIAGNE",
            "D": "Blaise Diagne International Airport",
            "E": "Senegal",
            "F": "SN",
            "G": "O",
            "H": "",
            "I": "",
            "J": "AFR"
        }
    ],
    "DTM": [
        {
            "B": "EDLW",
            "C": "DORTMUND",
            "D": "Dortmund",
            "E": "Germany",
            "F": "DE",
            "G": "E",
            "H": "",
            "I": "",
            "J": "EUR"
        }
    ],
    "DTW": [
        {
            "B": "KDTW",
            "C": "DETROIT - WAYNE COUNTY",
            "D": "Detroit",
            "E": "USA",
            "F": "US",
            "G": "T",
            "H": "",
            "I": "",
            "J": "NOA"
        }
    ],
    "DTZ": [
        {
            "B": "",
            "C": "DORTMUND RAILWAY STATION",
            "D": "Dortmund Railway Station",
            "E": "Germany",
            "F": "DE",
            "G": "E",
            "H": "",
            "I": "",
            "J": "EUR"
        }
    ],
    "DUB": [
        {
            "B": "EIDW",
            "C": "DUBLIN",
            "D": "Dublin",
            "E": "Republic of Ireland",
            "F": "IE",
            "G": "D",
            "H": "",
            "I": "",
            "J": "EUR"
        }
    ],
    "DUD": [
        {
            "B": "NZDN",
            "C": "DUNEDIN",
            "D": "Dunedin",
            "E": "New Zealand",
            "F": "NZ",
            "G": "O",
            "H": "",
            "I": "",
            "J": "SWP"
        }
    ],
    "DUJ": [
        {
            "B": "KDUJ",
            "C": "Jefferson County",
            "D": "Dubois Regional",
            "E": "USA",
            "F": "US",
            "G": "T",
            "H": "",
            "I": "",
            "J": "NOA"
        }
    ],
    "DUN": [
        {
            "B": "",
            "C": "",
            "D": "",
            "E": "UNKNOWN",
            "F": "",
            "G": "A",
            "H": "",
            "I": "",
            "J": ""
        }
    ],
    "DUR": [
        {
            "B": "FADN",
            "C": "DURBAN INTERNATIONAL",
            "D": "Durban",
            "E": "South Africa",
            "F": "ZA",
            "G": "O",
            "H": "",
            "I": "",
            "J": "AFR"
        }
    ],
    "DUS": [
        {
            "B": "EDDL",
            "C": "DUSSELDORF",
            "D": "Dusseldorf",
            "E": "Germany",
            "F": "DE",
            "G": "E",
            "H": "",
            "I": "",
            "J": "EUR"
        }
    ],
    "DUT": [
        {
            "B": "PADU",
            "C": "TOM MADSEN ",
            "D": "Tom Madsen ",
            "E": "USA",
            "F": "US",
            "G": "T",
            "H": "",
            "I": "",
            "J": "NOA"
        }
    ],
    "DVO": [
        {
            "B": "RPMD",
            "C": "Mati",
            "D": "Francisco Bangoy International",
            "E": "Philippines",
            "F": "PH",
            "G": "O",
            "H": "",
            "I": "",
            "J": "SEA"
        }
    ],
    "DWC": [
        {
            "B": "OMDW",
            "C": "Dubai World Central",
            "D": "Dubai World Central - Al Maktoum International",
            "E": "United Arab Emirates",
            "F": "AE",
            "G": "O",
            "H": "",
            "I": "",
            "J": "MDE"
        }
    ],
    "DWS": [
        {
            "B": "None",
            "C": "WALT DISNEY WORLD",
            "D": "Walt Disney World",
            "E": "USA",
            "F": "US",
            "G": "T",
            "H": "",
            "I": "",
            "J": "NOA"
        }
    ],
    "DXB": [
        {
            "B": "OMDB",
            "C": "DUBAI",
            "D": "Dubai",
            "E": "United Arab Emirates",
            "F": "AE",
            "G": "O",
            "H": "",
            "I": "",
            "J": "MDE"
        }
    ],
    "DYU": [
        {
            "B": "UTDD",
            "C": "Dushanbe",
            "D": "Dushanbe International",
            "E": "Tajikistan",
            "F": "TJ",
            "G": "O",
            "H": "",
            "I": "",
            "J": "SEA"
        }
    ],
    "DZA": [
        {
            "B": "FMCZ",
            "C": "DZAOUDZI",
            "D": "Dzaoudzi",
            "E": "Mayotte",
            "F": "YT",
            "G": "O",
            "H": "",
            "I": "",
            "J": "AFR"
        }
    ],
    "E01": [
        {
            "B": "EIAB",
            "C": "ABBEYSHRULE",
            "D": "Abbeyshrule",
            "E": "Republic of Ireland",
            "F": "IE",
            "G": "D",
            "H": "",
            "I": "",
            "J": "EUR"
        }
    ],
    "E02": [
        {
            "B": "EO2Z",
            "C": "LYONS ESTATE",
            "D": "Aliababi rig",
            "E": "Republic of Ireland",
            "F": "IE",
            "G": "D",
            "H": "",
            "I": "",
            "J": "EUR"
        }
    ],
    "E03": [
        {
            "B": "EIBB",
            "C": "BRITTAS BAY",
            "D": "Brittas Bay",
            "E": "Republic of Ireland",
            "F": "IE",
            "G": "D",
            "H": "",
            "I": "",
            "J": "EUR"
        }
    ],
    "E04": [
        {
            "B": "EIBR",
            "C": "BIRR",
            "D": "Birr",
            "E": "Republic of Ireland",
            "F": "IE",
            "G": "D",
            "H": "",
            "I": "",
            "J": "EUR"
        }
    ],
    "E05": [
        {
            "B": "EICL",
            "C": "CLONBULLOGUE",
            "D": "Clonbullogue",
            "E": "Republic of Ireland",
            "F": "IE",
            "G": "D",
            "H": "",
            "I": "",
            "J": "EUR"
        }
    ],
    "E06": [
        {
            "B": "EICN",
            "C": "COONAGH",
            "D": "Coonagh",
            "E": "Republic of Ireland",
            "F": "IE",
            "G": "D",
            "H": "",
            "I": "",
            "J": "EUR"
        }
    ],
    "E07": [
        {
            "B": "EICS",
            "C": "CASTLEFORBES",
            "D": "Castleforbes",
            "E": "Republic of Ireland",
            "F": "IE",
            "G": "D",
            "H": "",
            "I": "",
            "J": "EUR"
        }
    ],
    "E08": [
        {
            "B": "EIDD",
            "C": "DUNDALK",
            "D": "Dundalk",
            "E": "Republic of Ireland",
            "F": "IE",
            "G": "D",
            "H": "",
            "I": "",
            "J": "EUR"
        }
    ],
    "E09": [
        {
            "B": "EIHC",
            "C": "CELTIC HELI KNOCKSEDAN",
            "D": "CELTIC HELICOPTERS KNOCKSEDAN",
            "E": "Republic of Ireland",
            "F": "IE",
            "G": "D",
            "H": "",
            "I": "",
            "J": "EUR"
        }
    ],
    "E10": [
        {
            "B": "EIFD",
            "C": "CASHEL",
            "D": "Cashel",
            "E": "Republic of Ireland",
            "F": "IE",
            "G": "D",
            "H": "",
            "I": "",
            "J": "EUR"
        }
    ],
    "E11": [
        {
            "B": "EIFR",
            "C": "FINNER",
            "D": "Finner",
            "E": "Republic of Ireland",
            "F": "IE",
            "G": "D",
            "H": "",
            "I": "",
            "J": "EUR"
        }
    ],
    "E12": [
        {
            "B": "EIRE",
            "C": "ABBEYFEALE",
            "D": "Gas field",
            "E": "Republic of Ireland",
            "F": "IE",
            "G": "D",
            "H": "",
            "I": "",
            "J": "EUR"
        }
    ],
    "E13": [
        {
            "B": "EIGM",
            "C": "GORMANSTON",
            "D": "Gormanston",
            "E": "Republic of Ireland",
            "F": "IE",
            "G": "D",
            "H": "",
            "I": "",
            "J": "EUR"
        }
    ],
    "E14": [
        {
            "B": "EIGN",
            "C": "GOWRAN GRANGE",
            "D": "Gowran Grange",
            "E": "Republic of Ireland",
            "F": "IE",
            "G": "D",
            "H": "",
            "I": "",
            "J": "EUR"
        }
    ],
    "E15": [
        {
            "B": "EIHN",
            "C": "HACKETSTOWN",
            "D": "Hacketstown",
            "E": "Republic of Ireland",
            "F": "IE",
            "G": "D",
            "H": "",
            "I": "",
            "J": "EUR"
        }
    ],
    "E16": [
        {
            "B": "EIKG",
            "C": "KINSALE GAS FIELD",
            "D": "Kinsale Gas",
            "E": "Republic of Ireland",
            "F": "IE",
            "G": "D",
            "H": "",
            "I": "",
            "J": "EUR"
        }
    ],
    "E17": [
        {
            "B": "EIKH",
            "C": "KILRUSH",
            "D": "Kilrush",
            "E": "Republic of Ireland",
            "F": "IE",
            "G": "D",
            "H": "",
            "I": "",
            "J": "EUR"
        }
    ],
    "E18": [
        {
            "B": "EILT",
            "C": "LETTERKENNY",
            "D": "Letterkenny",
            "E": "Republic of Ireland",
            "F": "IE",
            "G": "D",
            "H": "",
            "I": "",
            "J": "EUR"
        }
    ],
    "E19": [
        {
            "B": "EIME",
            "C": "CASEMENT AERODROME",
            "D": "Baldonnel",
            "E": "Republic of Ireland",
            "F": "IE",
            "G": "D",
            "H": "",
            "I": "",
            "J": "EUR"
        }
    ],
    "E20": [
        {
            "B": "EIMY",
            "C": "MOYNE",
            "D": "Moyne",
            "E": "Republic of Ireland",
            "F": "IE",
            "G": "D",
            "H": "",
            "I": "",
            "J": "EUR"
        }
    ],
    "E21": [
        {
            "B": "EINC",
            "C": "NEWCASTLE - IE",
            "D": "Newcastle",
            "E": "Republic of Ireland",
            "F": "IE",
            "G": "D",
            "H": "",
            "I": "",
            "J": "EUR"
        }
    ],
    "E22": [
        {
            "B": "EIER",
            "C": "ERINAGH",
            "D": "Oil rig",
            "E": "Republic of Ireland",
            "F": "IE",
            "G": "D",
            "H": "",
            "I": "",
            "J": "EUR"
        }
    ],
    "E23": [
        {
            "B": "EIPT",
            "C": "POWERSCOURT",
            "D": "Powerscourt",
            "E": "Republic of Ireland",
            "F": "IE",
            "G": "D",
            "H": "",
            "I": "",
            "J": "EUR"
        }
    ],
    "E24": [
        {
            "B": "EIRT",
            "C": "RATHCOOLE",
            "D": "Rathcoole",
            "E": "Republic of Ireland",
            "F": "IE",
            "G": "D",
            "H": "",
            "I": "",
            "J": "EUR"
        }
    ],
    "E25": [
        {
            "B": "EISP",
            "C": "SPANISH POINT",
            "D": "Spanish Point",
            "E": "Republic of Ireland",
            "F": "IE",
            "G": "D",
            "H": "",
            "I": "",
            "J": "EUR"
        }
    ],
    "E26": [
        {
            "B": "EITM",
            "C": "TRIM",
            "D": "Trim",
            "E": "Republic of Ireland",
            "F": "IE",
            "G": "D",
            "H": "",
            "I": "",
            "J": "EUR"
        }
    ],
    "E27": [
        {
            "B": "EIUS",
            "C": "SHIP OFFSHORE",
            "D": "Naval Vessel",
            "E": "Republic of Ireland",
            "F": "IE",
            "G": "D",
            "H": "",
            "I": "",
            "J": "EUR"
        }
    ],
    "E28": [
        {
            "B": "EIWT",
            "C": "WESTON",
            "D": "Weston",
            "E": "Republic of Ireland",
            "F": "IE",
            "G": "D",
            "H": "",
            "I": "",
            "J": "EUR"
        }
    ],
    "E29": [
        {
            "B": "EIWV",
            "C": "WATERVILLE",
            "D": "Waterville",
            "E": "Republic of Ireland",
            "F": "IE",
            "G": "D",
            "H": "",
            "I": "",
            "J": "EUR"
        }
    ],
    "E30": [
        {
            "B": "E30Z",
            "C": "BALLYBRIT",
            "D": "Ballybrit",
            "E": "Republic of Ireland",
            "F": "IE",
            "G": "D",
            "H": "",
            "I": "",
            "J": "EUR"
        }
    ],
    "E31": [
        {
            "B": "E31Z",
            "C": "BALLYMOREUSTACE",
            "D": "Ballymoreustace",
            "E": "Republic of Ireland",
            "F": "IE",
            "G": "D",
            "H": "",
            "I": "",
            "J": "EUR"
        }
    ],
    "E32": [
        {
            "B": "E32Z",
            "C": "BLESSINGTON",
            "D": "Blessington",
            "E": "Republic of Ireland",
            "F": "IE",
            "G": "D",
            "H": "",
            "I": "",
            "J": "EUR"
        }
    ],
    "E33": [
        {
            "B": "E33Z",
            "C": "BRAGGANSTOWN",
            "D": "Bragganstown",
            "E": "Republic of Ireland",
            "F": "IE",
            "G": "D",
            "H": "",
            "I": "",
            "J": "EUR"
        }
    ],
    "E34": [
        {
            "B": "E34Z",
            "C": "PARK WEST",
            "D": "Brown's barn",
            "E": "Republic of Ireland",
            "F": "IE",
            "G": "D",
            "H": "",
            "I": "",
            "J": "EUR"
        }
    ],
    "E35": [
        {
            "B": "E35Z",
            "C": "CAHIR",
            "D": "Cahir",
            "E": "Republic of Ireland",
            "F": "IE",
            "G": "D",
            "H": "",
            "I": "",
            "J": "EUR"
        }
    ],
    "E36": [
        {
            "B": "E36Z",
            "C": "CARLOW",
            "D": "Carlow",
            "E": "Republic of Ireland",
            "F": "IE",
            "G": "D",
            "H": "",
            "I": "",
            "J": "EUR"
        }
    ],
    "E37": [
        {
            "B": "E37Z",
            "C": "CASTLEDERMOT",
            "D": "Castledermot",
            "E": "Republic of Ireland",
            "F": "IE",
            "G": "D",
            "H": "",
            "I": "",
            "J": "EUR"
        }
    ],
    "E38": [
        {
            "B": "E38Z",
            "C": "CASTLETOWNBERE",
            "D": "Castletownbere",
            "E": "Republic of Ireland",
            "F": "IE",
            "G": "D",
            "H": "",
            "I": "",
            "J": "EUR"
        }
    ],
    "E39": [
        {
            "B": "E39Z",
            "C": "CITYWEST",
            "D": "Dublin",
            "E": "Republic of Ireland",
            "F": "IE",
            "G": "D",
            "H": "",
            "I": "",
            "J": "EUR"
        }
    ],
    "E40": [
        {
            "B": "E40Z",
            "C": "DUBLIN DOCKS",
            "D": "Dublin docks",
            "E": "Republic of Ireland",
            "F": "IE",
            "G": "D",
            "H": "",
            "I": "",
            "J": "EUR"
        }
    ],
    "E41": [
        {
            "B": "EITT",
            "C": "TREVITT",
            "D": "Ennis",
            "E": "Republic of Ireland",
            "F": "IE",
            "G": "D",
            "H": "",
            "I": "",
            "J": "EUR"
        }
    ],
    "E42": [
        {
            "B": "E42Z",
            "C": "FAIRYHOUSE",
            "D": "Fairyhouse",
            "E": "Republic of Ireland",
            "F": "IE",
            "G": "D",
            "H": "",
            "I": "",
            "J": "EUR"
        }
    ],
    "E43": [
        {
            "B": "E43Z",
            "C": "INNISHBOFFIN",
            "D": "Innishboffin",
            "E": "Republic of Ireland",
            "F": "IE",
            "G": "D",
            "H": "",
            "I": "",
            "J": "EUR"
        }
    ],
    "E44": [
        {
            "B": "E44Z",
            "C": "K-CLUB STRAFFAN",
            "D": "K-club",
            "E": "Republic of Ireland",
            "F": "IE",
            "G": "D",
            "H": "",
            "I": "",
            "J": "EUR"
        }
    ],
    "E45": [
        {
            "B": "E45Z",
            "C": "KILLARNEY",
            "D": "Killarney",
            "E": "Republic of Ireland",
            "F": "IE",
            "G": "D",
            "H": "",
            "I": "",
            "J": "EUR"
        }
    ],
    "E46": [
        {
            "B": "E46Z",
            "C": "KILLINEY",
            "D": "Killiney",
            "E": "Republic of Ireland",
            "F": "IE",
            "G": "D",
            "H": "",
            "I": "",
            "J": "EUR"
        }
    ],
    "E47": [
        {
            "B": "E47Z",
            "C": "LEOPARDSTOWN",
            "D": "Leopardstown",
            "E": "Republic of Ireland",
            "F": "IE",
            "G": "D",
            "H": "",
            "I": "",
            "J": "EUR"
        }
    ],
    "E48": [
        {
            "B": "E48Z",
            "C": "MERRION SQUARE",
            "D": "Merrion square",
            "E": "Republic of Ireland",
            "F": "IE",
            "G": "D",
            "H": "",
            "I": "",
            "J": "EUR"
        }
    ],
    "E49": [
        {
            "B": "E49Z",
            "C": "NAAS",
            "D": "Naas",
            "E": "Republic of Ireland",
            "F": "IE",
            "G": "D",
            "H": "",
            "I": "",
            "J": "EUR"
        }
    ],
    "E50": [
        {
            "B": "E50Z",
            "C": "OMEGA HANGAR",
            "D": "Omega hangar",
            "E": "Republic of Ireland",
            "F": "IE",
            "G": "D",
            "H": "",
            "I": "",
            "J": "EUR"
        }
    ],
    "E51": [
        {
            "B": "E51Z",
            "C": "PUNCHESTOWN",
            "D": "Punchestown",
            "E": "Republic of Ireland",
            "F": "IE",
            "G": "D",
            "H": "",
            "I": "",
            "J": "EUR"
        }
    ],
    "E52": [
        {
            "B": "E52Z",
            "C": "SLANE CASTLE",
            "D": "Slane castle",
            "E": "Republic of Ireland",
            "F": "IE",
            "G": "D",
            "H": "",
            "I": "",
            "J": "EUR"
        }
    ],
    "E53": [
        {
            "B": "E53Z",
            "C": "CAVAN",
            "D": "Cavan",
            "E": "Republic of Ireland",
            "F": "IE",
            "G": "D",
            "H": "",
            "I": "",
            "J": "EUR"
        }
    ],
    "E54": [
        {
            "B": "E54Z",
            "C": "THE CURRAGH",
            "D": "The curragh",
            "E": "Republic of Ireland",
            "F": "IE",
            "G": "D",
            "H": "",
            "I": "",
            "J": "EUR"
        }
    ],
    "E55": [
        {
            "B": "E55Z",
            "C": "WEXFORD",
            "D": "Wexford",
            "E": "Republic of Ireland",
            "F": "IE",
            "G": "D",
            "H": "",
            "I": "",
            "J": "EUR"
        }
    ],
    "E56": [
        {
            "B": "E56Z",
            "C": "GAS PIPELINE/POWERLINE",
            "D": "Gas pipeline",
            "E": "Republic of Ireland",
            "F": "IE",
            "G": "D",
            "H": "",
            "I": "",
            "J": "EUR"
        }
    ],
    "E57": [
        {
            "B": "E57Z",
            "C": "LOUGH CORRIB",
            "D": "Lough corrib",
            "E": "Republic of Ireland",
            "F": "IE",
            "G": "D",
            "H": "",
            "I": "",
            "J": "EUR"
        }
    ],
    "E58": [
        {
            "B": "E58Z",
            "C": "LOUGH DERG",
            "D": "Lough derg",
            "E": "Republic of Ireland",
            "F": "IE",
            "G": "D",
            "H": "",
            "I": "",
            "J": "EUR"
        }
    ],
    "E59": [
        {
            "B": "E59Z",
            "C": "ENNISKERRY",
            "D": "Enniskerry",
            "E": "Republic of Ireland",
            "F": "IE",
            "G": "D",
            "H": "",
            "I": "",
            "J": "EUR"
        }
    ],
    "E60": [
        {
            "B": "E60Z",
            "C": "DALKEY",
            "D": "Dalkey",
            "E": "Republic of Ireland",
            "F": "IE",
            "G": "D",
            "H": "",
            "I": "",
            "J": "EUR"
        }
    ],
    "E61": [
        {
            "B": "E61Z",
            "C": "ARDENODE",
            "D": "Ardenode",
            "E": "Republic of Ireland",
            "F": "IE",
            "G": "D",
            "H": "",
            "I": "",
            "J": "EUR"
        }
    ],
    "E62": [
        {
            "B": "E62Z",
            "C": "DRUID'S GLEN",
            "D": "Druid's glen",
            "E": "Republic of Ireland",
            "F": "IE",
            "G": "D",
            "H": "",
            "I": "",
            "J": "EUR"
        }
    ],
    "E63": [
        {
            "B": "E63Z",
            "C": "DOLLYS GROVE",
            "D": "Dunboyne",
            "E": "Republic of Ireland",
            "F": "IE",
            "G": "D",
            "H": "",
            "I": "",
            "J": "EUR"
        }
    ],
    "E64": [
        {
            "B": "E64Z",
            "C": "BAILEY LIGHTHOUSE HOWTH",
            "D": "Bailey lighthouse",
            "E": "Republic of Ireland",
            "F": "IE",
            "G": "D",
            "H": "",
            "I": "",
            "J": "EUR"
        }
    ],
    "E65": [
        {
            "B": "E65Z",
            "C": "CLONSKEAGH",
            "D": "Clonskeagh",
            "E": "Republic of Ireland",
            "F": "IE",
            "G": "D",
            "H": "",
            "I": "",
            "J": "EUR"
        }
    ],
    "E66": [
        {
            "B": "E66Z",
            "C": "BEAUMONT HOSPITAL",
            "D": "Beaumont",
            "E": "Republic of Ireland",
            "F": "IE",
            "G": "D",
            "H": "",
            "I": "",
            "J": "EUR"
        }
    ],
    "E67": [
        {
            "B": "E67Z",
            "C": "COASTAL PATROL",
            "D": "Coastal patrol",
            "E": "Republic of Ireland",
            "F": "IE",
            "G": "D",
            "H": "",
            "I": "",
            "J": "EUR"
        }
    ],
    "E68": [
        {
            "B": "EIAH",
            "C": "ANDONA AIRFIELD",
            "D": "ANDONA AIRFIELD",
            "E": "Republic of Ireland",
            "F": "IE",
            "G": "D",
            "H": "",
            "I": "",
            "J": "EUR"
        }
    ],
    "E69": [
        {
            "B": "EGYK",
            "C": "ELVINGTON",
            "D": "Elvington",
            "E": "United Kingdom",
            "F": "GB",
            "G": "U",
            "H": "",
            "I": "",
            "J": "EUR"
        }
    ],
    "E83": [
        {
            "B": "",
            "C": "",
            "D": "",
            "E": "UNKNOWN",
            "F": "",
            "G": "A",
            "H": "",
            "I": "",
            "J": ""
        }
    ],
    "E90": [
        {
            "B": "EISF",
            "C": "SANTA FLIGHT",
            "D": "SANTA FLIGHT",
            "E": "Republic of Ireland",
            "F": "IE",
            "G": "D",
            "H": "",
            "I": "",
            "J": "EUR"
        }
    ],
    "E99": [
        {
            "B": "E99Z",
            "C": "BILLING EXEMPT",
            "D": "Billing exempt",
            "E": "UNKNOWN",
            "F": "IE",
            "G": "D",
            "H": "",
            "I": "",
            "J": "EUR"
        }
    ],
    "EAM": [
        {
            "B": "OENG",
            "C": "Nejran",
            "D": "Nejran",
            "E": "Saudi Arabia",
            "F": "SA",
            "G": "O",
            "H": "",
            "I": "",
            "J": "MDE"
        }
    ],
    "EAR": [
        {
            "B": "KEAR",
            "C": "KEARNEY",
            "D": "Kearney",
            "E": "USA",
            "F": "US",
            "G": "T",
            "H": "",
            "I": "",
            "J": "NOA"
        }
    ],
    "EAS": [
        {
            "B": "LESO",
            "C": "SAN SEBASTIAN",
            "D": "San Sebastian",
            "E": "Spain",
            "F": "ES",
            "G": "E",
            "H": "",
            "I": "",
            "J": "EUR"
        }
    ],
    "EAT": [
        {
            "B": "KEAT",
            "C": "Wenatchee, Washington",
            "D": "Pangborn Memorial",
            "E": "USA",
            "F": "US",
            "G": "T",
            "H": "",
            "I": "",
            "J": "NOA"
        }
    ],
    "EAU": [
        {
            "B": "KEAU",
            "C": "Eau Claire",
            "D": "Chippewa Valley Rgnl",
            "E": "USA",
            "F": "US",
            "G": "T",
            "H": "",
            "I": "",
            "J": "NOA"
        }
    ],
    "EBA": [
        {
            "B": "LIRJ",
            "C": "MARINA DI CAMPO",
            "D": "Elba",
            "E": "Italy",
            "F": "IT",
            "G": "E",
            "H": "",
            "I": "",
            "J": "EUR"
        }
    ],
    "EBB": [
        {
            "B": "HUEN",
            "C": "ENTEBBE / KAMPALA",
            "D": "Kampala",
            "E": "Uganda",
            "F": "UG",
            "G": "O",
            "H": "",
            "I": "",
            "J": "AFR"
        }
    ],
    "EBJ": [
        {
            "B": "EKEB",
            "C": "ESBJERG",
            "D": "Esbjerg",
            "E": "Denmark",
            "F": "DK",
            "G": "E",
            "H": "",
            "I": "",
            "J": "EUR"
        }
    ],
    "EBL": [
        {
            "B": "ORER",
            "C": "Arbil (Erbil)",
            "D": "Erbil International Airport",
            "E": "Iraq",
            "F": "IQ",
            "G": "O",
            "H": "",
            "I": "",
            "J": "MDE"
        }
    ],
    "EBU": [
        {
            "B": "LFMH",
            "C": "ST. ETIENNE",
            "D": "St Etienne",
            "E": "France",
            "F": "FR",
            "G": "E",
            "H": "",
            "I": "",
            "J": "EUR"
        }
    ],
    "ECN": [
        {
            "B": "LCEN",
            "C": "ERCAN",
            "D": "Ercan",
            "E": "Turkey",
            "F": "TR",
            "G": "E",
            "H": "",
            "I": "",
            "J": "EUR"
        }
    ],
    "ECP": [
        {
            "B": "KECP",
            "C": "Northwest Florida Beaches",
            "D": "Northwest Florida Beaches International Airport",
            "E": "USA",
            "F": "US",
            "G": "T",
            "H": "",
            "I": "",
            "J": "NOA"
        }
    ],
    "EDI": [
        {
            "B": "EGPH",
            "C": "EDINBURGH",
            "D": "Edinburgh",
            "E": "United Kingdom",
            "F": "GB",
            "G": "U",
            "H": "",
            "I": "",
            "J": "EUR"
        }
    ],
    "EDL": [
        {
            "B": "HKEL",
            "C": "Eldoret",
            "D": "Eldoret International",
            "E": "Kenya",
            "F": "KE",
            "G": "O",
            "H": "",
            "I": "",
            "J": "AFR"
        }
    ],
    "EDM": [
        {
            "B": "LFRI",
            "C": "LA ROCHE-SUR-YON-LES",
            "D": "La Roche",
            "E": "France",
            "F": "FR",
            "G": "E",
            "H": "",
            "I": "",
            "J": "EUR"
        }
    ],
    "EDO": [
        {
            "B": "LTFD",
            "C": "BALIKESIR KOCA SEYIT",
            "D": "Bal?kesir Koca Seyit",
            "E": "Turkey",
            "F": "TR",
            "G": "E",
            "H": "",
            "I": "",
            "J": "EUR"
        }
    ],
    "EEZ": [
        {
            "B": "",
            "C": "UNKNOWN AIRPORT",
            "D": "",
            "E": "UNKNOWN",
            "F": "IE",
            "G": "A",
            "H": "",
            "I": "",
            "J": "EUR"
        }
    ],
    "EFD": [
        {
            "B": "KEFD",
            "C": "ELLINGTON - HOUSTON",
            "D": "Ellington field",
            "E": "USA",
            "F": "US",
            "G": "T",
            "H": "",
            "I": "",
            "J": "NOA"
        }
    ],
    "EFL": [
        {
            "B": "LGKF",
            "C": "KEFALLINIA",
            "D": "Kefallinia",
            "E": "Greece",
            "F": "GR",
            "G": "E",
            "H": "",
            "I": "",
            "J": "EUR"
        }
    ],
    "EGC": [
        {
            "B": "LFBE",
            "C": "BERGERAC",
            "D": "Bergerac",
            "E": "France",
            "F": "FR",
            "G": "E",
            "H": "",
            "I": "",
            "J": "EUR"
        }
    ],
    "EGE": [
        {
            "B": "KEGE",
            "C": "Eagle Vail Airport",
            "D": "Eagle County Regl",
            "E": "USA",
            "F": "US",
            "G": "T",
            "H": "",
            "I": "",
            "J": "NOA"
        }
    ],
    "EGN": [
        {
            "B": "HSGN",
            "C": "GENEINA",
            "D": "Geneina",
            "E": "Sudan",
            "F": "SD",
            "G": "O",
            "H": "",
            "I": "",
            "J": "AFR"
        }
    ],
    "EGO": [
        {
            "B": "UUOB",
            "C": "BELGOROD",
            "D": "Belgorod",
            "E": "Russian Federation",
            "F": "RU",
            "G": "E",
            "H": "",
            "I": "",
            "J": "EUR"
        }
    ],
    "EGS": [
        {
            "B": "BIEG",
            "C": "EGILSSTADIR",
            "D": "Egilsstadir",
            "E": "Iceland",
            "F": "IS",
            "G": "E",
            "H": "",
            "I": "",
            "J": "EUR"
        }
    ],
    "EIN": [
        {
            "B": "EHEH",
            "C": "EINDHOVEN",
            "D": "Eindhoven",
            "E": "Netherlands",
            "F": "NL",
            "G": "E",
            "H": "",
            "I": "",
            "J": "EUR"
        }
    ],
    "EIO": [
        {
            "B": "EIOR",
            "C": "KINSALE OIL RIG",
            "D": "Oil rig",
            "E": "Republic of Ireland",
            "F": "IE",
            "G": "D",
            "H": "",
            "I": "",
            "J": "EUR"
        }
    ],
    "EIS": [
        {
            "B": "TUPJ",
            "C": "Beef I",
            "D": "Terrance B. Lettsome",
            "E": "British Virgin Islands",
            "F": "VG",
            "G": "T",
            "H": "",
            "I": "",
            "J": "CAR"
        }
    ],
    "EIY": [
        {
            "B": "LLEY",
            "C": "EYN-YAHAV AIRSTRIP",
            "D": "Eyn Yahav",
            "E": "Israel",
            "F": "IL",
            "G": "O",
            "H": "",
            "I": "",
            "J": "MDE"
        }
    ],
    "EJH": [
        {
            "B": "OEWJ",
            "C": "WEDJH",
            "D": "Wedjh",
            "E": "Saudi Arabia",
            "F": "SA",
            "G": "O",
            "H": "",
            "I": "",
            "J": "MDE"
        }
    ],
    "EKO": [
        {
            "B": "KEKO",
            "C": "Elko",
            "D": "Elko Municipal",
            "E": "USA",
            "F": "US",
            "G": "T",
            "H": "",
            "I": "",
            "J": "NOA"
        }
    ],
    "EKT": [
        {
            "B": "ESSU",
            "C": "ESKILSTUNA",
            "D": "Eskilstuna Airport",
            "E": "Sweden",
            "F": "SE",
            "G": "E",
            "H": "",
            "I": "",
            "J": "EUR"
        }
    ],
    "ELM": [
        {
            "B": "KELM",
            "C": "Regional Airport",
            "D": "Celmira/Corning Regl",
            "E": "USA",
            "F": "US",
            "G": "T",
            "H": "",
            "I": "",
            "J": "NOA"
        }
    ],
    "ELP": [
        {
            "B": "KELP",
            "C": "International",
            "D": "El Paso Intl",
            "E": "USA",
            "F": "US",
            "G": "T",
            "H": "",
            "I": "",
            "J": "NOA"
        }
    ],
    "ELQ": [
        {
            "B": "OEGS",
            "C": "Gassim",
            "D": "Gassim",
            "E": "Saudi Arabia",
            "F": "SA",
            "G": "O",
            "H": "",
            "I": "",
            "J": "MDE"
        }
    ],
    "ELS": [
        {
            "B": "FAEL",
            "C": "East London",
            "D": "East London Airport",
            "E": "South Africa",
            "F": "ZA",
            "G": "O",
            "H": "",
            "I": "",
            "J": "AFR"
        }
    ],
    "EMA": [
        {
            "B": "EGNX",
            "C": "EAST MIDLANDS",
            "D": "East Midlands",
            "E": "United Kingdom",
            "F": "GB",
            "G": "U",
            "H": "",
            "I": "",
            "J": "EUR"
        }
    ],
    "EMD": [
        {
            "B": "YEML",
            "C": "Emerald",
            "D": "Emerald",
            "E": "Australia",
            "F": "AU",
            "G": "O",
            "H": "",
            "I": "",
            "J": "SWP"
        }
    ],
    "EME": [
        {
            "B": "EDWE",
            "C": "EMDEN",
            "D": "Emden",
            "E": "Germany",
            "F": "DE",
            "G": "E",
            "H": "",
            "I": "",
            "J": "EUR"
        }
    ],
    "ENA": [
        {
            "B": "PAEN",
            "C": "KENAI MUNICIPAL",
            "D": "Kenai",
            "E": "USA",
            "F": "US",
            "G": "T",
            "H": "",
            "I": "",
            "J": "NOA"
        }
    ],
    "ENC": [
        {
            "B": "LFSN",
            "C": "NANCY - ESSEY",
            "D": "Nancy Essey",
            "E": "France",
            "F": "FR",
            "G": "E",
            "H": "",
            "I": "",
            "J": "EUR"
        }
    ],
    "END": [
        {
            "B": "BDU",
            "C": "BARDUFOSS",
            "D": "Bardufoss",
            "E": "Norway",
            "F": "NO",
            "G": "E",
            "H": "",
            "I": "",
            "J": "EUR"
        }
    ],
    "ENF": [
        {
            "B": "EFET",
            "C": "ENONTEKIO",
            "D": "Enontekio",
            "E": "Finland",
            "F": "FI",
            "G": "E",
            "H": "",
            "I": "",
            "J": "EUR"
        }
    ],
    "ENK": [
        {
            "B": "EGAB",
            "C": "ENNISKILLEN - ST.ANGELO",
            "D": "Enniskillen",
            "E": "United Kingdom",
            "F": "GB",
            "G": "U",
            "H": "",
            "I": "",
            "J": "EUR"
        }
    ],
    "ENS": [
        {
            "B": "EHTW",
            "C": "ENSCHEDE",
            "D": "Enschede",
            "E": "Netherlands",
            "F": "NL",
            "G": "E",
            "H": "",
            "I": "",
            "J": "EUR"
        }
    ],
    "ENU": [
        {
            "B": "DNEN",
            "C": "Enugu",
            "D": "Akanu Ibiam International Airport",
            "E": "Nigeria",
            "F": "NG",
            "G": "O",
            "H": "",
            "I": "",
            "J": "AFR"
        }
    ],
    "ENW": [
        {
            "B": "KENW",
            "C": "KENOSHA REGIONAL",
            "D": "Kenosha",
            "E": "USA",
            "F": "US",
            "G": "T",
            "H": "",
            "I": "",
            "J": "NOA"
        }
    ],
    "EOI": [
        {
            "B": "EGED",
            "C": "EDAY",
            "D": "Eday",
            "E": "United Kingdom",
            "F": "GB",
            "G": "U",
            "H": "",
            "I": "",
            "J": "EUR"
        }
    ],
    "EPK": [
        {
            "B": "LCRO",
            "C": "EPISKOPI",
            "D": "Episkopi",
            "E": "Cyprus",
            "F": "CY",
            "G": "E",
            "H": "",
            "I": "",
            "J": "EUR"
        }
    ],
    "EPL": [
        {
            "B": "LFSG",
            "C": "EPINAL",
            "D": "Epinal",
            "E": "France",
            "F": "FR",
            "G": "E",
            "H": "",
            "I": "",
            "J": "EUR"
        }
    ],
    "EPR": [
        {
            "B": "YESP",
            "C": "Esperance",
            "D": "Esperance",
            "E": "Australia",
            "F": "AU",
            "G": "O",
            "H": "",
            "I": "",
            "J": "SWP"
        }
    ],
    "ERA": [
        {
            "B": "HCMU",
            "C": "ERIGAVO",
            "D": "Erigavo",
            "E": "Somalia",
            "F": "SO",
            "G": "O",
            "H": "",
            "I": "",
            "J": "AFR"
        }
    ],
    "ERC": [
        {
            "B": "LTCD",
            "C": "ERZINCAN",
            "D": "Erzincan",
            "E": "Turkey",
            "F": "TR",
            "G": "E",
            "H": "",
            "I": "",
            "J": "EUR"
        }
    ],
    "ERD": [
        {
            "B": "UKDB",
            "C": "BERDYANSK",
            "D": "Berdyansk",
            "E": "Ukraine",
            "F": "UA",
            "G": "E",
            "H": "",
            "I": "",
            "J": "EUR"
        }
    ],
    "ERF": [
        {
            "B": "EDDE",
            "C": "ERFURT",
            "D": "Erfurt",
            "E": "Germany",
            "F": "DE",
            "G": "E",
            "H": "",
            "I": "",
            "J": "EUR"
        }
    ],
    "ERI": [
        {
            "B": "KERI",
            "C": "International",
            "D": "Erie International",
            "E": "USA",
            "F": "US",
            "G": "T",
            "H": "",
            "I": "",
            "J": "NOA"
        }
    ],
    "ERM": [
        {
            "B": "EERM",
            "C": "ERECHIM",
            "D": "Erechim",
            "E": "Estonia",
            "F": "EE",
            "G": "E",
            "H": "",
            "I": "",
            "J": "EUR"
        }
    ],
    "ERZ": [
        {
            "B": "LTCE",
            "C": "ERZURUM",
            "D": "Erzurum",
            "E": "Turkey",
            "F": "TR",
            "G": "E",
            "H": "",
            "I": "",
            "J": "EUR"
        }
    ],
    "ES1": [
        {
            "B": "",
            "C": "Rota",
            "D": "Rota navy military",
            "E": "Spain",
            "F": "ES",
            "G": "E",
            "H": "",
            "I": "",
            "J": "EUR"
        }
    ],
    "ESB": [
        {
            "B": "LTAC",
            "C": "ANKARA - ESENBOGA",
            "D": "Ankara",
            "E": "Turkey",
            "F": "TR",
            "G": "E",
            "H": "",
            "I": "",
            "J": "EUR"
        }
    ],
    "ESC": [
        {
            "B": "KESC",
            "C": "Delta County",
            "D": "Delta County",
            "E": "USA",
            "F": "US",
            "G": "T",
            "H": "",
            "I": "",
            "J": "NOA"
        }
    ],
    "ESH": [
        {
            "B": "EGKA",
            "C": "SHOREHAM",
            "D": "Shoreham",
            "E": "United Kingdom",
            "F": "GB",
            "G": "U",
            "H": "",
            "I": "",
            "J": "EUR"
        }
    ],
    "ESK": [
        {
            "B": "LTBY",
            "C": "ESKISEHIR - ANADOLU",
            "D": "Eskisehir",
            "E": "Turkey",
            "F": "TR",
            "G": "E",
            "H": "",
            "I": "",
            "J": "EUR"
        }
    ],
    "ESS": [
        {
            "B": "EDLE",
            "C": "ESSEN",
            "D": "Essen",
            "E": "Germany",
            "F": "DE",
            "G": "E",
            "H": "",
            "I": "",
            "J": "EUR"
        }
    ],
    "ESU": [
        {
            "B": "GMMI",
            "C": "ESSAOUIRA / MOGADOR",
            "D": "Essaouira",
            "E": "Morocco",
            "F": "MA",
            "G": "O",
            "H": "",
            "I": "",
            "J": "AFR"
        }
    ],
    "ETH": [
        {
            "B": "LLET",
            "C": "ELAT",
            "D": "Elat",
            "E": "Israel",
            "F": "IL",
            "G": "O",
            "H": "",
            "I": "",
            "J": "MDE"
        }
    ],
    "ETM": [
        {
            "B": "LLER",
            "C": "RAMON",
            "D": "Ramon",
            "E": "Israel",
            "F": "IL",
            "G": "O",
            "H": "",
            "I": "",
            "J": "MDE"
        }
    ],
    "ETZ": [
        {
            "B": "LFJL",
            "C": "METZ / NANCY",
            "D": "Metz Nancy",
            "E": "France",
            "F": "FR",
            "G": "E",
            "H": "",
            "I": "",
            "J": "EUR"
        }
    ],
    "EUG": [
        {
            "B": "KEUG",
            "C": "Eugene",
            "D": "Mahlon Sweet Field",
            "E": "USA",
            "F": "US",
            "G": "T",
            "H": "",
            "I": "",
            "J": "NOA"
        }
    ],
    "EUN": [
        {
            "B": "GMML",
            "C": "Hassan I Airport",
            "D": "Hassan I Airport",
            "E": "Morocco",
            "F": "",
            "G": "O",
            "H": "",
            "I": "",
            "J": ""
        }
    ],
    "EUQ": [
        {
            "B": "RPVS",
            "C": "EVELIO JAVIER",
            "D": "Evelio Javier",
            "E": "Philippines",
            "F": "PH",
            "G": "O",
            "H": "",
            "I": "",
            "J": "SEA"
        }
    ],
    "EUX": [
        {
            "B": "TNCE",
            "C": "F D Roosevelt",
            "D": "F. D. Roosevelt",
            "E": "Netherlands Antilles",
            "F": "AN",
            "G": "T",
            "H": "",
            "I": "",
            "J": "CAR"
        }
    ],
    "EVE": [
        {
            "B": "ENEV",
            "C": "HARSTAD / NARVIK",
            "D": "Narvik",
            "E": "Norway",
            "F": "NO",
            "G": "E",
            "H": "",
            "I": "",
            "J": "EUR"
        }
    ],
    "EVN": [
        {
            "B": "UDYZ",
            "C": "YEREVAN",
            "D": "Yerevan",
            "E": "Armenia",
            "F": "AM",
            "G": "E",
            "H": "",
            "I": "",
            "J": "EUR"
        }
    ],
    "EVV": [
        {
            "B": "KEVV",
            "C": "Dress Regional",
            "D": "Evansville Regional",
            "E": "USA",
            "F": "US",
            "G": "T",
            "H": "",
            "I": "",
            "J": "NOA"
        }
    ],
    "EVX": [
        {
            "B": "LFOE",
            "C": "EVREUX - FAUVILLE",
            "D": "Evreux",
            "E": "France",
            "F": "FR",
            "G": "E",
            "H": "",
            "I": "",
            "J": "EUR"
        }
    ],
    "EWB": [
        {
            "B": "KEWB",
            "C": "New Bedford",
            "D": "New Bedford Regional",
            "E": "USA",
            "F": "",
            "G": "T",
            "H": "",
            "I": "",
            "J": ""
        }
    ],
    "EWN": [
        {
            "B": "KEWN",
            "C": "Simmons Nott",
            "D": "Coastal Carolina Regional Airport",
            "E": "USA",
            "F": "US",
            "G": "T",
            "H": "",
            "I": "",
            "J": "NOA"
        }
    ],
    "EWR": [
        {
            "B": "KEWR",
            "C": "NEWARK",
            "D": "Newark",
            "E": "USA",
            "F": "US",
            "G": "T",
            "H": "",
            "I": "",
            "J": "NOA"
        }
    ],
    "EXT": [
        {
            "B": "EGTE",
            "C": "EXETER",
            "D": "Exeter",
            "E": "United Kingdom",
            "F": "GB",
            "G": "U",
            "H": "",
            "I": "",
            "J": "EUR"
        }
    ],
    "EYW": [
        {
            "B": "KEYW",
            "C": "KEY WEST INTERNATIONAL",
            "D": "Key West",
            "E": "USA",
            "F": "US",
            "G": "T",
            "H": "",
            "I": "",
            "J": "NOA"
        }
    ],
    "EZE": [
        {
            "B": "SAEZ",
            "C": "EZEIZA MINISTRO PISTARINI",
            "D": "Ministro pistarini",
            "E": "Argentina",
            "F": "AR",
            "G": "T",
            "H": "",
            "I": "",
            "J": "SOA"
        }
    ],
    "EZS": [
        {
            "B": "LTCA",
            "C": "ELAZIG",
            "D": "Elazig",
            "E": "Turkey",
            "F": "TR",
            "G": "E",
            "H": "",
            "I": "",
            "J": "EUR"
        }
    ],
    "EZZ": [
        {
            "B": "EIZZ",
            "C": "UNKNOWN DOMESTIC",
            "D": "Misc",
            "E": "Republic of Ireland",
            "F": "IE",
            "G": "D",
            "H": "",
            "I": "",
            "J": "EUR"
        }
    ],
    "F02": [
        {
            "B": "LFQK",
            "C": "CHALONS-ECURY-SUR-COOLE",
            "D": "CHALONS SUR MARNE",
            "E": "France",
            "F": "FR",
            "G": "E",
            "H": "",
            "I": "",
            "J": "EUR"
        }
    ],
    "F03": [
        {
            "B": "LFMC",
            "C": "LE MUC",
            "D": "Le muc",
            "E": "France",
            "F": "FR",
            "G": "E",
            "H": "",
            "I": "",
            "J": "EUR"
        }
    ],
    "F04": [
        {
            "B": "LFPM",
            "C": "MELUN",
            "D": "Melun",
            "E": "France",
            "F": "FR",
            "G": "E",
            "H": "",
            "I": "",
            "J": "EUR"
        }
    ],
    "F05": [
        {
            "B": "LFOZ",
            "C": "ORLEANS-ST-DENIS DE LL'HOTEL",
            "D": "Orleans-st-denis de ll'hotel",
            "E": "France",
            "F": "FR",
            "G": "E",
            "H": "",
            "I": "",
            "J": "EUR"
        }
    ],
    "": [
        {
            "B": "LFDH",
            "C": "AUCH",
            "D": "AUCH LAMOTHE",
            "E": "France",
            "F": "FR",
            "G": "E",
            "H": "",
            "I": "",
            "J": "EUR"
        },
        {
            "B": "ZZZ1",
            "C": "",
            "D": "",
            "E": "UNKNOWN",
            "F": "",
            "G": "D",
            "H": "",
            "I": "",
            "J": ""
        }
    ],
    "F07": [
        {
            "B": "LFOA",
            "C": "AVORD AIR BASE",
            "D": "AVORD AIR BASE",
            "E": "France",
            "F": "FR",
            "G": "E",
            "H": "",
            "I": "",
            "J": "EUR"
        }
    ],
    "FAB": [
        {
            "B": "EGLF",
            "C": "FARNBOROUGH",
            "D": "Farnborough",
            "E": "United Kingdom",
            "F": "GB",
            "G": "U",
            "H": "",
            "I": "",
            "J": "EUR"
        }
    ],
    "FAE": [
        {
            "B": "EKVG",
            "C": "VAGAR FAROE ISLANDS",
            "D": "Faroe Islands",
            "E": "Faroe Islands",
            "F": "FO",
            "G": "E",
            "H": "",
            "I": "",
            "J": "EUR"
        }
    ],
    "FAI": [
        {
            "B": "PAFA",
            "C": "International",
            "D": "Fairbanks Intl",
            "E": "USA",
            "F": "US",
            "G": "T",
            "H": "",
            "I": "",
            "J": "NOA"
        }
    ],
    "FAK": [
        {
            "B": "KFAK",
            "C": "FALSE ISLAND",
            "D": "False Island",
            "E": "USA",
            "F": "US",
            "G": "T",
            "H": "",
            "I": "",
            "J": "NOA"
        }
    ],
    "FAN": [
        {
            "B": "ENLI",
            "C": "FARSUND / LISTA",
            "D": "FARSUND",
            "E": "Norway",
            "F": "NO",
            "G": "E",
            "H": "",
            "I": "",
            "J": "EUR"
        }
    ],
    "FAO": [
        {
            "B": "LPFR",
            "C": "FARO",
            "D": "Faro",
            "E": "Portugal",
            "F": "PT",
            "G": "E",
            "H": "",
            "I": "",
            "J": "EUR"
        }
    ],
    "FAR": [
        {
            "B": "KFAR",
            "C": "FARGO",
            "D": "Fargo",
            "E": "USA",
            "F": "US",
            "G": "T",
            "H": "",
            "I": "",
            "J": "NOA"
        }
    ],
    "FAT": [
        {
            "B": "KFAT",
            "C": "Airterminal",
            "D": "Fresno Yosemite International",
            "E": "USA",
            "F": "US",
            "G": "T",
            "H": "",
            "I": "",
            "J": "NOA"
        }
    ],
    "FBM": [
        {
            "B": "FZQA",
            "C": "Luano",
            "D": "Luano",
            "E": "Democratic Republic of Congo",
            "F": "CD",
            "G": "O",
            "H": "",
            "I": "",
            "J": "AFR"
        }
    ],
    "FBU": [
        {
            "B": "ENFB",
            "C": "OSLO - FORNEBU",
            "D": "Oslo",
            "E": "Norway",
            "F": "NO",
            "G": "E",
            "H": "",
            "I": "",
            "J": "EUR"
        }
    ],
    "FCA": [
        {
            "B": "KFCA",
            "C": "Glacier National Park",
            "D": "Glacier Park Intl",
            "E": "USA",
            "F": "US",
            "G": "T",
            "H": "",
            "I": "",
            "J": "NOA"
        }
    ],
    "FCN": [
        {
            "B": "ETMN",
            "C": "CUXHAVEN SEA AIRPORT",
            "D": "Cuxhaven",
            "E": "Germany",
            "F": "DE",
            "G": "E",
            "H": "",
            "I": "",
            "J": "EUR"
        }
    ],
    "FCO": [
        {
            "B": "LIRF",
            "C": "ROME",
            "D": "Rome FCO",
            "E": "Italy",
            "F": "IT",
            "G": "E",
            "H": "",
            "I": "",
            "J": "EUR"
        }
    ],
    "FDE": [
        {
            "B": "ENBL",
            "C": "Bringeland",
            "D": "Bringeland",
            "E": "Norway",
            "F": "NO",
            "G": "E",
            "H": "",
            "I": "",
            "J": "EUR"
        }
    ],
    "FDF": [
        {
            "B": "TFFF",
            "C": "MARTINIQUE-AIME-CESARE",
            "D": "Fort de france",
            "E": "Martinique",
            "F": "MQ",
            "G": "T",
            "H": "",
            "I": "",
            "J": "CAR"
        }
    ],
    "FDH": [
        {
            "B": "EDNY",
            "C": "FRIEDRICHSHAFEN",
            "D": "Friedrichshafen",
            "E": "Germany",
            "F": "DE",
            "G": "E",
            "H": "",
            "I": "",
            "J": "EUR"
        }
    ],
    "FEA": [
        {
            "B": "EGYY",
            "C": "FETLAR - SHETLAND ISLANDS",
            "D": "Fetlar",
            "E": "United Kingdom",
            "F": "GB",
            "G": "U",
            "H": "",
            "I": "",
            "J": "EUR"
        }
    ],
    "FEZ": [
        {
            "B": "GMFF",
            "C": "FES",
            "D": "Fez",
            "E": "Morocco",
            "F": "MA",
            "G": "O",
            "H": "",
            "I": "",
            "J": "AFR"
        }
    ],
    "FFD": [
        {
            "B": "EGVA",
            "C": "FAIRFORD MIL",
            "D": "Fairford",
            "E": "United Kingdom",
            "F": "GB",
            "G": "U",
            "H": "",
            "I": "",
            "J": "EUR"
        }
    ],
    "FFL": [
        {
            "B": "KFFL",
            "C": "FAIRFIELD MUNICIPAL AIRPORT",
            "D": "Fairfield",
            "E": "USA",
            "F": "US",
            "G": "T",
            "H": "",
            "I": "",
            "J": "NOA"
        }
    ],
    "FFO": [
        {
            "B": "KFFO",
            "C": "DAYTON - PATTERSON",
            "D": "Dayton",
            "E": "USA",
            "F": "US",
            "G": "T",
            "H": "",
            "I": "",
            "J": "NOA"
        }
    ],
    "FIE": [
        {
            "B": "EGEF",
            "C": "FAIR ISLE",
            "D": "Fair Isle",
            "E": "United Kingdom",
            "F": "GB",
            "G": "U",
            "H": "",
            "I": "",
            "J": "EUR"
        }
    ],
    "FIH": [
        {
            "B": "FZAA",
            "C": "NDJILI AIRPORT",
            "D": "NDJILI AIRPORT",
            "E": "Democratic Republic of Congo",
            "F": "CD",
            "G": "O",
            "H": "",
            "I": "",
            "J": "AFR"
        }
    ],
    "FJR": [
        {
            "B": "OMFJ",
            "C": "FUJAIRAH INT",
            "D": "FUJAIRAH INTERNATIONAL",
            "E": "United Arab Emirates",
            "F": "AE",
            "G": "O",
            "H": "",
            "I": "",
            "J": "MDE"
        }
    ],
    "FKB": [
        {
            "B": "EDSB",
            "C": "KARLSRUHE / BADEN-BADEN",
            "D": "Karlsruhe",
            "E": "Germany",
            "F": "DE",
            "G": "E",
            "H": "",
            "I": "",
            "J": "EUR"
        }
    ],
    "FLF": [
        {
            "B": "EDXF",
            "C": "FLENSBURG / SCHAFERHAU",
            "D": "Flensburg",
            "E": "Germany",
            "F": "DE",
            "G": "E",
            "H": "",
            "I": "",
            "J": "EUR"
        }
    ],
    "FLG": [
        {
            "B": "KFLG",
            "C": "Pulliam Field",
            "D": "Flagstaff Pulliam",
            "E": "USA",
            "F": "US",
            "G": "T",
            "H": "",
            "I": "",
            "J": "NOA"
        }
    ],
    "FLL": [
        {
            "B": "KFLL",
            "C": "FORT LAUDERDALE - HOLLYWOOD",
            "D": "Ft Lauderdale",
            "E": "USA",
            "F": "US",
            "G": "T",
            "H": "",
            "I": "",
            "J": "NOA"
        }
    ],
    "FLN": [
        {
            "B": "SBFL",
            "C": "Hercilio Luz",
            "D": "Hercilio Luz International",
            "E": "Brazil",
            "F": "BR",
            "G": "T",
            "H": "",
            "I": "",
            "J": "SOA"
        }
    ],
    "FLO": [
        {
            "B": "KFLO",
            "C": "FLORENCE REGIONAL",
            "D": "Florence usa",
            "E": "USA",
            "F": "US",
            "G": "T",
            "H": "",
            "I": "",
            "J": "NOA"
        }
    ],
    "FLR": [
        {
            "B": "LIRQ",
            "C": "FLORENCE PERETOLA",
            "D": "Florence",
            "E": "Italy",
            "F": "IT",
            "G": "E",
            "H": "",
            "I": "",
            "J": "EUR"
        }
    ],
    "FLW": [
        {
            "B": "LPFL",
            "C": "ASORENE / FLORES",
            "D": "Flores",
            "E": "Portugal",
            "F": "PT",
            "G": "E",
            "H": "",
            "I": "",
            "J": "EUR"
        }
    ],
    "FMH": [
        {
            "B": "KFMH",
            "C": "OTIS AFB",
            "D": "Otis AFB",
            "E": "USA",
            "F": "US",
            "G": "T",
            "H": "",
            "I": "",
            "J": "NOA"
        }
    ],
    "FMM": [
        {
            "B": "EDJA",
            "C": "MEMMINGEN - ALLGAU",
            "D": "Memmingen",
            "E": "Germany",
            "F": "DE",
            "G": "E",
            "H": "",
            "I": "",
            "J": "EUR"
        }
    ],
    "FMO": [
        {
            "B": "EDDG",
            "C": "MUNSTER",
            "D": "Munster",
            "E": "Germany",
            "F": "DE",
            "G": "E",
            "H": "",
            "I": "",
            "J": "EUR"
        }
    ],
    "FNA": [
        {
            "B": "GFLL",
            "C": "LUNGI INTL",
            "D": "lungi international",
            "E": "Sierra Leone",
            "F": "SL",
            "G": "O",
            "H": "",
            "I": "",
            "J": "AFR"
        }
    ],
    "FNB": [
        {
            "B": "ETNU",
            "C": "NEUBRANDENBURG",
            "D": "NEUBRANDENBURG",
            "E": "Germany",
            "F": "DE",
            "G": "E",
            "H": "",
            "I": "",
            "J": "EUR"
        }
    ],
    "FNC": [
        {
            "B": "LPMA",
            "C": "FUNCHAL",
            "D": "Funchal",
            "E": "Portugal",
            "F": "PT",
            "G": "E",
            "H": "",
            "I": "",
            "J": "EUR"
        }
    ],
    "FNI": [
        {
            "B": "LFTW",
            "C": "NIMES",
            "D": "Nimes",
            "E": "France",
            "F": "FR",
            "G": "E",
            "H": "",
            "I": "",
            "J": "EUR"
        }
    ],
    "FNJ": [
        {
            "B": "ZKPY",
            "C": "SUNAN",
            "D": "Sunan",
            "E": "Korea, Democratic People's Republic of",
            "F": "KP",
            "G": "O",
            "H": "",
            "I": "",
            "J": "SEA"
        }
    ],
    "FNT": [
        {
            "B": "KFNT",
            "C": "Bishop",
            "D": "Bishop International",
            "E": "USA",
            "F": "US",
            "G": "T",
            "H": "",
            "I": "",
            "J": "NOA"
        }
    ],
    "FOC": [
        {
            "B": "ZSFZ",
            "C": "Fuzhou",
            "D": "Changle International",
            "E": "Peoples Republic of China",
            "F": "CN",
            "G": "O",
            "H": "",
            "I": "",
            "J": "SEA"
        }
    ],
    "FOG": [
        {
            "B": "LIBF",
            "C": "FOGGIA",
            "D": "Foggia",
            "E": "Italy",
            "F": "IT",
            "G": "E",
            "H": "",
            "I": "",
            "J": "EUR"
        }
    ],
    "FOK": [
        {
            "B": "KFOK",
            "C": "GABRESKI AIRPORT",
            "D": "FRANCIS S. GABRESKI AIRPORT",
            "E": "USA",
            "F": "US",
            "G": "T",
            "H": "",
            "I": "",
            "J": "NOA"
        }
    ],
    "FPO": [
        {
            "B": "MYGF",
            "C": "GRAND BAHAMA INTL",
            "D": "GRAND BAHAMA INTERNATIONAL AIRPORT",
            "E": "Bahamas",
            "F": "BS",
            "G": "T",
            "H": "",
            "I": "",
            "J": "CAR"
        }
    ],
    "FRA": [
        {
            "B": "EDDF",
            "C": "FRANKFURT",
            "D": "Frankfurt FRA",
            "E": "Germany",
            "F": "DE",
            "G": "E",
            "H": "",
            "I": "",
            "J": "EUR"
        }
    ],
    "FRG": [
        {
            "B": "KFRG",
            "C": "FARMINGDALE / REPUBLIC FIELD",
            "D": "Farmingdale",
            "E": "USA",
            "F": "US",
            "G": "T",
            "H": "",
            "I": "",
            "J": "NOA"
        }
    ],
    "FRK": [
        {
            "B": "FSSF",
            "C": "FREGATE ISLAND AIRPORT",
            "D": "Fregate Island Airport",
            "E": "Seychelles",
            "F": "",
            "G": "O",
            "H": "",
            "I": "",
            "J": ""
        }
    ],
    "FRL": [
        {
            "B": "LIPK",
            "C": "FORLI",
            "D": "Forli",
            "E": "Italy",
            "F": "IT",
            "G": "E",
            "H": "",
            "I": "",
            "J": "EUR"
        }
    ],
    "FRO": [
        {
            "B": "ENFL",
            "C": "FLORO",
            "D": "Floro",
            "E": "Norway",
            "F": "NO",
            "G": "E",
            "H": "",
            "I": "",
            "J": "EUR"
        }
    ],
    "FRS": [
        {
            "B": "MGFL",
            "C": "FLORES",
            "D": "Flores",
            "E": "Guatemala",
            "F": "GT",
            "G": "T",
            "H": "",
            "I": "",
            "J": "CEM"
        }
    ],
    "FRU": [
        {
            "B": "UAFM",
            "C": "BISHKEK",
            "D": "Bishkek",
            "E": "Kyrgyzstan",
            "F": "KG",
            "G": "O",
            "H": "",
            "I": "",
            "J": "SEA"
        }
    ],
    "FRW": [
        {
            "B": "FBFT",
            "C": "Francistown",
            "D": "Francistown",
            "E": "Botswana",
            "F": "BW",
            "G": "O",
            "H": "",
            "I": "",
            "J": "AFR"
        }
    ],
    "FSC": [
        {
            "B": "LFKF",
            "C": "FIGARI",
            "D": "Figari",
            "E": "France",
            "F": "FR",
            "G": "E",
            "H": "",
            "I": "",
            "J": "EUR"
        }
    ],
    "FSD": [
        {
            "B": "KFSD",
            "C": "Regional(Jo Foss Fld)",
            "D": "Joe Foss Field",
            "E": "USA",
            "F": "US",
            "G": "T",
            "H": "",
            "I": "",
            "J": "NOA"
        }
    ],
    "FSP": [
        {
            "B": "LFVP",
            "C": "ST. PIERRE",
            "D": "St. pierre",
            "E": "Saint Pierre And Miquelon",
            "F": "PM",
            "G": "T",
            "H": "",
            "I": "",
            "J": "NOA"
        }
    ],
    "FSS": [
        {
            "B": "EGQK",
            "C": "KINLOSS",
            "D": "Kinloss",
            "E": "United Kingdom",
            "F": "GB",
            "G": "U",
            "H": "",
            "I": "",
            "J": "EUR"
        }
    ],
    "FSZ": [
        {
            "B": "RJNS",
            "C": "SHIZUOKA",
            "D": "Shizuoka",
            "E": "Japan",
            "F": "JP",
            "G": "O",
            "H": "",
            "I": "",
            "J": "JAK"
        }
    ],
    "FTA": [
        {
            "B": "NVVF",
            "C": "FUTUNA",
            "D": "Futuna",
            "E": "Wallis And Futuna Islands",
            "F": "WF",
            "G": "O",
            "H": "",
            "I": "",
            "J": "SWP"
        }
    ],
    "FTE": [
        {
            "B": "SAWC",
            "C": "El Calafate",
            "D": "Comandante A. Tola",
            "E": "Argentina",
            "F": "AR",
            "G": "T",
            "H": "",
            "I": "",
            "J": "SOA"
        }
    ],
    "FTW": [
        {
            "B": "KFTW",
            "C": "FORT WORTH - MEACHAM",
            "D": "Fort worth",
            "E": "USA",
            "F": "US",
            "G": "T",
            "H": "",
            "I": "",
            "J": "NOA"
        }
    ],
    "FTX": [
        {
            "B": "FCOO",
            "C": "OWANDO AIRPORT",
            "D": "OWANDO AIRPORT",
            "E": "Congo",
            "F": "CG",
            "G": "O",
            "H": "",
            "I": "",
            "J": "AFR"
        }
    ],
    "FTY": [
        {
            "B": "KFTY",
            "C": "ATLANTA - FULTON",
            "D": "Atlanta",
            "E": "USA",
            "F": "US",
            "G": "T",
            "H": "",
            "I": "",
            "J": "NOA"
        }
    ],
    "FUE": [
        {
            "B": "GCFV",
            "C": "FUERTEVENTURA",
            "D": "Fuerteventura",
            "E": "Spain",
            "F": "ES",
            "G": "E",
            "H": "",
            "I": "",
            "J": "EUR"
        }
    ],
    "FUG": [
        {
            "B": "ZSFY",
            "C": "FUYANG",
            "D": "Fuyang",
            "E": "Peoples Republic of China",
            "F": "CN",
            "G": "O",
            "H": "",
            "I": "",
            "J": "SEA"
        }
    ],
    "FUK": [
        {
            "B": "RJFF",
            "C": "FUKUOKA",
            "D": "Fukuoka",
            "E": "Japan",
            "F": "JP",
            "G": "O",
            "H": "",
            "I": "",
            "J": "JAK"
        }
    ],
    "FWA": [
        {
            "B": "KFWA",
            "C": "FORT WAYNE",
            "D": "FORT WAYNE INTERNATIONAL",
            "E": "USA",
            "F": "US",
            "G": "T",
            "H": "",
            "I": "",
            "J": "NOA"
        }
    ],
    "FXE": [
        {
            "B": "KFXE",
            "C": "FORT LAUDERDALE EXECUTIVE",
            "D": "FORT LAUDERDALE EXECUTIVE AIRPORT",
            "E": "USA",
            "F": "US",
            "G": "T",
            "H": "",
            "I": "",
            "J": "NOA"
        }
    ],
    "FYU": [
        {
            "B": "PFYU",
            "C": "FORT YUKON",
            "D": "Fort Yukon",
            "E": "USA",
            "F": "US",
            "G": "T",
            "H": "",
            "I": "",
            "J": "NOA"
        }
    ],
    "FZO": [
        {
            "B": "EGTG",
            "C": "FILTON",
            "D": "Filton",
            "E": "United Kingdom",
            "F": "GB",
            "G": "U",
            "H": "",
            "I": "",
            "J": "EUR"
        }
    ],
    "G01": [
        {
            "B": "EGBD",
            "C": "DERBY",
            "D": "Derby",
            "E": "United Kingdom",
            "F": "GB",
            "G": "U",
            "H": "",
            "I": "",
            "J": "EUR"
        }
    ],
    "G02": [
        {
            "B": "EGBG",
            "C": "LEICESTER",
            "D": "Leicester",
            "E": "United Kingdom",
            "F": "GB",
            "G": "U",
            "H": "",
            "I": "",
            "J": "EUR"
        }
    ],
    "G03": [
        {
            "B": "EGCB",
            "C": "MANCHESTER BARTON",
            "D": "Manchester",
            "E": "United Kingdom",
            "F": "GB",
            "G": "U",
            "H": "",
            "I": "",
            "J": "EUR"
        }
    ],
    "G04": [
        {
            "B": "",
            "C": "",
            "D": "",
            "E": "UNKNOWN",
            "F": "",
            "G": "U",
            "H": "",
            "I": "",
            "J": ""
        }
    ],
    "G05": [
        {
            "B": "EGBT",
            "C": "TURWESTON",
            "D": "Turweston",
            "E": "United Kingdom",
            "F": "GB",
            "G": "U",
            "H": "",
            "I": "",
            "J": "EUR"
        }
    ],
    "G06": [
        {
            "B": "EGCJ",
            "C": "SHERBURN IN ELMET",
            "D": "Sherburn",
            "E": "United Kingdom",
            "F": "GB",
            "G": "U",
            "H": "",
            "I": "",
            "J": "EUR"
        }
    ],
    "G07": [
        {
            "B": "EGCL",
            "C": "FENLAND",
            "D": "Fenland",
            "E": "United Kingdom",
            "F": "GB",
            "G": "U",
            "H": "",
            "I": "",
            "J": "EUR"
        }
    ],
    "G08": [
        {
            "B": "EGCO",
            "C": "SOUTHPORT",
            "D": "Southport",
            "E": "United Kingdom",
            "F": "GB",
            "G": "U",
            "H": "",
            "I": "",
            "J": "EUR"
        }
    ],
    "G09": [
        {
            "B": "EGDC",
            "C": "CHIVENOR",
            "D": "Chivenor",
            "E": "United Kingdom",
            "F": "GB",
            "G": "U",
            "H": "",
            "I": "",
            "J": "EUR"
        }
    ],
    "G10": [
        {
            "B": "EGCK",
            "C": "CAERNAFON",
            "D": "Caernarvon",
            "E": "United Kingdom",
            "F": "GB",
            "G": "U",
            "H": "",
            "I": "",
            "J": "EUR"
        }
    ],
    "G11": [
        {
            "B": "EGDM",
            "C": "BOSCOMBE DOWN",
            "D": "Boscomb Down",
            "E": "United Kingdom",
            "F": "GB",
            "G": "U",
            "H": "",
            "I": "",
            "J": "EUR"
        }
    ],
    "G12": [
        {
            "B": "EGDN",
            "C": "NETHERAVON",
            "D": "Netheravon",
            "E": "United Kingdom",
            "F": "GB",
            "G": "U",
            "H": "",
            "I": "",
            "J": "EUR"
        }
    ],
    "G13": [
        {
            "B": "EGDP",
            "C": "PORTLAND - UK",
            "D": "Portland",
            "E": "United Kingdom",
            "F": "GB",
            "G": "U",
            "H": "",
            "I": "",
            "J": "EUR"
        }
    ],
    "G14": [
        {
            "B": "EGDR",
            "C": "CULDROSE MIL",
            "D": "Culdrose",
            "E": "United Kingdom",
            "F": "GB",
            "G": "U",
            "H": "",
            "I": "",
            "J": "EUR"
        }
    ],
    "G15": [
        {
            "B": "",
            "C": "ST. ATHAN MIL",
            "D": "St Athan",
            "E": "United Kingdom",
            "F": "GB",
            "G": "U",
            "H": "",
            "I": "",
            "J": "EUR"
        }
    ],
    "G16": [
        {
            "B": "EGBO",
            "C": "WOLVERHAMPTON HALFPENNY GREEN",
            "D": "WOLVERHAMPTON HALFPENNY GREEN",
            "E": "United Kingdom",
            "F": "GB",
            "G": "U",
            "H": "",
            "I": "",
            "J": "EUR"
        }
    ],
    "G17": [
        {
            "B": "EGLB",
            "C": "BROOKLANDS",
            "D": "Brooklands",
            "E": "United Kingdom",
            "F": "GB",
            "G": "U",
            "H": "",
            "I": "",
            "J": "EUR"
        }
    ],
    "G18": [
        {
            "B": "EGFC",
            "C": "CARDIFF HELIPORT",
            "D": "Cardiff",
            "E": "United Kingdom",
            "F": "GB",
            "G": "U",
            "H": "",
            "I": "",
            "J": "EUR"
        }
    ],
    "G19": [
        {
            "B": "EGHA",
            "C": "COMPTON ABBAS",
            "D": "Compton Abbas",
            "E": "United Kingdom",
            "F": "GB",
            "G": "U",
            "H": "",
            "I": "",
            "J": "EUR"
        }
    ],
    "G1A": [
        {
            "B": "EGCM",
            "C": "CHURCH FENTON",
            "D": "CHURCH FENTON",
            "E": "United Kingdom",
            "F": "GB",
            "G": "U",
            "H": "",
            "I": "",
            "J": "EUR"
        }
    ],
    "G20": [
        {
            "B": "EGHF",
            "C": "LEE ON SOLENT",
            "D": "Lee on Solent",
            "E": "United Kingdom",
            "F": "GB",
            "G": "U",
            "H": "",
            "I": "",
            "J": "EUR"
        }
    ],
    "G21": [
        {
            "B": "EGHG",
            "C": "YEOVIL MIL",
            "D": "Yeovil",
            "E": "United Kingdom",
            "F": "GB",
            "G": "U",
            "H": "",
            "I": "",
            "J": "EUR"
        }
    ],
    "G22": [
        {
            "B": "EGHN",
            "C": "SANDOWN",
            "D": "Sandown",
            "E": "United Kingdom",
            "F": "GB",
            "G": "U",
            "H": "",
            "I": "",
            "J": "EUR"
        }
    ],
    "G23": [
        {
            "B": "EGHO",
            "C": "THRUXTON",
            "D": "Thruxton",
            "E": "United Kingdom",
            "F": "GB",
            "G": "U",
            "H": "",
            "I": "",
            "J": "EUR"
        }
    ],
    "G24": [
        {
            "B": "EGHP",
            "C": "POPHAM",
            "D": "Popham",
            "E": "United Kingdom",
            "F": "GB",
            "G": "U",
            "H": "",
            "I": "",
            "J": "EUR"
        }
    ],
    "G26": [
        {
            "B": "EGHY",
            "C": "TRURO",
            "D": "Truro",
            "E": "United Kingdom",
            "F": "GB",
            "G": "U",
            "H": "",
            "I": "",
            "J": "EUR"
        }
    ],
    "G27": [
        {
            "B": "EGSB",
            "C": "BEDFORD",
            "D": "Bedford",
            "E": "United Kingdom",
            "F": "GB",
            "G": "U",
            "H": "",
            "I": "",
            "J": "EUR"
        }
    ],
    "G28": [
        {
            "B": "EGTR",
            "C": "ELSTREE",
            "D": "Elstree",
            "E": "United Kingdom",
            "F": "GB",
            "G": "U",
            "H": "",
            "I": "",
            "J": "EUR"
        }
    ],
    "G29": [
        {
            "B": "EGKD",
            "C": "ALBOURNE",
            "D": "Albourne",
            "E": "United Kingdom",
            "F": "GB",
            "G": "U",
            "H": "",
            "I": "",
            "J": "EUR"
        }
    ],
    "G30": [
        {
            "B": "EGKG",
            "C": "GOODWOOD RACECOURSE",
            "D": "Goodwood",
            "E": "United Kingdom",
            "F": "GB",
            "G": "U",
            "H": "",
            "I": "",
            "J": "EUR"
        }
    ],
    "G31": [
        {
            "B": "EGKH",
            "C": "LASHENDEN",
            "D": "Lashenden",
            "E": "United Kingdom",
            "F": "GB",
            "G": "U",
            "H": "",
            "I": "",
            "J": "EUR"
        }
    ],
    "G32": [
        {
            "B": "EGLA",
            "C": "BODMIN",
            "D": "Bodmin",
            "E": "United Kingdom",
            "F": "GB",
            "G": "U",
            "H": "",
            "I": "",
            "J": "EUR"
        }
    ],
    "G33": [
        {
            "B": "EGLD",
            "C": "DENHAM",
            "D": "Denham",
            "E": "United Kingdom",
            "F": "GB",
            "G": "U",
            "H": "",
            "I": "",
            "J": "EUR"
        }
    ],
    "G34": [
        {
            "B": "EGLG",
            "C": "PANSHANGAR",
            "D": "Panshangar",
            "E": "United Kingdom",
            "F": "GB",
            "G": "U",
            "H": "",
            "I": "",
            "J": "EUR"
        }
    ],
    "G35": [
        {
            "B": "EGLI",
            "C": "ISLEWORTH",
            "D": "Isleworth",
            "E": "United Kingdom",
            "F": "GB",
            "G": "U",
            "H": "",
            "I": "",
            "J": "EUR"
        }
    ],
    "G36": [
        {
            "B": "EGLJ",
            "C": "CHALGROVE",
            "D": "Chalgrove",
            "E": "United Kingdom",
            "F": "GB",
            "G": "U",
            "H": "",
            "I": "",
            "J": "EUR"
        }
    ],
    "G37": [
        {
            "B": "EGLM",
            "C": "WHITE WALTHAM",
            "D": "White Waltham",
            "E": "United Kingdom",
            "F": "GB",
            "G": "U",
            "H": "",
            "I": "",
            "J": "EUR"
        }
    ],
    "G38": [
        {
            "B": "EGLS",
            "C": "OLD SARUM",
            "D": "Old Sarum",
            "E": "United Kingdom",
            "F": "GB",
            "G": "U",
            "H": "",
            "I": "",
            "J": "EUR"
        }
    ],
    "G39": [
        {
            "B": "EGLT",
            "C": "ASCOT RACECOURSE",
            "D": "Ascot",
            "E": "United Kingdom",
            "F": "GB",
            "G": "U",
            "H": "",
            "I": "",
            "J": "EUR"
        }
    ],
    "G40": [
        {
            "B": "EGMA",
            "C": "FOWLEMERE",
            "D": "Fowlemere",
            "E": "United Kingdom",
            "F": "GB",
            "G": "U",
            "H": "",
            "I": "",
            "J": "EUR"
        }
    ],
    "G41": [
        {
            "B": "EGMF",
            "C": "FARTHING CORNER",
            "D": "Farthing Corner",
            "E": "United Kingdom",
            "F": "GB",
            "G": "U",
            "H": "",
            "I": "",
            "J": "EUR"
        }
    ],
    "G42": [
        {
            "B": "EGMJ",
            "C": "LITTLE GRANDSDEN",
            "D": "Little Grandsden",
            "E": "United Kingdom",
            "F": "GB",
            "G": "U",
            "H": "",
            "I": "",
            "J": "EUR"
        }
    ],
    "G43": [
        {
            "B": "EGNA",
            "C": "HUCKNALL",
            "D": "Hucknall",
            "E": "United Kingdom",
            "F": "GB",
            "G": "U",
            "H": "",
            "I": "",
            "J": "EUR"
        }
    ],
    "G44": [
        {
            "B": "EGNB",
            "C": "BROUGH",
            "D": "Brough",
            "E": "United Kingdom",
            "F": "GB",
            "G": "U",
            "H": "",
            "I": "",
            "J": "EUR"
        }
    ],
    "G45": [
        {
            "B": "EGNE",
            "C": "GAMSTON",
            "D": "Gamston",
            "E": "United Kingdom",
            "F": "GB",
            "G": "U",
            "H": "",
            "I": "",
            "J": "EUR"
        }
    ],
    "G46": [
        {
            "B": "EGNI",
            "C": "SKEGNESS",
            "D": "Skegness",
            "E": "United Kingdom",
            "F": "GB",
            "G": "U",
            "H": "",
            "I": "",
            "J": "EUR"
        }
    ],
    "G47": [
        {
            "B": "EGNO",
            "C": "WARTON",
            "D": "Warton",
            "E": "United Kingdom",
            "F": "GB",
            "G": "U",
            "H": "",
            "I": "",
            "J": "EUR"
        }
    ],
    "G48": [
        {
            "B": "G48Z",
            "C": "ROSTREVOR CO.DOWN",
            "D": "Rostrevor",
            "E": "United Kingdom",
            "F": "GB",
            "G": "U",
            "H": "",
            "I": "",
            "J": "EUR"
        }
    ],
    "G49": [
        {
            "B": "EGOW",
            "C": "WOODVALE",
            "D": "Woodvale",
            "E": "United Kingdom",
            "F": "GB",
            "G": "U",
            "H": "",
            "I": "",
            "J": "EUR"
        }
    ],
    "G50": [
        {
            "B": "EGPG",
            "C": "CUMBERNAULD",
            "D": "Cumbernauld",
            "E": "United Kingdom",
            "F": "GB",
            "G": "U",
            "H": "",
            "I": "",
            "J": "EUR"
        }
    ],
    "G51": [
        {
            "B": "EGPS",
            "C": "PETERHEAD",
            "D": "Peterhead",
            "E": "United Kingdom",
            "F": "GB",
            "G": "U",
            "H": "",
            "I": "",
            "J": "EUR"
        }
    ],
    "G52": [
        {
            "B": "EGQD",
            "C": "LISBURN",
            "D": "Lisburn",
            "E": "United Kingdom",
            "F": "GB",
            "G": "U",
            "H": "",
            "I": "",
            "J": "EUR"
        }
    ],
    "G53": [
        {
            "B": "EGSF",
            "C": "PETERBOROUGH/CONINGTON",
            "D": "Peterborough",
            "E": "United Kingdom",
            "F": "GB",
            "G": "U",
            "H": "",
            "I": "",
            "J": "EUR"
        }
    ],
    "G54": [
        {
            "B": "EGSG",
            "C": "STAPLEFORD",
            "D": "Stapleford",
            "E": "United Kingdom",
            "F": "GB",
            "G": "U",
            "H": "",
            "I": "",
            "J": "EUR"
        }
    ],
    "G55": [
        {
            "B": "EGTW",
            "C": "OAKSEY PARK",
            "D": "Oaksey park",
            "E": "United Kingdom",
            "F": "GB",
            "G": "U",
            "H": "",
            "I": "",
            "J": "EUR"
        }
    ],
    "G56": [
        {
            "B": "EGSP",
            "C": "PETERBOROUGH/SIBSON",
            "D": "Peterborough",
            "E": "United Kingdom",
            "F": "GB",
            "G": "U",
            "H": "",
            "I": "",
            "J": "EUR"
        }
    ],
    "G57": [
        {
            "B": "EGSQ",
            "C": "CLACTON",
            "D": "Clacton",
            "E": "United Kingdom",
            "F": "GB",
            "G": "U",
            "H": "",
            "I": "",
            "J": "EUR"
        }
    ],
    "G58": [
        {
            "B": "EGSR",
            "C": "EARL'S COLNE",
            "D": "Earls Colne",
            "E": "United Kingdom",
            "F": "GB",
            "G": "U",
            "H": "",
            "I": "",
            "J": "EUR"
        }
    ],
    "G59": [
        {
            "B": "EGSV",
            "C": "OLD BUCKENHAM",
            "D": "Old Buckenham",
            "E": "United Kingdom",
            "F": "GB",
            "G": "U",
            "H": "",
            "I": "",
            "J": "EUR"
        }
    ],
    "G60": [
        {
            "B": "EGSW",
            "C": "NEWMARKET",
            "D": "Newmarket",
            "E": "United Kingdom",
            "F": "GB",
            "G": "U",
            "H": "",
            "I": "",
            "J": "EUR"
        }
    ],
    "G61": [
        {
            "B": "EGSX",
            "C": "NORTH WEALD",
            "D": "North Weald",
            "E": "United Kingdom",
            "F": "GB",
            "G": "U",
            "H": "",
            "I": "",
            "J": "EUR"
        }
    ],
    "G62": [
        {
            "B": "EGTA",
            "C": "AYLESBURY",
            "D": "Aylesbury",
            "E": "United Kingdom",
            "F": "GB",
            "G": "U",
            "H": "",
            "I": "",
            "J": "EUR"
        }
    ],
    "G63": [
        {
            "B": "EGTB",
            "C": "WYCOMBE AIR PARK",
            "D": "Wycombe",
            "E": "United Kingdom",
            "F": "GB",
            "G": "U",
            "H": "",
            "I": "",
            "J": "EUR"
        }
    ],
    "G64": [
        {
            "B": "",
            "C": "CRANFIELD",
            "D": "Cranfield",
            "E": "United Kingdom",
            "F": "GB",
            "G": "U",
            "H": "",
            "I": "",
            "J": "EUR"
        }
    ],
    "G65": [
        {
            "B": "EGBW",
            "C": "WELLESBOURNE MOUNTFORD",
            "D": "Wellesbourne",
            "E": "United Kingdom",
            "F": "GB",
            "G": "U",
            "H": "",
            "I": "",
            "J": "EUR"
        }
    ],
    "G66": [
        {
            "B": "EGTP",
            "C": "PERRANPORTH",
            "D": "Perranporth",
            "E": "United Kingdom",
            "F": "GB",
            "G": "U",
            "H": "",
            "I": "",
            "J": "EUR"
        }
    ],
    "G67": [
        {
            "B": "EGTU",
            "C": "DUKESWELL",
            "D": "Dukeswell",
            "E": "United Kingdom",
            "F": "GB",
            "G": "U",
            "H": "",
            "I": "",
            "J": "EUR"
        }
    ],
    "G68": [
        {
            "B": "EGBM",
            "C": "TATENHILL",
            "D": "Benson",
            "E": "United Kingdom",
            "F": "GB",
            "G": "U",
            "H": "",
            "I": "",
            "J": "EUR"
        }
    ],
    "G69": [
        {
            "B": "EGUC",
            "C": "ABERPORTH",
            "D": "Aberporth",
            "E": "United Kingdom",
            "F": "GB",
            "G": "U",
            "H": "",
            "I": "",
            "J": "EUR"
        }
    ],
    "G70": [
        {
            "B": "EGTF",
            "C": "FAIROAKS",
            "D": "Fairoaks",
            "E": "United Kingdom",
            "F": "GB",
            "G": "U",
            "H": "",
            "I": "",
            "J": "EUR"
        }
    ],
    "G71": [
        {
            "B": "EGCP",
            "C": "THORNE",
            "D": "Thorne",
            "E": "United Kingdom",
            "F": "GB",
            "G": "U",
            "H": "",
            "I": "",
            "J": "EUR"
        }
    ],
    "G72": [
        {
            "B": "EGUO",
            "C": "COLERNE MIL",
            "D": "Colerne",
            "E": "United Kingdom",
            "F": "GB",
            "G": "U",
            "H": "",
            "I": "",
            "J": "EUR"
        }
    ],
    "G73": [
        {
            "B": "EGUW",
            "C": "WATTISHAM",
            "D": "Wattisham",
            "E": "United Kingdom",
            "F": "GB",
            "G": "U",
            "H": "",
            "I": "",
            "J": "EUR"
        }
    ],
    "G74": [
        {
            "B": "EGCW",
            "C": "WELSHPOOL",
            "D": "Welshpool",
            "E": "United Kingdom",
            "F": "GB",
            "G": "U",
            "H": "",
            "I": "",
            "J": "EUR"
        }
    ],
    "G75": [
        {
            "B": "EGVE",
            "C": "PLYMOUTH MIL",
            "D": "Plymouth",
            "E": "United Kingdom",
            "F": "GB",
            "G": "U",
            "H": "",
            "I": "",
            "J": "EUR"
        }
    ],
    "G76": [
        {
            "B": "EGVH",
            "C": "HEREFORD",
            "D": "Hereford",
            "E": "United Kingdom",
            "F": "GB",
            "G": "U",
            "H": "",
            "I": "",
            "J": "EUR"
        }
    ],
    "G77": [
        {
            "B": "EGAD",
            "C": "NEWTOWNARDS",
            "D": "Newtownards",
            "E": "United Kingdom",
            "F": "GB",
            "G": "U",
            "H": "",
            "I": "",
            "J": "EUR"
        }
    ],
    "G78": [
        {
            "B": "EGWC",
            "C": "COSFORD MIL",
            "D": "Cosford",
            "E": "United Kingdom",
            "F": "GB",
            "G": "U",
            "H": "",
            "I": "",
            "J": "EUR"
        }
    ],
    "G79": [
        {
            "B": "EGWE",
            "C": "HENLOW",
            "D": "Henlow",
            "E": "United Kingdom",
            "F": "GB",
            "G": "U",
            "H": "",
            "I": "",
            "J": "EUR"
        }
    ],
    "G80": [
        {
            "B": "EGWN",
            "C": "HALTON",
            "D": "Halton",
            "E": "United Kingdom",
            "F": "GB",
            "G": "U",
            "H": "",
            "I": "",
            "J": "EUR"
        }
    ],
    "G81": [
        {
            "B": "EGWR",
            "C": "CROUGHTON",
            "D": "Croughton",
            "E": "United Kingdom",
            "F": "GB",
            "G": "U",
            "H": "",
            "I": "",
            "J": "EUR"
        }
    ],
    "G82": [
        {
            "B": "EGWS",
            "C": "BENTLEY PRIORY",
            "D": "Bentley Priory",
            "E": "United Kingdom",
            "F": "GB",
            "G": "U",
            "H": "",
            "I": "",
            "J": "EUR"
        }
    ],
    "G83": [
        {
            "B": "EGWX",
            "C": "NORTHWOOD",
            "D": "Northwood",
            "E": "United Kingdom",
            "F": "GB",
            "G": "U",
            "H": "",
            "I": "",
            "J": "EUR"
        }
    ],
    "G85": [
        {
            "B": "EGOS",
            "C": "SHAWBURY",
            "D": "Shawbury",
            "E": "United Kingdom",
            "F": "GB",
            "G": "U",
            "H": "",
            "I": "",
            "J": "EUR"
        }
    ],
    "G86": [
        {
            "B": "EGNU",
            "C": "FULL SUTTON",
            "D": "Full sutton",
            "E": "United Kingdom",
            "F": "GB",
            "G": "U",
            "H": "",
            "I": "",
            "J": "EUR"
        }
    ],
    "G87": [
        {
            "B": "EGXN",
            "C": "NEWTON",
            "D": "Newton",
            "E": "United Kingdom",
            "F": "GB",
            "G": "U",
            "H": "",
            "I": "",
            "J": "EUR"
        }
    ],
    "G88": [
        {
            "B": "EGBC",
            "C": "CHELTENHAM",
            "D": "Scampton",
            "E": "United Kingdom",
            "F": "GB",
            "G": "U",
            "H": "",
            "I": "",
            "J": "EUR"
        }
    ],
    "G89": [
        {
            "B": "EGXT",
            "C": "WITTERING",
            "D": "Wittering",
            "E": "United Kingdom",
            "F": "GB",
            "G": "U",
            "H": "",
            "I": "",
            "J": "EUR"
        }
    ],
    "G90": [
        {
            "B": "EGST",
            "C": "ELMSETT",
            "D": "Elmsett",
            "E": "United Kingdom",
            "F": "GB",
            "G": "U",
            "H": "",
            "I": "",
            "J": "EUR"
        }
    ],
    "G91": [
        {
            "B": "EGXV",
            "C": "LECONFIELD",
            "D": "Leconfield",
            "E": "United Kingdom",
            "F": "GB",
            "G": "U",
            "H": "",
            "I": "",
            "J": "EUR"
        }
    ],
    "G92": [
        {
            "B": "EGXZ",
            "C": "TOPCLIFFE",
            "D": "Topcliffe",
            "E": "United Kingdom",
            "F": "GB",
            "G": "U",
            "H": "",
            "I": "",
            "J": "EUR"
        }
    ],
    "G93": [
        {
            "B": "EGYB",
            "C": "BRAMPTON",
            "D": "Brampton",
            "E": "United Kingdom",
            "F": "GB",
            "G": "U",
            "H": "",
            "I": "",
            "J": "EUR"
        }
    ],
    "G94": [
        {
            "B": "EGOQ",
            "C": "MONA",
            "D": "Coltishall",
            "E": "United Kingdom",
            "F": "GB",
            "G": "U",
            "H": "",
            "I": "",
            "J": "EUR"
        }
    ],
    "G95": [
        {
            "B": "EGYD",
            "C": "CRANWELL",
            "D": "Cranwell",
            "E": "United Kingdom",
            "F": "GB",
            "G": "U",
            "H": "",
            "I": "",
            "J": "EUR"
        }
    ],
    "G96": [
        {
            "B": "EGYE",
            "C": "BARKSTON HEATH",
            "D": "Barkston Heath",
            "E": "United Kingdom",
            "F": "GB",
            "G": "U",
            "H": "",
            "I": "",
            "J": "EUR"
        }
    ],
    "G97": [
        {
            "B": "EGOV",
            "C": "VALLEY",
            "D": "Valley",
            "E": "United Kingdom",
            "F": "GB",
            "G": "U",
            "H": "",
            "I": "",
            "J": "EUR"
        }
    ],
    "G98": [
        {
            "B": "EGYW",
            "C": "WAINFLEET",
            "D": "Wainfleet",
            "E": "United Kingdom",
            "F": "GB",
            "G": "U",
            "H": "",
            "I": "",
            "J": "EUR"
        }
    ],
    "G99": [
        {
            "B": "EGLW",
            "C": "BATTERSEA HELIPORT",
            "D": "Battersea",
            "E": "United Kingdom",
            "F": "GB",
            "G": "U",
            "H": "",
            "I": "",
            "J": "EUR"
        }
    ],
    "GA.": [
        {
            "B": "",
            "C": "",
            "D": "",
            "E": "United Kingdom",
            "F": "",
            "G": "U",
            "H": "",
            "I": "",
            "J": ""
        }
    ],
    "GAJ": [
        {
            "B": "RJSC",
            "C": "Junmachi",
            "D": "Yamagata",
            "E": "Japan",
            "F": "JP",
            "G": "O",
            "H": "",
            "I": "",
            "J": "JAK"
        }
    ],
    "GAN": [
        {
            "B": "VRMG",
            "C": "GAN",
            "D": "Gan",
            "E": "Maldives",
            "F": "MV",
            "G": "O",
            "H": "",
            "I": "",
            "J": "SAS"
        }
    ],
    "GAP": [
        {
            "B": "LTCS",
            "C": "?ANLIURFA",
            "D": "?anl?urfa",
            "E": "Turkey",
            "F": "TR",
            "G": "E",
            "H": "",
            "I": "",
            "J": "EUR"
        }
    ],
    "GAT": [
        {
            "B": "LFNA",
            "C": "GAP-TALLARD",
            "D": "Gap Tallard",
            "E": "France",
            "F": "FR",
            "G": "E",
            "H": "",
            "I": "",
            "J": "EUR"
        }
    ],
    "GAU": [
        {
            "B": "VEGT",
            "C": "Guwahati (Assam)",
            "D": "Gopinath Bordoloi International",
            "E": "India",
            "F": "IN",
            "G": "O",
            "H": "",
            "I": "",
            "J": "SAS"
        }
    ],
    "GAY": [
        {
            "B": "VEGY",
            "C": "Gaya",
            "D": "Gaya",
            "E": "India",
            "F": "IN",
            "G": "O",
            "H": "",
            "I": "",
            "J": "SAS"
        }
    ],
    "GB1": [
        {
            "B": "EGAL",
            "C": "LANGFORD LODGE",
            "D": "Langford lodge",
            "E": "United Kingdom",
            "F": "GB",
            "G": "U",
            "H": "",
            "I": "",
            "J": "EUR"
        }
    ],
    "GB2": [
        {
            "B": "EGCE",
            "C": "WREXHAM/BORAS",
            "D": "Wrexham/boras",
            "E": "United Kingdom",
            "F": "GB",
            "G": "U",
            "H": "",
            "I": "",
            "J": "EUR"
        }
    ],
    "GB3": [
        {
            "B": "EGNG",
            "C": "PRESTON/BLACKBURN",
            "D": "Preston/blackburn",
            "E": "United Kingdom",
            "F": "GB",
            "G": "U",
            "H": "",
            "I": "",
            "J": "EUR"
        }
    ],
    "GB4": [
        {
            "B": "EGHS",
            "C": "HENSTRIDGE",
            "D": "HENSTRIDGE",
            "E": "United Kingdom",
            "F": "GB",
            "G": "U",
            "H": "",
            "I": "",
            "J": "EUR"
        }
    ],
    "GB5": [
        {
            "B": "EGOY",
            "C": "WEST FREUGH",
            "D": "West freugh",
            "E": "United Kingdom",
            "F": "GB",
            "G": "U",
            "H": "",
            "I": "",
            "J": "EUR"
        }
    ],
    "GBA": [
        {
            "B": "EGBP",
            "C": "KEMBLE",
            "D": "Kemble",
            "E": "United Kingdom",
            "F": "GB",
            "G": "U",
            "H": "",
            "I": "",
            "J": "EUR"
        }
    ],
    "GBE": [
        {
            "B": "FBSK",
            "C": "SIR SERETSE KHAMA INT",
            "D": "Gaborone",
            "E": "Botswana",
            "F": "BW",
            "G": "O",
            "H": "",
            "I": "",
            "J": "AFR"
        }
    ],
    "GBI": [
        {
            "B": "MYGM",
            "C": "GRAND BAHAMA",
            "D": "Grand Bahama",
            "E": "Bahamas",
            "F": "BS",
            "G": "T",
            "H": "",
            "I": "",
            "J": "CAR"
        }
    ],
    "GCC": [
        {
            "B": "KGCC",
            "C": "Gillette, Wyoming",
            "D": "Campbell County",
            "E": "USA",
            "F": "US",
            "G": "T",
            "H": "",
            "I": "",
            "J": "NOA"
        }
    ],
    "GCI": [
        {
            "B": "EGJB",
            "C": "GUERNSEY",
            "D": "Guernsey",
            "E": "United Kingdom",
            "F": "GB",
            "G": "U",
            "H": "",
            "I": "",
            "J": "EUR"
        }
    ],
    "GCJ": [
        {
            "B": "FAGC",
            "C": "GRAND CENTRAL",
            "D": "Grand Central",
            "E": "South Africa",
            "F": "ZA",
            "G": "O",
            "H": "",
            "I": "",
            "J": "AFR"
        }
    ],
    "GCK": [
        {
            "B": "KGCK",
            "C": "Municipal Airport",
            "D": "Garden City Regional",
            "E": "USA",
            "F": "US",
            "G": "T",
            "H": "",
            "I": "",
            "J": "NOA"
        }
    ],
    "GCM": [
        {
            "B": "MWCR",
            "C": "GRAND CAYMAN - OWEN ROBERTS",
            "D": "Grand cayman",
            "E": "Cayman Islands",
            "F": "KY",
            "G": "T",
            "H": "",
            "I": "",
            "J": "CAR"
        }
    ],
    "GCN": [
        {
            "B": "KGCN",
            "C": "GRAND CANYON",
            "D": "GRAND CANYON NATIONAL PARK",
            "E": "USA",
            "F": "US",
            "G": "T",
            "H": "",
            "I": "",
            "J": "NOA"
        }
    ],
    "GDE": [
        {
            "B": "HAGO",
            "C": "GODE",
            "D": "Gode",
            "E": "Ethiopia",
            "F": "ET",
            "G": "O",
            "H": "",
            "I": "",
            "J": "AFR"
        }
    ],
    "GDL": [
        {
            "B": "MMGL",
            "C": "Miguel Hidal",
            "D": "Miguel Hidalgo Intl",
            "E": "Mexico",
            "F": "MX",
            "G": "T",
            "H": "",
            "I": "",
            "J": "CEM"
        }
    ],
    "GDN": [
        {
            "B": "EPGD",
            "C": "GDANSK",
            "D": "Gdansk",
            "E": "Poland",
            "F": "PL",
            "G": "E",
            "H": "",
            "I": "",
            "J": "EUR"
        }
    ],
    "GDQ": [
        {
            "B": "HAGN",
            "C": "Gondar",
            "D": "Azezo",
            "E": "Ethiopia",
            "F": "ET",
            "G": "O",
            "H": "",
            "I": "",
            "J": "AFR"
        }
    ],
    "GDX": [
        {
            "B": "UHMM",
            "C": "MAGADAN/SOKOL",
            "D": "Magadan/Sokol",
            "E": "Russian Federation",
            "F": "RU",
            "G": "E",
            "H": "",
            "I": "",
            "J": "EUR"
        }
    ],
    "GDZ": [
        {
            "B": "URKG",
            "C": "GELENDZHIK",
            "D": "Gelendzhik",
            "E": "Russian Federation",
            "F": "RU",
            "G": "E",
            "H": "",
            "I": "",
            "J": "EUR"
        }
    ],
    "GEG": [
        {
            "B": "KGEG",
            "C": "SPOKANE INTERNATIONAL",
            "D": "Spokane",
            "E": "USA",
            "F": "US",
            "G": "T",
            "H": "",
            "I": "",
            "J": "NOA"
        }
    ],
    "GEN": [
        {
            "B": "ENXX",
            "C": "OSLO - GARDERMOEN",
            "D": "OSLO",
            "E": "Norway",
            "F": "NO",
            "G": "E",
            "H": "",
            "I": "",
            "J": "EUR"
        }
    ],
    "GEO": [
        {
            "B": "SYCJ",
            "C": "Cheddi Jagan Int'l",
            "D": "Cheddi Jagan International",
            "E": "Guyana",
            "F": "GY",
            "G": "T",
            "H": "",
            "I": "",
            "J": "SOA"
        }
    ],
    "GES": [
        {
            "B": "RPMB",
            "C": "BUAYAN, SOUTH COTOBATO",
            "D": "General Santos/Buayan,South Cotabato",
            "E": "Philippines",
            "F": "PH",
            "G": "O",
            "H": "",
            "I": "",
            "J": "SEA"
        }
    ],
    "GET": [
        {
            "B": "YGEL",
            "C": "Geraldton",
            "D": "Geraldton",
            "E": "Australia",
            "F": "AU",
            "G": "O",
            "H": "",
            "I": "",
            "J": "SWP"
        }
    ],
    "GEV": [
        {
            "B": "ESNG",
            "C": "Gallivare",
            "D": "Gallivare",
            "E": "Sweden",
            "F": "SE",
            "G": "E",
            "H": "",
            "I": "",
            "J": "EUR"
        }
    ],
    "GFK": [
        {
            "B": "KGFK",
            "C": "GRAND FORKS",
            "D": "Grand forks",
            "E": "USA",
            "F": "US",
            "G": "T",
            "H": "",
            "I": "",
            "J": "NOA"
        }
    ],
    "GFR": [
        {
            "B": "LFRF",
            "C": "GRANVILLE",
            "D": "Granville",
            "E": "France",
            "F": "FR",
            "G": "E",
            "H": "",
            "I": "",
            "J": "EUR"
        }
    ],
    "GGG": [
        {
            "B": "KGGG",
            "C": "Gregg County",
            "D": "East Texas Regional",
            "E": "USA",
            "F": "US",
            "G": "T",
            "H": "",
            "I": "",
            "J": "NOA"
        }
    ],
    "GGR": [
        {
            "B": "HCMW",
            "C": "GENERAL MOHAMED ABSHIR",
            "D": "General Mohamed Abshir",
            "E": "Somalia",
            "F": "SO",
            "G": "O",
            "H": "",
            "I": "",
            "J": "AFR"
        }
    ],
    "GGT": [
        {
            "B": "MYEF",
            "C": "EXUMA INTERNATIONAL",
            "D": "EXUMA INTERNATIONAL",
            "E": "Bahamas",
            "F": "BS",
            "G": "T",
            "H": "",
            "I": "",
            "J": "CAR"
        }
    ],
    "GGW": [
        {
            "B": "KGGW",
            "C": "GLASGOW/INTL",
            "D": "Glasgow/Intl",
            "E": "USA",
            "F": "US",
            "G": "T",
            "H": "",
            "I": "",
            "J": "NOA"
        }
    ],
    "GHA": [
        {
            "B": "DAUG",
            "C": "GHARDAIA",
            "D": "Ghardaia",
            "E": "Algeria",
            "F": "DZ",
            "G": "O",
            "H": "",
            "I": "",
            "J": "AFR"
        }
    ],
    "GHF": [
        {
            "B": "ETEU",
            "C": "WURTZBURG GIEBELSTADT  MIL",
            "D": "Wurzburg",
            "E": "Germany",
            "F": "DE",
            "G": "E",
            "H": "",
            "I": "",
            "J": "EUR"
        }
    ],
    "GHK": [
        {
            "B": "LLAZ",
            "C": "GUSH KATIF",
            "D": "Gush Katif",
            "E": "Israel",
            "F": "IL",
            "G": "O",
            "H": "",
            "I": "",
            "J": "MDE"
        }
    ],
    "GIB": [
        {
            "B": "LXGB",
            "C": "GIBRALTAR - NORTH FRONT",
            "D": "Gibraltar",
            "E": "Gibraltar",
            "F": "GI",
            "G": "E",
            "H": "",
            "I": "",
            "J": "EUR"
        }
    ],
    "GIG": [
        {
            "B": "SBGL",
            "C": "RIO DE JANEIRO",
            "D": "Rio de Janeiro",
            "E": "Brazil",
            "F": "BR",
            "G": "T",
            "H": "",
            "I": "",
            "J": "SOA"
        }
    ],
    "GIS": [
        {
            "B": "NZGS",
            "C": "Gisborne",
            "D": "Gisborne",
            "E": "New Zealand",
            "F": "NZ",
            "G": "O",
            "H": "",
            "I": "",
            "J": "SWP"
        }
    ],
    "GIZ": [
        {
            "B": "OEGN",
            "C": "JIZAN REGIONAL AIRPORT",
            "D": "JIZAN REGIONAL AIRPORT",
            "E": "Saudi Arabia",
            "F": "SA",
            "G": "O",
            "H": "",
            "I": "",
            "J": "MDE"
        }
    ],
    "GJT": [
        {
            "B": "KGJT",
            "C": "Walker Field",
            "D": "Walker Field",
            "E": "USA",
            "F": "US",
            "G": "T",
            "H": "",
            "I": "",
            "J": "NOA"
        }
    ],
    "GKA": [
        {
            "B": "AYGA",
            "C": "Goroka",
            "D": "Goroka",
            "E": "Papua New Guinea",
            "F": "PG",
            "G": "O",
            "H": "",
            "I": "",
            "J": "SEA"
        }
    ],
    "GKE": [
        {
            "B": "ETNG",
            "C": "GEILENKIRCHEN",
            "D": "Geilenkirchen",
            "E": "Germany",
            "F": "DE",
            "G": "E",
            "H": "",
            "I": "",
            "J": "EUR"
        }
    ],
    "GLA": [
        {
            "B": "EGPF",
            "C": "GLASGOW",
            "D": "Glasgow",
            "E": "United Kingdom",
            "F": "GB",
            "G": "U",
            "H": "",
            "I": "",
            "J": "EUR"
        }
    ],
    "GLO": [
        {
            "B": "EGBJ",
            "C": "GLOUCESTER",
            "D": "Gloucester",
            "E": "United Kingdom",
            "F": "GB",
            "G": "U",
            "H": "",
            "I": "",
            "J": "EUR"
        }
    ],
    "GLT": [
        {
            "B": "YGLA",
            "C": "Gladstone",
            "D": "Gladstone",
            "E": "Australia",
            "F": "AU",
            "G": "O",
            "H": "",
            "I": "",
            "J": "SWP"
        }
    ],
    "GMB": [
        {
            "B": "HAGM",
            "C": "Gambela",
            "D": "Gambela",
            "E": "Ethiopia",
            "F": "ET",
            "G": "O",
            "H": "",
            "I": "",
            "J": "AFR"
        }
    ],
    "GME": [
        {
            "B": "UMGG",
            "C": "Gomel",
            "D": "Gomel",
            "E": "Belarus",
            "F": "BY",
            "G": "E",
            "H": "",
            "I": "",
            "J": "EUR"
        }
    ],
    "GMP": [
        {
            "B": "RKSS",
            "C": "SEOUL - KIMPO",
            "D": "Seoul",
            "E": "Republic of Korea",
            "F": "KR",
            "G": "O",
            "H": "",
            "I": "",
            "J": "JAK"
        }
    ],
    "GNB": [
        {
            "B": "LFLS",
            "C": "GRENOBLE",
            "D": "Grenoble",
            "E": "France",
            "F": "FR",
            "G": "E",
            "H": "",
            "I": "",
            "J": "EUR"
        }
    ],
    "GND": [
        {
            "B": "TGPY",
            "C": "MAURICE BISHOP INTL",
            "D": "MAURICE BISHOP INTL",
            "E": "Grenada",
            "F": "GD",
            "G": "T",
            "H": "",
            "I": "",
            "J": "CAR"
        }
    ],
    "GNV": [
        {
            "B": "KGNV",
            "C": "J R Alison Municipal",
            "D": "J R Alison Regional Municipal",
            "E": "USA",
            "F": "US",
            "G": "T",
            "H": "",
            "I": "",
            "J": "NOA"
        }
    ],
    "GNY": [
        {
            "B": "LTCS",
            "C": "Sanliurfa",
            "D": "Guney Anadolu Projesi",
            "E": "Turkey",
            "F": "TR",
            "G": "E",
            "H": "",
            "I": "",
            "J": "EUR"
        }
    ],
    "GOA": [
        {
            "B": "LIMJ",
            "C": "GENOA",
            "D": "Genoa",
            "E": "Italy",
            "F": "IT",
            "G": "E",
            "H": "",
            "I": "",
            "J": "EUR"
        }
    ],
    "GOB": [
        {
            "B": "HAGB",
            "C": "GOBA/ROBE",
            "D": "Goba/Robe",
            "E": "Ethiopia",
            "F": "ET",
            "G": "O",
            "H": "",
            "I": "",
            "J": "AFR"
        }
    ],
    "GOH": [
        {
            "B": "BGGH",
            "C": "Nuuk",
            "D": "Nuuk",
            "E": "Greenland",
            "F": "GL",
            "G": "E",
            "H": "",
            "I": "",
            "J": "EUR"
        }
    ],
    "GOI": [
        {
            "B": "VAGO",
            "C": "GOA",
            "D": "Goa",
            "E": "India",
            "F": "IN",
            "G": "O",
            "H": "",
            "I": "",
            "J": "SAS"
        }
    ],
    "GOJ": [
        {
            "B": "UWGG",
            "C": "NIZHNY NOVGOROD",
            "D": "NIZHNY NOVGOROD/STRIGINO",
            "E": "Russian Federation",
            "F": "RU",
            "G": "E",
            "H": "",
            "I": "",
            "J": "EUR"
        }
    ],
    "GOM": [
        {
            "B": "FZNA",
            "C": "Goma",
            "D": "Goma International",
            "E": "Democratic Republic of Congo",
            "F": "CD",
            "G": "O",
            "H": "",
            "I": "",
            "J": "AFR"
        }
    ],
    "GON": [
        {
            "B": "KGON",
            "C": "GROTON NEW LONDON",
            "D": "Groton",
            "E": "USA",
            "F": "US",
            "G": "T",
            "H": "",
            "I": "",
            "J": "NOA"
        }
    ],
    "GOT": [
        {
            "B": "ESGG",
            "C": "GOTEBORG - LANDVETTER",
            "D": "Goteborg",
            "E": "Sweden",
            "F": "SE",
            "G": "E",
            "H": "",
            "I": "",
            "J": "EUR"
        }
    ],
    "GOU": [
        {
            "B": "FKKR",
            "C": "GAROUA",
            "D": "Garoua",
            "E": "Cameroon",
            "F": "CM",
            "G": "O",
            "H": "",
            "I": "",
            "J": "AFR"
        }
    ],
    "GOV": [
        {
            "B": "YPGV",
            "C": "Nhulunbuy",
            "D": "Gove",
            "E": "Australia",
            "F": "AU",
            "G": "O",
            "H": "",
            "I": "",
            "J": "SWP"
        }
    ],
    "GOZ": [
        {
            "B": "LBGO",
            "C": "GORNA ORECHOVITSA",
            "D": "Gorna Orechovitsa",
            "E": "Bulgaria",
            "F": "BG",
            "G": "E",
            "H": "",
            "I": "",
            "J": "EUR"
        }
    ],
    "GPA": [
        {
            "B": "LGRX",
            "C": "PATRAS /RAXOS",
            "D": "Patras",
            "E": "Greece",
            "F": "GR",
            "G": "E",
            "H": "",
            "I": "",
            "J": "EUR"
        }
    ],
    "GPS": [
        {
            "B": "SEGS",
            "C": "Baltra (Galapagos)",
            "D": "Seymour Airport",
            "E": "Ecuador",
            "F": "EC",
            "G": "T",
            "H": "",
            "I": "",
            "J": "SOA"
        }
    ],
    "GQJ": [
        {
            "B": "ZGQJ",
            "C": "MACHRIHANISH AIRPORT",
            "D": "Machrihanish",
            "E": "United Kingdom",
            "F": "GB",
            "G": "U",
            "H": "",
            "I": "",
            "J": "EUR"
        }
    ],
    "GRB": [
        {
            "B": "KGRB",
            "C": "AUSTIN-STRAUBEL FIELD",
            "D": "Austin-straubel fiel",
            "E": "USA",
            "F": "US",
            "G": "T",
            "H": "",
            "I": "",
            "J": "NOA"
        }
    ],
    "GRI": [
        {
            "B": "KGRI",
            "C": "Grand Island",
            "D": "Central Nebraska Rgnl",
            "E": "USA",
            "F": "US",
            "G": "T",
            "H": "",
            "I": "",
            "J": "NOA"
        }
    ],
    "GRJ": [
        {
            "B": "FAGG",
            "C": "George",
            "D": "George-Arpt",
            "E": "South Africa",
            "F": "ZA",
            "G": "O",
            "H": "",
            "I": "",
            "J": "AFR"
        }
    ],
    "GRO": [
        {
            "B": "LEGE",
            "C": "GERONA",
            "D": "Girona Barcelona",
            "E": "Spain",
            "F": "ES",
            "G": "E",
            "H": "",
            "I": "",
            "J": "EUR"
        }
    ],
    "GRQ": [
        {
            "B": "EHGG",
            "C": "GRONINGEN",
            "D": "Groningen",
            "E": "Netherlands",
            "F": "NL",
            "G": "E",
            "H": "",
            "I": "",
            "J": "EUR"
        }
    ],
    "GRR": [
        {
            "B": "KGRR",
            "C": "Gerald Ford GR",
            "D": "Gerald Ford Grand Rapids",
            "E": "USA",
            "F": "US",
            "G": "T",
            "H": "",
            "I": "",
            "J": "NOA"
        }
    ],
    "GRS": [
        {
            "B": "LIRS",
            "C": "GROSSETO",
            "D": "Grosseto",
            "E": "Italy",
            "F": "IT",
            "G": "E",
            "H": "",
            "I": "",
            "J": "EUR"
        }
    ],
    "GRU": [
        {
            "B": "SBGR",
            "C": "SAO PAULO - GUARULHOS",
            "D": "Sao Paulo",
            "E": "Brazil",
            "F": "BR",
            "G": "T",
            "H": "",
            "I": "",
            "J": "SOA"
        }
    ],
    "GRV": [
        {
            "B": "URMG",
            "C": "GROZNY",
            "D": "Grozny",
            "E": "Russian Federation",
            "F": "RU",
            "G": "E",
            "H": "",
            "I": "",
            "J": "EUR"
        }
    ],
    "GRW": [
        {
            "B": "LPGR",
            "C": "GRACIOSA",
            "D": "Graciosa",
            "E": "Portugal",
            "F": "PT",
            "G": "E",
            "H": "",
            "I": "",
            "J": "EUR"
        }
    ],
    "GRX": [
        {
            "B": "LEGR",
            "C": "GRANADA",
            "D": "Granada",
            "E": "Spain",
            "F": "ES",
            "G": "E",
            "H": "",
            "I": "",
            "J": "EUR"
        }
    ],
    "GRZ": [
        {
            "B": "LOWG",
            "C": "GRAZ",
            "D": "Graz",
            "E": "Austria",
            "F": "AT",
            "G": "E",
            "H": "",
            "I": "",
            "J": "EUR"
        }
    ],
    "GSE": [
        {
            "B": "ESGP",
            "C": "GOTHENBURG - SAVE",
            "D": "Goteborg",
            "E": "Sweden",
            "F": "SE",
            "G": "E",
            "H": "",
            "I": "",
            "J": "EUR"
        }
    ],
    "GSO": [
        {
            "B": "KGSO",
            "C": "GREENSBORO/HIGH POINT",
            "D": "Greensboro",
            "E": "USA",
            "F": "US",
            "G": "T",
            "H": "",
            "I": "",
            "J": "NOA"
        }
    ],
    "GSP": [
        {
            "B": "KGSP",
            "C": "GREER - GREENVILLE-SPARTANBURG",
            "D": "Greenville",
            "E": "USA",
            "F": "US",
            "G": "T",
            "H": "",
            "I": "",
            "J": "NOA"
        }
    ],
    "GSV": [
        {
            "B": "UWSG",
            "C": "GAGARIN",
            "D": "Gagarin",
            "E": "Russian Federation",
            "F": "RU",
            "G": "E",
            "H": "",
            "I": "",
            "J": "EUR"
        }
    ],
    "GTF": [
        {
            "B": "KGTF",
            "C": "GREAT FALLS",
            "D": "Great falls",
            "E": "USA",
            "F": "US",
            "G": "T",
            "H": "",
            "I": "",
            "J": "NOA"
        }
    ],
    "GTR": [
        {
            "B": "KGTR",
            "C": "Columbus, Mississippi",
            "D": "Golden Triangle Regl",
            "E": "USA",
            "F": "US",
            "G": "T",
            "H": "",
            "I": "",
            "J": "NOA"
        }
    ],
    "GTW": [
        {
            "B": "LKHO",
            "C": "ZLIN / HOLESOV",
            "D": "Zlin",
            "E": "Czech Republic",
            "F": "CZ",
            "G": "E",
            "H": "",
            "I": "",
            "J": "EUR"
        }
    ],
    "GUA": [
        {
            "B": "MGGT",
            "C": "LA AURORA",
            "D": "La aurora",
            "E": "Guatemala",
            "F": "GT",
            "G": "T",
            "H": "",
            "I": "",
            "J": "CEM"
        }
    ],
    "GUL": [
        {
            "B": "YGLB",
            "C": "GOULBURN",
            "D": "Goulburn",
            "E": "Australia",
            "F": "AU",
            "G": "O",
            "H": "",
            "I": "",
            "J": "SWP"
        }
    ],
    "GUM": [
        {
            "B": "PGUM",
            "C": "Guam",
            "D": "Antonio B. Won Pat International Airport",
            "E": "Guam",
            "F": "GU",
            "G": "O",
            "H": "",
            "I": "",
            "J": "SEA"
        }
    ],
    "GUW": [
        {
            "B": "UATG",
            "C": "ATYRAU",
            "D": "Atyrau",
            "E": "Kazakhstan",
            "F": "KZ",
            "G": "O",
            "H": "",
            "I": "",
            "J": "SEA"
        }
    ],
    "GVA": [
        {
            "B": "LSGG",
            "C": "GENEVA",
            "D": "Geneva",
            "E": "Switzerland",
            "F": "CH",
            "G": "E",
            "H": "",
            "I": "",
            "J": "EUR"
        }
    ],
    "GWI": [
        {
            "B": "",
            "C": "",
            "D": "",
            "E": "UNKNOWN",
            "F": "",
            "G": "A",
            "H": "",
            "I": "",
            "J": ""
        }
    ],
    "GWT": [
        {
            "B": "EDXW",
            "C": "WESTERLAND",
            "D": "Westerland",
            "E": "Germany",
            "F": "DE",
            "G": "E",
            "H": "",
            "I": "",
            "J": "EUR"
        }
    ],
    "GWY": [
        {
            "B": "EICM",
            "C": "GALWAY",
            "D": "Galway",
            "E": "Republic of Ireland",
            "F": "IE",
            "G": "D",
            "H": "",
            "I": "",
            "J": "EUR"
        }
    ],
    "GYD": [
        {
            "B": "UBBB",
            "C": "HEYDAR ALIYEV INT",
            "D": "Heydar aliyev",
            "E": "Azerbaijan",
            "F": "AZ",
            "G": "E",
            "H": "",
            "I": "",
            "J": "EUR"
        }
    ],
    "GYE": [
        {
            "B": "SEGU",
            "C": "Simon Bolivar",
            "D": "Jose Joaquin de Olmedo",
            "E": "Ecuador",
            "F": "EC",
            "G": "T",
            "H": "",
            "I": "",
            "J": "SOA"
        }
    ],
    "GYN": [
        {
            "B": "SBGO",
            "C": "Santa Genoveva",
            "D": "Santa Genoveva",
            "E": "Brazil",
            "F": "BR",
            "G": "T",
            "H": "",
            "I": "",
            "J": "SOA"
        }
    ],
    "GYR": [
        {
            "B": "KGYR",
            "C": "PHOENIX GOODYEAR AIRPORT",
            "D": "PHOENIX GOODYEAR AIRPORT",
            "E": "USA",
            "F": "US",
            "G": "T",
            "H": "",
            "I": "",
            "J": "NOA"
        }
    ],
    "GYY": [
        {
            "B": "KGYY",
            "C": "GARY/CHICAGO INTL",
            "D": "GARY/CHICAGO INTERNATIONAL AIRPORT",
            "E": "USA",
            "F": "US",
            "G": "T",
            "H": "",
            "I": "",
            "J": "NOA"
        }
    ],
    "GZP": [
        {
            "B": "LTFG",
            "C": "Gazipasa",
            "D": "Gazipasa",
            "E": "Turkey",
            "F": "TR",
            "G": "E",
            "H": "",
            "I": "",
            "J": "EUR"
        }
    ],
    "GZT": [
        {
            "B": "LTAJ",
            "C": "GAZIANTEP",
            "D": "Gaziantep",
            "E": "Turkey",
            "F": "TR",
            "G": "E",
            "H": "",
            "I": "",
            "J": "EUR"
        }
    ],
    "GZZ": [
        {
            "B": "EGZZ",
            "C": "[UNKNOWN U.K.]",
            "D": "Misc",
            "E": "United Kingdom",
            "F": "GB",
            "G": "U",
            "H": "",
            "I": "",
            "J": "EUR"
        }
    ],
    "HAC": [
        {
            "B": "RJTH",
            "C": "HACHIJOJIMA",
            "D": "Hachijojima",
            "E": "Japan",
            "F": "JP",
            "G": "O",
            "H": "",
            "I": "",
            "J": "JAK"
        }
    ],
    "HAD": [
        {
            "B": "ESMT",
            "C": "HALMSTAD",
            "D": "Halmstad",
            "E": "Sweden",
            "F": "SE",
            "G": "E",
            "H": "",
            "I": "",
            "J": "EUR"
        }
    ],
    "HAH": [
        {
            "B": "FMCH",
            "C": "Moroni",
            "D": "Prince Said Ibrahim International",
            "E": "Comoros",
            "F": "KM",
            "G": "O",
            "H": "",
            "I": "",
            "J": "AFR"
        }
    ],
    "HAJ": [
        {
            "B": "EDDV",
            "C": "HANOVER",
            "D": "Hanover",
            "E": "Germany",
            "F": "DE",
            "G": "E",
            "H": "",
            "I": "",
            "J": "EUR"
        }
    ],
    "HAK": [
        {
            "B": "ZJHK",
            "C": "Haikou",
            "D": "Meilan International",
            "E": "Peoples Republic of China",
            "F": "CN",
            "G": "O",
            "H": "",
            "I": "",
            "J": "SEA"
        }
    ],
    "HAM": [
        {
            "B": "EDDH",
            "C": "HAMBURG",
            "D": "Hamburg",
            "E": "Germany",
            "F": "DE",
            "G": "E",
            "H": "",
            "I": "",
            "J": "EUR"
        }
    ],
    "HAN": [
        {
            "B": "VVNB",
            "C": "HANOI",
            "D": "Hanoi",
            "E": "Vietnam",
            "F": "VN",
            "G": "O",
            "H": "",
            "I": "",
            "J": "SEA"
        }
    ],
    "HAR": [
        {
            "B": "KHAR",
            "C": "CAPITAL CITY",
            "D": "Harrisburg",
            "E": "USA",
            "F": "US",
            "G": "T",
            "H": "",
            "I": "",
            "J": "NOA"
        }
    ],
    "HAS": [
        {
            "B": "OEHL",
            "C": "Hail",
            "D": "Hail",
            "E": "Saudi Arabia",
            "F": "SA",
            "G": "O",
            "H": "",
            "I": "",
            "J": "MDE"
        }
    ],
    "HAU": [
        {
            "B": "ENHD",
            "C": "HAUGESUND - KARMOY",
            "D": "Haugesund",
            "E": "Norway",
            "F": "NO",
            "G": "E",
            "H": "",
            "I": "",
            "J": "EUR"
        }
    ],
    "HAV": [
        {
            "B": "MUHA",
            "C": "HAVANA",
            "D": "Havana",
            "E": "Cuba",
            "F": "CU",
            "G": "T",
            "H": "",
            "I": "",
            "J": "CAR"
        }
    ],
    "HAW": [
        {
            "B": "EGFE",
            "C": "HAVERFORDWEST",
            "D": "Haverfordwest",
            "E": "United Kingdom",
            "F": "GB",
            "G": "U",
            "H": "",
            "I": "",
            "J": "EUR"
        }
    ],
    "HBA": [
        {
            "B": "YMHB",
            "C": "HOBART",
            "D": "Hobart",
            "E": "Australia",
            "F": "AU",
            "G": "O",
            "H": "",
            "I": "",
            "J": "SWP"
        }
    ],
    "HBE": [
        {
            "B": "HEBA",
            "C": "Borg El Arab Airport",
            "D": "Borg el Arab Airport",
            "E": "Egypt",
            "F": "EG",
            "G": "O",
            "H": "",
            "I": "",
            "J": "MDE"
        }
    ],
    "HBH": [
        {
            "B": "KHBH",
            "C": "HOBART BAY",
            "D": "Hobart",
            "E": "USA",
            "F": "US",
            "G": "T",
            "H": "",
            "I": "",
            "J": "NOA"
        }
    ],
    "HBX": [
        {
            "B": "VOHB",
            "C": "HUBBALLI",
            "D": "Hubballi",
            "E": "India",
            "F": "IN",
            "G": "O",
            "H": "",
            "I": "",
            "J": "SAS"
        }
    ],
    "HDD": [
        {
            "B": "OPKD",
            "C": "HYDERABAD",
            "D": "Hyderabad",
            "E": "Pakistan",
            "F": "PK",
            "G": "O",
            "H": "",
            "I": "",
            "J": "SAS"
        }
    ],
    "HDF": [
        {
            "B": "EDAH",
            "C": "HERINGSDORF",
            "D": "Heringsdorf",
            "E": "Germany",
            "F": "DE",
            "G": "E",
            "H": "",
            "I": "",
            "J": "EUR"
        }
    ],
    "HDN": [
        {
            "B": "KHDN",
            "C": "Hayden, Colorado",
            "D": "Yampa Valley",
            "E": "USA",
            "F": "US",
            "G": "T",
            "H": "",
            "I": "",
            "J": "NOA"
        }
    ],
    "HDS": [
        {
            "B": "FAHT",
            "C": "HOEDSPRUIT",
            "D": "Hoedspruit",
            "E": "South Africa",
            "F": "ZA",
            "G": "O",
            "H": "",
            "I": "",
            "J": "AFR"
        }
    ],
    "HDY": [
        {
            "B": "VTSS",
            "C": "Hat Yai / Songkhla",
            "D": "Hat Yai International",
            "E": "Thailand",
            "F": "TH",
            "G": "O",
            "H": "",
            "I": "",
            "J": "SEA"
        }
    ],
    "HEF": [
        {
            "B": "KHEF",
            "C": "MANASSAS",
            "D": "MANASSAS",
            "E": "USA",
            "F": "US",
            "G": "T",
            "H": "",
            "I": "",
            "J": "NOA"
        }
    ],
    "HEK": [
        {
            "B": "ZYHE",
            "C": "HEIHE",
            "D": "Heihe",
            "E": "Peoples Republic of China",
            "F": "CN",
            "G": "O",
            "H": "",
            "I": "",
            "J": "SEA"
        }
    ],
    "HEL": [
        {
            "B": "EFHK",
            "C": "HELSINKI",
            "D": "Helsinki",
            "E": "Finland",
            "F": "FI",
            "G": "E",
            "H": "",
            "I": "",
            "J": "EUR"
        }
    ],
    "HEM": [
        {
            "B": "EFHF",
            "C": "HELSINKI - MALMI",
            "D": "Helsinki",
            "E": "Finland",
            "F": "FI",
            "G": "E",
            "H": "",
            "I": "",
            "J": "EUR"
        }
    ],
    "HER": [
        {
            "B": "LGIR",
            "C": "HERAKLION",
            "D": "Heraklion",
            "E": "Greece",
            "F": "GR",
            "G": "E",
            "H": "",
            "I": "",
            "J": "EUR"
        }
    ],
    "HET": [
        {
            "B": "ZBHH",
            "C": "Hohhot",
            "D": "Baita International",
            "E": "Peoples Republic of China",
            "F": "CN",
            "G": "O",
            "H": "",
            "I": "",
            "J": "SEA"
        }
    ],
    "HEW": [
        {
            "B": "LGAT",
            "C": "ATHENS HELLIKON",
            "D": "Athens",
            "E": "Greece",
            "F": "GR",
            "G": "E",
            "H": "",
            "I": "",
            "J": "EUR"
        }
    ],
    "HFA": [
        {
            "B": "LLHA",
            "C": "HAIFA",
            "D": "Haifa",
            "E": "Israel",
            "F": "IL",
            "G": "O",
            "H": "",
            "I": "",
            "J": "MDE"
        }
    ],
    "HFD": [
        {
            "B": "KHFD",
            "C": "HARTFORD",
            "D": "Hartford",
            "E": "USA",
            "F": "US",
            "G": "T",
            "H": "",
            "I": "",
            "J": "NOA"
        }
    ],
    "HFE": [
        {
            "B": "ZSOF",
            "C": "Hefei",
            "D": "Xinqiao International",
            "E": "Peoples Republic of China",
            "F": "CN",
            "G": "O",
            "H": "",
            "I": "",
            "J": "SEA"
        }
    ],
    "HFN": [
        {
            "B": "BIHN",
            "C": "HORNAFJORDUR",
            "D": "Hornafjordur",
            "E": "Iceland",
            "F": "IS",
            "G": "E",
            "H": "",
            "I": "",
            "J": "EUR"
        }
    ],
    "HFT": [
        {
            "B": "ENHF",
            "C": "HAMMERFEST",
            "D": "Hammerfest",
            "E": "Norway",
            "F": "NO",
            "G": "E",
            "H": "",
            "I": "",
            "J": "EUR"
        }
    ],
    "HGA": [
        {
            "B": "HCMH",
            "C": "Hargeisa",
            "D": "Egal International",
            "E": "Somalia",
            "F": "SO",
            "G": "O",
            "H": "",
            "I": "",
            "J": "AFR"
        }
    ],
    "HGD": [
        {
            "B": "YHUG",
            "C": "HUGHENDEN",
            "D": "Hughenden",
            "E": "Australia",
            "F": "AU",
            "G": "O",
            "H": "",
            "I": "",
            "J": "SWP"
        }
    ],
    "HGH": [
        {
            "B": "ZSHC",
            "C": "Hangzhou",
            "D": "Hangzhou",
            "E": "Peoples Republic of China",
            "F": "CN",
            "G": "O",
            "H": "",
            "I": "",
            "J": "SEA"
        }
    ],
    "HGL": [
        {
            "B": "EDXH",
            "C": "HELGOLAND",
            "D": "Helgoland",
            "E": "Germany",
            "F": "DE",
            "G": "E",
            "H": "",
            "I": "",
            "J": "EUR"
        }
    ],
    "HGU": [
        {
            "B": "AYMH",
            "C": "MOUNT HAGEN",
            "D": "Mount Hagen",
            "E": "Papua New Guinea",
            "F": "PG",
            "G": "O",
            "H": "",
            "I": "",
            "J": "SEA"
        }
    ],
    "HHH": [
        {
            "B": "KHXD",
            "C": "Hilton Head",
            "D": "Hilton Head",
            "E": "USA",
            "F": "US",
            "G": "T",
            "H": "",
            "I": "",
            "J": "NOA"
        }
    ],
    "HHN": [
        {
            "B": "EDFH",
            "C": "HAHN",
            "D": "Frankfurt HHN",
            "E": "Germany",
            "F": "DE",
            "G": "E",
            "H": "",
            "I": "",
            "J": "EUR"
        }
    ],
    "HHQ": [
        {
            "B": "VTPH",
            "C": "HUA HIN",
            "D": "Hua hin",
            "E": "Thailand",
            "F": "TH",
            "G": "O",
            "H": "",
            "I": "",
            "J": "SEA"
        }
    ],
    "HIA": [
        {
            "B": "ZSSH",
            "C": "HUAI'AN LIANSHUI",
            "D": "Huai'an Lianshui",
            "E": "Peoples Republic of China",
            "F": "CN",
            "G": "O",
            "H": "",
            "I": "",
            "J": "SEA"
        }
    ],
    "HIB": [
        {
            "B": "KHIB",
            "C": "Chisholm",
            "D": "Range Regional",
            "E": "USA",
            "F": "US",
            "G": "T",
            "H": "",
            "I": "",
            "J": "NOA"
        }
    ],
    "HIJ": [
        {
            "B": "RJOA",
            "C": "HIROSHIMA",
            "D": "Hiroshima",
            "E": "Japan",
            "F": "JP",
            "G": "O",
            "H": "",
            "I": "",
            "J": "JAK"
        }
    ],
    "HIO": [
        {
            "B": "KHIO",
            "C": "PORTLAND HILLSBORO",
            "D": "Portland",
            "E": "USA",
            "F": "US",
            "G": "T",
            "H": "",
            "I": "",
            "J": "NOA"
        }
    ],
    "HIR": [
        {
            "B": "AGGH",
            "C": "Henderson Intl",
            "D": "Honiara International",
            "E": "Solomon Islands",
            "F": "SB",
            "G": "O",
            "H": "",
            "I": "",
            "J": "SWP"
        }
    ],
    "HJR": [
        {
            "B": "VAKJ",
            "C": "Khajuraho",
            "D": "Khajuraho",
            "E": "India",
            "F": "IN",
            "G": "O",
            "H": "",
            "I": "",
            "J": "SAS"
        }
    ],
    "HKC": [
        {
            "B": "",
            "C": "HONG KONG CHINA FERRY",
            "D": "Hong Kong China Ferry Terminal",
            "E": "Hong Kong",
            "F": "HK",
            "G": "O",
            "H": "",
            "I": "",
            "J": "SEA"
        }
    ],
    "HKD": [
        {
            "B": "RJCH",
            "C": "HAKODATE",
            "D": "Hakodate",
            "E": "Japan",
            "F": "JP",
            "G": "O",
            "H": "",
            "I": "",
            "J": "JAK"
        }
    ],
    "HKG": [
        {
            "B": "VHHH",
            "C": "HONG KONG INTL",
            "D": "Hong Kong",
            "E": "Hong Kong",
            "F": "HK",
            "G": "O",
            "H": "",
            "I": "",
            "J": "SEA"
        }
    ],
    "HKK": [
        {
            "B": "NZHK",
            "C": "Hokitika Arpt",
            "D": "Hokitika",
            "E": "New Zealand",
            "F": "NZ",
            "G": "O",
            "H": "",
            "I": "",
            "J": "SWP"
        }
    ],
    "HKT": [
        {
            "B": "VTSP",
            "C": "PHUKET",
            "D": "Phuket",
            "E": "Thailand",
            "F": "TH",
            "G": "O",
            "H": "",
            "I": "",
            "J": "SEA"
        }
    ],
    "HLA": [
        {
            "B": "FALA",
            "C": "LANSERIA",
            "D": "Lanseria",
            "E": "South Africa",
            "F": "ZA",
            "G": "O",
            "H": "",
            "I": "",
            "J": "AFR"
        }
    ],
    "HLE": [
        {
            "B": "FHSH",
            "C": "SAINT HELENA",
            "D": "Saint Helena",
            "E": "Saint Helena",
            "F": "SH",
            "G": "O",
            "H": "",
            "I": "",
            "J": "AFR"
        }
    ],
    "HLF": [
        {
            "B": "ESSF",
            "C": "HULTSFRED   MIL",
            "D": "Hultsfred",
            "E": "Sweden",
            "F": "SE",
            "G": "E",
            "H": "",
            "I": "",
            "J": "EUR"
        }
    ],
    "HLN": [
        {
            "B": "KHLN",
            "C": "Helena",
            "D": "Helena Regional",
            "E": "USA",
            "F": "US",
            "G": "T",
            "H": "",
            "I": "",
            "J": "NOA"
        }
    ],
    "HLP": [
        {
            "B": "WIHH",
            "C": "HALIM PERDANA KUSUMA",
            "D": "HALIM PERDANA KUSUMA",
            "E": "Indonesia",
            "F": "ID",
            "G": "O",
            "H": "",
            "I": "",
            "J": "SEA"
        }
    ],
    "HLY": [
        {
            "B": "EGCH",
            "C": "HOLYHEAD",
            "D": "Holyhead",
            "E": "United Kingdom",
            "F": "GB",
            "G": "U",
            "H": "",
            "I": "",
            "J": "EUR"
        }
    ],
    "HLZ": [
        {
            "B": "NZHN",
            "C": "Hamilton",
            "D": "Hamilton International",
            "E": "New Zealand",
            "F": "NZ",
            "G": "O",
            "H": "",
            "I": "",
            "J": "SWP"
        }
    ],
    "HMA": [
        {
            "B": "USHH",
            "C": "KHANTY-MANSIYSK",
            "D": "Khanty-Mansiysk",
            "E": "Russian Federation",
            "F": "RU",
            "G": "E",
            "H": "",
            "I": "",
            "J": "EUR"
        }
    ],
    "HME": [
        {
            "B": "DAUH",
            "C": "OUED IRARA",
            "D": "Oued",
            "E": "Algeria",
            "F": "DZ",
            "G": "O",
            "H": "",
            "I": "",
            "J": "AFR"
        }
    ],
    "HMT": [
        {
            "B": "KHMT",
            "C": "HEMET - RYAN FIELD",
            "D": "Hemet",
            "E": "USA",
            "F": "US",
            "G": "T",
            "H": "",
            "I": "",
            "J": "NOA"
        }
    ],
    "HNA": [
        {
            "B": "RJSI",
            "C": "HANAMAKI",
            "D": "Hanamaki",
            "E": "Japan",
            "F": "JP",
            "G": "O",
            "H": "",
            "I": "",
            "J": "JAK"
        }
    ],
    "HND": [
        {
            "B": "RJTT",
            "C": "TOKYO HANEDA",
            "D": "Tokyo",
            "E": "Japan",
            "F": "JP",
            "G": "O",
            "H": "",
            "I": "",
            "J": "JAK"
        }
    ],
    "HNL": [
        {
            "B": "PHNL",
            "C": "HONOLULU INTERNATIONAL",
            "D": "HONOLULU INTERNATIONAL",
            "E": "USA",
            "F": "US",
            "G": "T",
            "H": "",
            "I": "",
            "J": "NOA"
        }
    ],
    "HOB": [
        {
            "B": "KHOB",
            "C": "Lea County",
            "D": "Lea County Regional",
            "E": "USA",
            "F": "US",
            "G": "T",
            "H": "",
            "I": "",
            "J": "NOA"
        }
    ],
    "HOF": [
        {
            "B": "OEHR",
            "C": "Al Hasa",
            "D": "Al-Ahsa Airport",
            "E": "Saudi Arabia",
            "F": "SA",
            "G": "O",
            "H": "",
            "I": "",
            "J": "MDE"
        }
    ],
    "HOG": [
        {
            "B": "MUHG",
            "C": "FRANK PAIS INTL",
            "D": "Holguin",
            "E": "Cuba",
            "F": "CU",
            "G": "T",
            "H": "",
            "I": "",
            "J": "CAR"
        }
    ],
    "HOH": [
        {
            "B": "LOIH",
            "C": "HOHENEMS",
            "D": "Hohenems",
            "E": "Austria",
            "F": "AT",
            "G": "E",
            "H": "",
            "I": "",
            "J": "EUR"
        }
    ],
    "HOP": [
        {
            "B": "KHOP",
            "C": "FORT CAMPBELL",
            "D": "Fort campbell",
            "E": "USA",
            "F": "US",
            "G": "T",
            "H": "",
            "I": "",
            "J": "NOA"
        }
    ],
    "HOQ": [
        {
            "B": "EDQM",
            "C": "HOF - PLAUEN",
            "D": "Hof Plauen",
            "E": "Germany",
            "F": "DE",
            "G": "E",
            "H": "",
            "I": "",
            "J": "EUR"
        }
    ],
    "HOR": [
        {
            "B": "LPHR",
            "C": "HORTA FAIAL I. ACORES",
            "D": "Horta",
            "E": "Portugal",
            "F": "PT",
            "G": "E",
            "H": "",
            "I": "",
            "J": "EUR"
        }
    ],
    "HOU": [
        {
            "B": "KHOU",
            "C": "HOUSTON HOBBY",
            "D": "Houston",
            "E": "USA",
            "F": "US",
            "G": "T",
            "H": "",
            "I": "",
            "J": "NOA"
        }
    ],
    "HOV": [
        {
            "B": "ENOV",
            "C": "Hovden",
            "D": "Hovden",
            "E": "Norway",
            "F": "NO",
            "G": "E",
            "H": "",
            "I": "",
            "J": "EUR"
        }
    ],
    "HPN": [
        {
            "B": "KHPN",
            "C": "WHITE PLAINS/WESTCHESTER",
            "D": "White plains",
            "E": "USA",
            "F": "US",
            "G": "T",
            "H": "",
            "I": "",
            "J": "NOA"
        }
    ],
    "HRB": [
        {
            "B": "ZYHB",
            "C": "Harbin",
            "D": "Taiping International",
            "E": "Peoples Republic of China",
            "F": "CN",
            "G": "O",
            "H": "",
            "I": "",
            "J": "SEA"
        }
    ],
    "HRE": [
        {
            "B": "FVHQ",
            "C": "Harare",
            "D": "Harare International",
            "E": "Zimbabwe",
            "F": "ZW",
            "G": "O",
            "H": "",
            "I": "",
            "J": "AFR"
        }
    ],
    "HRG": [
        {
            "B": "HEGN",
            "C": "HURGHADA",
            "D": "Hurghada",
            "E": "Egypt",
            "F": "EG",
            "G": "O",
            "H": "",
            "I": "",
            "J": "MDE"
        }
    ],
    "HRK": [
        {
            "B": "UKHH",
            "C": "Kharkov",
            "D": "Osnova International",
            "E": "Ukraine",
            "F": "UA",
            "G": "E",
            "H": "",
            "I": "",
            "J": "EUR"
        }
    ],
    "HRL": [
        {
            "B": "KHRL",
            "C": "Valley International",
            "D": "Valley International",
            "E": "USA",
            "F": "US",
            "G": "T",
            "H": "",
            "I": "",
            "J": "NOA"
        }
    ],
    "HRT": [
        {
            "B": "EGXU",
            "C": "LINTON-ON-OUSE",
            "D": "Linton on Ouse",
            "E": "United Kingdom",
            "F": "GB",
            "G": "U",
            "H": "",
            "I": "",
            "J": "EUR"
        }
    ],
    "HSG": [
        {
            "B": "RJFS",
            "C": "SAGA",
            "D": "Saga",
            "E": "Japan",
            "F": "JP",
            "G": "O",
            "H": "",
            "I": "",
            "J": "JAK"
        }
    ],
    "HSV": [
        {
            "B": "KHSV",
            "C": "HUNTSVILLE",
            "D": "Huntsville",
            "E": "USA",
            "F": "US",
            "G": "T",
            "H": "",
            "I": "",
            "J": "NOA"
        }
    ],
    "HTI": [
        {
            "B": "YBHM",
            "C": "Hamilton Island",
            "D": "Great Barrier Reef",
            "E": "Australia",
            "F": "AU",
            "G": "O",
            "H": "",
            "I": "",
            "J": "SWP"
        }
    ],
    "HTJ": [
        {
            "B": "",
            "C": "Railway Station Hathras",
            "D": "Railway Station Hathras",
            "E": "India",
            "F": "IN",
            "G": "O",
            "H": "",
            "I": "",
            "J": "SAS"
        }
    ],
    "HTO": [
        {
            "B": "KHTO",
            "C": "EAST HAMPTON",
            "D": "East hampton",
            "E": "USA",
            "F": "US",
            "G": "T",
            "H": "",
            "I": "",
            "J": "NOA"
        }
    ],
    "HTS": [
        {
            "B": "KHTS",
            "C": "Tri-State/Milton",
            "D": "Tri-State",
            "E": "USA",
            "F": "US",
            "G": "T",
            "H": "",
            "I": "",
            "J": "NOA"
        }
    ],
    "HTY": [
        {
            "B": "LTDA",
            "C": "Hatay",
            "D": "Hatay Airport",
            "E": "Turkey",
            "F": "TR",
            "G": "E",
            "H": "",
            "I": "",
            "J": "EUR"
        }
    ],
    "HUI": [
        {
            "B": "VVPB",
            "C": "HUE/PHU BAI",
            "D": "Hue/Phu Bai",
            "E": "Vietnam",
            "F": "VN",
            "G": "O",
            "H": "",
            "I": "",
            "J": "SEA"
        }
    ],
    "HUY": [
        {
            "B": "EGNJ",
            "C": "HUMBERSIDE",
            "D": "Humberside",
            "E": "United Kingdom",
            "F": "GB",
            "G": "U",
            "H": "",
            "I": "",
            "J": "EUR"
        }
    ],
    "HVB": [
        {
            "B": "YHBA",
            "C": "Hervey Bay",
            "D": "Hervey Bay",
            "E": "Australia",
            "F": "AU",
            "G": "O",
            "H": "",
            "I": "",
            "J": "SWP"
        }
    ],
    "HVN": [
        {
            "B": "KHVN",
            "C": "NEW HAVEN",
            "D": "New haven",
            "E": "USA",
            "F": "US",
            "G": "T",
            "H": "",
            "I": "",
            "J": "NOA"
        }
    ],
    "HWN": [
        {
            "B": "FVWN",
            "C": "HWANGE NATIONAL PARK",
            "D": "Hwange",
            "E": "Zimbabwe",
            "F": "ZW",
            "G": "O",
            "H": "",
            "I": "",
            "J": "AFR"
        }
    ],
    "HYA": [
        {
            "B": "KHYA",
            "C": "HYANNIS",
            "D": "Hyannis",
            "E": "USA",
            "F": "US",
            "G": "T",
            "H": "",
            "I": "",
            "J": "NOA"
        }
    ],
    "HYC": [
        {
            "B": "EGUH",
            "C": "HIGH WYCOMBE",
            "D": "High Wycombe",
            "E": "United Kingdom",
            "F": "GB",
            "G": "U",
            "H": "",
            "I": "",
            "J": "EUR"
        }
    ],
    "HYD": [
        {
            "B": "VOHS",
            "C": "RAJIV GANDHI INTL",
            "D": "HYDERABAD",
            "E": "India",
            "F": "IN",
            "G": "O",
            "H": "",
            "I": "",
            "J": "SAS"
        }
    ],
    "HYS": [
        {
            "B": "KHYS",
            "C": "Hays",
            "D": "Hays Regional",
            "E": "USA",
            "F": "US",
            "G": "T",
            "H": "",
            "I": "",
            "J": "NOA"
        }
    ],
    "IAD": [
        {
            "B": "KIAD",
            "C": "WASHINGTON - DULLES",
            "D": "Washington",
            "E": "USA",
            "F": "US",
            "G": "T",
            "H": "",
            "I": "",
            "J": "NOA"
        }
    ],
    "IAG": [
        {
            "B": "KIAG",
            "C": "NIAGARA FALLS",
            "D": "Niagara Falls",
            "E": "USA",
            "F": "US",
            "G": "T",
            "H": "",
            "I": "",
            "J": "NOA"
        }
    ],
    "IAH": [
        {
            "B": "KIAH",
            "C": "HOUSTON",
            "D": "Houston",
            "E": "USA",
            "F": "US",
            "G": "T",
            "H": "",
            "I": "",
            "J": "NOA"
        }
    ],
    "IAS": [
        {
            "B": "LRIA",
            "C": "IASI",
            "D": "Iasi",
            "E": "Romania",
            "F": "RO",
            "G": "E",
            "H": "",
            "I": "",
            "J": "EUR"
        }
    ],
    "IBZ": [
        {
            "B": "LEIB",
            "C": "IBIZA",
            "D": "Ibiza",
            "E": "Spain",
            "F": "ES",
            "G": "E",
            "H": "",
            "I": "",
            "J": "EUR"
        }
    ],
    "ICN": [
        {
            "B": "RKSI",
            "C": "INCHEON INTERNATIONAL",
            "D": "Incheon",
            "E": "Republic of Korea",
            "F": "KR",
            "G": "O",
            "H": "",
            "I": "",
            "J": "JAK"
        }
    ],
    "ICT": [
        {
            "B": "KICT",
            "C": "WICHITA NATIONAL AIRPORT",
            "D": "WICHITA DWIGHT D. EISENHOWER",
            "E": "USA",
            "F": "US",
            "G": "T",
            "H": "",
            "I": "",
            "J": "NOA"
        }
    ],
    "IDA": [
        {
            "B": "KIDA",
            "C": "Fanning Field",
            "D": "Idaho Falls/Fanning Field",
            "E": "USA",
            "F": "US",
            "G": "T",
            "H": "",
            "I": "",
            "J": "NOA"
        }
    ],
    "IDR": [
        {
            "B": "VAID",
            "C": "Indore (Madhya Pradesh)",
            "D": "Devi Ahilya Bai Holkar",
            "E": "India",
            "F": "IN",
            "G": "O",
            "H": "",
            "I": "",
            "J": "SAS"
        }
    ],
    "IEG": [
        {
            "B": "EPZG",
            "C": "ZIELONA GORA",
            "D": "ZIELONA GORA BABIMOST AIRPORT",
            "E": "Poland",
            "F": "PL",
            "G": "E",
            "H": "",
            "I": "",
            "J": "EUR"
        }
    ],
    "IEV": [
        {
            "B": "UKKK",
            "C": "KIEV - ZHULIANY",
            "D": "Kiev",
            "E": "Ukraine",
            "F": "UA",
            "G": "E",
            "H": "",
            "I": "",
            "J": "EUR"
        }
    ],
    "IFN": [
        {
            "B": "OIFM",
            "C": "ISFAHAN",
            "D": "Isfahan",
            "E": "Iran",
            "F": "IR",
            "G": "O",
            "H": "",
            "I": "",
            "J": "MDE"
        }
    ],
    "IFO": [
        {
            "B": "UKLI",
            "C": "IVANO FRANKIVSK INTERNATIONAL",
            "D": "Ivano",
            "E": "Ukraine",
            "F": "UA",
            "G": "E",
            "H": "",
            "I": "",
            "J": "EUR"
        }
    ],
    "IGL": [
        {
            "B": "LTBL",
            "C": "IZMIR / CIGLI",
            "D": "Izmir",
            "E": "Turkey",
            "F": "TR",
            "G": "E",
            "H": "",
            "I": "",
            "J": "EUR"
        }
    ],
    "IGR": [
        {
            "B": "SARI",
            "C": "Cataratas",
            "D": "Cataratas Del Iguazu",
            "E": "Argentina",
            "F": "AR",
            "G": "T",
            "H": "",
            "I": "",
            "J": "SOA"
        }
    ],
    "IGS": [
        {
            "B": "ETSI",
            "C": "INGOLSTADT",
            "D": "Ingolstadt",
            "E": "Germany",
            "F": "DE",
            "G": "E",
            "H": "",
            "I": "",
            "J": "EUR"
        }
    ],
    "IGU": [
        {
            "B": "SBFI",
            "C": "Foz do Iguacu",
            "D": "Cataratas International",
            "E": "Brazil",
            "F": "BR",
            "G": "T",
            "H": "",
            "I": "",
            "J": "SOA"
        }
    ],
    "IIA": [
        {
            "B": "EIMN",
            "C": "INISHMAAN",
            "D": "Inishmaan",
            "E": "Republic of Ireland",
            "F": "IE",
            "G": "D",
            "H": "",
            "I": "",
            "J": "EUR"
        }
    ],
    "IJK": [
        {
            "B": "USII",
            "C": "IZHEVSK",
            "D": "Izhevsk",
            "E": "Russian Federation",
            "F": "RU",
            "G": "E",
            "H": "",
            "I": "",
            "J": "EUR"
        }
    ],
    "IKA": [
        {
            "B": "OIIE",
            "C": "IMAM KHOMEINI INTL",
            "D": "Imam khomeini",
            "E": "Iran",
            "F": "IR",
            "G": "O",
            "H": "",
            "I": "",
            "J": "MDE"
        }
    ],
    "IKT": [
        {
            "B": "UIII",
            "C": "Irkutsk",
            "D": "Irkutsk",
            "E": "Russian Federation",
            "F": "RU",
            "G": "E",
            "H": "",
            "I": "",
            "J": "EUR"
        }
    ],
    "ILD": [
        {
            "B": "LEDA",
            "C": "LLEIDA",
            "D": "Lleida",
            "E": "Spain",
            "F": "ES",
            "G": "E",
            "H": "",
            "I": "",
            "J": "EUR"
        }
    ],
    "ILG": [
        {
            "B": "KILG",
            "C": "WILMINGTION",
            "D": "Wilmingtion",
            "E": "USA",
            "F": "US",
            "G": "T",
            "H": "",
            "I": "",
            "J": "NOA"
        }
    ],
    "ILM": [
        {
            "B": "KILM",
            "C": "WILMINGTON",
            "D": "Wilmington",
            "E": "USA",
            "F": "US",
            "G": "T",
            "H": "",
            "I": "",
            "J": "NOA"
        }
    ],
    "ILO": [
        {
            "B": "RPVI",
            "C": "Mandurriao",
            "D": "Iloilo, Iloilo",
            "E": "Philippines",
            "F": "PH",
            "G": "O",
            "H": "",
            "I": "",
            "J": "SEA"
        }
    ],
    "ILY": [
        {
            "B": "EGPI",
            "C": "ISLAY",
            "D": "Islay",
            "E": "United Kingdom",
            "F": "GB",
            "G": "U",
            "H": "",
            "I": "",
            "J": "EUR"
        }
    ],
    "ILZ": [
        {
            "B": "LZZI",
            "C": "ZILINA",
            "D": "Zilina",
            "E": "Slovakia",
            "F": "SK",
            "G": "E",
            "H": "",
            "I": "",
            "J": "EUR"
        }
    ],
    "IMT": [
        {
            "B": "KIMT",
            "C": "FORD AIRPORT",
            "D": "Ford Airport",
            "E": "USA",
            "F": "US",
            "G": "T",
            "H": "",
            "I": "",
            "J": "NOA"
        }
    ],
    "IND": [
        {
            "B": "KIND",
            "C": "INDIANAPOLIS",
            "D": "Indianapolis",
            "E": "USA",
            "F": "US",
            "G": "T",
            "H": "",
            "I": "",
            "J": "NOA"
        }
    ],
    "INH": [
        {
            "B": "FQIN",
            "C": "Inhambane",
            "D": "Inhambane",
            "E": "Mozambique",
            "F": "MZ",
            "G": "O",
            "H": "",
            "I": "",
            "J": "AFR"
        }
    ],
    "INI": [
        {
            "B": "LYNI",
            "C": "NIS",
            "D": "Nis",
            "E": "SERBIA",
            "F": "CS",
            "G": "E",
            "H": "",
            "I": "",
            "J": "EUR"
        }
    ],
    "INL": [
        {
            "B": "KINL",
            "C": "INTERNATIONAL FALLS",
            "D": "Int'l falls",
            "E": "USA",
            "F": "US",
            "G": "T",
            "H": "",
            "I": "",
            "J": "NOA"
        }
    ],
    "INN": [
        {
            "B": "LOWI",
            "C": "INNSBRUCK",
            "D": "Innsbruck",
            "E": "Austria",
            "F": "AT",
            "G": "E",
            "H": "",
            "I": "",
            "J": "EUR"
        }
    ],
    "INQ": [
        {
            "B": "EIIR",
            "C": "INISHEER",
            "D": "Inisheer",
            "E": "Republic of Ireland",
            "F": "IE",
            "G": "D",
            "H": "",
            "I": "",
            "J": "EUR"
        }
    ],
    "INS": [
        {
            "B": "KINS",
            "C": "CREECH AFB",
            "D": "Creech afb",
            "E": "USA",
            "F": "US",
            "G": "T",
            "H": "",
            "I": "",
            "J": "NOA"
        }
    ],
    "INT": [
        {
            "B": "KINT",
            "C": "WINSTON-SALEM",
            "D": "Winston Salem",
            "E": "USA",
            "F": "US",
            "G": "T",
            "H": "",
            "I": "",
            "J": "NOA"
        }
    ],
    "INU": [
        {
            "B": "AUUU",
            "C": "NAURU I.",
            "D": "Nauru I.",
            "E": "Nauru",
            "F": "NR",
            "G": "O",
            "H": "",
            "I": "",
            "J": "SEA"
        }
    ],
    "INV": [
        {
            "B": "EGPE",
            "C": "INVERNESS",
            "D": "Inverness",
            "E": "United Kingdom",
            "F": "GB",
            "G": "U",
            "H": "",
            "I": "",
            "J": "EUR"
        }
    ],
    "IOA": [
        {
            "B": "LGIO",
            "C": "IOANNINA",
            "D": "Ioannina",
            "E": "Greece",
            "F": "GR",
            "G": "E",
            "H": "",
            "I": "",
            "J": "EUR"
        }
    ],
    "IOM": [
        {
            "B": "EGNS",
            "C": "ISLE OF MAN",
            "D": "Isle Of Man",
            "E": "United Kingdom",
            "F": "GB",
            "G": "U",
            "H": "",
            "I": "",
            "J": "EUR"
        }
    ],
    "IOR": [
        {
            "B": "EIIM",
            "C": "INISHMORE / KILRONAN",
            "D": "Inishmore",
            "E": "Republic of Ireland",
            "F": "IE",
            "G": "D",
            "H": "",
            "I": "",
            "J": "EUR"
        }
    ],
    "IPC": [
        {
            "B": "SCIP",
            "C": "MATAVERI",
            "D": "Mataveri",
            "E": "Chile",
            "F": "CL",
            "G": "T",
            "H": "",
            "I": "",
            "J": "SOA"
        }
    ],
    "IPH": [
        {
            "B": "WMKI",
            "C": "IPOH/SULTAN AZLAN SHAH",
            "D": "Ipoh/Sultan Azlan Shah",
            "E": "Malaysia",
            "F": "MY",
            "G": "O",
            "H": "",
            "I": "",
            "J": "SEA"
        }
    ],
    "IPL": [
        {
            "B": "KIPL",
            "C": "IMPERIAL COUNTY",
            "D": "Imperial County",
            "E": "USA",
            "F": "US",
            "G": "T",
            "H": "",
            "I": "",
            "J": "NOA"
        }
    ],
    "IPT": [
        {
            "B": "KIPT",
            "C": "WILLIAMSPORT",
            "D": "Williamsport",
            "E": "USA",
            "F": "US",
            "G": "T",
            "H": "",
            "I": "",
            "J": "NOA"
        }
    ],
    "IPW": [
        {
            "B": "EGSE",
            "C": "IPSWICH",
            "D": "Ipswich",
            "E": "United Kingdom",
            "F": "GB",
            "G": "U",
            "H": "",
            "I": "",
            "J": "EUR"
        }
    ],
    "IQQ": [
        {
            "B": "SCDA",
            "C": "Cavancha",
            "D": "Diego Aracena",
            "E": "Chile",
            "F": "CL",
            "G": "T",
            "H": "",
            "I": "",
            "J": "SOA"
        }
    ],
    "IQT": [
        {
            "B": "SPQT",
            "C": "CF SECADA INTL",
            "D": "CF SECADA INTL",
            "E": "Peru",
            "F": "PE",
            "G": "T",
            "H": "",
            "I": "",
            "J": "SOA"
        }
    ],
    "IRK": [
        {
            "B": "KIRK",
            "C": "KIRKSVILLE",
            "D": "Kirksville",
            "E": "USA",
            "F": "US",
            "G": "T",
            "H": "",
            "I": "",
            "J": "NOA"
        }
    ],
    "ISA": [
        {
            "B": "YBMA",
            "C": "Mount Isa",
            "D": "Mount Isa",
            "E": "Australia",
            "F": "AU",
            "G": "O",
            "H": "",
            "I": "",
            "J": "SWP"
        }
    ],
    "ISB": [
        {
            "B": "OPRN",
            "C": "ISLAMABAD",
            "D": "Islamabad",
            "E": "Pakistan",
            "F": "PK",
            "G": "O",
            "H": "",
            "I": "",
            "J": "SAS"
        }
    ],
    "ISC": [
        {
            "B": "EGHE",
            "C": "SCILLY ISLES",
            "D": "Scilly Isles",
            "E": "United Kingdom",
            "F": "GB",
            "G": "U",
            "H": "",
            "I": "",
            "J": "EUR"
        }
    ],
    "ISD": [
        {
            "B": "",
            "C": "Iscuande Airport",
            "D": "Iscuande Airport",
            "E": "Colombia",
            "F": "CO",
            "G": "T",
            "H": "",
            "I": "",
            "J": "SOA"
        }
    ],
    "ISE": [
        {
            "B": "LTBM",
            "C": "ISPARTA - S.DEMIREL",
            "D": "Isparta",
            "E": "Turkey",
            "F": "TR",
            "G": "E",
            "H": "",
            "I": "",
            "J": "EUR"
        }
    ],
    "ISF": [
        {
            "B": "UAFI",
            "C": "ISFANA",
            "D": "Isfana",
            "E": "Kyrgyzstan",
            "F": "KG",
            "G": "O",
            "H": "",
            "I": "",
            "J": "SEA"
        }
    ],
    "ISG": [
        {
            "B": "ROIG",
            "C": "ISHIGAKI JIMA",
            "D": "Ishigaki Jima",
            "E": "Japan",
            "F": "JP",
            "G": "O",
            "H": "",
            "I": "",
            "J": "JAK"
        }
    ],
    "ISK": [
        {
            "B": "VANR",
            "C": "NASIK ROAD",
            "D": "Nasik Road",
            "E": "India",
            "F": "IN",
            "G": "O",
            "H": "",
            "I": "",
            "J": "SAS"
        }
    ],
    "ISL": [
        {
            "B": "LTBA",
            "C": "ISTANBUL ATATURK",
            "D": "Istanbul  Ataturk",
            "E": "Turkey",
            "F": "TR",
            "G": "E",
            "H": "",
            "I": "",
            "J": "EUR"
        }
    ],
    "ISN": [
        {
            "B": "KISN",
            "C": "Sloulin Field International",
            "D": "Sloulin Field Intl",
            "E": "USA",
            "F": "US",
            "G": "T",
            "H": "",
            "I": "",
            "J": "NOA"
        }
    ],
    "ISP": [
        {
            "B": "KISP",
            "C": "LONG ISLAND MACARTHUR",
            "D": "Long Island",
            "E": "USA",
            "F": "US",
            "G": "T",
            "H": "",
            "I": "",
            "J": "NOA"
        }
    ],
    "IST": [
        {
            "B": "LTFM",
            "C": "ISTANBUL",
            "D": "Istanbul",
            "E": "Turkey",
            "F": "TR",
            "G": "E",
            "H": "",
            "I": "",
            "J": "EUR"
        }
    ],
    "ISU": [
        {
            "B": "ORSU",
            "C": "Sulaymaniyah",
            "D": "Sulaymaniyah Airport",
            "E": "Iraq",
            "F": "IQ",
            "G": "O",
            "H": "",
            "I": "",
            "J": "MDE"
        }
    ],
    "ITH": [
        {
            "B": "KITH",
            "C": "ITHACA",
            "D": "Ithaca",
            "E": "USA",
            "F": "US",
            "G": "T",
            "H": "",
            "I": "",
            "J": "NOA"
        }
    ],
    "ITM": [
        {
            "B": "RJOO",
            "C": "OSAKA - ITAMI",
            "D": "Osaka",
            "E": "Japan",
            "F": "JP",
            "G": "O",
            "H": "",
            "I": "",
            "J": "JAK"
        }
    ],
    "ITO": [
        {
            "B": "PHTO",
            "C": "Hilo International",
            "D": "Hilo International",
            "E": "USA",
            "F": "US",
            "G": "T",
            "H": "",
            "I": "",
            "J": "NOA"
        }
    ],
    "IUD": [
        {
            "B": "OTBH",
            "C": "DOHA",
            "D": "Doha al udeid [mil]",
            "E": "Qatar",
            "F": "QA",
            "G": "O",
            "H": "",
            "I": "",
            "J": "MDE"
        }
    ],
    "IVC": [
        {
            "B": "NZNV",
            "C": "Invercargill",
            "D": "Invercargill",
            "E": "New Zealand",
            "F": "NZ",
            "G": "O",
            "H": "",
            "I": "",
            "J": "SWP"
        }
    ],
    "IVL": [
        {
            "B": "EFIV",
            "C": "IVALO",
            "D": "Ivalo",
            "E": "Finland",
            "F": "FI",
            "G": "E",
            "H": "",
            "I": "",
            "J": "EUR"
        }
    ],
    "IWJ": [
        {
            "B": "RJOW",
            "C": "IWAMI",
            "D": "Iwami",
            "E": "Japan",
            "F": "JP",
            "G": "O",
            "H": "",
            "I": "",
            "J": "JAK"
        }
    ],
    "IWK": [
        {
            "B": "RJOI",
            "C": "Iwakuni",
            "D": "Iwakuni Airport",
            "E": "Japan",
            "F": "JP",
            "G": "O",
            "H": "",
            "I": "",
            "J": "JAK"
        }
    ],
    "IXB": [
        {
            "B": "VEBD",
            "C": "Bagdogra",
            "D": "Bagdogra",
            "E": "India",
            "F": "IN",
            "G": "O",
            "H": "",
            "I": "",
            "J": "SAS"
        }
    ],
    "IXC": [
        {
            "B": "VICG",
            "C": "Chandigarh",
            "D": "Chandigarh",
            "E": "India",
            "F": "IN",
            "G": "O",
            "H": "",
            "I": "",
            "J": "SAS"
        }
    ],
    "IXD": [
        {
            "B": "VIAL",
            "C": "Bamrauli",
            "D": "Allahabad",
            "E": "India",
            "F": "IN",
            "G": "O",
            "H": "",
            "I": "",
            "J": "SAS"
        }
    ],
    "IXE": [
        {
            "B": "VOML",
            "C": "Bajpe",
            "D": "Mangalore Airport",
            "E": "India",
            "F": "IN",
            "G": "O",
            "H": "",
            "I": "",
            "J": "SAS"
        }
    ],
    "IXJ": [
        {
            "B": "VIJU",
            "C": "Satwari",
            "D": "Satwari",
            "E": "India",
            "F": "IN",
            "G": "O",
            "H": "",
            "I": "",
            "J": "SAS"
        }
    ],
    "IXL": [
        {
            "B": "VILH",
            "C": "Leh",
            "D": "Kushok Bakula Rimpoche",
            "E": "India",
            "F": "IN",
            "G": "O",
            "H": "",
            "I": "",
            "J": "SAS"
        }
    ],
    "IXM": [
        {
            "B": "VOMD",
            "C": "Madurai",
            "D": "Madurai",
            "E": "India",
            "F": "IN",
            "G": "O",
            "H": "",
            "I": "",
            "J": "SAS"
        }
    ],
    "IXR": [
        {
            "B": "VERC",
            "C": "Ranchi",
            "D": "Birsa Munda",
            "E": "India",
            "F": "IN",
            "G": "O",
            "H": "",
            "I": "",
            "J": "SAS"
        }
    ],
    "IXS": [
        {
            "B": "VEKU",
            "C": "SILCHAR (KUMBHIGRAM)",
            "D": "Silchar (Kumbhigram)",
            "E": "India",
            "F": "IN",
            "G": "O",
            "H": "",
            "I": "",
            "J": "SAS"
        }
    ],
    "IXU": [
        {
            "B": "VAAU",
            "C": "Chikkalthana",
            "D": "Aurangabad",
            "E": "India",
            "F": "IN",
            "G": "O",
            "H": "",
            "I": "",
            "J": "SAS"
        }
    ],
    "IXZ": [
        {
            "B": "VOPB",
            "C": "VEER SAVARKAR",
            "D": "Veer Savarkar",
            "E": "India",
            "F": "IN",
            "G": "O",
            "H": "",
            "I": "",
            "J": "SAS"
        }
    ],
    "IZO": [
        {
            "B": "RJOC",
            "C": "Izumo",
            "D": "Izumo",
            "E": "Japan",
            "F": "JP",
            "G": "O",
            "H": "",
            "I": "",
            "J": "JAK"
        }
    ],
    "JAC": [
        {
            "B": "KJAC",
            "C": "Jackson Hole",
            "D": "Jackson Hole",
            "E": "USA",
            "F": "US",
            "G": "T",
            "H": "",
            "I": "",
            "J": "NOA"
        }
    ],
    "JAI": [
        {
            "B": "VIJP",
            "C": "Sanganeer",
            "D": "Jaipur International",
            "E": "India",
            "F": "IN",
            "G": "O",
            "H": "",
            "I": "",
            "J": "SAS"
        }
    ],
    "JAX": [
        {
            "B": "KJAX",
            "C": "JACKSONVILLE INTERNATIONAL",
            "D": "Jacksonville",
            "E": "USA",
            "F": "US",
            "G": "T",
            "H": "",
            "I": "",
            "J": "NOA"
        }
    ],
    "JCN": [
        {
            "B": "RKRN",
            "C": "ICHEON",
            "D": "Icheon",
            "E": "Republic of Korea",
            "F": "KR",
            "G": "O",
            "H": "",
            "I": "",
            "J": "JAK"
        }
    ],
    "JDH": [
        {
            "B": "VIJO",
            "C": "Jodhpur (Rajasthan)",
            "D": "Jodhpur",
            "E": "India",
            "F": "IN",
            "G": "O",
            "H": "",
            "I": "",
            "J": "SAS"
        }
    ],
    "JED": [
        {
            "B": "OEJN",
            "C": "JEDDAH",
            "D": "Jeddah",
            "E": "Saudi Arabia",
            "F": "SA",
            "G": "O",
            "H": "",
            "I": "",
            "J": "MDE"
        }
    ],
    "JER": [
        {
            "B": "EGJJ",
            "C": "JERSEY",
            "D": "Jersey",
            "E": "United Kingdom",
            "F": "GB",
            "G": "U",
            "H": "",
            "I": "",
            "J": "EUR"
        }
    ],
    "JFK": [
        {
            "B": "KJFK",
            "C": "NEW YORK - JFK",
            "D": "New York JFK",
            "E": "USA",
            "F": "US",
            "G": "T",
            "H": "",
            "I": "",
            "J": "NOA"
        }
    ],
    "JGA": [
        {
            "B": "VAJM",
            "C": "Govardhanpur",
            "D": "Govardhanpur",
            "E": "India",
            "F": "IN",
            "G": "O",
            "H": "",
            "I": "",
            "J": "SAS"
        }
    ],
    "JHB": [
        {
            "B": "WMKJ",
            "C": "Sultan Ismail Intl",
            "D": "Senai Intl",
            "E": "Malaysia",
            "F": "MY",
            "G": "O",
            "H": "",
            "I": "",
            "J": "SEA"
        }
    ],
    "JHS": [
        {
            "B": "BGSS",
            "C": "SISIMIUT",
            "D": "Sisimiut",
            "E": "Greenland",
            "F": "GL",
            "G": "E",
            "H": "",
            "I": "",
            "J": "EUR"
        }
    ],
    "JIB": [
        {
            "B": "HDAM",
            "C": "DJIBOUTI",
            "D": "Djibouti",
            "E": "Djibouti",
            "F": "DJ",
            "G": "O",
            "H": "",
            "I": "",
            "J": "AFR"
        }
    ],
    "JIJ": [
        {
            "B": "HAJJ",
            "C": "Jigiga",
            "D": "Jigiga Airport",
            "E": "Ethiopia",
            "F": "ET",
            "G": "O",
            "H": "",
            "I": "",
            "J": "AFR"
        }
    ],
    "JIK": [
        {
            "B": "LGIK",
            "C": "IKARIA ISLAND",
            "D": "Ikaria Island",
            "E": "Greece",
            "F": "GR",
            "G": "E",
            "H": "",
            "I": "",
            "J": "EUR"
        }
    ],
    "JIM": [
        {
            "B": "HAJM",
            "C": "Jimma",
            "D": "Jimma",
            "E": "Ethiopia",
            "F": "ET",
            "G": "O",
            "H": "",
            "I": "",
            "J": "AFR"
        }
    ],
    "JJG": [
        {
            "B": "SBJA",
            "C": "HUMBERTO GHIZZO",
            "D": "Humberto Ghizzo Bortoluzzi",
            "E": "Brazil",
            "F": "BR",
            "G": "T",
            "H": "",
            "I": "",
            "J": "SOA"
        }
    ],
    "JJN": [
        {
            "B": "ZSQZ",
            "C": "Quanzhou",
            "D": "Jinjiang",
            "E": "Peoples Republic of China",
            "F": "CN",
            "G": "O",
            "H": "",
            "I": "",
            "J": "SEA"
        }
    ],
    "JKG": [
        {
            "B": "ESGJ",
            "C": "JONKOPING",
            "D": "Jonkoping",
            "E": "Sweden",
            "F": "SE",
            "G": "E",
            "H": "",
            "I": "",
            "J": "EUR"
        }
    ],
    "JKH": [
        {
            "B": "LGHI",
            "C": "CHIOS",
            "D": "Chios",
            "E": "Greece",
            "F": "GR",
            "G": "E",
            "H": "",
            "I": "",
            "J": "EUR"
        }
    ],
    "JLN": [
        {
            "B": "KJLN",
            "C": "Joplin",
            "D": "Joplin Regional",
            "E": "USA",
            "F": "US",
            "G": "T",
            "H": "",
            "I": "",
            "J": "NOA"
        }
    ],
    "JLR": [
        {
            "B": "VAJB",
            "C": "Jabalpur",
            "D": "Jabalpur",
            "E": "India",
            "F": "IN",
            "G": "O",
            "H": "",
            "I": "",
            "J": "SAS"
        }
    ],
    "JMK": [
        {
            "B": "LGMK",
            "C": "MYKONOS",
            "D": "Mykonos",
            "E": "Greece",
            "F": "GR",
            "G": "E",
            "H": "",
            "I": "",
            "J": "EUR"
        }
    ],
    "JMS": [
        {
            "B": "KJMS",
            "C": "Jamestown",
            "D": "Jamestown Regional",
            "E": "USA",
            "F": "US",
            "G": "T",
            "H": "",
            "I": "",
            "J": "NOA"
        }
    ],
    "JNB": [
        {
            "B": "FAJS",
            "C": "JOHANNESBURG INTERNATIONAL",
            "D": "Johannesburg",
            "E": "South Africa",
            "F": "ZA",
            "G": "O",
            "H": "",
            "I": "",
            "J": "AFR"
        }
    ],
    "JNG": [
        {
            "B": "ZLJN",
            "C": "Jining",
            "D": "Oufu Airport",
            "E": "Peoples Republic of China",
            "F": "CN",
            "G": "O",
            "H": "",
            "I": "",
            "J": "SEA"
        }
    ],
    "JNU": [
        {
            "B": "PAJN",
            "C": "JUNEAU",
            "D": "Juneau",
            "E": "USA",
            "F": "US",
            "G": "T",
            "H": "",
            "I": "",
            "J": "NOA"
        }
    ],
    "JNX": [
        {
            "B": "LGNX",
            "C": "NAXOS IS",
            "D": "Naxos Is",
            "E": "Greece",
            "F": "GR",
            "G": "E",
            "H": "",
            "I": "",
            "J": "EUR"
        }
    ],
    "JOE": [
        {
            "B": "EFJO",
            "C": "JOENSUU",
            "D": "Joensuu",
            "E": "Finland",
            "F": "FI",
            "G": "E",
            "H": "",
            "I": "",
            "J": "EUR"
        }
    ],
    "JOG": [
        {
            "B": "WIIJ",
            "C": "Yogyakarta",
            "D": "Adisutjipto",
            "E": "Indonesia",
            "F": "ID",
            "G": "O",
            "H": "",
            "I": "",
            "J": "SEA"
        }
    ],
    "JOI": [
        {
            "B": "SBJV",
            "C": "Cubatao",
            "D": "Lauro Carneiro Loyola",
            "E": "Brazil",
            "F": "BR",
            "G": "T",
            "H": "",
            "I": "",
            "J": "SOA"
        }
    ],
    "JOR": [
        {
            "B": "",
            "C": "ORANGE HELIPORT",
            "D": "Orange Heliport",
            "E": "USA",
            "F": "US",
            "G": "T",
            "H": "",
            "I": "",
            "J": "NOA"
        }
    ],
    "JPA": [
        {
            "B": "SBJP",
            "C": "Castro Pinto",
            "D": "Castro Pinto International",
            "E": "Brazil",
            "F": "BR",
            "G": "T",
            "H": "",
            "I": "",
            "J": "SOA"
        }
    ],
    "JPR": [
        {
            "B": "SWJI",
            "C": "JI-PARANA",
            "D": "Ji-Parana",
            "E": "Brazil",
            "F": "BR",
            "G": "T",
            "H": "",
            "I": "",
            "J": "SOA"
        }
    ],
    "JQF": [
        {
            "B": "KJQF",
            "C": "CONCORD REGIONAL",
            "D": "CONCORD REGIONAL AIRPORT",
            "E": "USA",
            "F": "US",
            "G": "T",
            "H": "",
            "I": "",
            "J": "NOA"
        }
    ],
    "JRO": [
        {
            "B": "HTKJ",
            "C": "KILIMANJARO",
            "D": "KILIMANJARO",
            "E": "Tanzania",
            "F": "TZ",
            "G": "O",
            "H": "",
            "I": "",
            "J": "AFR"
        }
    ],
    "JSH": [
        {
            "B": "LGST",
            "C": "SITIA",
            "D": "Sitia",
            "E": "Greece",
            "F": "GR",
            "G": "E",
            "H": "",
            "I": "",
            "J": "EUR"
        }
    ],
    "JSI": [
        {
            "B": "LGSK",
            "C": "SKIATHOS",
            "D": "Skiathos",
            "E": "Greece",
            "F": "GR",
            "G": "E",
            "H": "",
            "I": "",
            "J": "EUR"
        }
    ],
    "JST": [
        {
            "B": "KJST",
            "C": "Cambria County",
            "D": "John Murtha-Cambria Co",
            "E": "USA",
            "F": "US",
            "G": "T",
            "H": "",
            "I": "",
            "J": "NOA"
        }
    ],
    "JSY": [
        {
            "B": "LGSO",
            "C": "SYROS ISLAND",
            "D": "Syros Island",
            "E": "Greece",
            "F": "GR",
            "G": "E",
            "H": "",
            "I": "",
            "J": "EUR"
        }
    ],
    "JTR": [
        {
            "B": "LGSR",
            "C": "SANTORINI / THIRA",
            "D": "Santorini",
            "E": "Greece",
            "F": "GR",
            "G": "E",
            "H": "",
            "I": "",
            "J": "EUR"
        }
    ],
    "JTY": [
        {
            "B": "LGPL",
            "C": "ASTYPALAIA ISLAND",
            "D": "Astypalaia",
            "E": "Greece",
            "F": "GR",
            "G": "E",
            "H": "",
            "I": "",
            "J": "EUR"
        }
    ],
    "JUB": [
        {
            "B": "HSSJ",
            "C": "Juba",
            "D": "Juba",
            "E": "Sudan",
            "F": "SD",
            "G": "O",
            "H": "",
            "I": "",
            "J": "AFR"
        }
    ],
    "JUI": [
        {
            "B": "EDWJ",
            "C": "JUIST",
            "D": "Juist",
            "E": "Germany",
            "F": "DE",
            "G": "E",
            "H": "",
            "I": "",
            "J": "EUR"
        }
    ],
    "JUL": [
        {
            "B": "SPJL",
            "C": "JULIACA",
            "D": "Juliaca",
            "E": "Peru",
            "F": "PE",
            "G": "T",
            "H": "",
            "I": "",
            "J": "SOA"
        }
    ],
    "JYV": [
        {
            "B": "EFJY",
            "C": "JYVASKYLA",
            "D": "Jyvaskyla",
            "E": "Finland",
            "F": "FI",
            "G": "E",
            "H": "",
            "I": "",
            "J": "EUR"
        }
    ],
    "K01": [
        {
            "B": "",
            "C": "HRADEC KRALOVE",
            "D": "Hradec kralove",
            "E": "Czech Republic",
            "F": "CZ",
            "G": "E",
            "H": "",
            "I": "",
            "J": "EUR"
        }
    ],
    "K02": [
        {
            "B": "OKAS",
            "C": "ALI AL SALEM AFB",
            "D": "Ali al salem",
            "E": "Kuwait",
            "F": "KW",
            "G": "O",
            "H": "",
            "I": "",
            "J": "MDE"
        }
    ],
    "KAB": [
        {
            "B": "FVKB",
            "C": "Kariba",
            "D": "Kariba",
            "E": "Zimbabwe",
            "F": "ZW",
            "G": "O",
            "H": "",
            "I": "",
            "J": "AFR"
        }
    ],
    "KAD": [
        {
            "B": "DNKA",
            "C": "KADUNA AIRPORT",
            "D": "Kaduna",
            "E": "Nigeria",
            "F": "",
            "G": "O",
            "H": "",
            "I": "",
            "J": ""
        }
    ],
    "KAJ": [
        {
            "B": "EFKI",
            "C": "KAJAANI",
            "D": "Kajaani",
            "E": "Finland",
            "F": "FI",
            "G": "E",
            "H": "",
            "I": "",
            "J": "EUR"
        }
    ],
    "KAN": [
        {
            "B": "DNKN",
            "C": "KANO",
            "D": "Kano",
            "E": "Nigeria",
            "F": "NG",
            "G": "O",
            "H": "",
            "I": "",
            "J": "AFR"
        }
    ],
    "KAO": [
        {
            "B": "EFKS",
            "C": "KUUSAMO",
            "D": "Kuusamo",
            "E": "Finland",
            "F": "FI",
            "G": "E",
            "H": "",
            "I": "",
            "J": "EUR"
        }
    ],
    "KAR": [
        {
            "B": "SYKM",
            "C": "KAMARANG",
            "D": "Kamarang",
            "E": "Guyana",
            "F": "GY",
            "G": "T",
            "H": "",
            "I": "",
            "J": "SOA"
        }
    ],
    "KBL": [
        {
            "B": "OAKB",
            "C": "KABUL AD",
            "D": "Kabul ad",
            "E": "Afghanistan",
            "F": "AF",
            "G": "O",
            "H": "",
            "I": "",
            "J": "SAS"
        }
    ],
    "KBP": [
        {
            "B": "UKBB",
            "C": "KYIV BORYSPIL",
            "D": "Kyiv",
            "E": "Ukraine",
            "F": "UA",
            "G": "E",
            "H": "",
            "I": "",
            "J": "EUR"
        }
    ],
    "KBR": [
        {
            "B": "WMKC",
            "C": "Pengkalan Chepa",
            "D": "Sultan Ismail Petra",
            "E": "Malaysia",
            "F": "MY",
            "G": "O",
            "H": "",
            "I": "",
            "J": "SEA"
        }
    ],
    "KBV": [
        {
            "B": "VTSG",
            "C": "KRABI",
            "D": "Krabi",
            "E": "Thailand",
            "F": "TH",
            "G": "O",
            "H": "",
            "I": "",
            "J": "SEA"
        }
    ],
    "KCH": [
        {
            "B": "WBGG",
            "C": "Kuching",
            "D": "Kuching International",
            "E": "Malaysia",
            "F": "MY",
            "G": "O",
            "H": "",
            "I": "",
            "J": "SEA"
        }
    ],
    "KCM": [
        {
            "B": "LTCN",
            "C": "KAHRAMANMARAS",
            "D": "Kahramanmaras",
            "E": "Turkey",
            "F": "TR",
            "G": "E",
            "H": "",
            "I": "",
            "J": "EUR"
        }
    ],
    "KCZ": [
        {
            "B": "RJOK",
            "C": "Kochi",
            "D": "Kochi",
            "E": "Japan",
            "F": "JP",
            "G": "O",
            "H": "",
            "I": "",
            "J": "JAK"
        }
    ],
    "KDH": [
        {
            "B": "OAKN",
            "C": "Kandahar",
            "D": "Kandahar International",
            "E": "Afghanistan",
            "F": "AF",
            "G": "O",
            "H": "",
            "I": "",
            "J": "SAS"
        }
    ],
    "KDL": [
        {
            "B": "EEKA",
            "C": "KARDLA",
            "D": "Kardla",
            "E": "Estonia",
            "F": "EE",
            "G": "E",
            "H": "",
            "I": "",
            "J": "EUR"
        }
    ],
    "KED": [
        {
            "B": "GQNK",
            "C": "KAEDI",
            "D": "Kaedi",
            "E": "Mauritania",
            "F": "MR",
            "G": "O",
            "H": "",
            "I": "",
            "J": "AFR"
        }
    ],
    "KEF": [
        {
            "B": "BIKF",
            "C": "KEFLAVIK",
            "D": "Reykjavik",
            "E": "Iceland",
            "F": "IS",
            "G": "E",
            "H": "",
            "I": "",
            "J": "EUR"
        }
    ],
    "KEH": [
        {
            "B": "KKEH",
            "C": "KENMORE AIR HARBOR",
            "D": "Kenmore Air Harbor",
            "E": "USA",
            "F": "US",
            "G": "T",
            "H": "",
            "I": "",
            "J": "NOA"
        }
    ],
    "KEJ": [
        {
            "B": "UNEE",
            "C": "Kemerovo",
            "D": "Kemerovo",
            "E": "Russian Federation",
            "F": "RU",
            "G": "E",
            "H": "",
            "I": "",
            "J": "EUR"
        }
    ],
    "KEL": [
        {
            "B": "EDHK",
            "C": "KIEL",
            "D": "Kiel",
            "E": "Germany",
            "F": "DE",
            "G": "E",
            "H": "",
            "I": "",
            "J": "EUR"
        }
    ],
    "KEM": [
        {
            "B": "EFKE",
            "C": "KEMI-TORNIO",
            "D": "KEMI-TORNIO",
            "E": "Finland",
            "F": "FI",
            "G": "E",
            "H": "",
            "I": "",
            "J": "EUR"
        }
    ],
    "KER": [
        {
            "B": "OIKK",
            "C": "KERMAN",
            "D": "Kerman",
            "E": "Iran",
            "F": "IR",
            "G": "O",
            "H": "",
            "I": "",
            "J": "MDE"
        }
    ],
    "KGD": [
        {
            "B": "UMKK",
            "C": "KHRABROVO",
            "D": "Kaliningrad",
            "E": "RUSSIAN FEDERATION",
            "F": "RU1",
            "G": "O",
            "H": "",
            "I": "",
            "J": "EUR"
        }
    ],
    "KGF": [
        {
            "B": "UAKK",
            "C": "Karaganda",
            "D": "Sary-Arka",
            "E": "Kazakhstan",
            "F": "KZ",
            "G": "O",
            "H": "",
            "I": "",
            "J": "SEA"
        }
    ],
    "KGI": [
        {
            "B": "YPKG",
            "C": "Kalgoorlie",
            "D": "Kalgoorlie-Boulder",
            "E": "Australia",
            "F": "AU",
            "G": "O",
            "H": "",
            "I": "",
            "J": "SWP"
        }
    ],
    "KGL": [
        {
            "B": "HRYR",
            "C": "KIGALI INTL AIRPORT",
            "D": "KIGALI INTERNATIONAL AIRPORT",
            "E": "Rwanda",
            "F": "RW",
            "G": "O",
            "H": "",
            "I": "",
            "J": "AFR"
        }
    ],
    "KGP": [
        {
            "B": "USRK",
            "C": "KOGALYM INTERNATIONAL",
            "D": "KOGALYM INTERNATIONAL",
            "E": "RUSSIAN FEDERATION",
            "F": "RU1",
            "G": "O",
            "H": "",
            "I": "",
            "J": "EUR"
        }
    ],
    "KGS": [
        {
            "B": "LGKO",
            "C": "KOS / IPPOKRATIS",
            "D": "Kos",
            "E": "Greece",
            "F": "GR",
            "G": "E",
            "H": "",
            "I": "",
            "J": "EUR"
        }
    ],
    "KHE": [
        {
            "B": "UKOH",
            "C": "Kherson",
            "D": "Kherson",
            "E": "Ukraine",
            "F": "UA",
            "G": "E",
            "H": "",
            "I": "",
            "J": "EUR"
        }
    ],
    "KHH": [
        {
            "B": "RCKH",
            "C": "Kaohsiung City",
            "D": "Kaohsiung Intl",
            "E": "Taiwan, Province of China",
            "F": "TW",
            "G": "O",
            "H": "",
            "I": "",
            "J": "SEA"
        }
    ],
    "KHI": [
        {
            "B": "OPKC",
            "C": "KARACHI",
            "D": "Karachi",
            "E": "Pakistan",
            "F": "PK",
            "G": "O",
            "H": "",
            "I": "",
            "J": "SAS"
        }
    ],
    "KHN": [
        {
            "B": "ZSCN",
            "C": "Nanchang",
            "D": "Changbei International",
            "E": "Peoples Republic of China",
            "F": "CN",
            "G": "O",
            "H": "",
            "I": "",
            "J": "SEA"
        }
    ],
    "KHS": [
        {
            "B": "OOKB",
            "C": "KHASAB",
            "D": "KHASAB",
            "E": "Oman",
            "F": "OM",
            "G": "O",
            "H": "",
            "I": "",
            "J": "MDE"
        }
    ],
    "KHV": [
        {
            "B": "UHHH",
            "C": "KHABAROVSK NOVY",
            "D": "KHABAROVSK NOVY",
            "E": "Russian Federation",
            "F": "RU",
            "G": "E",
            "H": "",
            "I": "",
            "J": "EUR"
        }
    ],
    "KID": [
        {
            "B": "ESMK",
            "C": "KRISTIANSTAD",
            "D": "Kristianstad",
            "E": "Sweden",
            "F": "SE",
            "G": "E",
            "H": "",
            "I": "",
            "J": "EUR"
        }
    ],
    "KIJ": [
        {
            "B": "RJSN",
            "C": "NIIGATA",
            "D": "Niigata",
            "E": "Japan",
            "F": "JP",
            "G": "O",
            "H": "",
            "I": "",
            "J": "JAK"
        }
    ],
    "KIM": [
        {
            "B": "FAKM",
            "C": "Kimberley",
            "D": "Kimberly Airport",
            "E": "South Africa",
            "F": "ZA",
            "G": "O",
            "H": "",
            "I": "",
            "J": "AFR"
        }
    ],
    "KIN": [
        {
            "B": "MKJP",
            "C": "KINGSTON NORMAN MANLEY",
            "D": "Kingston",
            "E": "Jamaica",
            "F": "JM",
            "G": "T",
            "H": "",
            "I": "",
            "J": "CAR"
        }
    ],
    "KIR": [
        {
            "B": "EIKY",
            "C": "KERRY COUNTY",
            "D": "Kerry",
            "E": "Republic of Ireland",
            "F": "IE",
            "G": "D",
            "H": "",
            "I": "",
            "J": "EUR"
        }
    ],
    "KIS": [
        {
            "B": "HKKI",
            "C": "Kisumu",
            "D": "Kisumu",
            "E": "Kenya",
            "F": "KE",
            "G": "O",
            "H": "",
            "I": "",
            "J": "AFR"
        }
    ],
    "KIT": [
        {
            "B": "LGKC",
            "C": "KITHIRA",
            "D": "Kithira",
            "E": "Greece",
            "F": "GR",
            "G": "E",
            "H": "",
            "I": "",
            "J": "EUR"
        }
    ],
    "KIV": [
        {
            "B": "LUKK",
            "C": "CHISINAU",
            "D": "Chisinau",
            "E": "Moldova",
            "F": "MD",
            "G": "E",
            "H": "",
            "I": "",
            "J": "EUR"
        }
    ],
    "KIX": [
        {
            "B": "RJBB",
            "C": "OSAKA - KANSAI",
            "D": "Osaka",
            "E": "Japan",
            "F": "JP",
            "G": "O",
            "H": "",
            "I": "",
            "J": "JAK"
        }
    ],
    "KJA": [
        {
            "B": "UNKL",
            "C": "KRASNOYARSK",
            "D": "Krasnoyarsk",
            "E": "RUSSIAN FEDERATION",
            "F": "RU",
            "G": "E",
            "H": "",
            "I": "",
            "J": "EUR"
        }
    ],
    "KJK": [
        {
            "B": "EBKT",
            "C": "KORTRIJK / WEVELGEM",
            "D": "Kortrijk",
            "E": "Belgium",
            "F": "BE",
            "G": "E",
            "H": "",
            "I": "",
            "J": "EUR"
        }
    ],
    "KKC": [
        {
            "B": "VTUK",
            "C": "Khon Kaen",
            "D": "Khon Kaen",
            "E": "Thailand",
            "F": "TH",
            "G": "O",
            "H": "",
            "I": "",
            "J": "SEA"
        }
    ],
    "KKE": [
        {
            "B": "NZKK",
            "C": "Kerikeri",
            "D": "Kerikeri",
            "E": "New Zealand",
            "F": "NZ",
            "G": "O",
            "H": "",
            "I": "",
            "J": "SWP"
        }
    ],
    "KKN": [
        {
            "B": "ENKR",
            "C": "KIRKENES / HOYBUKTMOEN",
            "D": "Kirkenes",
            "E": "Norway",
            "F": "NO",
            "G": "E",
            "H": "",
            "I": "",
            "J": "EUR"
        }
    ],
    "KKY": [
        {
            "B": "EIKL",
            "C": "KILKENNY",
            "D": "Kilkenny",
            "E": "Republic of Ireland",
            "F": "IE",
            "G": "D",
            "H": "",
            "I": "",
            "J": "EUR"
        }
    ],
    "KLA": [
        {
            "B": "",
            "C": "",
            "D": "",
            "E": "UNKNOWN",
            "F": "",
            "G": "A",
            "H": "",
            "I": "",
            "J": ""
        }
    ],
    "KLO": [
        {
            "B": "RPVK",
            "C": "Kalibo",
            "D": "Kalibo International",
            "E": "Philippines",
            "F": "PH",
            "G": "O",
            "H": "",
            "I": "",
            "J": "SEA"
        }
    ],
    "KLR": [
        {
            "B": "ESMQ",
            "C": "KALMAR",
            "D": "Kalmar",
            "E": "Sweden",
            "F": "SE",
            "G": "E",
            "H": "",
            "I": "",
            "J": "EUR"
        }
    ],
    "KLU": [
        {
            "B": "LOWK",
            "C": "KLAGENFURT",
            "D": "Klagenfurt",
            "E": "Austria",
            "F": "AT",
            "G": "E",
            "H": "",
            "I": "",
            "J": "EUR"
        }
    ],
    "KLV": [
        {
            "B": "LKKV",
            "C": "KARLOVY VARY",
            "D": "Karlovy Vary",
            "E": "Czech Republic",
            "F": "CZ",
            "G": "E",
            "H": "",
            "I": "",
            "J": "EUR"
        }
    ],
    "KLX": [
        {
            "B": "LGKL",
            "C": "KALAMATA",
            "D": "Kalamata",
            "E": "Greece",
            "F": "GR",
            "G": "E",
            "H": "",
            "I": "",
            "J": "EUR"
        }
    ],
    "KMC": [
        {
            "B": "OEKK",
            "C": "KING KHALID MILITARY CITY",
            "D": "King Khalid Military City",
            "E": "Saudi Arabia",
            "F": "SA",
            "G": "O",
            "H": "",
            "I": "",
            "J": "MDE"
        }
    ],
    "KMG": [
        {
            "B": "",
            "C": "",
            "D": "",
            "E": "UNKNOWN",
            "F": "",
            "G": "A",
            "H": "",
            "I": "",
            "J": ""
        }
    ],
    "KMI": [
        {
            "B": "RJFM",
            "C": "Miyazaki",
            "D": "Miyazaki",
            "E": "Japan",
            "F": "JP",
            "G": "O",
            "H": "",
            "I": "",
            "J": "JAK"
        }
    ],
    "KMJ": [
        {
            "B": "RJFT",
            "C": "Kumamoto",
            "D": "Kumamoto",
            "E": "Japan",
            "F": "JP",
            "G": "O",
            "H": "",
            "I": "",
            "J": "JAK"
        }
    ],
    "KMQ": [
        {
            "B": "RJOP",
            "C": "Komatsu",
            "D": "Komatsu",
            "E": "Japan",
            "F": "JP",
            "G": "O",
            "H": "",
            "I": "",
            "J": "JAK"
        }
    ],
    "KNF": [
        {
            "B": "EGYM",
            "C": "MARHAM",
            "D": "Marham",
            "E": "United Kingdom",
            "F": "GB",
            "G": "U",
            "H": "",
            "I": "",
            "J": "EUR"
        }
    ],
    "KNO": [
        {
            "B": "WIMM",
            "C": "Kualanamu International",
            "D": "Kuala Namu",
            "E": "Indonesia",
            "F": "ID",
            "G": "O",
            "H": "",
            "I": "",
            "J": "SEA"
        }
    ],
    "KNX": [
        {
            "B": "YPKU",
            "C": "Kununurra",
            "D": "Kununurra",
            "E": "Australia",
            "F": "AU",
            "G": "O",
            "H": "",
            "I": "",
            "J": "SWP"
        }
    ],
    "KOA": [
        {
            "B": "PHKO",
            "C": "KONA",
            "D": "KONA INTERNATIONAL AIRPORT",
            "E": "USA",
            "F": "US",
            "G": "T",
            "H": "",
            "I": "",
            "J": "NOA"
        }
    ],
    "KOI": [
        {
            "B": "EGPA",
            "C": "KIRKWALL",
            "D": "Kirkwall",
            "E": "United Kingdom",
            "F": "GB",
            "G": "U",
            "H": "",
            "I": "",
            "J": "EUR"
        }
    ],
    "KOJ": [
        {
            "B": "RJFK",
            "C": "KAGOSHIMA",
            "D": "Kagoshima",
            "E": "Japan",
            "F": "JP",
            "G": "O",
            "H": "",
            "I": "",
            "J": "JAK"
        }
    ],
    "KOK": [
        {
            "B": "EFKK",
            "C": "KOKKOLA AIRPORT",
            "D": "Kokkola-Pietarsaari Airport",
            "E": "Finland",
            "F": "FI",
            "G": "E",
            "H": "",
            "I": "",
            "J": "EUR"
        }
    ],
    "KOS": [
        {
            "B": "VDSV",
            "C": "SIHANOUKVILLE",
            "D": "Sihanoukville",
            "E": "Cambodia",
            "F": "KH",
            "G": "O",
            "H": "",
            "I": "",
            "J": "SEA"
        }
    ],
    "KOW": [
        {
            "B": "ZSGZ",
            "C": "Ganzhou",
            "D": "Huangjin",
            "E": "Peoples Republic of China",
            "F": "CN",
            "G": "O",
            "H": "",
            "I": "",
            "J": "SEA"
        }
    ],
    "KRF": [
        {
            "B": "ESNK",
            "C": "KRAMFORS/SOLLEFTEA",
            "D": "",
            "E": "Sweden",
            "F": "",
            "G": "E",
            "H": "",
            "I": "",
            "J": "EUR"
        }
    ],
    "KRH": [
        {
            "B": "EGKR",
            "C": "REDHILL",
            "D": "Redhill",
            "E": "United Kingdom",
            "F": "GB",
            "G": "U",
            "H": "",
            "I": "",
            "J": "EUR"
        }
    ],
    "KRK": [
        {
            "B": "EPKK",
            "C": "KRAKOW",
            "D": "Krakow",
            "E": "Poland",
            "F": "PL",
            "G": "E",
            "H": "",
            "I": "",
            "J": "EUR"
        }
    ],
    "KRN": [
        {
            "B": "ESNQ",
            "C": "KIRUNA",
            "D": "Kiruna",
            "E": "Sweden",
            "F": "SE",
            "G": "E",
            "H": "",
            "I": "",
            "J": "EUR"
        }
    ],
    "KRP": [
        {
            "B": "EKKA",
            "C": "KARUP",
            "D": "Karup",
            "E": "Denmark",
            "F": "DK",
            "G": "E",
            "H": "",
            "I": "",
            "J": "EUR"
        }
    ],
    "KRR": [
        {
            "B": "URKK",
            "C": "KRASNODAR",
            "D": "Krasnodar",
            "E": "Russian Federation",
            "F": "RU",
            "G": "E",
            "H": "",
            "I": "",
            "J": "EUR"
        }
    ],
    "KRS": [
        {
            "B": "ENCN",
            "C": "KRISTIANSAND - KJEVIK",
            "D": "Kristiansand",
            "E": "Norway",
            "F": "NO",
            "G": "E",
            "H": "",
            "I": "",
            "J": "EUR"
        }
    ],
    "KRT": [
        {
            "B": "HSSS",
            "C": "Khartoum",
            "D": "Khartoum",
            "E": "Sudan",
            "F": "SD",
            "G": "O",
            "H": "",
            "I": "",
            "J": "AFR"
        }
    ],
    "KSC": [
        {
            "B": "LZKZ",
            "C": "KOSICE BARCA",
            "D": "Kosice",
            "E": "Slovakia",
            "F": "SK",
            "G": "E",
            "H": "",
            "I": "",
            "J": "EUR"
        }
    ],
    "KSD": [
        {
            "B": "ESOK",
            "C": "KARLSTAD",
            "D": "Karlstad",
            "E": "Sweden",
            "F": "SE",
            "G": "E",
            "H": "",
            "I": "",
            "J": "EUR"
        }
    ],
    "KSF": [
        {
            "B": "EDVK",
            "C": "KASSEL-CALDEN",
            "D": "Kassel",
            "E": "Germany",
            "F": "DE",
            "G": "E",
            "H": "",
            "I": "",
            "J": "EUR"
        }
    ],
    "KSH": [
        {
            "B": "OICC",
            "C": "Kermanshah",
            "D": "Shahid Ashrafi Esfahani",
            "E": "Iran",
            "F": "IR",
            "G": "O",
            "H": "",
            "I": "",
            "J": "MDE"
        }
    ],
    "KSJ": [
        {
            "B": "LGKS",
            "C": "KASOS ISLAND",
            "D": "Kasos Island",
            "E": "Greece",
            "F": "GR",
            "G": "E",
            "H": "",
            "I": "",
            "J": "EUR"
        }
    ],
    "KSK": [
        {
            "B": "ESKK",
            "C": "KARLSKOGA",
            "D": "Karlskoga",
            "E": "Sweden",
            "F": "SE",
            "G": "E",
            "H": "",
            "I": "",
            "J": "EUR"
        }
    ],
    "KSN": [
        {
            "B": "UAUU",
            "C": "KOSTANAY",
            "D": "Kostanay",
            "E": "Kazakhstan",
            "F": "KZ",
            "G": "O",
            "H": "",
            "I": "",
            "J": "SEA"
        }
    ],
    "KSO": [
        {
            "B": "LGKA",
            "C": "KASTORIA",
            "D": "Kastoria",
            "E": "Greece",
            "F": "GR",
            "G": "E",
            "H": "",
            "I": "",
            "J": "EUR"
        }
    ],
    "KSU": [
        {
            "B": "ENKB",
            "C": "Kvernberget Airport",
            "D": "Kvernberget",
            "E": "Norway",
            "F": "NO",
            "G": "E",
            "H": "",
            "I": "",
            "J": "EUR"
        }
    ],
    "KSW": [
        {
            "B": "LLKS",
            "C": "KIRYAT SHMONA",
            "D": "Kiryat Shmona",
            "E": "Israel",
            "F": "IL",
            "G": "O",
            "H": "",
            "I": "",
            "J": "MDE"
        }
    ],
    "KSY": [
        {
            "B": "LTCF",
            "C": "KARS",
            "D": "Kars",
            "E": "Turkey",
            "F": "TR",
            "G": "E",
            "H": "",
            "I": "",
            "J": "EUR"
        }
    ],
    "KTA": [
        {
            "B": "YPKA",
            "C": "Karratha",
            "D": "Karratha",
            "E": "Australia",
            "F": "AU",
            "G": "O",
            "H": "",
            "I": "",
            "J": "SWP"
        }
    ],
    "KTM": [
        {
            "B": "VNKT",
            "C": "TRIBHUVAN AIRPORT",
            "D": "Tribhuvan International Airport",
            "E": "Nepal",
            "F": "NP",
            "G": "O",
            "H": "",
            "I": "",
            "J": "SEA"
        }
    ],
    "KTN": [
        {
            "B": "PAKT",
            "C": "KETCHIKAN INTL AIRPORT",
            "D": "KETCHIKAN INTL AIRPORT",
            "E": "USA",
            "F": "US",
            "G": "T",
            "H": "",
            "I": "",
            "J": "NOA"
        }
    ],
    "KTT": [
        {
            "B": "EFKT",
            "C": "KITTILA",
            "D": "North Pole",
            "E": "Finland",
            "F": "FI",
            "G": "E",
            "H": "",
            "I": "",
            "J": "EUR"
        }
    ],
    "KTW": [
        {
            "B": "EPKT",
            "C": "KATOWICE",
            "D": "Katowice",
            "E": "Poland",
            "F": "PL",
            "G": "E",
            "H": "",
            "I": "",
            "J": "EUR"
        }
    ],
    "KUA": [
        {
            "B": "WMKD",
            "C": "Kuantan",
            "D": "Sultan Haji Ahmad Shah",
            "E": "Malaysia",
            "F": "MY",
            "G": "O",
            "H": "",
            "I": "",
            "J": "SEA"
        }
    ],
    "KUF": [
        {
            "B": "UWWW",
            "C": "SAMORA KURUMOCH",
            "D": "Kurumoch",
            "E": "Russian Federation",
            "F": "RU",
            "G": "E",
            "H": "",
            "I": "",
            "J": "EUR"
        }
    ],
    "KUH": [
        {
            "B": "RJCS",
            "C": "Kushiro",
            "D": "Kushiro",
            "E": "Japan",
            "F": "JP",
            "G": "O",
            "H": "",
            "I": "",
            "J": "JAK"
        }
    ],
    "KUL": [
        {
            "B": "WMKK",
            "C": "KUALA LUMPUR",
            "D": "Kuala Lumpur",
            "E": "Malaysia",
            "F": "MY",
            "G": "O",
            "H": "",
            "I": "",
            "J": "SEA"
        }
    ],
    "KUN": [
        {
            "B": "EYKA",
            "C": "KAUNAS",
            "D": "Kaunas",
            "E": "Lithuania",
            "F": "LT",
            "G": "E",
            "H": "",
            "I": "",
            "J": "EUR"
        }
    ],
    "KUO": [
        {
            "B": "EFKU",
            "C": "KUOPIO",
            "D": "Kuopio",
            "E": "Finland",
            "F": "FI",
            "G": "E",
            "H": "",
            "I": "",
            "J": "EUR"
        }
    ],
    "KUT": [
        {
            "B": "UGKO",
            "C": "KUTAISI INTL",
            "D": "KUTAISI INTERNATIONAL AIRPORT",
            "E": "Georgia",
            "F": "GE",
            "G": "E",
            "H": "",
            "I": "",
            "J": "EUR"
        }
    ],
    "KUU": [
        {
            "B": "VIBR",
            "C": "Bhuntar (Himachal Pradesh)",
            "D": "Bhuntar",
            "E": "India",
            "F": "IN",
            "G": "O",
            "H": "",
            "I": "",
            "J": "SAS"
        }
    ],
    "KVA": [
        {
            "B": "LGKV",
            "C": "KAVALA",
            "D": "Kavala",
            "E": "Greece",
            "F": "GR",
            "G": "E",
            "H": "",
            "I": "",
            "J": "EUR"
        }
    ],
    "KVB": [
        {
            "B": "ESGR",
            "C": "SKOVDE",
            "D": "Skovde",
            "E": "Sweden",
            "F": "SE",
            "G": "E",
            "H": "",
            "I": "",
            "J": "EUR"
        }
    ],
    "KVD": [
        {
            "B": "UBBG",
            "C": "Ganja",
            "D": "Ganja",
            "E": "Azerbaijan",
            "F": "AZ",
            "G": "E",
            "H": "",
            "I": "",
            "J": "EUR"
        }
    ],
    "KVG": [
        {
            "B": "AYKV",
            "C": "Kavieng",
            "D": "Kavieng",
            "E": "Papua New Guinea",
            "F": "PG",
            "G": "O",
            "H": "",
            "I": "",
            "J": "SEA"
        }
    ],
    "KWE": [
        {
            "B": "ZUGY",
            "C": "GUIYANG",
            "D": "Guiyang",
            "E": "Peoples Republic of China",
            "F": "CN",
            "G": "O",
            "H": "",
            "I": "",
            "J": "SEA"
        }
    ],
    "KWI": [
        {
            "B": "OKBK",
            "C": "KUWAIT INTERNATIONAL",
            "D": "Kuwait",
            "E": "Kuwait",
            "F": "KW",
            "G": "O",
            "H": "",
            "I": "",
            "J": "MDE"
        }
    ],
    "KWJ": [
        {
            "B": "RKJJ",
            "C": "GWANGJU",
            "D": "Gwangju",
            "E": "Republic of Korea",
            "F": "KR",
            "G": "O",
            "H": "",
            "I": "",
            "J": "JAK"
        }
    ],
    "KWL": [
        {
            "B": "ZGKL",
            "C": "Guilin",
            "D": "Liangjiang International",
            "E": "Peoples Republic of China",
            "F": "CN",
            "G": "O",
            "H": "",
            "I": "",
            "J": "SEA"
        }
    ],
    "KYA": [
        {
            "B": "LTAN",
            "C": "KONYA",
            "D": "Konya",
            "E": "Turkey",
            "F": "TR",
            "G": "E",
            "H": "",
            "I": "",
            "J": "EUR"
        }
    ],
    "KYN": [
        {
            "B": "",
            "C": "MILTON KEYES",
            "D": "Milton Keyes",
            "E": "United Kingdom",
            "F": "GB",
            "G": "U",
            "H": "",
            "I": "",
            "J": "EUR"
        }
    ],
    "KZI": [
        {
            "B": "LGKZ",
            "C": "KOZANI",
            "D": "Kozani",
            "E": "Greece",
            "F": "GR",
            "G": "E",
            "H": "",
            "I": "",
            "J": "EUR"
        }
    ],
    "KZN": [
        {
            "B": "UWKD",
            "C": "KAZAN",
            "D": "Kazan",
            "E": "Russian Federation",
            "F": "RU",
            "G": "E",
            "H": "",
            "I": "",
            "J": "EUR"
        }
    ],
    "KZO": [
        {
            "B": "UAOO",
            "C": "KYZYLORDA",
            "D": "Kyzylorda",
            "E": "Kazakhstan",
            "F": "KZ",
            "G": "O",
            "H": "",
            "I": "",
            "J": "SEA"
        }
    ],
    "KZR": [
        {
            "B": "LTBZ",
            "C": "Zafer",
            "D": "Zafer",
            "E": "Turkey",
            "F": "TR",
            "G": "E",
            "H": "",
            "I": "",
            "J": "EUR"
        }
    ],
    "KZS": [
        {
            "B": "LGKJ",
            "C": "KASTELORIZO",
            "D": "Kastelorizo",
            "E": "Greece",
            "F": "GR",
            "G": "E",
            "H": "",
            "I": "",
            "J": "EUR"
        }
    ],
    "LAD": [
        {
            "B": "FNLU",
            "C": "LUANDA AIRPORT",
            "D": "LUANDA AIRPORT",
            "E": "Angola",
            "F": "AO",
            "G": "O",
            "H": "",
            "I": "",
            "J": "AFR"
        }
    ],
    "LAE": [
        {
            "B": "AYNZ",
            "C": "Nadzab",
            "D": "Nadzab",
            "E": "Papua New Guinea",
            "F": "PG",
            "G": "O",
            "H": "",
            "I": "",
            "J": "SEA"
        }
    ],
    "LAI": [
        {
            "B": "LFRO",
            "C": "LANNION",
            "D": "Lannion",
            "E": "France",
            "F": "FR",
            "G": "E",
            "H": "",
            "I": "",
            "J": "EUR"
        }
    ],
    "LAL": [
        {
            "B": "",
            "C": "",
            "D": "",
            "E": "UNKNOWN",
            "F": "",
            "G": "A",
            "H": "",
            "I": "",
            "J": ""
        }
    ],
    "LAN": [
        {
            "B": "KLAN",
            "C": "Capital City",
            "D": "Capital Region International",
            "E": "USA",
            "F": "US",
            "G": "T",
            "H": "",
            "I": "",
            "J": "NOA"
        }
    ],
    "LAO": [
        {
            "B": "RPLI",
            "C": "Laoag",
            "D": "Laoag International",
            "E": "Philippines",
            "F": "PH",
            "G": "O",
            "H": "",
            "I": "",
            "J": "SEA"
        }
    ],
    "LAP": [
        {
            "B": "MMLP",
            "C": "Leon",
            "D": "Manuel Marquez de Leon",
            "E": "Mexico",
            "F": "MX",
            "G": "T",
            "H": "",
            "I": "",
            "J": "CEM"
        }
    ],
    "LAR": [
        {
            "B": "KLAR",
            "C": "General Brees Field",
            "D": "General Brees Field",
            "E": "USA",
            "F": "US",
            "G": "T",
            "H": "",
            "I": "",
            "J": "NOA"
        }
    ],
    "LAS": [
        {
            "B": "KLAS",
            "C": "LAS VEGAS",
            "D": "Las Vegas",
            "E": "USA",
            "F": "US",
            "G": "T",
            "H": "",
            "I": "",
            "J": "NOA"
        }
    ],
    "LAU": [
        {
            "B": "HKLU",
            "C": "LAMU",
            "D": "Lamu",
            "E": "Kenya",
            "F": "KE",
            "G": "O",
            "H": "",
            "I": "",
            "J": "AFR"
        }
    ],
    "LAW": [
        {
            "B": "KLAW",
            "C": "Lawton , Oklahoma",
            "D": "Lawton Regional",
            "E": "USA",
            "F": "US",
            "G": "T",
            "H": "",
            "I": "",
            "J": "NOA"
        }
    ],
    "LAX": [
        {
            "B": "KLAX",
            "C": "LOS ANGELES INTERNATIONAL",
            "D": "Los Angeles",
            "E": "USA",
            "F": "US",
            "G": "T",
            "H": "",
            "I": "",
            "J": "NOA"
        }
    ],
    "LBA": [
        {
            "B": "EGNM",
            "C": "LEEDS BRADFORD",
            "D": "Leeds Bradford",
            "E": "United Kingdom",
            "F": "GB",
            "G": "U",
            "H": "",
            "I": "",
            "J": "EUR"
        }
    ],
    "LBB": [
        {
            "B": "KLBB",
            "C": "International",
            "D": "Preston Smith Intl",
            "E": "USA",
            "F": "US",
            "G": "T",
            "H": "",
            "I": "",
            "J": "NOA"
        }
    ],
    "LBC": [
        {
            "B": "EDHL",
            "C": "LUBECK",
            "D": "Hamburg LBC",
            "E": "Germany",
            "F": "DE",
            "G": "E",
            "H": "",
            "I": "",
            "J": "EUR"
        }
    ],
    "LBD": [
        {
            "B": "UTDL",
            "C": "Khudzhand",
            "D": "Khujand International",
            "E": "Tajikistan",
            "F": "TJ",
            "G": "O",
            "H": "",
            "I": "",
            "J": "SEA"
        }
    ],
    "LBF": [
        {
            "B": "KLBF",
            "C": "NORTH PLATTE/REGIONAL",
            "D": "North Platte/Regional",
            "E": "USA",
            "F": "US",
            "G": "T",
            "H": "",
            "I": "",
            "J": "NOA"
        }
    ],
    "LBG": [
        {
            "B": "LFPB",
            "C": "PARIS - LE BOURGET",
            "D": "Paris LBG",
            "E": "France",
            "F": "FR",
            "G": "E",
            "H": "",
            "I": "",
            "J": "EUR"
        }
    ],
    "LBI": [
        {
            "B": "LFCI",
            "C": "ALBI / LE SEQUESTRE",
            "D": "Albi",
            "E": "France",
            "F": "FR",
            "G": "E",
            "H": "",
            "I": "",
            "J": "EUR"
        }
    ],
    "LBJ": [
        {
            "B": "WATO",
            "C": "LABUAN BAJO/MUTIARA II",
            "D": "Labuan Bajo/Mutiara Ii",
            "E": "Indonesia",
            "F": "ID",
            "G": "O",
            "H": "",
            "I": "",
            "J": "SEA"
        }
    ],
    "LBL": [
        {
            "B": "KLBL",
            "C": "LIBERAL MUNI",
            "D": "Liberal Muni",
            "E": "USA",
            "F": "US",
            "G": "T",
            "H": "",
            "I": "",
            "J": "NOA"
        }
    ],
    "LBS": [
        {
            "B": "NFNL",
            "C": "LABASA",
            "D": "Labasa",
            "E": "Fiji",
            "F": "FJ",
            "G": "O",
            "H": "",
            "I": "",
            "J": "SWP"
        }
    ],
    "LBU": [
        {
            "B": "WBKL",
            "C": "Labuan",
            "D": "Labuan",
            "E": "Malaysia",
            "F": "MY",
            "G": "O",
            "H": "",
            "I": "",
            "J": "SEA"
        }
    ],
    "LBV": [
        {
            "B": "FOOL",
            "C": "LIBREVILLE MBA INTERNATIONAL",
            "D": "Libreville",
            "E": "Gabon",
            "F": "GA",
            "G": "O",
            "H": "",
            "I": "",
            "J": "AFR"
        }
    ],
    "LBY": [
        {
            "B": "LFRE",
            "C": "LA BAULE - ESCOUBLAC",
            "D": "La Baule Escoublac",
            "E": "France",
            "F": "FR",
            "G": "E",
            "H": "",
            "I": "",
            "J": "EUR"
        }
    ],
    "LCA": [
        {
            "B": "LCLK",
            "C": "LARNACA",
            "D": "Larnaca",
            "E": "Cyprus",
            "F": "CY",
            "G": "E",
            "H": "",
            "I": "",
            "J": "EUR"
        }
    ],
    "LCC": [
        {
            "B": "LIBN",
            "C": "LECCE / LEPORE",
            "D": "Lecce Lepore",
            "E": "Italy",
            "F": "IT",
            "G": "E",
            "H": "",
            "I": "",
            "J": "EUR"
        }
    ],
    "LCG": [
        {
            "B": "LECO",
            "C": "LA CORUNA",
            "D": "La Coruna",
            "E": "Spain",
            "F": "ES",
            "G": "E",
            "H": "",
            "I": "",
            "J": "EUR"
        }
    ],
    "LCJ": [
        {
            "B": "EPLL",
            "C": "LODZ LUBLINEK",
            "D": "Lodz",
            "E": "Poland",
            "F": "PL",
            "G": "E",
            "H": "",
            "I": "",
            "J": "EUR"
        }
    ],
    "LCK": [
        {
            "B": "KLCK",
            "C": "RICKENBACKER - COLUMBUS",
            "D": "Columbus",
            "E": "USA",
            "F": "US",
            "G": "T",
            "H": "",
            "I": "",
            "J": "NOA"
        }
    ],
    "LCV": [
        {
            "B": "LIQL",
            "C": "LUCCA / TASSIGNANO",
            "D": "Lucca",
            "E": "Italy",
            "F": "IT",
            "G": "E",
            "H": "",
            "I": "",
            "J": "EUR"
        }
    ],
    "LCY": [
        {
            "B": "EGLC",
            "C": "LONDON CITY",
            "D": "London LCY",
            "E": "United Kingdom",
            "F": "GB1",
            "G": "U",
            "H": "",
            "I": "",
            "J": "EUR"
        }
    ],
    "LDB": [
        {
            "B": "SBLO",
            "C": "Londrina",
            "D": "Jose Richa",
            "E": "Brazil",
            "F": "BR",
            "G": "T",
            "H": "",
            "I": "",
            "J": "SOA"
        }
    ],
    "LDE": [
        {
            "B": "LFBT",
            "C": "LOURDES / TARBES",
            "D": "Lourdes",
            "E": "France",
            "F": "FR",
            "G": "E",
            "H": "",
            "I": "",
            "J": "EUR"
        }
    ],
    "LDH": [
        {
            "B": "YLHI",
            "C": "LORD HOWE ISLAND",
            "D": "Lord Howe Island",
            "E": "Australia",
            "F": "AU",
            "G": "O",
            "H": "",
            "I": "",
            "J": "SWP"
        }
    ],
    "LDK": [
        {
            "B": "ESGL",
            "C": "LIDKOPING",
            "D": "Lidkoping",
            "E": "Sweden",
            "F": "SE",
            "G": "E",
            "H": "",
            "I": "",
            "J": "EUR"
        }
    ],
    "LDV": [
        {
            "B": "LFRJ",
            "C": "LANDIVISIAU",
            "D": "Landivisiau",
            "E": "France",
            "F": "FR",
            "G": "E",
            "H": "",
            "I": "",
            "J": "EUR"
        }
    ],
    "LDY": [
        {
            "B": "EGAE",
            "C": "DERRY - EGLINGTON",
            "D": "Derry",
            "E": "United Kingdom",
            "F": "GB",
            "G": "U",
            "H": "",
            "I": "",
            "J": "EUR"
        }
    ],
    "LEA": [
        {
            "B": "YPLM",
            "C": "Learmonth",
            "D": "Learmonth",
            "E": "Australia",
            "F": "AU",
            "G": "O",
            "H": "",
            "I": "",
            "J": "SWP"
        }
    ],
    "LEB": [
        {
            "B": "KLEB",
            "C": "Regional",
            "D": "Lebanon/Hanover Municipal",
            "E": "USA",
            "F": "US",
            "G": "T",
            "H": "",
            "I": "",
            "J": "NOA"
        }
    ],
    "LED": [
        {
            "B": "ULLI",
            "C": "ST. PETERSBURG - PULKOVO",
            "D": "St Petersburg",
            "E": "Russian Federation",
            "F": "RU",
            "G": "E",
            "H": "",
            "I": "",
            "J": "EUR"
        }
    ],
    "LEH": [
        {
            "B": "LFOH",
            "C": "LE HAVRE",
            "D": "Le Havre",
            "E": "France",
            "F": "FR",
            "G": "E",
            "H": "",
            "I": "",
            "J": "EUR"
        }
    ],
    "LEI": [
        {
            "B": "LEAM",
            "C": "ALMERIA",
            "D": "Almeria",
            "E": "Spain",
            "F": "ES",
            "G": "E",
            "H": "",
            "I": "",
            "J": "EUR"
        }
    ],
    "LEJ": [
        {
            "B": "EDDP",
            "C": "LEIPZIG",
            "D": "Leipzig",
            "E": "Germany",
            "F": "DE",
            "G": "E",
            "H": "",
            "I": "",
            "J": "EUR"
        }
    ],
    "LEN": [
        {
            "B": "LELN",
            "C": "LEON",
            "D": "Leon",
            "E": "Spain",
            "F": "ES",
            "G": "E",
            "H": "",
            "I": "",
            "J": "EUR"
        }
    ],
    "LEQ": [
        {
            "B": "EGHC",
            "C": "LANDS END",
            "D": "Lands End",
            "E": "United Kingdom",
            "F": "GB",
            "G": "U",
            "H": "",
            "I": "",
            "J": "EUR"
        }
    ],
    "LEU": [
        {
            "B": "LESU",
            "C": "SEO DE URGEL",
            "D": "Seo De Urgel",
            "E": "Spain",
            "F": "ES",
            "G": "E",
            "H": "",
            "I": "",
            "J": "EUR"
        }
    ],
    "LEX": [
        {
            "B": "KLEX",
            "C": "LEXINGTON BLUE GRASS",
            "D": "Lexington",
            "E": "USA",
            "F": "US",
            "G": "T",
            "H": "",
            "I": "",
            "J": "NOA"
        }
    ],
    "LEY": [
        {
            "B": "EHLE",
            "C": "LELYSTAD",
            "D": "Lelystad",
            "E": "Netherlands",
            "F": "NL",
            "G": "E",
            "H": "",
            "I": "",
            "J": "EUR"
        }
    ],
    "LFM": [
        {
            "B": "OISR",
            "C": "LAMERD AIRPORT",
            "D": "Lamerd Airport",
            "E": "Iran",
            "F": "",
            "G": "O",
            "H": "",
            "I": "",
            "J": ""
        }
    ],
    "LFW": [
        {
            "B": "DXXX",
            "C": "LOME-TOKOIN",
            "D": "LOME-TOKOIN",
            "E": "Togo",
            "F": "TG",
            "G": "O",
            "H": "",
            "I": "",
            "J": "AFR"
        }
    ],
    "LGA": [
        {
            "B": "KLGA",
            "C": "NEW YORK LA GUARDIA",
            "D": "New York LGA",
            "E": "USA",
            "F": "US",
            "G": "T",
            "H": "",
            "I": "",
            "J": "NOA"
        }
    ],
    "LGB": [
        {
            "B": "KLGB",
            "C": "LONG BEACH",
            "D": "Long Beach",
            "E": "USA",
            "F": "US",
            "G": "T",
            "H": "",
            "I": "",
            "J": "NOA"
        }
    ],
    "LGG": [
        {
            "B": "EBLG",
            "C": "LIEGE",
            "D": "Liege",
            "E": "Belgium",
            "F": "BE",
            "G": "E",
            "H": "",
            "I": "",
            "J": "EUR"
        }
    ],
    "LGK": [
        {
            "B": "WMKL",
            "C": "Langkawi I.",
            "D": "Langkawi International",
            "E": "Malaysia",
            "F": "MY",
            "G": "O",
            "H": "",
            "I": "",
            "J": "SEA"
        }
    ],
    "LGO": [
        {
            "B": "EDWL",
            "C": "LANGEOOG",
            "D": "Langeoog",
            "E": "Germany",
            "F": "DE",
            "G": "E",
            "H": "",
            "I": "",
            "J": "EUR"
        }
    ],
    "LGP": [
        {
            "B": "RPLP",
            "C": "Legaspi",
            "D": "Legaspi",
            "E": "Philippines",
            "F": "PH",
            "G": "O",
            "H": "",
            "I": "",
            "J": "SEA"
        }
    ],
    "LGW": [
        {
            "B": "EGKK",
            "C": "LONDON - GATWICK",
            "D": "London LGW",
            "E": "United Kingdom",
            "F": "GB1",
            "G": "U",
            "H": "",
            "I": "",
            "J": "EUR"
        }
    ],
    "LHA": [
        {
            "B": "EDTL",
            "C": "LAHR",
            "D": "Lahr",
            "E": "Germany",
            "F": "DE",
            "G": "E",
            "H": "",
            "I": "",
            "J": "EUR"
        }
    ],
    "LHE": [
        {
            "B": "OPLA",
            "C": "LAHORE",
            "D": "Lahore",
            "E": "Pakistan",
            "F": "PK",
            "G": "O",
            "H": "",
            "I": "",
            "J": "SAS"
        }
    ],
    "LHR": [
        {
            "B": "EGLL",
            "C": "LONDON - HEATHROW",
            "D": "London LHR",
            "E": "United Kingdom",
            "F": "GB1",
            "G": "U",
            "H": "",
            "I": "",
            "J": "EUR"
        }
    ],
    "LHW": [
        {
            "B": "ZLLL",
            "C": "LANZHOU ZHONGCHUAN",
            "D": "LANZHOU ZHONGCHUAN",
            "E": "Peoples Republic of China",
            "F": "CN",
            "G": "O",
            "H": "",
            "I": "",
            "J": "SEA"
        }
    ],
    "LID": [
        {
            "B": "EHVB",
            "C": "LEIDEN VALKENBURG",
            "D": "Leiden",
            "E": "Netherlands",
            "F": "NL",
            "G": "E",
            "H": "",
            "I": "",
            "J": "EUR"
        }
    ],
    "LIG": [
        {
            "B": "LFBL",
            "C": "LIMOGES",
            "D": "Limoges",
            "E": "France",
            "F": "FR",
            "G": "E",
            "H": "",
            "I": "",
            "J": "EUR"
        }
    ],
    "LIH": [
        {
            "B": "PHLI",
            "C": "Lihue",
            "D": "Lihue",
            "E": "USA",
            "F": "US",
            "G": "T",
            "H": "",
            "I": "",
            "J": "NOA"
        }
    ],
    "LIL": [
        {
            "B": "LFQQ",
            "C": "LILLE",
            "D": "Lille",
            "E": "France",
            "F": "FR",
            "G": "E",
            "H": "",
            "I": "",
            "J": "EUR"
        }
    ],
    "LIM": [
        {
            "B": "SPIM",
            "C": "LIMA",
            "D": "Lima",
            "E": "Peru",
            "F": "PE",
            "G": "T",
            "H": "",
            "I": "",
            "J": "SOA"
        }
    ],
    "LIN": [
        {
            "B": "LIML",
            "C": "MILAN - LINATE",
            "D": "Milan LIN",
            "E": "Italy",
            "F": "IT",
            "G": "E",
            "H": "",
            "I": "",
            "J": "EUR"
        }
    ],
    "LIP": [
        {
            "B": "SBLN",
            "C": "LINS AIRPORT",
            "D": "Lins airport",
            "E": "Brazil",
            "F": "BR",
            "G": "T",
            "H": "",
            "I": "",
            "J": "SOA"
        }
    ],
    "LIR": [
        {
            "B": "MRLB",
            "C": "Liberia",
            "D": "D. Oduber Quiros Intl",
            "E": "Costa Rica",
            "F": "CR",
            "G": "T",
            "H": "",
            "I": "",
            "J": "CEM"
        }
    ],
    "LIS": [
        {
            "B": "LPPT",
            "C": "LISBON",
            "D": "Lisbon",
            "E": "Portugal",
            "F": "PT",
            "G": "E",
            "H": "",
            "I": "",
            "J": "EUR"
        }
    ],
    "LIT": [
        {
            "B": "KLIT",
            "C": "LITTLE ROCK ADAMS FIELD",
            "D": "Little rock",
            "E": "USA",
            "F": "US",
            "G": "T",
            "H": "",
            "I": "",
            "J": "NOA"
        }
    ],
    "LJG": [
        {
            "B": "ZPLJ",
            "C": "Lijiang",
            "D": "Sanyi Airport",
            "E": "Peoples Republic of China",
            "F": "CN",
            "G": "O",
            "H": "",
            "I": "",
            "J": "SEA"
        }
    ],
    "LJU": [
        {
            "B": "LJLJ",
            "C": "LJUBLJANA",
            "D": "Ljubljana",
            "E": "Slovenia",
            "F": "SI",
            "G": "E",
            "H": "",
            "I": "",
            "J": "EUR"
        }
    ],
    "LKG": [
        {
            "B": "HKLK",
            "C": "LOKICHOGIO",
            "D": "Lokichogio",
            "E": "Kenya",
            "F": "KE",
            "G": "O",
            "H": "",
            "I": "",
            "J": "AFR"
        }
    ],
    "LKL": [
        {
            "B": "ENNA",
            "C": "LAKSELV - BANAK",
            "D": "Banak",
            "E": "Norway",
            "F": "NO",
            "G": "E",
            "H": "",
            "I": "",
            "J": "EUR"
        }
    ],
    "LKN": [
        {
            "B": "ENLK",
            "C": "Leknes",
            "D": "Leknes",
            "E": "Norway",
            "F": "NO",
            "G": "E",
            "H": "",
            "I": "",
            "J": "EUR"
        }
    ],
    "LKO": [
        {
            "B": "VILK",
            "C": "Amausi",
            "D": "Chaudhary Charan Singh",
            "E": "India",
            "F": "IN",
            "G": "O",
            "H": "",
            "I": "",
            "J": "SAS"
        }
    ],
    "LKZ": [
        {
            "B": "EGUL",
            "C": "LAKENHEATH MIL",
            "D": "Lakenheath",
            "E": "United Kingdom",
            "F": "GB",
            "G": "U",
            "H": "",
            "I": "",
            "J": "EUR"
        }
    ],
    "LLA": [
        {
            "B": "ESPA",
            "C": "LULEA / KALLAX",
            "D": "Lulea",
            "E": "Sweden",
            "F": "SE",
            "G": "E",
            "H": "",
            "I": "",
            "J": "EUR"
        }
    ],
    "LLI": [
        {
            "B": "HALL",
            "C": "Lalibela",
            "D": "Lalibela",
            "E": "Ethiopia",
            "F": "ET",
            "G": "O",
            "H": "",
            "I": "",
            "J": "AFR"
        }
    ],
    "LLW": [
        {
            "B": "FWKI",
            "C": "KUMUZU INTERNATIONAL AIRPORT",
            "D": "Lilongwe",
            "E": "Malawi",
            "F": "MW",
            "G": "O",
            "H": "",
            "I": "",
            "J": "AFR"
        }
    ],
    "LME": [
        {
            "B": "LFRM",
            "C": "LE MANS - ARNAGE",
            "D": "Le Mans",
            "E": "France",
            "F": "FR",
            "G": "E",
            "H": "",
            "I": "",
            "J": "EUR"
        }
    ],
    "LMM": [
        {
            "B": "MMLM",
            "C": "LOS MOCHIS",
            "D": "Los Mochis",
            "E": "Mexico",
            "F": "MX",
            "G": "T",
            "H": "",
            "I": "",
            "J": "CEM"
        }
    ],
    "LMO": [
        {
            "B": "EGQS",
            "C": "LOSSIEMOUTH",
            "D": "Lossiemouth",
            "E": "United Kingdom",
            "F": "GB",
            "G": "U",
            "H": "",
            "I": "",
            "J": "EUR"
        }
    ],
    "LMP": [
        {
            "B": "LICD",
            "C": "LAMPEDUSA",
            "D": "Lampedusa",
            "E": "Italy",
            "F": "IT",
            "G": "E",
            "H": "",
            "I": "",
            "J": "EUR"
        }
    ],
    "LMT": [
        {
            "B": "KLMT",
            "C": "KINGSLEY FIELD",
            "D": "Kingsley field",
            "E": "USA",
            "F": "US",
            "G": "T",
            "H": "",
            "I": "",
            "J": "NOA"
        }
    ],
    "LNK": [
        {
            "B": "KLNK",
            "C": "LINCOLN MUNICIPAL",
            "D": "Lincoln",
            "E": "USA",
            "F": "US",
            "G": "T",
            "H": "",
            "I": "",
            "J": "NOA"
        }
    ],
    "LNY": [
        {
            "B": "PHNY",
            "C": "LANAI CITY, LANAI",
            "D": "Lanai City, Lanai",
            "E": "USA",
            "F": "US",
            "G": "T",
            "H": "",
            "I": "",
            "J": "NOA"
        }
    ],
    "LNZ": [
        {
            "B": "LOWL",
            "C": "LINZ",
            "D": "Linz",
            "E": "Austria",
            "F": "AT",
            "G": "E",
            "H": "",
            "I": "",
            "J": "EUR"
        }
    ],
    "LOH": [
        {
            "B": "SETM",
            "C": "Ciudad de Catamayo",
            "D": "Catamayo",
            "E": "Ecuador",
            "F": "EC",
            "G": "T",
            "H": "",
            "I": "",
            "J": "SOA"
        }
    ],
    "LOP": [
        {
            "B": "WADL",
            "C": "Lombok International",
            "D": "Lombok International",
            "E": "Indonesia",
            "F": "ID",
            "G": "O",
            "H": "",
            "I": "",
            "J": "SEA"
        }
    ],
    "LOS": [
        {
            "B": "DNMM",
            "C": "LAGOS",
            "D": "Lagos",
            "E": "Nigeria",
            "F": "NG",
            "G": "O",
            "H": "",
            "I": "",
            "J": "AFR"
        }
    ],
    "LPA": [
        {
            "B": "GCLP",
            "C": "GRAN CANARIA",
            "D": "Gran Canaria",
            "E": "Spain",
            "F": "ES",
            "G": "E",
            "H": "",
            "I": "",
            "J": "EUR"
        }
    ],
    "LPB": [
        {
            "B": "SLLP",
            "C": "LA PAZ",
            "D": "La paz",
            "E": "Bolivia",
            "F": "BO",
            "G": "T",
            "H": "",
            "I": "",
            "J": "SOA"
        }
    ],
    "LPI": [
        {
            "B": "ESCF",
            "C": "LINKOPING",
            "D": "Linkoping",
            "E": "Sweden",
            "F": "SE",
            "G": "E",
            "H": "",
            "I": "",
            "J": "EUR"
        }
    ],
    "LPL": [
        {
            "B": "EGGP",
            "C": "LIVERPOOL",
            "D": "Liverpool",
            "E": "United Kingdom",
            "F": "GB",
            "G": "U",
            "H": "",
            "I": "",
            "J": "EUR"
        }
    ],
    "LPP": [
        {
            "B": "EFLP",
            "C": "LAPPEENRANTA",
            "D": "Lappeenranta",
            "E": "Finland",
            "F": "FI",
            "G": "E",
            "H": "",
            "I": "",
            "J": "EUR"
        }
    ],
    "LPQ": [
        {
            "B": "VLLB",
            "C": "Luang Prabang",
            "D": "Luang Prabang International",
            "E": "Laos",
            "F": "LA",
            "G": "O",
            "H": "",
            "I": "",
            "J": "SEA"
        }
    ],
    "LPX": [
        {
            "B": "EVLA",
            "C": "LIEPAJA",
            "D": "Liepaja",
            "E": "Latvia",
            "F": "LV",
            "G": "E",
            "H": "",
            "I": "",
            "J": "EUR"
        }
    ],
    "LPY": [
        {
            "B": "LFHP",
            "C": "LE PUY",
            "D": "Le Puy",
            "E": "France",
            "F": "FR",
            "G": "E",
            "H": "",
            "I": "",
            "J": "EUR"
        }
    ],
    "LRA": [
        {
            "B": "LGLR",
            "C": "LARISA",
            "D": "Larisa",
            "E": "Greece",
            "F": "GR",
            "G": "E",
            "H": "",
            "I": "",
            "J": "EUR"
        }
    ],
    "LRD": [
        {
            "B": "KLRD",
            "C": "International",
            "D": "Laredo Intl",
            "E": "USA",
            "F": "US",
            "G": "T",
            "H": "",
            "I": "",
            "J": "NOA"
        }
    ],
    "LRE": [
        {
            "B": "YLRE",
            "C": "Longreach",
            "D": "Longreach",
            "E": "Australia",
            "F": "AU",
            "G": "O",
            "H": "",
            "I": "",
            "J": "SWP"
        }
    ],
    "LRH": [
        {
            "B": "LFBH",
            "C": "LA ROCHELLE",
            "D": "La Rochelle",
            "E": "France",
            "F": "FR",
            "G": "E",
            "H": "",
            "I": "",
            "J": "EUR"
        }
    ],
    "LRM": [
        {
            "B": "MDLR",
            "C": "LA ROMANA / CASA DE CAMPO",
            "D": "La romana",
            "E": "Dominican Republic",
            "F": "DO",
            "G": "T",
            "H": "",
            "I": "",
            "J": "CAR"
        }
    ],
    "LRS": [
        {
            "B": "LGLE",
            "C": "LEROS",
            "D": "Leros",
            "E": "Greece",
            "F": "GR",
            "G": "E",
            "H": "",
            "I": "",
            "J": "EUR"
        }
    ],
    "LRT": [
        {
            "B": "LFRH",
            "C": "LORIENT",
            "D": "Lorient",
            "E": "France",
            "F": "FR",
            "G": "E",
            "H": "",
            "I": "",
            "J": "EUR"
        }
    ],
    "LSC": [
        {
            "B": "SCSE",
            "C": "LA SERENA/AD LA FLORIDA",
            "D": "La Serena/Ad La Florida",
            "E": "Chile",
            "F": "CL",
            "G": "T",
            "H": "",
            "I": "",
            "J": "SOA"
        }
    ],
    "LSE": [
        {
            "B": "KLSE",
            "C": "Municipal",
            "D": "La Crosse Regional",
            "E": "USA",
            "F": "US",
            "G": "T",
            "H": "",
            "I": "",
            "J": "NOA"
        }
    ],
    "LSI": [
        {
            "B": "EGPB",
            "C": "SHETLAND",
            "D": "Shetland",
            "E": "United Kingdom",
            "F": "GB",
            "G": "U",
            "H": "",
            "I": "",
            "J": "EUR"
        }
    ],
    "LST": [
        {
            "B": "YMLT",
            "C": "Launceston",
            "D": "Launceston",
            "E": "Australia",
            "F": "AU",
            "G": "O",
            "H": "",
            "I": "",
            "J": "SWP"
        }
    ],
    "LSV": [
        {
            "B": "KLSV",
            "C": "LAS VEGAS / NELLIS",
            "D": "Las Vegas",
            "E": "USA",
            "F": "US",
            "G": "T",
            "H": "",
            "I": "",
            "J": "NOA"
        }
    ],
    "LSZ": [
        {
            "B": "LDLO",
            "C": "LOSINJ",
            "D": "LOSINJ",
            "E": "Croatia",
            "F": "HR",
            "G": "E",
            "H": "",
            "I": "",
            "J": "EUR"
        }
    ],
    "LTH": [
        {
            "B": "KLTH",
            "C": "LATHORP WELLS",
            "D": "Lathorp Wells",
            "E": "USA",
            "F": "US",
            "G": "T",
            "H": "",
            "I": "",
            "J": "NOA"
        }
    ],
    "LTN": [
        {
            "B": "EGGW",
            "C": "LONDON - LUTON",
            "D": "London LTN",
            "E": "United Kingdom",
            "F": "GB1",
            "G": "U",
            "H": "",
            "I": "",
            "J": "EUR"
        }
    ],
    "LTO": [
        {
            "B": "MMLT",
            "C": "LORETO",
            "D": "Loreto",
            "E": "Mexico",
            "F": "MX",
            "G": "T",
            "H": "",
            "I": "",
            "J": "CEM"
        }
    ],
    "LTQ": [
        {
            "B": "LFAT",
            "C": "LE TOUQUET",
            "D": "Le Touquet",
            "E": "France",
            "F": "FR",
            "G": "E",
            "H": "",
            "I": "",
            "J": "EUR"
        }
    ],
    "LTT": [
        {
            "B": "LFTZ",
            "C": "SAINT TROPEZ - LA MOLE",
            "D": "Saint tropez",
            "E": "France",
            "F": "FR",
            "G": "E",
            "H": "",
            "I": "",
            "J": "EUR"
        }
    ],
    "LTZ": [
        {
            "B": "",
            "C": "",
            "D": "",
            "E": "UNKNOWN",
            "F": "",
            "G": "A",
            "H": "",
            "I": "",
            "J": ""
        }
    ],
    "LUG": [
        {
            "B": "LSZA",
            "C": "LUGANO",
            "D": "Lugano",
            "E": "Switzerland",
            "F": "CH",
            "G": "E",
            "H": "",
            "I": "",
            "J": "EUR"
        }
    ],
    "LUK": [
        {
            "B": "KLUK",
            "C": "CINCINNATI",
            "D": "Cincinnati",
            "E": "USA",
            "F": "US",
            "G": "T",
            "H": "",
            "I": "",
            "J": "NOA"
        }
    ],
    "LUN": [
        {
            "B": "FLLS",
            "C": "Lusaka",
            "D": "Lusaka Intl",
            "E": "Zambia",
            "F": "ZM",
            "G": "O",
            "H": "",
            "I": "",
            "J": "AFR"
        }
    ],
    "LUX": [
        {
            "B": "ELLX",
            "C": "LUXEMBOURG",
            "D": "Luxembourg",
            "E": "Luxembourg",
            "F": "LU",
            "G": "E",
            "H": "",
            "I": "",
            "J": "EUR"
        }
    ],
    "LUZ": [
        {
            "B": "EPLB",
            "C": "LUBLIN",
            "D": "Lublin",
            "E": "Poland",
            "F": "PL",
            "G": "E",
            "H": "",
            "I": "",
            "J": "EUR"
        }
    ],
    "LVA": [
        {
            "B": "LFOV",
            "C": "LAVAL - ENTRAMMES",
            "D": "Laval Entrammes",
            "E": "France",
            "F": "FR",
            "G": "E",
            "H": "",
            "I": "",
            "J": "EUR"
        }
    ],
    "LVI": [
        {
            "B": "FLLI",
            "C": "LIVINGSTONE",
            "D": "Livingstone",
            "E": "Zambia",
            "F": "ZM",
            "G": "O",
            "H": "",
            "I": "",
            "J": "AFR"
        }
    ],
    "LWB": [
        {
            "B": "KLWB",
            "C": "Greenbrier Valley",
            "D": "Greenbrier Valley",
            "E": "USA",
            "F": "US",
            "G": "T",
            "H": "",
            "I": "",
            "J": "NOA"
        }
    ],
    "LWG": [
        {
            "B": "",
            "C": "",
            "D": "",
            "E": "UNKNOWN",
            "F": "",
            "G": "A",
            "H": "",
            "I": "",
            "J": ""
        }
    ],
    "LWK": [
        {
            "B": "EGET",
            "C": "SHETLAND ISLANDS",
            "D": "Shetland",
            "E": "United Kingdom",
            "F": "GB",
            "G": "U",
            "H": "",
            "I": "",
            "J": "EUR"
        }
    ],
    "LWO": [
        {
            "B": "UKLL",
            "C": "L'VIV",
            "D": "L'viv",
            "E": "Ukraine",
            "F": "UA",
            "G": "E",
            "H": "",
            "I": "",
            "J": "EUR"
        }
    ],
    "LWR": [
        {
            "B": "EHLW",
            "C": "LEEUWARDEN",
            "D": "Leeuwarden",
            "E": "Netherlands",
            "F": "NL",
            "G": "E",
            "H": "",
            "I": "",
            "J": "EUR"
        }
    ],
    "LWS": [
        {
            "B": "KLWS",
            "C": "Lewiston, Idaho",
            "D": "Nez Perce County",
            "E": "USA",
            "F": "US",
            "G": "T",
            "H": "",
            "I": "",
            "J": "NOA"
        }
    ],
    "LXC": [
        {
            "B": "",
            "C": "LIVERPOOL CENTRAL RAILWAY",
            "D": "Liverpool Central Railway Station",
            "E": "United Kingdom",
            "F": "GB",
            "G": "U",
            "H": "",
            "I": "",
            "J": "EUR"
        }
    ],
    "LXR": [
        {
            "B": "HELX",
            "C": "LUXOR",
            "D": "Luxor",
            "E": "Egypt",
            "F": "EG",
            "G": "O",
            "H": "",
            "I": "",
            "J": "MDE"
        }
    ],
    "LXS": [
        {
            "B": "LGLM",
            "C": "LEMNOS",
            "D": "Lemnos",
            "E": "Greece",
            "F": "GR",
            "G": "E",
            "H": "",
            "I": "",
            "J": "EUR"
        }
    ],
    "LYA": [
        {
            "B": "ZHLY",
            "C": "LUOYANG",
            "D": "Luoyang",
            "E": "Peoples Republic of China",
            "F": "CN",
            "G": "O",
            "H": "",
            "I": "",
            "J": "SEA"
        }
    ],
    "LYC": [
        {
            "B": "ESNL",
            "C": "LYCKSELE",
            "D": "Lycksele",
            "E": "Sweden",
            "F": "SE",
            "G": "E",
            "H": "",
            "I": "",
            "J": "EUR"
        }
    ],
    "LYE": [
        {
            "B": "EGDL",
            "C": "LYNHAM MIL",
            "D": "Lynham",
            "E": "United Kingdom",
            "F": "GB",
            "G": "U",
            "H": "",
            "I": "",
            "J": "EUR"
        }
    ],
    "LYH": [
        {
            "B": "KLYH",
            "C": "Preston-Glenn Field",
            "D": "Regional-P. Glenn Field",
            "E": "USA",
            "F": "US",
            "G": "T",
            "H": "",
            "I": "",
            "J": "NOA"
        }
    ],
    "LYN": [
        {
            "B": "LFLY",
            "C": "LYON - BRON",
            "D": "Lyon Bron",
            "E": "France",
            "F": "FR",
            "G": "E",
            "H": "",
            "I": "",
            "J": "EUR"
        }
    ],
    "LYP": [
        {
            "B": "OPFA",
            "C": "Faisalabad",
            "D": "Faisalabad International",
            "E": "Pakistan",
            "F": "PK",
            "G": "O",
            "H": "",
            "I": "",
            "J": "SAS"
        }
    ],
    "LYR": [
        {
            "B": "ENSB",
            "C": "LONGYEARBYEN",
            "D": "Longyearbyen",
            "E": "Norway",
            "F": "NO",
            "G": "E",
            "H": "",
            "I": "",
            "J": "EUR"
        }
    ],
    "LYS": [
        {
            "B": "LFLL",
            "C": "LYON - SATOLAS",
            "D": "Lyon",
            "E": "France",
            "F": "FR",
            "G": "E",
            "H": "",
            "I": "",
            "J": "EUR"
        }
    ],
    "LYX": [
        {
            "B": "EGMD",
            "C": "LYDD",
            "D": "Lydd",
            "E": "United Kingdom",
            "F": "GB",
            "G": "U",
            "H": "",
            "I": "",
            "J": "EUR"
        }
    ],
    "LZH": [
        {
            "B": "ZGZH",
            "C": "Liuzhou",
            "D": "Bailian",
            "E": "Peoples Republic of China",
            "F": "CN",
            "G": "O",
            "H": "",
            "I": "",
            "J": "SEA"
        }
    ],
    "MAA": [
        {
            "B": "VOMM",
            "C": "MADRAS - CHENNAI",
            "D": "Madras",
            "E": "India",
            "F": "IN",
            "G": "O",
            "H": "",
            "I": "",
            "J": "SAS"
        }
    ],
    "MAB": [
        {
            "B": "SBMA",
            "C": "Maraba",
            "D": "Joao Correa da Rocha",
            "E": "Brazil",
            "F": "BR",
            "G": "T",
            "H": "",
            "I": "",
            "J": "SOA"
        }
    ],
    "MAD": [
        {
            "B": "LEMD",
            "C": "MADRID",
            "D": "Madrid",
            "E": "Spain",
            "F": "ES",
            "G": "E",
            "H": "",
            "I": "",
            "J": "EUR"
        }
    ],
    "MAF": [
        {
            "B": "KMAF",
            "C": "Odessa Regional",
            "D": "Midland-Odessa Regl",
            "E": "USA",
            "F": "US",
            "G": "T",
            "H": "",
            "I": "",
            "J": "NOA"
        }
    ],
    "MAH": [
        {
            "B": "LEMH",
            "C": "MENORCA-MAHON",
            "D": "Menorca-Mahon",
            "E": "Spain",
            "F": "ES",
            "G": "E",
            "H": "",
            "I": "",
            "J": "EUR"
        }
    ],
    "MAJ": [
        {
            "B": "PKMJ",
            "C": "MARSHALL ISLANDS",
            "D": "Marshall Islands/Intl Majuro Atoll",
            "E": "Marshall Islands",
            "F": "MH",
            "G": "O",
            "H": "",
            "I": "",
            "J": "SEA"
        }
    ],
    "MAM": [
        {
            "B": "MMMA",
            "C": "MATAMOROS AIRPORT",
            "D": "General Servanos Canales International Airport",
            "E": "Mexico",
            "F": "",
            "G": "T",
            "H": "",
            "I": "",
            "J": ""
        }
    ],
    "MAN": [
        {
            "B": "EGCC",
            "C": "MANCHESTER",
            "D": "Manchester",
            "E": "United Kingdom",
            "F": "GB",
            "G": "U",
            "H": "",
            "I": "",
            "J": "EUR"
        }
    ],
    "MAO": [
        {
            "B": "SBEG",
            "C": "Manaus Intl Airport",
            "D": "Manaus International Airport",
            "E": "Brazil",
            "F": "BR",
            "G": "T",
            "H": "",
            "I": "",
            "J": "SOA"
        }
    ],
    "MAR": [
        {
            "B": "SVMC",
            "C": "MARACAIBO",
            "D": "Maracaibo",
            "E": "Venezuela",
            "F": "VE",
            "G": "T",
            "H": "",
            "I": "",
            "J": "SOA"
        }
    ],
    "MAS": [
        {
            "B": "",
            "C": "",
            "D": "",
            "E": "UNKNOWN",
            "F": "",
            "G": "A",
            "H": "",
            "I": "",
            "J": ""
        }
    ],
    "MAZ": [
        {
            "B": "TJMZ",
            "C": "DE HOSTOS",
            "D": "De Hostos",
            "E": "Puerto Rico",
            "F": "PR",
            "G": "T",
            "H": "",
            "I": "",
            "J": "CAR"
        }
    ],
    "MBA": [
        {
            "B": "HKMO",
            "C": "MOMBASA INT",
            "D": "Mombasa",
            "E": "Kenya",
            "F": "KE",
            "G": "O",
            "H": "",
            "I": "",
            "J": "AFR"
        }
    ],
    "MBI": [
        {
            "B": "HTMB",
            "C": "Mbeya",
            "D": "Mbeya",
            "E": "Tanzania",
            "F": "TZ",
            "G": "O",
            "H": "",
            "I": "",
            "J": "AFR"
        }
    ],
    "MBJ": [
        {
            "B": "MKJS",
            "C": "MONTEGO BAY",
            "D": "Montego Bay",
            "E": "Jamaica",
            "F": "JM",
            "G": "T",
            "H": "",
            "I": "",
            "J": "CAR"
        }
    ],
    "MBS": [
        {
            "B": "KMBS",
            "C": "SAGINAW  / TRI CITY",
            "D": "Saginaw",
            "E": "USA",
            "F": "US",
            "G": "T",
            "H": "",
            "I": "",
            "J": "NOA"
        }
    ],
    "MBT": [
        {
            "B": "RPVJ",
            "C": "Masbate",
            "D": "Moises R. Espinosa",
            "E": "Philippines",
            "F": "PH",
            "G": "O",
            "H": "",
            "I": "",
            "J": "SEA"
        }
    ],
    "MBX": [
        {
            "B": "LJMB",
            "C": "MARIBOR",
            "D": "Maribor",
            "E": "Slovenia",
            "F": "SI",
            "G": "E",
            "H": "",
            "I": "",
            "J": "EUR"
        }
    ],
    "MCE": [
        {
            "B": "KMCE",
            "C": "Merced",
            "D": "Merced Municipal",
            "E": "USA",
            "F": "US",
            "G": "T",
            "H": "",
            "I": "",
            "J": "NOA"
        }
    ],
    "MCF": [
        {
            "B": "KMCF",
            "C": "TAMPA / MAC DILL",
            "D": "Tampa",
            "E": "USA",
            "F": "US",
            "G": "T",
            "H": "",
            "I": "",
            "J": "NOA"
        }
    ],
    "MCI": [
        {
            "B": "KMCI",
            "C": "KANSAS CITY",
            "D": "Kansas City",
            "E": "USA",
            "F": "US",
            "G": "T",
            "H": "",
            "I": "",
            "J": "NOA"
        }
    ],
    "MCM": [
        {
            "B": "LNMC",
            "C": "MONTE CARLO",
            "D": "Monte Carlo",
            "E": "Monaco",
            "F": "MC",
            "G": "E",
            "H": "",
            "I": "",
            "J": "EUR"
        }
    ],
    "MCN": [
        {
            "B": "KMCN",
            "C": "MIDDLE GEORGIA REGIONAL",
            "D": "MIDDLE GEORGIA REGIONAL",
            "E": "USA",
            "F": "US",
            "G": "T",
            "H": "",
            "I": "",
            "J": "NOA"
        }
    ],
    "MCO": [
        {
            "B": "KMCO",
            "C": "ORLANDO INTERNATIONAL",
            "D": "Orlando",
            "E": "USA",
            "F": "US",
            "G": "T",
            "H": "",
            "I": "",
            "J": "NOA"
        }
    ],
    "MCT": [
        {
            "B": "OOMS",
            "C": "MUSCAT",
            "D": "Muscat",
            "E": "Oman",
            "F": "OM",
            "G": "O",
            "H": "",
            "I": "",
            "J": "MDE"
        }
    ],
    "MCU": [
        {
            "B": "LFBK",
            "C": "MONTLUCON",
            "D": "Montlucon",
            "E": "France",
            "F": "FR",
            "G": "E",
            "H": "",
            "I": "",
            "J": "EUR"
        }
    ],
    "MCW": [
        {
            "B": "KMCW",
            "C": "Mason City, Iowa",
            "D": "Mason City Municipal",
            "E": "USA",
            "F": "US",
            "G": "T",
            "H": "",
            "I": "",
            "J": "NOA"
        }
    ],
    "MCX": [
        {
            "B": "URML",
            "C": "MAKHACHKALA/UYTASH",
            "D": "Makhachkala/Uytash",
            "E": "Russian Federation",
            "F": "RU",
            "G": "E",
            "H": "",
            "I": "",
            "J": "EUR"
        }
    ],
    "MCY": [
        {
            "B": "YBMC",
            "C": "MAROOCHYDORE",
            "D": "Maroochydore",
            "E": "Australia",
            "F": "AU",
            "G": "O",
            "H": "",
            "I": "",
            "J": "SWP"
        }
    ],
    "MCZ": [
        {
            "B": "SBMO",
            "C": "Palmares",
            "D": "Zumbi dos Palmares Int",
            "E": "Brazil",
            "F": "BR",
            "G": "T",
            "H": "",
            "I": "",
            "J": "SOA"
        }
    ],
    "MDC": [
        {
            "B": "WAMM",
            "C": "Samratulangi",
            "D": "Sam Ratulangi",
            "E": "Indonesia",
            "F": "ID",
            "G": "O",
            "H": "",
            "I": "",
            "J": "SEA"
        }
    ],
    "MDE": [
        {
            "B": "SKRG",
            "C": "CORDOVA INTL AIRPORT",
            "D": "José maría córdova international airport",
            "E": "Colombia",
            "F": "CO",
            "G": "T",
            "H": "",
            "I": "",
            "J": "SOA"
        }
    ],
    "MDL": [
        {
            "B": "VYMD",
            "C": "MANDALAY",
            "D": "MANDALAY INTERNATIONAL",
            "E": "Myanmar",
            "F": "MM",
            "G": "O",
            "H": "",
            "I": "",
            "J": "SEA"
        }
    ],
    "MDQ": [
        {
            "B": "SAZM",
            "C": "ÁSTOR PIAZZOLLA",
            "D": "Ástor Piazzolla",
            "E": "Argentina",
            "F": "AR",
            "G": "T",
            "H": "",
            "I": "",
            "J": "SOA"
        }
    ],
    "MDT": [
        {
            "B": "KMDT",
            "C": "HARRISBURG INTERNATIONAL",
            "D": "HARRISBURG INTERNATIONAL",
            "E": "USA",
            "F": "US",
            "G": "T",
            "H": "",
            "I": "",
            "J": "NOA"
        }
    ],
    "MDW": [
        {
            "B": "KMDW",
            "C": "CHICAGO - MIDWAY",
            "D": "Chicago",
            "E": "USA",
            "F": "US",
            "G": "T",
            "H": "",
            "I": "",
            "J": "NOA"
        }
    ],
    "MDZ": [
        {
            "B": "SAME",
            "C": "El Plumerillo",
            "D": "El Plumerillo",
            "E": "Argentina",
            "F": "AR",
            "G": "T",
            "H": "",
            "I": "",
            "J": "SOA"
        }
    ],
    "MED": [
        {
            "B": "OEMA",
            "C": "MADINAH",
            "D": "Madinah",
            "E": "Saudi Arabia",
            "F": "SA",
            "G": "O",
            "H": "",
            "I": "",
            "J": "MDE"
        }
    ],
    "MEH": [
        {
            "B": "ENMH",
            "C": "MEHAMN",
            "D": "Mehamn",
            "E": "Norway",
            "F": "NO",
            "G": "E",
            "H": "",
            "I": "",
            "J": "EUR"
        }
    ],
    "MEI": [
        {
            "B": "KMEI",
            "C": "Key Field",
            "D": "Regional (Key Field)",
            "E": "USA",
            "F": "US",
            "G": "T",
            "H": "",
            "I": "",
            "J": "NOA"
        }
    ],
    "MEL": [
        {
            "B": "YMML",
            "C": "MELBOURNE",
            "D": "Melbourne",
            "E": "Australia",
            "F": "AU",
            "G": "O",
            "H": "",
            "I": "",
            "J": "SWP"
        }
    ],
    "MEM": [
        {
            "B": "KMEM",
            "C": "MEMPHIS INTERNATIONAL",
            "D": "Memphis",
            "E": "USA",
            "F": "US",
            "G": "T",
            "H": "",
            "I": "",
            "J": "NOA"
        }
    ],
    "MEN": [
        {
            "B": "LFNB",
            "C": "MENDE-BRENOUX",
            "D": "Mende Brenoux",
            "E": "France",
            "F": "FR",
            "G": "E",
            "H": "",
            "I": "",
            "J": "EUR"
        }
    ],
    "MEX": [
        {
            "B": "MMMX",
            "C": "MEXICO CITY",
            "D": "Mexico City",
            "E": "Mexico",
            "F": "MX",
            "G": "T",
            "H": "",
            "I": "",
            "J": "CEM"
        }
    ],
    "MFE": [
        {
            "B": "KMFE",
            "C": "Miller International",
            "D": "Miller International",
            "E": "USA",
            "F": "US",
            "G": "T",
            "H": "",
            "I": "",
            "J": "NOA"
        }
    ],
    "MFM": [
        {
            "B": "VMMC",
            "C": "Macau",
            "D": "Macau International",
            "E": "Macau",
            "F": "MO",
            "G": "O",
            "H": "",
            "I": "",
            "J": "SEA"
        }
    ],
    "MFR": [
        {
            "B": "KMFR",
            "C": "Jackson County",
            "D": "Rogue Valley Intl",
            "E": "USA",
            "F": "US",
            "G": "T",
            "H": "",
            "I": "",
            "J": "NOA"
        }
    ],
    "MFU": [
        {
            "B": "FLMF",
            "C": "MFUWE",
            "D": "Mfuwe",
            "E": "Zambia",
            "F": "ZM",
            "G": "O",
            "H": "",
            "I": "",
            "J": "AFR"
        }
    ],
    "MFX": [
        {
            "B": "LFKX",
            "C": "MERIBEL - BOIS DES FRAISES",
            "D": "Meribel",
            "E": "France",
            "F": "FR",
            "G": "E",
            "H": "",
            "I": "",
            "J": "EUR"
        }
    ],
    "MGA": [
        {
            "B": "MNMG",
            "C": "MANAGUA",
            "D": "Managua",
            "E": "Nicaragua",
            "F": "NI",
            "G": "T",
            "H": "",
            "I": "",
            "J": "CEM"
        }
    ],
    "MGF": [
        {
            "B": "SBMH",
            "C": "Maringa",
            "D": "Silvio Name Jr.",
            "E": "Brazil",
            "F": "BR",
            "G": "T",
            "H": "",
            "I": "",
            "J": "SOA"
        }
    ],
    "MGL": [
        {
            "B": "EDLN",
            "C": "DUSSELDORF MONCHEN-GLADBACH",
            "D": "Monchen-gladbach",
            "E": "Germany",
            "F": "DE",
            "G": "E",
            "H": "",
            "I": "",
            "J": "EUR"
        }
    ],
    "MGM": [
        {
            "B": "KMGM",
            "C": "MONTGOMERY",
            "D": "Montgomery regional",
            "E": "USA",
            "F": "US",
            "G": "T",
            "H": "",
            "I": "",
            "J": "NOA"
        }
    ],
    "MGQ": [
        {
            "B": "HCMM",
            "C": "International",
            "D": "Mogadishu Intl",
            "E": "Somalia",
            "F": "SO",
            "G": "O",
            "H": "",
            "I": "",
            "J": "AFR"
        }
    ],
    "MGW": [
        {
            "B": "KMGW",
            "C": "Morgantown",
            "D": "Morgantown Municipal",
            "E": "USA",
            "F": "US",
            "G": "T",
            "H": "",
            "I": "",
            "J": "NOA"
        }
    ],
    "MHD": [
        {
            "B": "OIMM",
            "C": "MASHAD",
            "D": "Mashad",
            "E": "Iran",
            "F": "IR",
            "G": "O",
            "H": "",
            "I": "",
            "J": "MDE"
        }
    ],
    "MHG": [
        {
            "B": "EDFM",
            "C": "MANNHEIM",
            "D": "Mannheim",
            "E": "Germany",
            "F": "DE",
            "G": "E",
            "H": "",
            "I": "",
            "J": "EUR"
        }
    ],
    "MHH": [
        {
            "B": "MYAM",
            "C": "International",
            "D": "Marsh Harbour",
            "E": "Bahamas",
            "F": "BS",
            "G": "T",
            "H": "",
            "I": "",
            "J": "CAR"
        }
    ],
    "MHK": [
        {
            "B": "KMHK",
            "C": "Municipal",
            "D": "Manhattan Regional",
            "E": "USA",
            "F": "US",
            "G": "T",
            "H": "",
            "I": "",
            "J": "NOA"
        }
    ],
    "MHP": [
        {
            "B": "UMMM",
            "C": "MINSK INTERNATIONAL 1",
            "D": "Minsk",
            "E": "Belarus",
            "F": "BY",
            "G": "E",
            "H": "",
            "I": "",
            "J": "EUR"
        }
    ],
    "MHQ": [
        {
            "B": "EFMA",
            "C": "Mariehamn",
            "D": "Mariehamn",
            "E": "Finland",
            "F": "FI",
            "G": "E",
            "H": "",
            "I": "",
            "J": "EUR"
        }
    ],
    "MHT": [
        {
            "B": "KMHT",
            "C": "MANCHESTER MUNICIPAL",
            "D": "Manchester usa",
            "E": "USA",
            "F": "US",
            "G": "T",
            "H": "",
            "I": "",
            "J": "NOA"
        }
    ],
    "MHV": [
        {
            "B": "KMHV",
            "C": "MOJAVE KERN COUNTY",
            "D": "Mojave",
            "E": "USA",
            "F": "US",
            "G": "T",
            "H": "",
            "I": "",
            "J": "NOA"
        }
    ],
    "MHZ": [
        {
            "B": "EGUN",
            "C": "MILDENHALL",
            "D": "Mildenhall",
            "E": "United Kingdom",
            "F": "GB",
            "G": "U",
            "H": "",
            "I": "",
            "J": "EUR"
        }
    ],
    "MIA": [
        {
            "B": "KMIA",
            "C": "MIAMI",
            "D": "Miami",
            "E": "USA",
            "F": "US",
            "G": "T",
            "H": "",
            "I": "",
            "J": "NOA"
        }
    ],
    "MID": [
        {
            "B": "MMMD",
            "C": "Rejon",
            "D": "M. Crescencio Rejon",
            "E": "Mexico",
            "F": "MX",
            "G": "T",
            "H": "",
            "I": "",
            "J": "CEM"
        }
    ],
    "MIP": [
        {
            "B": "LLMR",
            "C": "MITZPE-RAMON AIRSTRIP",
            "D": "Mitzpe Ramon",
            "E": "Israel",
            "F": "IL",
            "G": "O",
            "H": "",
            "I": "",
            "J": "MDE"
        }
    ],
    "MIR": [
        {
            "B": "DTMB",
            "C": "MONASTIR",
            "D": "Monastir",
            "E": "Tunisia",
            "F": "TN",
            "G": "O",
            "H": "",
            "I": "",
            "J": "AFR"
        }
    ],
    "MJC": [
        {
            "B": "DIMN",
            "C": "MAN AIRPORT",
            "D": "Man Airport",
            "E": "Cote d'Ivoire",
            "F": "CI",
            "G": "O",
            "H": "",
            "I": "",
            "J": "AFR"
        }
    ],
    "MJF": [
        {
            "B": "ENMS",
            "C": "Kjaerstad",
            "D": "Mosjoen",
            "E": "Norway",
            "F": "NO",
            "G": "E",
            "H": "",
            "I": "",
            "J": "EUR"
        }
    ],
    "MJI": [
        {
            "B": "HLLM",
            "C": "MITIGA",
            "D": "Mitiga",
            "E": "Libya",
            "F": "LY",
            "G": "O",
            "H": "",
            "I": "",
            "J": "AFR"
        }
    ],
    "MJT": [
        {
            "B": "LGMT",
            "C": "MYTILENE",
            "D": "Mytilene",
            "E": "Greece",
            "F": "GR",
            "G": "E",
            "H": "",
            "I": "",
            "J": "EUR"
        }
    ],
    "MJV": [
        {
            "B": "LELC",
            "C": "MURCIA",
            "D": "Murcia",
            "E": "Spain",
            "F": "ES",
            "G": "E",
            "H": "",
            "I": "",
            "J": "EUR"
        }
    ],
    "MKA": [
        {
            "B": "LKMR",
            "C": "MARIANSKE LAZNE",
            "D": "Marianske Lazne",
            "E": "Czech Republic",
            "F": "CZ",
            "G": "E",
            "H": "",
            "I": "",
            "J": "EUR"
        }
    ],
    "MKC": [
        {
            "B": "KMKC",
            "C": "KANSAS CITY",
            "D": "Kansas city downtown airport",
            "E": "USA",
            "F": "US",
            "G": "T",
            "H": "",
            "I": "",
            "J": "NOA"
        }
    ],
    "MKE": [
        {
            "B": "KMKE",
            "C": "MILWAUKEE GENERAL MITCHELL",
            "D": "Milwaukee general",
            "E": "USA",
            "F": "US",
            "G": "T",
            "H": "",
            "I": "",
            "J": "NOA"
        }
    ],
    "MKG": [
        {
            "B": "KMKG",
            "C": "MUSKEGON",
            "D": "Muskegon",
            "E": "USA",
            "F": "US",
            "G": "T",
            "H": "",
            "I": "",
            "J": "NOA"
        }
    ],
    "MKY": [
        {
            "B": "YBMK",
            "C": "Mackay",
            "D": "Mackay",
            "E": "Australia",
            "F": "AU",
            "G": "O",
            "H": "",
            "I": "",
            "J": "SWP"
        }
    ],
    "MKZ": [
        {
            "B": "WMKM",
            "C": "MALACCA",
            "D": "Malacca",
            "E": "Malaysia",
            "F": "MY",
            "G": "O",
            "H": "",
            "I": "",
            "J": "SEA"
        }
    ],
    "MLA": [
        {
            "B": "LMML",
            "C": "MALTA",
            "D": "Malta",
            "E": "Malta",
            "F": "MT",
            "G": "E",
            "H": "",
            "I": "",
            "J": "EUR"
        }
    ],
    "MLB": [
        {
            "B": "KMLB",
            "C": "MELBOURNE INTERNATIONAL",
            "D": "MELBOURNE INTERNATIONAL",
            "E": "USA",
            "F": "US",
            "G": "T",
            "H": "",
            "I": "",
            "J": "NOA"
        }
    ],
    "MLE": [
        {
            "B": "VRMM",
            "C": "MALE INTERNATIONAL",
            "D": "Male",
            "E": "Maldives",
            "F": "MV",
            "G": "O",
            "H": "",
            "I": "",
            "J": "SAS"
        }
    ],
    "MLG": [
        {
            "B": "WARA",
            "C": "MALANG/ABDUL RAHMAN SALEH",
            "D": "Malang/Abdul Rahman Saleh",
            "E": "Indonesia",
            "F": "ID",
            "G": "O",
            "H": "",
            "I": "",
            "J": "SEA"
        }
    ],
    "MLH": [
        {
            "B": "LFGB",
            "C": "MULHOUSE",
            "D": "Mulhouse",
            "E": "France",
            "F": "FR",
            "G": "E",
            "H": "",
            "I": "",
            "J": "EUR"
        }
    ],
    "MLI": [
        {
            "B": "KMLI",
            "C": "MOLINE / QUAD CITY",
            "D": "Moline",
            "E": "USA",
            "F": "US",
            "G": "T",
            "H": "",
            "I": "",
            "J": "NOA"
        }
    ],
    "MLM": [
        {
            "B": "MMMM",
            "C": "Morelia",
            "D": "F.J. Mujica Intl",
            "E": "Mexico",
            "F": "MX",
            "G": "T",
            "H": "",
            "I": "",
            "J": "CEM"
        }
    ],
    "MLN": [
        {
            "B": "GEML",
            "C": "Melilla",
            "D": "Melilla",
            "E": "Spain",
            "F": "ES",
            "G": "E",
            "H": "",
            "I": "",
            "J": "EUR"
        }
    ],
    "MLO": [
        {
            "B": "LGML",
            "C": "MILOS",
            "D": "Milos",
            "E": "Greece",
            "F": "GR",
            "G": "E",
            "H": "",
            "I": "",
            "J": "EUR"
        }
    ],
    "MLU": [
        {
            "B": "KMLU",
            "C": "Municipal",
            "D": "Monroe Regional",
            "E": "USA",
            "F": "US",
            "G": "T",
            "H": "",
            "I": "",
            "J": "NOA"
        }
    ],
    "MLX": [
        {
            "B": "LTAT",
            "C": "MALATYA",
            "D": "Malatya",
            "E": "Turkey",
            "F": "TR",
            "G": "E",
            "H": "",
            "I": "",
            "J": "EUR"
        }
    ],
    "MMA": [
        {
            "B": "",
            "C": "",
            "D": "",
            "E": "UNKNOWN",
            "F": "",
            "G": "A",
            "H": "",
            "I": "",
            "J": ""
        }
    ],
    "MMD": [
        {
            "B": "ROMD",
            "C": "MINAMI DAITO",
            "D": "Minami Daito",
            "E": "Japan",
            "F": "JP",
            "G": "O",
            "H": "",
            "I": "",
            "J": "JAK"
        }
    ],
    "MME": [
        {
            "B": "EGNV",
            "C": "DURHAM TEES VALLEY",
            "D": "Teesside",
            "E": "United Kingdom",
            "F": "GB",
            "G": "U",
            "H": "",
            "I": "",
            "J": "EUR"
        }
    ],
    "MMH": [
        {
            "B": "KMMH",
            "C": "Mammoth Yosemite",
            "D": "Mammoth Yosemite Airport",
            "E": "USA",
            "F": "US",
            "G": "T",
            "H": "",
            "I": "",
            "J": "NOA"
        }
    ],
    "MMU": [
        {
            "B": "KMMU",
            "C": "MORRISTOWN MUNICIPLE",
            "D": "Morristown",
            "E": "USA",
            "F": "US",
            "G": "T",
            "H": "",
            "I": "",
            "J": "NOA"
        }
    ],
    "MMX": [
        {
            "B": "ESMS",
            "C": "MALMO",
            "D": "Malmo Sturup",
            "E": "Sweden",
            "F": "SE",
            "G": "E",
            "H": "",
            "I": "",
            "J": "EUR"
        }
    ],
    "MNC": [
        {
            "B": "FQNC",
            "C": "Nacala",
            "D": "Nacala",
            "E": "Mozambique",
            "F": "MZ",
            "G": "O",
            "H": "",
            "I": "",
            "J": "AFR"
        }
    ],
    "MNI": [
        {
            "B": "TRPM",
            "C": "Bramble",
            "D": "John A. Osborne",
            "E": "Montserrat",
            "F": "MS",
            "G": "T",
            "H": "",
            "I": "",
            "J": "CAR"
        }
    ],
    "MNL": [
        {
            "B": "RPLL",
            "C": "MANILA - NINOY AQUINO",
            "D": "Manila",
            "E": "Philippines",
            "F": "PH",
            "G": "O",
            "H": "",
            "I": "",
            "J": "SEA"
        }
    ],
    "MOL": [
        {
            "B": "ENML",
            "C": "ARO",
            "D": "Aro",
            "E": "Norway",
            "F": "NO",
            "G": "E",
            "H": "",
            "I": "",
            "J": "EUR"
        }
    ],
    "MON": [
        {
            "B": "NZMC",
            "C": "MOUNT COOK",
            "D": "Mount Cook",
            "E": "New Zealand",
            "F": "NZ",
            "G": "O",
            "H": "",
            "I": "",
            "J": "SWP"
        }
    ],
    "MOT": [
        {
            "B": "KMOT",
            "C": "MINOT INTERNATIONAL",
            "D": "Minot",
            "E": "USA",
            "F": "US",
            "G": "T",
            "H": "",
            "I": "",
            "J": "NOA"
        }
    ],
    "MOV": [
        {
            "B": "YMRB",
            "C": "Moranbah",
            "D": "Moranbah",
            "E": "Australia",
            "F": "AU",
            "G": "O",
            "H": "",
            "I": "",
            "J": "SWP"
        }
    ],
    "MOW": [
        {
            "B": "ZMOW",
            "C": "MOSCOW METROPOLITAN AREA",
            "D": "Moscow",
            "E": "Russian Federation",
            "F": "RU",
            "G": "E",
            "H": "",
            "I": "",
            "J": "EUR"
        }
    ],
    "MPH": [
        {
            "B": "RPVE",
            "C": "Malay",
            "D": "Godofredo P. Ramos",
            "E": "Philippines",
            "F": "PH",
            "G": "O",
            "H": "",
            "I": "",
            "J": "SEA"
        }
    ],
    "MPL": [
        {
            "B": "LFMT",
            "C": "MONTPELLIER",
            "D": "Montpellier",
            "E": "France",
            "F": "FR",
            "G": "E",
            "H": "",
            "I": "",
            "J": "EUR"
        }
    ],
    "MPM": [
        {
            "B": "FQMA",
            "C": "Maputo International",
            "D": "Maputo International",
            "E": "Mozambique",
            "F": "MZ",
            "G": "O",
            "H": "",
            "I": "",
            "J": "AFR"
        }
    ],
    "MQC": [
        {
            "B": "LFVM",
            "C": "MIQUELON",
            "D": "Miquelon",
            "E": "France",
            "F": "FR",
            "G": "E",
            "H": "",
            "I": "",
            "J": "EUR"
        }
    ],
    "MQF": [
        {
            "B": "USCM",
            "C": "MAGNITOGORSK",
            "D": "Magnitogorsk",
            "E": "Russian Federation",
            "F": "RU",
            "G": "E",
            "H": "",
            "I": "",
            "J": "EUR"
        }
    ],
    "MQL": [
        {
            "B": "YMIA",
            "C": "Mildura",
            "D": "Mildura",
            "E": "Australia",
            "F": "AU",
            "G": "O",
            "H": "",
            "I": "",
            "J": "SWP"
        }
    ],
    "MQN": [
        {
            "B": "ENRA",
            "C": "Mo I Rana",
            "D": "Mo I Rana",
            "E": "Norway",
            "F": "NO",
            "G": "E",
            "H": "",
            "I": "",
            "J": "EUR"
        }
    ],
    "MQP": [
        {
            "B": "FAKN",
            "C": "Kruger Mpumalanga",
            "D": "Kruger Mpumalanga Intl",
            "E": "South Africa",
            "F": "ZA",
            "G": "O",
            "H": "",
            "I": "",
            "J": "AFR"
        }
    ],
    "MQT": [
        {
            "B": "KMQT",
            "C": "Sawyer International",
            "D": "Marquette County",
            "E": "USA",
            "F": "US",
            "G": "T",
            "H": "",
            "I": "",
            "J": "NOA"
        }
    ],
    "MQX": [
        {
            "B": "HAMK",
            "C": "Makale",
            "D": "Alula Aba Nega",
            "E": "Ethiopia",
            "F": "ET",
            "G": "O",
            "H": "",
            "I": "",
            "J": "AFR"
        }
    ],
    "MRS": [
        {
            "B": "LFML",
            "C": "MARSEILLE - PROVENCE",
            "D": "Marseille",
            "E": "France",
            "F": "FR",
            "G": "E",
            "H": "",
            "I": "",
            "J": "EUR"
        }
    ],
    "MRU": [
        {
            "B": "FIMP",
            "C": "MAURITIUS",
            "D": "Mauritius",
            "E": "Mauritius",
            "F": "MU",
            "G": "O",
            "H": "",
            "I": "",
            "J": "AFR"
        }
    ],
    "MRW": [
        {
            "B": "EKMB",
            "C": "LOLLAND FALSTER",
            "D": "Maribo",
            "E": "Denmark",
            "F": "DK",
            "G": "E",
            "H": "",
            "I": "",
            "J": "EUR"
        }
    ],
    "MRY": [
        {
            "B": "KMRY",
            "C": "MONTEREY REGIONAL AIRPORT",
            "D": "MONTEREY REGIONAL AIRPORT",
            "E": "USA",
            "F": "US",
            "G": "T",
            "H": "",
            "I": "",
            "J": "NOA"
        }
    ],
    "MRZ": [
        {
            "B": "YMOR",
            "C": "Moree",
            "D": "Moree",
            "E": "Australia",
            "F": "AU",
            "G": "O",
            "H": "",
            "I": "",
            "J": "SWP"
        }
    ],
    "MSE": [
        {
            "B": "EGMH",
            "C": "MANSTON",
            "D": "Kent Manston",
            "E": "United Kingdom",
            "F": "GB",
            "G": "U",
            "H": "",
            "I": "",
            "J": "EUR"
        }
    ],
    "MSJ": [
        {
            "B": "RJSM",
            "C": "MISAWA",
            "D": "Misawa",
            "E": "Japan",
            "F": "JP",
            "G": "O",
            "H": "",
            "I": "",
            "J": "JAK"
        }
    ],
    "MSL": [
        {
            "B": "KMSL",
            "C": "MUSCLE SHOALS",
            "D": "Muscle Shoals",
            "E": "USA",
            "F": "US",
            "G": "T",
            "H": "",
            "I": "",
            "J": "NOA"
        }
    ],
    "MSN": [
        {
            "B": "KMSN",
            "C": "DANE COUNTY REGIONAL",
            "D": "Madison",
            "E": "USA",
            "F": "US",
            "G": "T",
            "H": "",
            "I": "",
            "J": "NOA"
        }
    ],
    "MSO": [
        {
            "B": "KMSO",
            "C": "Johnson-Bell Field",
            "D": "Missoula/Intl",
            "E": "USA",
            "F": "US",
            "G": "T",
            "H": "",
            "I": "",
            "J": "NOA"
        }
    ],
    "MSP": [
        {
            "B": "KMSP",
            "C": "MINNEAPOLIS",
            "D": "Minneapolis",
            "E": "USA",
            "F": "US",
            "G": "T",
            "H": "",
            "I": "",
            "J": "NOA"
        }
    ],
    "MSQ": [
        {
            "B": "UMMS",
            "C": "MINSK INTERNATIONAL 2",
            "D": "Minsk",
            "E": "Belarus",
            "F": "BY",
            "G": "E",
            "H": "",
            "I": "",
            "J": "EUR"
        }
    ],
    "MSR": [
        {
            "B": "LTCK",
            "C": "MUS",
            "D": "Mus",
            "E": "Turkey",
            "F": "TR",
            "G": "E",
            "H": "",
            "I": "",
            "J": "EUR"
        }
    ],
    "MST": [
        {
            "B": "EHBK",
            "C": "MAASTRICHT",
            "D": "Maastricht",
            "E": "Netherlands",
            "F": "NL",
            "G": "E",
            "H": "",
            "I": "",
            "J": "EUR"
        }
    ],
    "MSU": [
        {
            "B": "FXMM",
            "C": "Moshoeshoe Intl",
            "D": "Moshoeshoe I International",
            "E": "Lesotho",
            "F": "LS",
            "G": "O",
            "H": "",
            "I": "",
            "J": "AFR"
        }
    ],
    "MSY": [
        {
            "B": "KMSY",
            "C": "NEW ORLEANS INTERNATIONAL",
            "D": "New Orleans",
            "E": "USA",
            "F": "US",
            "G": "T",
            "H": "",
            "I": "",
            "J": "NOA"
        }
    ],
    "MSZ": [
        {
            "B": "FNMO",
            "C": "NAMIBE",
            "D": "Namibe",
            "E": "Angola",
            "F": "AO",
            "G": "O",
            "H": "",
            "I": "",
            "J": "AFR"
        }
    ],
    "MTJ": [
        {
            "B": "KMTJ",
            "C": "Montrose, Colorado",
            "D": "Montrose County",
            "E": "USA",
            "F": "US",
            "G": "T",
            "H": "",
            "I": "",
            "J": "NOA"
        }
    ],
    "MTN": [
        {
            "B": "KMTN",
            "C": "BALTIMORE",
            "D": "Baltimore",
            "E": "USA",
            "F": "US",
            "G": "T",
            "H": "",
            "I": "",
            "J": "NOA"
        }
    ],
    "MTS": [
        {
            "B": "FDMS",
            "C": "Matsapha Intl",
            "D": "Matsapha Intl",
            "E": "Swaziland",
            "F": "SZ",
            "G": "O",
            "H": "",
            "I": "",
            "J": "AFR"
        }
    ],
    "MTT": [
        {
            "B": "MMMT",
            "C": "MINATITLAN",
            "D": "Minatitlan",
            "E": "Mexico",
            "F": "MX",
            "G": "T",
            "H": "",
            "I": "",
            "J": "CEM"
        }
    ],
    "MTY": [
        {
            "B": "MMMY",
            "C": "Gen Mariano Escobedo",
            "D": "Mariano Escobedo Intl",
            "E": "Mexico",
            "F": "MX",
            "G": "T",
            "H": "",
            "I": "",
            "J": "CEM"
        }
    ],
    "MUB": [
        {
            "B": "FBMN",
            "C": "Maun",
            "D": "Maun",
            "E": "Botswana",
            "F": "BW",
            "G": "O",
            "H": "",
            "I": "",
            "J": "AFR"
        }
    ],
    "MUC": [
        {
            "B": "EDDM",
            "C": "MUNICH",
            "D": "Munich",
            "E": "Germany",
            "F": "DE",
            "G": "E",
            "H": "",
            "I": "",
            "J": "EUR"
        }
    ],
    "MUH": [
        {
            "B": "HEMM",
            "C": "MERSA MATRUH",
            "D": "Mersa Matruh",
            "E": "Egypt",
            "F": "EG",
            "G": "O",
            "H": "",
            "I": "",
            "J": "MDE"
        }
    ],
    "MUN": [
        {
            "B": "SVMT",
            "C": "MATURIN MONAGAS",
            "D": "Maturin",
            "E": "Venezuela",
            "F": "VE",
            "G": "T",
            "H": "",
            "I": "",
            "J": "SOA"
        }
    ],
    "MUX": [
        {
            "B": "OPMT",
            "C": "Multan",
            "D": "Multan International",
            "E": "Pakistan",
            "F": "PK",
            "G": "O",
            "H": "",
            "I": "",
            "J": "SAS"
        }
    ],
    "MVB": [
        {
            "B": "FOON",
            "C": "FRANCEVILLE/MVENGUE",
            "D": "Franceville/Mvengue",
            "E": "Gabon",
            "F": "GA",
            "G": "O",
            "H": "",
            "I": "",
            "J": "AFR"
        }
    ],
    "MVD": [
        {
            "B": "SUMU",
            "C": "MONTEVIDEO",
            "D": "Montevideo",
            "E": "Uruguay",
            "F": "UY",
            "G": "T",
            "H": "",
            "I": "",
            "J": "SOA"
        }
    ],
    "MVV": [
        {
            "B": "LFHM",
            "C": "MEGEVE",
            "D": "Megeve",
            "E": "France",
            "F": "FR",
            "G": "E",
            "H": "",
            "I": "",
            "J": "EUR"
        }
    ],
    "MVY": [
        {
            "B": "KMVY",
            "C": "MARTHA'S VINEYARD",
            "D": "Marthas Vnyrd",
            "E": "USA",
            "F": "US",
            "G": "T",
            "H": "",
            "I": "",
            "J": "NOA"
        }
    ],
    "MWA": [
        {
            "B": "KMWA",
            "C": "Marion, Illinois",
            "D": "Williamson County Regional",
            "E": "USA",
            "F": "US",
            "G": "T",
            "H": "",
            "I": "",
            "J": "NOA"
        }
    ],
    "MWZ": [
        {
            "B": "HTMW",
            "C": "Mwanza",
            "D": "Mwanza",
            "E": "Tanzania",
            "F": "TZ",
            "G": "O",
            "H": "",
            "I": "",
            "J": "AFR"
        }
    ],
    "MXL": [
        {
            "B": "MMML",
            "C": "Mexicali",
            "D": "R.Sanchez Taboada Intl",
            "E": "Mexico",
            "F": "MX",
            "G": "T",
            "H": "",
            "I": "",
            "J": "CEM"
        }
    ],
    "MXN": [
        {
            "B": "LFRU",
            "C": "MORLAIX - PLOUJEAN",
            "D": "Morlaix Ploujean",
            "E": "France",
            "F": "FR",
            "G": "E",
            "H": "",
            "I": "",
            "J": "EUR"
        }
    ],
    "MXP": [
        {
            "B": "LIMC",
            "C": "MILAN MALPENSA",
            "D": "Milan MXP",
            "E": "Italy",
            "F": "IT",
            "G": "E",
            "H": "",
            "I": "",
            "J": "EUR"
        }
    ],
    "MXZ": [
        {
            "B": "ZGMX",
            "C": "MEIXIAN/CHANGGANGJI",
            "D": "Meixian/Changgangji",
            "E": "Peoples Republic of China",
            "F": "CN",
            "G": "O",
            "H": "",
            "I": "",
            "J": "SEA"
        }
    ],
    "MYD": [
        {
            "B": "HKML",
            "C": "Malindi",
            "D": "Malindi",
            "E": "Kenya",
            "F": "KE",
            "G": "O",
            "H": "",
            "I": "",
            "J": "AFR"
        }
    ],
    "MYJ": [
        {
            "B": "RJOM",
            "C": "Matsuyama",
            "D": "Matsuyama",
            "E": "Japan",
            "F": "JP",
            "G": "O",
            "H": "",
            "I": "",
            "J": "JAK"
        }
    ],
    "MYR": [
        {
            "B": "KMYR",
            "C": "MYRTLE BEACH INTL",
            "D": "MYRTLE BEACH INTERNATIONAL",
            "E": "USA",
            "F": "US",
            "G": "T",
            "H": "",
            "I": "",
            "J": "NOA"
        }
    ],
    "MYY": [
        {
            "B": "WBGR",
            "C": "Miri",
            "D": "Miri",
            "E": "Malaysia",
            "F": "MY",
            "G": "O",
            "H": "",
            "I": "",
            "J": "SEA"
        }
    ],
    "MZR": [
        {
            "B": "OAMS",
            "C": "Mazar-I-Sharif",
            "D": "Mazar-e-Sharif",
            "E": "Afghanistan",
            "F": "AF",
            "G": "O",
            "H": "",
            "I": "",
            "J": "SAS"
        }
    ],
    "MZT": [
        {
            "B": "MMMZ",
            "C": "Gen Rafael Buelna",
            "D": "Rafael Buelna Intl",
            "E": "Mexico",
            "F": "MX",
            "G": "T",
            "H": "",
            "I": "",
            "J": "CEM"
        }
    ],
    "N01": [
        {
            "B": "N01R",
            "C": "RYGGE AB",
            "D": "Rygge",
            "E": "Norway",
            "F": "NO",
            "G": "E",
            "H": "",
            "I": "",
            "J": "EUR"
        }
    ],
    "NAG": [
        {
            "B": "VANP",
            "C": "Sonegaon",
            "D": "Dr. Ambedkar Intl Airport",
            "E": "India",
            "F": "IN",
            "G": "O",
            "H": "",
            "I": "",
            "J": "SAS"
        }
    ],
    "NAN": [
        {
            "B": "NFFN",
            "C": "International",
            "D": "Nadi International",
            "E": "Fiji",
            "F": "FJ",
            "G": "O",
            "H": "",
            "I": "",
            "J": "SWP"
        }
    ],
    "NAP": [
        {
            "B": "LIRN",
            "C": "NAPLES",
            "D": "Naples",
            "E": "Italy",
            "F": "IT",
            "G": "E",
            "H": "",
            "I": "",
            "J": "EUR"
        }
    ],
    "NAS": [
        {
            "B": "MYNN",
            "C": "NASSAU INTERNATIONAL",
            "D": "Nassau",
            "E": "Bahamas",
            "F": "BS",
            "G": "T",
            "H": "",
            "I": "",
            "J": "CAR"
        }
    ],
    "NAT": [
        {
            "B": "SBNT",
            "C": "Augusto Severo",
            "D": "Natal International",
            "E": "Brazil",
            "F": "BR",
            "G": "T",
            "H": "",
            "I": "",
            "J": "SOA"
        }
    ],
    "NAV": [
        {
            "B": "LTAZ",
            "C": "Nevsehir",
            "D": "Kapadokya Airport",
            "E": "Turkey",
            "F": "TR",
            "G": "E",
            "H": "",
            "I": "",
            "J": "EUR"
        }
    ],
    "NBC": [
        {
            "B": "UWKE",
            "C": "Begishevo",
            "D": "Naberezhnye Chelny Begishevo",
            "E": "Russian Federation",
            "F": "RU",
            "G": "E",
            "H": "",
            "I": "",
            "J": "EUR"
        }
    ],
    "NBE": [
        {
            "B": "DTTE",
            "C": "ENFIDHA",
            "D": "Enfidha",
            "E": "Tunisia",
            "F": "TN",
            "G": "O",
            "H": "",
            "I": "",
            "J": "AFR"
        }
    ],
    "NBO": [
        {
            "B": "HKNA",
            "C": "NAIROBI JOMO KENYATTA INTL",
            "D": "Nairobi",
            "E": "Kenya",
            "F": "KE",
            "G": "O",
            "H": "",
            "I": "",
            "J": "AFR"
        }
    ],
    "NCE": [
        {
            "B": "LFMN",
            "C": "NICE - COTE D'AZUR",
            "D": "Nice",
            "E": "France",
            "F": "FR",
            "G": "E",
            "H": "",
            "I": "",
            "J": "EUR"
        }
    ],
    "NCL": [
        {
            "B": "EGNT",
            "C": "NEWCASTLE - UK",
            "D": "Newcastle",
            "E": "United Kingdom",
            "F": "GB",
            "G": "U",
            "H": "",
            "I": "",
            "J": "EUR"
        }
    ],
    "NCO": [
        {
            "B": "KOQU",
            "C": "QUONSET STATE",
            "D": "QUONSET STATE AIRPORT",
            "E": "USA",
            "F": "US",
            "G": "T",
            "H": "",
            "I": "",
            "J": "NOA"
        }
    ],
    "NCY": [
        {
            "B": "LFLP",
            "C": "ANNECY",
            "D": "Annecy",
            "E": "France",
            "F": "FR",
            "G": "E",
            "H": "",
            "I": "",
            "J": "EUR"
        }
    ],
    "NDB": [
        {
            "B": "GQPP",
            "C": "Nouadhibou",
            "D": "Nouadhibou",
            "E": "Mauritania",
            "F": "MR",
            "G": "O",
            "H": "",
            "I": "",
            "J": "AFR"
        }
    ],
    "NDJ": [
        {
            "B": "FTTJ",
            "C": "NDJAMENA",
            "D": "N'Djamena",
            "E": "Chad",
            "F": "TD",
            "G": "O",
            "H": "",
            "I": "",
            "J": "AFR"
        }
    ],
    "NDZ": [
        {
            "B": "EDXN",
            "C": "NORDHOLZ-SPIEKA",
            "D": "Nordholz Spieka",
            "E": "Germany",
            "F": "DE",
            "G": "E",
            "H": "",
            "I": "",
            "J": "EUR"
        }
    ],
    "NEV": [
        {
            "B": "TKPN",
            "C": "Newcastle",
            "D": "Vance W. Amory Intl",
            "E": "St. Kitts And Nevis",
            "F": "KN",
            "G": "T",
            "H": "",
            "I": "",
            "J": "CAR"
        }
    ],
    "NGB": [
        {
            "B": "ZSNB",
            "C": "Ningbo",
            "D": "Lishe International",
            "E": "Peoples Republic of China",
            "F": "CN",
            "G": "O",
            "H": "",
            "I": "",
            "J": "SEA"
        }
    ],
    "NGE": [
        {
            "B": "FKKN",
            "C": "N'GAOUNDERE",
            "D": "N'Gaoundere",
            "E": "Cameroon",
            "F": "CM",
            "G": "O",
            "H": "",
            "I": "",
            "J": "AFR"
        }
    ],
    "NGO": [
        {
            "B": "RJGG",
            "C": "Chubu Centrair",
            "D": "Chubu Centrair International",
            "E": "Japan",
            "F": "JP",
            "G": "O",
            "H": "",
            "I": "",
            "J": "JAK"
        }
    ],
    "NGS": [
        {
            "B": "RJFU",
            "C": "NAGASAKI",
            "D": "Nagasaki",
            "E": "Japan",
            "F": "JP",
            "G": "O",
            "H": "",
            "I": "",
            "J": "JAK"
        }
    ],
    "NGU": [
        {
            "B": "KNGU",
            "C": "NORFOLK-NAS",
            "D": "Norfolk",
            "E": "USA",
            "F": "US",
            "G": "T",
            "H": "",
            "I": "",
            "J": "NOA"
        }
    ],
    "NGY": [
        {
            "B": "",
            "C": "Railway Station Nagoya Airport",
            "D": "EK Bus Station",
            "E": "Japan",
            "F": "JP",
            "G": "O",
            "H": "",
            "I": "",
            "J": "JAK"
        }
    ],
    "NHD": [
        {
            "B": "OMDM",
            "C": "Minhad AB UAE",
            "D": "Military Airfield UAE",
            "E": "United Arab Emirates",
            "F": "",
            "G": "O",
            "H": "",
            "I": "",
            "J": ""
        }
    ],
    "NHK": [
        {
            "B": "KNHK",
            "C": "PATUXENT RIVER-NAS",
            "D": "Patuxent River",
            "E": "USA",
            "F": "US",
            "G": "T",
            "H": "",
            "I": "",
            "J": "NOA"
        }
    ],
    "NHT": [
        {
            "B": "EGWU",
            "C": "NORTHOLT",
            "D": "Northolt",
            "E": "United Kingdom",
            "F": "GB",
            "G": "U",
            "H": "",
            "I": "",
            "J": "EUR"
        }
    ],
    "NHV": [
        {
            "B": "NTMD",
            "C": "NUKU HIVA",
            "D": "Nuku Hiva",
            "E": "French Polynesia",
            "F": "PF",
            "G": "O",
            "H": "",
            "I": "",
            "J": "SWP"
        }
    ],
    "NHZ": [
        {
            "B": "KNHZ",
            "C": "BRUNSWICK   MIL",
            "D": "Brunswick",
            "E": "USA",
            "F": "US",
            "G": "T",
            "H": "",
            "I": "",
            "J": "NOA"
        }
    ],
    "NIC": [
        {
            "B": "LCNC",
            "C": "NICOSIE",
            "D": "Nicosie",
            "E": "Cyprus",
            "F": "CY",
            "G": "E",
            "H": "",
            "I": "",
            "J": "EUR"
        }
    ],
    "NIM": [
        {
            "B": "DRRN",
            "C": "Niamey",
            "D": "Diori Hamani International Airport",
            "E": "Niger",
            "F": "NE",
            "G": "O",
            "H": "",
            "I": "",
            "J": "AFR"
        }
    ],
    "NIT": [
        {
            "B": "LFBN",
            "C": "NIORT / SOUCHE",
            "D": "Niort",
            "E": "France",
            "F": "FR",
            "G": "E",
            "H": "",
            "I": "",
            "J": "EUR"
        }
    ],
    "NJC": [
        {
            "B": "USNN",
            "C": "Nizhnevartovsk Airport",
            "D": "Nizhnevartovsk Airport",
            "E": "Russian Federation",
            "F": "RU",
            "G": "E",
            "H": "",
            "I": "",
            "J": "EUR"
        }
    ],
    "NJF": [
        {
            "B": "ORNI",
            "C": "Al Najaf International",
            "D": "Al-Ashraf International Airport",
            "E": "Iraq",
            "F": "IQ",
            "G": "O",
            "H": "",
            "I": "",
            "J": "MDE"
        }
    ],
    "NKC": [
        {
            "B": "GQNN",
            "C": "Nouakchott",
            "D": "Nouakchott",
            "E": "Mauritania",
            "F": "MR",
            "G": "O",
            "H": "",
            "I": "",
            "J": "AFR"
        }
    ],
    "NKG": [
        {
            "B": "ZSNJ",
            "C": "NANJING",
            "D": "Nanjing lukou international airport",
            "E": "Peoples Republic of China",
            "F": "CN",
            "G": "O",
            "H": "",
            "I": "",
            "J": "SEA"
        }
    ],
    "NKX": [
        {
            "B": "KNKX",
            "C": "SAN DIEGO - MIRAMAR",
            "D": "San Diego",
            "E": "USA",
            "F": "US",
            "G": "T",
            "H": "",
            "I": "",
            "J": "NOA"
        }
    ],
    "NLA": [
        {
            "B": "FLND",
            "C": "Ndola",
            "D": "Ndola",
            "E": "Zambia",
            "F": "ZM",
            "G": "O",
            "H": "",
            "I": "",
            "J": "AFR"
        }
    ],
    "NLD": [
        {
            "B": "MMNL",
            "C": "Intl Quetzalcoatl",
            "D": "Quetzalcoatl Intl",
            "E": "Mexico",
            "F": "MX",
            "G": "T",
            "H": "",
            "I": "",
            "J": "CEM"
        }
    ],
    "NNA": [
        {
            "B": "GMMY",
            "C": "KENTIRA",
            "D": "Kentira",
            "E": "Morocco",
            "F": "",
            "G": "O",
            "H": "",
            "I": "",
            "J": "AFR"
        }
    ],
    "NNG": [
        {
            "B": "ZGNN",
            "C": "Nanning",
            "D": "Wuxu International",
            "E": "Peoples Republic of China",
            "F": "CN",
            "G": "O",
            "H": "",
            "I": "",
            "J": "SEA"
        }
    ],
    "NNR": [
        {
            "B": "EICA",
            "C": "CONNEMARA - MINNA",
            "D": "Connemara",
            "E": "Republic of Ireland",
            "F": "IE",
            "G": "D",
            "H": "",
            "I": "",
            "J": "EUR"
        }
    ],
    "NNY": [
        {
            "B": "ZHNY",
            "C": "Nanyang",
            "D": "Jiangying",
            "E": "Peoples Republic of China",
            "F": "CN",
            "G": "O",
            "H": "",
            "I": "",
            "J": "SEA"
        }
    ],
    "NOC": [
        {
            "B": "EIKN",
            "C": "IRELAND WEST KNOCK",
            "D": "Knock",
            "E": "Republic of Ireland",
            "F": "IE",
            "G": "D",
            "H": "",
            "I": "",
            "J": "EUR"
        }
    ],
    "NOD": [
        {
            "B": "EDWS",
            "C": "NORDEN-NORDDEICH",
            "D": "Norden Norddeich",
            "E": "Germany",
            "F": "DE",
            "G": "E",
            "H": "",
            "I": "",
            "J": "EUR"
        }
    ],
    "NOM": [
        {
            "B": "",
            "C": "NOMAD RIVER AIRPORT",
            "D": "NOMAD RIVER AIRPORT",
            "E": "Papua New Guinea",
            "F": "PG",
            "G": "O",
            "H": "",
            "I": "",
            "J": "SEA"
        }
    ],
    "NOS": [
        {
            "B": "FMNN",
            "C": "Fascene",
            "D": "Fascene",
            "E": "Madagascar",
            "F": "MG",
            "G": "O",
            "H": "",
            "I": "",
            "J": "AFR"
        }
    ],
    "NOU": [
        {
            "B": "NWWW",
            "C": "Tontouta",
            "D": "La Tontouta",
            "E": "New Caledonia",
            "F": "NC",
            "G": "O",
            "H": "",
            "I": "",
            "J": "SWP"
        }
    ],
    "NOV": [
        {
            "B": "FNHU",
            "C": "HUAMBO",
            "D": "Huambo",
            "E": "Angola",
            "F": "AO",
            "G": "O",
            "H": "",
            "I": "",
            "J": "AFR"
        }
    ],
    "NOZ": [
        {
            "B": "UNWW",
            "C": "Novokuznetsk",
            "D": "Spichenkovo",
            "E": "Russian Federation",
            "F": "RU",
            "G": "E",
            "H": "",
            "I": "",
            "J": "EUR"
        }
    ],
    "NPA": [
        {
            "B": "KNPA",
            "C": "PENSACOLA",
            "D": "Pensacola",
            "E": "USA",
            "F": "US",
            "G": "T",
            "H": "",
            "I": "",
            "J": "NOA"
        }
    ],
    "NPE": [
        {
            "B": "NZNR",
            "C": "Hawkes Bay",
            "D": "Hawkes Bay",
            "E": "New Zealand",
            "F": "NZ",
            "G": "O",
            "H": "",
            "I": "",
            "J": "SWP"
        }
    ],
    "NPL": [
        {
            "B": "NZNP",
            "C": "New Plymouth",
            "D": "New Plymouth",
            "E": "New Zealand",
            "F": "NZ",
            "G": "O",
            "H": "",
            "I": "",
            "J": "SWP"
        }
    ],
    "NQN": [
        {
            "B": "SAZN",
            "C": "NEUQUEN",
            "D": "Neuquen",
            "E": "Argentina",
            "F": "AR",
            "G": "T",
            "H": "",
            "I": "",
            "J": "SOA"
        }
    ],
    "NQT": [
        {
            "B": "EGBN",
            "C": "NOTTINGHAM",
            "D": "Nottingham",
            "E": "United Kingdom",
            "F": "GB",
            "G": "U",
            "H": "",
            "I": "",
            "J": "EUR"
        }
    ],
    "NQX": [
        {
            "B": "KNQX",
            "C": "KEY WEST-NAS",
            "D": "Key West",
            "E": "USA",
            "F": "US",
            "G": "T",
            "H": "",
            "I": "",
            "J": "NOA"
        }
    ],
    "NQY": [
        {
            "B": "EGHQ",
            "C": "NEWQUAY CORNWALL",
            "D": "Newquay",
            "E": "United Kingdom",
            "F": "GB",
            "G": "U",
            "H": "",
            "I": "",
            "J": "EUR"
        }
    ],
    "NQZ": [
        {
            "B": "UACC",
            "C": "NURSULTAN",
            "D": "Nursultan",
            "E": "Kazakhstan",
            "F": "KZ",
            "G": "O",
            "H": "",
            "I": "",
            "J": "SEA"
        }
    ],
    "NRD": [
        {
            "B": "EDWY",
            "C": "NORDERNEY",
            "D": "Norderney",
            "E": "Germany",
            "F": "DE",
            "G": "E",
            "H": "",
            "I": "",
            "J": "EUR"
        }
    ],
    "NRK": [
        {
            "B": "ESSP",
            "C": "NORRKOPING",
            "D": "Norrkoping",
            "E": "Sweden",
            "F": "SE",
            "G": "E",
            "H": "",
            "I": "",
            "J": "EUR"
        }
    ],
    "NRL": [
        {
            "B": "EGEN",
            "C": "NORTH RONALDSWAY",
            "D": "Ronaldsway",
            "E": "United Kingdom",
            "F": "GB",
            "G": "U",
            "H": "",
            "I": "",
            "J": "EUR"
        }
    ],
    "NRN": [
        {
            "B": "EDLV",
            "C": "NIEDERRHEIN",
            "D": "D?sseldorfWeeze",
            "E": "Germany",
            "F": "DE",
            "G": "E",
            "H": "",
            "I": "",
            "J": "EUR"
        }
    ],
    "NRR": [
        {
            "B": "TJRV",
            "C": "JOSE APONTE DE LA TORRE",
            "D": "Jose aponte de la torre",
            "E": "Puerto Rico",
            "F": "PR",
            "G": "T",
            "H": "",
            "I": "",
            "J": "CAR"
        }
    ],
    "NRT": [
        {
            "B": "RJAA",
            "C": "TOKYO NARITA",
            "D": "Tokyo",
            "E": "Japan",
            "F": "JP",
            "G": "O",
            "H": "",
            "I": "",
            "J": "JAK"
        }
    ],
    "NSF": [
        {
            "B": "KNSF",
            "C": "ANDREWS CAMP SPRINGS   MIL",
            "D": "Camp springs",
            "E": "USA",
            "F": "US",
            "G": "T",
            "H": "",
            "I": "",
            "J": "NOA"
        }
    ],
    "NSI": [
        {
            "B": "FKYS",
            "C": "YAOUNDE NSIMALEN",
            "D": "Yaounde Nsimalen International Airport",
            "E": "Cameroon",
            "F": "CM",
            "G": "O",
            "H": "",
            "I": "",
            "J": "AFR"
        }
    ],
    "NSN": [
        {
            "B": "NZNS",
            "C": "Nelson",
            "D": "Nelson",
            "E": "New Zealand",
            "F": "NZ",
            "G": "O",
            "H": "",
            "I": "",
            "J": "SWP"
        }
    ],
    "NSY": [
        {
            "B": "LICZ",
            "C": "SIGONELLA",
            "D": "Sigonella",
            "E": "Italy",
            "F": "IT",
            "G": "E",
            "H": "",
            "I": "",
            "J": "EUR"
        }
    ],
    "NTE": [
        {
            "B": "LFRS",
            "C": "NANTES ATLANTIQUE",
            "D": "Nantes",
            "E": "France",
            "F": "FR",
            "G": "E",
            "H": "",
            "I": "",
            "J": "EUR"
        }
    ],
    "NTL": [
        {
            "B": "YWLM",
            "C": "WILLIAMTOWN",
            "D": "Williamtown",
            "E": "Australia",
            "F": "AU",
            "G": "O",
            "H": "",
            "I": "",
            "J": "SWP"
        }
    ],
    "NTQ": [
        {
            "B": "RJNW",
            "C": "NOTO",
            "D": "Noto",
            "E": "Japan",
            "F": "JP",
            "G": "O",
            "H": "",
            "I": "",
            "J": "JAK"
        }
    ],
    "NUE": [
        {
            "B": "EDDN",
            "C": "NUREMBERG",
            "D": "Nuremberg",
            "E": "Germany",
            "F": "DE",
            "G": "E",
            "H": "",
            "I": "",
            "J": "EUR"
        }
    ],
    "NUQ": [
        {
            "B": "KNUQ",
            "C": "MOUNTAIN VIEW - MOFFET FIELD",
            "D": "Moffet field",
            "E": "USA",
            "F": "US",
            "G": "T",
            "H": "",
            "I": "",
            "J": "NOA"
        }
    ],
    "NUX": [
        {
            "B": "USMU",
            "C": "Novy Urengoy",
            "D": "Novy Urengoy",
            "E": "Russian Federation",
            "F": "RU",
            "G": "E",
            "H": "",
            "I": "",
            "J": "EUR"
        }
    ],
    "NVI": [
        {
            "B": "UTSA",
            "C": "NAVOI",
            "D": "Navoi international airport",
            "E": "Uzbekistan",
            "F": "UZ",
            "G": "O",
            "H": "",
            "I": "",
            "J": "SEA"
        }
    ],
    "NVK": [
        {
            "B": "ENNK",
            "C": "NARVIK",
            "D": "Narvik",
            "E": "Norway",
            "F": "NO",
            "G": "E",
            "H": "",
            "I": "",
            "J": "EUR"
        }
    ],
    "NVS": [
        {
            "B": "LFQG",
            "C": "NEVERS / FOURCHAMBAULT",
            "D": "Nevers",
            "E": "France",
            "F": "FR",
            "G": "E",
            "H": "",
            "I": "",
            "J": "EUR"
        }
    ],
    "NVT": [
        {
            "B": "SBNF",
            "C": "Navegantes",
            "D": "Victor Konder Intl",
            "E": "Brazil",
            "F": "BR",
            "G": "T",
            "H": "",
            "I": "",
            "J": "SOA"
        }
    ],
    "NWI": [
        {
            "B": "EGSH",
            "C": "NORWICH",
            "D": "Norwich",
            "E": "United Kingdom",
            "F": "GB",
            "G": "U",
            "H": "",
            "I": "",
            "J": "EUR"
        }
    ],
    "NYG": [
        {
            "B": "KNYG",
            "C": "MCAF Quantico",
            "D": "Marine corps airfield quantico(turner field",
            "E": "USA",
            "F": "US",
            "G": "T",
            "H": "",
            "I": "",
            "J": "NOA"
        }
    ],
    "NYO": [
        {
            "B": "ESKN",
            "C": "STOCKHOLM - SKAVSTA",
            "D": "Stockholm",
            "E": "Sweden",
            "F": "SE",
            "G": "E",
            "H": "",
            "I": "",
            "J": "EUR"
        }
    ],
    "NYT": [
        {
            "B": "VYNT",
            "C": "NAY PYI TAW",
            "D": "Nay Pyi Taw",
            "E": "Myanmar",
            "F": "MM",
            "G": "O",
            "H": "",
            "I": "",
            "J": "SEA"
        }
    ],
    "NZY": [
        {
            "B": "KNZY",
            "C": "SAN DIEGO - NORTH IS",
            "D": "San Diego",
            "E": "USA",
            "F": "US",
            "G": "T",
            "H": "",
            "I": "",
            "J": "NOA"
        }
    ],
    "OAI": [
        {
            "B": "OAIX",
            "C": "BAGRAM",
            "D": "Bagram",
            "E": "Afghanistan",
            "F": "AF",
            "G": "O",
            "H": "",
            "I": "",
            "J": "SAS"
        }
    ],
    "OAJ": [
        {
            "B": "KOAJ",
            "C": "Jacksonville, North Carolina",
            "D": "Albert J Ellis",
            "E": "USA",
            "F": "US",
            "G": "T",
            "H": "",
            "I": "",
            "J": "NOA"
        }
    ],
    "OAK": [
        {
            "B": "KOAK",
            "C": "OAKLAND INTERNATIONAL",
            "D": "Oakland",
            "E": "USA",
            "F": "US",
            "G": "T",
            "H": "",
            "I": "",
            "J": "NOA"
        }
    ],
    "OAX": [
        {
            "B": "MMOX",
            "C": "Xoxocotlan",
            "D": "Xoxocotlan Intl",
            "E": "Mexico",
            "F": "MX",
            "G": "T",
            "H": "",
            "I": "",
            "J": "CEM"
        }
    ],
    "OBF": [
        {
            "B": "EDMO",
            "C": "OBERPFAFFENHOFEN",
            "D": "Oberpfaffenhofen",
            "E": "Germany",
            "F": "DE",
            "G": "E",
            "H": "",
            "I": "",
            "J": "EUR"
        }
    ],
    "OBN": [
        {
            "B": "EGEO",
            "C": "OBAN",
            "D": "Oban",
            "E": "United Kingdom",
            "F": "GB",
            "G": "U",
            "H": "",
            "I": "",
            "J": "EUR"
        }
    ],
    "OBO": [
        {
            "B": "RJCB",
            "C": "Obihiro",
            "D": "Obihiro",
            "E": "Japan",
            "F": "JP",
            "G": "O",
            "H": "",
            "I": "",
            "J": "JAK"
        }
    ],
    "OBS": [
        {
            "B": "LFHO",
            "C": "AUBENAS-VALS-LANAS",
            "D": "Aubenas",
            "E": "France",
            "F": "FR",
            "G": "E",
            "H": "",
            "I": "",
            "J": "EUR"
        }
    ],
    "ODB": [
        {
            "B": "LEBA",
            "C": "CORDOBA",
            "D": "Cordoba",
            "E": "Spain",
            "F": "ES",
            "G": "E",
            "H": "",
            "I": "",
            "J": "EUR"
        }
    ],
    "ODE": [
        {
            "B": "EKOD",
            "C": "ODENSE",
            "D": "Odense",
            "E": "Denmark",
            "F": "DK",
            "G": "E",
            "H": "",
            "I": "",
            "J": "EUR"
        }
    ],
    "ODH": [
        {
            "B": "EGVO",
            "C": "ODIHAM",
            "D": "Odiham",
            "E": "United Kingdom",
            "F": "GB",
            "G": "U",
            "H": "",
            "I": "",
            "J": "EUR"
        }
    ],
    "ODS": [
        {
            "B": "UKOO",
            "C": "ODESSA",
            "D": "Odessa",
            "E": "Ukraine",
            "F": "UA",
            "G": "E",
            "H": "",
            "I": "",
            "J": "EUR"
        }
    ],
    "OER": [
        {
            "B": "ESNO",
            "C": "Ornskoldsvik",
            "D": "Ornskoldsvik",
            "E": "Sweden",
            "F": "SE",
            "G": "E",
            "H": "",
            "I": "",
            "J": "EUR"
        }
    ],
    "OEY": [
        {
            "B": "OERY",
            "C": "Riyadh Air Base",
            "D": "Riyadh/King Salman Air Base Airport",
            "E": "Saudi Arabia",
            "F": "SA",
            "G": "O",
            "H": "",
            "I": "",
            "J": "MDE"
        }
    ],
    "OFK": [
        {
            "B": "KOFK",
            "C": "NORFOLK",
            "D": "Norfolk",
            "E": "USA",
            "F": "US",
            "G": "T",
            "H": "",
            "I": "",
            "J": "NOA"
        }
    ],
    "OGG": [
        {
            "B": "PHOG",
            "C": "Kahului",
            "D": "Kahului",
            "E": "USA",
            "F": "US",
            "G": "T",
            "H": "",
            "I": "",
            "J": "NOA"
        }
    ],
    "OGS": [
        {
            "B": "KOGS",
            "C": "Ogdensburg",
            "D": "Ogdensburg International",
            "E": "USA",
            "F": "US",
            "G": "T",
            "H": "",
            "I": "",
            "J": "NOA"
        }
    ],
    "OGZ": [
        {
            "B": "URMO",
            "C": "Vladikavkaz",
            "D": "Vladikavkaz",
            "E": "Russian Federation",
            "F": "RU",
            "G": "E",
            "H": "",
            "I": "",
            "J": "EUR"
        }
    ],
    "OHD": [
        {
            "B": "LWOH",
            "C": "OHRID",
            "D": "Ohrid",
            "E": "FYR Macedonia",
            "F": "MK",
            "G": "E",
            "H": "",
            "I": "",
            "J": "EUR"
        }
    ],
    "OHR": [
        {
            "B": "EDXY",
            "C": "WYK AUF FOHR",
            "D": "Wyk Auf Fohr",
            "E": "Germany",
            "F": "DE",
            "G": "E",
            "H": "",
            "I": "",
            "J": "EUR"
        }
    ],
    "OHS": [
        {
            "B": "OOSH",
            "C": "SOHAR",
            "D": "Sohar",
            "E": "Oman",
            "F": "OM",
            "G": "O",
            "H": "",
            "I": "",
            "J": "MDE"
        }
    ],
    "OIT": [
        {
            "B": "RJFO",
            "C": "OITA",
            "D": "Oita",
            "E": "Japan",
            "F": "JP",
            "G": "O",
            "H": "",
            "I": "",
            "J": "JAK"
        }
    ],
    "OKA": [
        {
            "B": "ROAH",
            "C": "Okinawa",
            "D": "Naha",
            "E": "Japan",
            "F": "JP",
            "G": "O",
            "H": "",
            "I": "",
            "J": "JAK"
        }
    ],
    "OKC": [
        {
            "B": "KOKC",
            "C": "OKLAHOMA CITY - WILL ROGERS",
            "D": "Oklahoma",
            "E": "USA",
            "F": "US",
            "G": "T",
            "H": "",
            "I": "",
            "J": "NOA"
        }
    ],
    "OKD": [
        {
            "B": "RJCO",
            "C": "SAPPORA - OKADAMA",
            "D": "Sappora",
            "E": "Japan",
            "F": "JP",
            "G": "O",
            "H": "",
            "I": "",
            "J": "JAK"
        }
    ],
    "OKH": [
        {
            "B": "EGXJ",
            "C": "COTTESMORE",
            "D": "Cottesmore",
            "E": "United Kingdom",
            "F": "GB",
            "G": "U",
            "H": "",
            "I": "",
            "J": "EUR"
        }
    ],
    "OKJ": [
        {
            "B": "RJOB",
            "C": "OKAYAMA",
            "D": "Okayama",
            "E": "Japan",
            "F": "JP",
            "G": "O",
            "H": "",
            "I": "",
            "J": "JAK"
        }
    ],
    "OKO": [
        {
            "B": "RJTY",
            "C": "YOKOTA",
            "D": "Tokyo",
            "E": "Japan",
            "F": "JP",
            "G": "O",
            "H": "",
            "I": "",
            "J": "JAK"
        }
    ],
    "OLA": [
        {
            "B": "ENOL",
            "C": "ORLAND",
            "D": "Orland",
            "E": "Norway",
            "F": "NO",
            "G": "E",
            "H": "",
            "I": "",
            "J": "EUR"
        }
    ],
    "OLB": [
        {
            "B": "LIEO",
            "C": "OLBIA",
            "D": "Olbia",
            "E": "Italy",
            "F": "IT",
            "G": "E",
            "H": "",
            "I": "",
            "J": "EUR"
        }
    ],
    "OLO": [
        {
            "B": "LKOL",
            "C": "OLOMOUC",
            "D": "Olten",
            "E": "Czech Republic",
            "F": "CZ",
            "G": "E",
            "H": "",
            "I": "",
            "J": "EUR"
        }
    ],
    "OLU": [
        {
            "B": "KOLU",
            "C": "COLUMBUS",
            "D": "Columbus",
            "E": "USA",
            "F": "US",
            "G": "T",
            "H": "",
            "I": "",
            "J": "NOA"
        }
    ],
    "OMA": [
        {
            "B": "KOMA",
            "C": "OMAHA EPPLEY AIRFIELD",
            "D": "Omaha",
            "E": "USA",
            "F": "US",
            "G": "T",
            "H": "",
            "I": "",
            "J": "NOA"
        }
    ],
    "OMD": [
        {
            "B": "FYOG",
            "C": "Oranjemund",
            "D": "Oranjemund Airport",
            "E": "Namibia",
            "F": "NA",
            "G": "O",
            "H": "",
            "I": "",
            "J": "AFR"
        }
    ],
    "OME": [
        {
            "B": "PAOM",
            "C": "NOME",
            "D": "Nome",
            "E": "USA",
            "F": "US",
            "G": "T",
            "H": "",
            "I": "",
            "J": "NOA"
        }
    ],
    "OMH": [
        {
            "B": "OITR",
            "C": "Urumiyeh",
            "D": "Urumiyeh",
            "E": "Iran",
            "F": "IR",
            "G": "O",
            "H": "",
            "I": "",
            "J": "MDE"
        }
    ],
    "OMO": [
        {
            "B": "LQMO",
            "C": "MOSTAR",
            "D": "Mostar",
            "E": "Bosnia and Herzegovina",
            "F": "BA",
            "G": "E",
            "H": "",
            "I": "",
            "J": "EUR"
        }
    ],
    "OMR": [
        {
            "B": "LROD",
            "C": "ORADEA",
            "D": "Oradea",
            "E": "Romania",
            "F": "RO",
            "G": "E",
            "H": "",
            "I": "",
            "J": "EUR"
        }
    ],
    "OMS": [
        {
            "B": "UNOO",
            "C": "OMSK",
            "D": "Omsk",
            "E": "Russian Federation",
            "F": "RU",
            "G": "E",
            "H": "",
            "I": "",
            "J": "EUR"
        }
    ],
    "ONJ": [
        {
            "B": "RJSR",
            "C": "ODATE-NOSHIRO",
            "D": "Odate-Noshiro",
            "E": "Japan",
            "F": "JP",
            "G": "O",
            "H": "",
            "I": "",
            "J": "JAK"
        }
    ],
    "ONS": [
        {
            "B": "YOLW",
            "C": "ONSLOW",
            "D": "Onslow",
            "E": "Australia",
            "F": "AU",
            "G": "O",
            "H": "",
            "I": "",
            "J": "SWP"
        }
    ],
    "ONT": [
        {
            "B": "KONT",
            "C": "LA/ONTARIO INT",
            "D": "LA/ONTARIO INTERNATION AIRPORT",
            "E": "USA",
            "F": "US",
            "G": "T",
            "H": "",
            "I": "",
            "J": "NOA"
        }
    ],
    "OOL": [
        {
            "B": "YBCG",
            "C": "COOLANGATTA",
            "D": "Coolangatta",
            "E": "Australia",
            "F": "AU",
            "G": "O",
            "H": "",
            "I": "",
            "J": "SWP"
        }
    ],
    "OPF": [
        {
            "B": "KOPF",
            "C": "OPALOCKA",
            "D": "Opalocka",
            "E": "USA",
            "F": "US",
            "G": "T",
            "H": "",
            "I": "",
            "J": "NOA"
        }
    ],
    "OPO": [
        {
            "B": "LPPR",
            "C": "PORTO",
            "D": "Porto",
            "E": "Portugal",
            "F": "PT",
            "G": "E",
            "H": "",
            "I": "",
            "J": "EUR"
        }
    ],
    "ORB": [
        {
            "B": "ESOE",
            "C": "OREBRO-BOFORS",
            "D": "Orebro",
            "E": "Sweden",
            "F": "SE",
            "G": "E",
            "H": "",
            "I": "",
            "J": "EUR"
        }
    ],
    "ORD": [
        {
            "B": "KORD",
            "C": "CHICAGO - O'HARE",
            "D": "Chicago",
            "E": "USA",
            "F": "US",
            "G": "T",
            "H": "",
            "I": "",
            "J": "NOA"
        }
    ],
    "ORE": [
        {
            "B": "LFOJ",
            "C": "ORLEANS",
            "D": "Orleans",
            "E": "France",
            "F": "FR",
            "G": "E",
            "H": "",
            "I": "",
            "J": "EUR"
        }
    ],
    "ORF": [
        {
            "B": "KORF",
            "C": "NORFOLK INTERNATIONAL",
            "D": "NORFOLK INTERNATIONAL AIRPORT",
            "E": "USA",
            "F": "US",
            "G": "T",
            "H": "",
            "I": "",
            "J": "NOA"
        }
    ],
    "ORH": [
        {
            "B": "KORH",
            "C": "WORCESTER REGIONAL",
            "D": "WORCESTER REGIONAL AIRPORT",
            "E": "USA",
            "F": "US",
            "G": "T",
            "H": "",
            "I": "",
            "J": "NOA"
        }
    ],
    "ORK": [
        {
            "B": "EICK",
            "C": "CORK",
            "D": "Cork",
            "E": "Republic of Ireland",
            "F": "IE",
            "G": "D",
            "H": "",
            "I": "",
            "J": "EUR"
        }
    ],
    "ORL": [
        {
            "B": "KORL",
            "C": "ORLANDO EXEC",
            "D": "ORLANDO EXECUTIVE",
            "E": "USA",
            "F": "US",
            "G": "T",
            "H": "",
            "I": "",
            "J": "NOA"
        }
    ],
    "ORM": [
        {
            "B": "EGBK",
            "C": "NORTHAMPTON / SYWELL",
            "D": "Northampton",
            "E": "United Kingdom",
            "F": "GB",
            "G": "U",
            "H": "",
            "I": "",
            "J": "EUR"
        }
    ],
    "ORY": [
        {
            "B": "LFPO",
            "C": "PARIS - ORLY",
            "D": "Paris Orly",
            "E": "France",
            "F": "FR",
            "G": "E",
            "H": "",
            "I": "",
            "J": "EUR"
        }
    ],
    "OSD": [
        {
            "B": "ESPC",
            "C": "OSTERSUND",
            "D": "Ostersund",
            "E": "Sweden",
            "F": "SE",
            "G": "E",
            "H": "",
            "I": "",
            "J": "EUR"
        }
    ],
    "OSF": [
        {
            "B": "UUMO",
            "C": "MOSCOW - OSTAFYEVO",
            "D": "MOSCOW - OSTAFYEVO",
            "E": "Russian Federation",
            "F": "RU",
            "G": "E",
            "H": "",
            "I": "",
            "J": "EUR"
        }
    ],
    "OSH": [
        {
            "B": "KOSH",
            "C": "OSHKOSH",
            "D": "Oshkosh",
            "E": "USA",
            "F": "US",
            "G": "T",
            "H": "",
            "I": "",
            "J": "NOA"
        }
    ],
    "OSI": [
        {
            "B": "LDOS",
            "C": "OSIJEK",
            "D": "Osijek",
            "E": "Croatia",
            "F": "HR",
            "G": "E",
            "H": "",
            "I": "",
            "J": "EUR"
        }
    ],
    "OSL": [
        {
            "B": "ENGM",
            "C": "OSLO - GARDERMOEN",
            "D": "Oslo",
            "E": "Norway",
            "F": "NO",
            "G": "E",
            "H": "",
            "I": "",
            "J": "EUR"
        }
    ],
    "OSP": [
        {
            "B": "EPSK",
            "C": "SLUPSK / REDZIKOW",
            "D": "Slupsk Redzikow",
            "E": "Poland",
            "F": "PL",
            "G": "E",
            "H": "",
            "I": "",
            "J": "EUR"
        }
    ],
    "OSR": [
        {
            "B": "LKMT",
            "C": "OSTRAVA",
            "D": "Ostrava",
            "E": "Czech Republic",
            "F": "CZ",
            "G": "E",
            "H": "",
            "I": "",
            "J": "EUR"
        }
    ],
    "OSS": [
        {
            "B": "UAFO",
            "C": "Osh",
            "D": "Osh",
            "E": "Kyrgyzstan",
            "F": "KG",
            "G": "O",
            "H": "",
            "I": "",
            "J": "SEA"
        }
    ],
    "OST": [
        {
            "B": "EBOS",
            "C": "OOSTENDE",
            "D": "Ostend",
            "E": "Belgium",
            "F": "BE",
            "G": "E",
            "H": "",
            "I": "",
            "J": "EUR"
        }
    ],
    "OSU": [
        {
            "B": "KOSU",
            "C": "COLUMBUS - OHIO STATE",
            "D": "Columbus",
            "E": "USA",
            "F": "US",
            "G": "T",
            "H": "",
            "I": "",
            "J": "NOA"
        }
    ],
    "OSW": [
        {
            "B": "UWOR",
            "C": "Orsk",
            "D": "Orsk Airport",
            "E": "Russian Federation",
            "F": "RU",
            "G": "E",
            "H": "",
            "I": "",
            "J": "EUR"
        }
    ],
    "OSY": [
        {
            "B": "ENNM",
            "C": "Namsos",
            "D": "Namsos",
            "E": "Norway",
            "F": "NO",
            "G": "E",
            "H": "",
            "I": "",
            "J": "EUR"
        }
    ],
    "OTH": [
        {
            "B": "KOTH",
            "C": "NORTH BEND",
            "D": "North bend",
            "E": "USA",
            "F": "US",
            "G": "T",
            "H": "",
            "I": "",
            "J": "NOA"
        }
    ],
    "OTP": [
        {
            "B": "LROP",
            "C": "BUCHAREST - OTOPENI",
            "D": "Bucharest",
            "E": "Romania",
            "F": "RO",
            "G": "E",
            "H": "",
            "I": "",
            "J": "EUR"
        }
    ],
    "OTT": [
        {
            "B": "SJKB",
            "C": "Andre Maggi",
            "D": "Andre Maggi Airport",
            "E": "Brazil",
            "F": "BR",
            "G": "T",
            "H": "",
            "I": "",
            "J": "SOA"
        }
    ],
    "OTZ": [
        {
            "B": "PAOT",
            "C": "KOTZEBUE",
            "D": "Kotzebue/Ralph Wien Memorial .",
            "E": "USA",
            "F": "US",
            "G": "T",
            "H": "",
            "I": "",
            "J": "NOA"
        }
    ],
    "OUA": [
        {
            "B": "DFFD",
            "C": "OUAGADOUGOU",
            "D": "OUAGADOUGOU AIRPORT",
            "E": "Burkina Faso",
            "F": "",
            "G": "O",
            "H": "",
            "I": "",
            "J": ""
        }
    ],
    "OUD": [
        {
            "B": "GMFO",
            "C": "ANGADS",
            "D": "Angads",
            "E": "Morocco",
            "F": "MA",
            "G": "O",
            "H": "",
            "I": "",
            "J": "AFR"
        }
    ],
    "OUL": [
        {
            "B": "EFOU",
            "C": "OULU",
            "D": "Oulu",
            "E": "Finland",
            "F": "FI",
            "G": "E",
            "H": "",
            "I": "",
            "J": "EUR"
        }
    ],
    "OVB": [
        {
            "B": "UNNT",
            "C": "NOVOSIBIRSK - TOLMACHEVO",
            "D": "Novosibirsk",
            "E": "RUSSIAN FEDERATION",
            "F": "RU",
            "G": "E",
            "H": "",
            "I": "",
            "J": "EUR"
        }
    ],
    "OVD": [
        {
            "B": "LEAS",
            "C": "ASTURIAS",
            "D": "Asturias",
            "E": "Spain",
            "F": "ES",
            "G": "E",
            "H": "",
            "I": "",
            "J": "EUR"
        }
    ],
    "OXB": [
        {
            "B": "GCOV",
            "C": "OSVALDO VIEIRA INTL",
            "D": "Osvaldo Vieira International Airport",
            "E": "Guinea-Bissau",
            "F": "GW",
            "G": "O",
            "H": "",
            "I": "",
            "J": "AFR"
        }
    ],
    "OXC": [
        {
            "B": "KOXC",
            "C": "WATERBURY OXFORD",
            "D": "Waterbury oxford",
            "E": "USA",
            "F": "US",
            "G": "T",
            "H": "",
            "I": "",
            "J": "NOA"
        }
    ],
    "OXF": [
        {
            "B": "EGTK",
            "C": "OXFORD / KIDLINGTON",
            "D": "Oxford",
            "E": "United Kingdom",
            "F": "GB",
            "G": "U",
            "H": "",
            "I": "",
            "J": "EUR"
        }
    ],
    "OXQ": [
        {
            "B": "",
            "C": "OXFORD RAILWAY STATION",
            "D": "Oxford Railway Station",
            "E": "United Kingdom",
            "F": "GB",
            "G": "U",
            "H": "",
            "I": "",
            "J": "EUR"
        }
    ],
    "OZC": [
        {
            "B": "RPMO",
            "C": "Labo",
            "D": "Labo Airport",
            "E": "Philippines",
            "F": "PH",
            "G": "O",
            "H": "",
            "I": "",
            "J": "SEA"
        }
    ],
    "OZZ": [
        {
            "B": "GMMZ",
            "C": "OUARZAZATE",
            "D": "Ouarzazate",
            "E": "Morocco",
            "F": "MA",
            "G": "O",
            "H": "",
            "I": "",
            "J": "AFR"
        }
    ],
    "PAD": [
        {
            "B": "EDLP",
            "C": "PADERBORN",
            "D": "Paderborn",
            "E": "Germany",
            "F": "DE",
            "G": "E",
            "H": "",
            "I": "",
            "J": "EUR"
        }
    ],
    "PAE": [
        {
            "B": "KPAE",
            "C": "SNOHMISH COUNTY",
            "D": "Everett",
            "E": "USA",
            "F": "US",
            "G": "T",
            "H": "",
            "I": "",
            "J": "NOA"
        }
    ],
    "PAH": [
        {
            "B": "KPAH",
            "C": "Barkley Regional",
            "D": "Barkley Regional",
            "E": "USA",
            "F": "US",
            "G": "T",
            "H": "",
            "I": "",
            "J": "NOA"
        }
    ],
    "PAK": [
        {
            "B": "PHPA",
            "C": "PORT ALLEN",
            "D": "Port Allen",
            "E": "USA",
            "F": "US",
            "G": "T",
            "H": "",
            "I": "",
            "J": "NOA"
        }
    ],
    "PAP": [
        {
            "B": "MTPP",
            "C": "PORT-AU-PRINCE INTERNATIONAL",
            "D": "Haiti",
            "E": "Haiti",
            "F": "HT",
            "G": "T",
            "H": "",
            "I": "",
            "J": "CAR"
        }
    ],
    "PAS": [
        {
            "B": "LGPA",
            "C": "PAROS",
            "D": "Paros",
            "E": "Greece",
            "F": "GR",
            "G": "E",
            "H": "",
            "I": "",
            "J": "EUR"
        }
    ],
    "PAT": [
        {
            "B": "VEPT",
            "C": "PATNA",
            "D": "Patna",
            "E": "India",
            "F": "IN",
            "G": "O",
            "H": "",
            "I": "",
            "J": "SAS"
        }
    ],
    "PAU": [
        {
            "B": "VYPK",
            "C": "PAUK",
            "D": "Pauk",
            "E": "Myanmar",
            "F": "MM",
            "G": "O",
            "H": "",
            "I": "",
            "J": "SEA"
        }
    ],
    "PBC": [
        {
            "B": "MMPB",
            "C": "HERMANOS SERDAN INTL",
            "D": "HERMANOS SERDAN INTERNATIONAL AIRPORT",
            "E": "Mexico",
            "F": "MX",
            "G": "T",
            "H": "",
            "I": "",
            "J": "CEM"
        }
    ],
    "PBG": [
        {
            "B": "KPBG",
            "C": "PLATTSBURGH",
            "D": "Plattsburgh International Airport",
            "E": "USA",
            "F": "US",
            "G": "T",
            "H": "",
            "I": "",
            "J": "NOA"
        }
    ],
    "PBH": [
        {
            "B": "VQPR",
            "C": "PARO",
            "D": "Paro",
            "E": "Bhutan",
            "F": "BT",
            "G": "O",
            "H": "",
            "I": "",
            "J": "SEA"
        }
    ],
    "PBI": [
        {
            "B": "KPBI",
            "C": "WEST PALM BEACH INTERNATIONAL",
            "D": "Palm Beach",
            "E": "USA",
            "F": "US",
            "G": "T",
            "H": "",
            "I": "",
            "J": "NOA"
        }
    ],
    "PBM": [
        {
            "B": "SMJP",
            "C": "Zanderij Intl",
            "D": "Johan A. Pengel Intl",
            "E": "Suriname",
            "F": "SR",
            "G": "T",
            "H": "",
            "I": "",
            "J": "SOA"
        }
    ],
    "PBO": [
        {
            "B": "YPBO",
            "C": "Paraburdoo",
            "D": "Paraburdoo",
            "E": "Australia",
            "F": "AU",
            "G": "O",
            "H": "",
            "I": "",
            "J": "SWP"
        }
    ],
    "PCL": [
        {
            "B": "SPCL",
            "C": "DAVID ABENSUR RENGIFO",
            "D": "Captain David Abensur Rengifo",
            "E": "Peru",
            "F": "PE",
            "G": "T",
            "H": "",
            "I": "",
            "J": "SOA"
        }
    ],
    "PDG": [
        {
            "B": "WIMG",
            "C": "Tabing",
            "D": "Minangkabau",
            "E": "Indonesia",
            "F": "ID",
            "G": "O",
            "H": "",
            "I": "",
            "J": "SEA"
        }
    ],
    "PDK": [
        {
            "B": "KPDK",
            "C": "ATLANTA - DE KALB",
            "D": "Atlanta",
            "E": "USA",
            "F": "US",
            "G": "T",
            "H": "",
            "I": "",
            "J": "NOA"
        }
    ],
    "PDL": [
        {
            "B": "LPPD",
            "C": "PONTA DELGADA",
            "D": "Ponta Delgada",
            "E": "Portugal",
            "F": "PT",
            "G": "E",
            "H": "",
            "I": "",
            "J": "EUR"
        }
    ],
    "PDS": [
        {
            "B": "MMPG",
            "C": "PIEDRAS NEGRAS",
            "D": "Piedras negras",
            "E": "Mexico",
            "F": "MX",
            "G": "T",
            "H": "",
            "I": "",
            "J": "CEM"
        }
    ],
    "PDV": [
        {
            "B": "LBPD",
            "C": "PLOVDIV",
            "D": "Plovdiv",
            "E": "Bulgaria",
            "F": "BG",
            "G": "E",
            "H": "",
            "I": "",
            "J": "EUR"
        }
    ],
    "PDX": [
        {
            "B": "KPDX",
            "C": "PORTLAND - US",
            "D": "Portland",
            "E": "USA",
            "F": "US",
            "G": "T",
            "H": "",
            "I": "",
            "J": "NOA"
        }
    ],
    "PED": [
        {
            "B": "LKPD",
            "C": "PARDUBICE",
            "D": "Pardubice",
            "E": "Czech Republic",
            "F": "CZ",
            "G": "E",
            "H": "",
            "I": "",
            "J": "EUR"
        }
    ],
    "PEE": [
        {
            "B": "USPP",
            "C": "PERM BOLSHOE SAVINO",
            "D": "Perm Oblast",
            "E": "Russian Federation",
            "F": "RU",
            "G": "E",
            "H": "",
            "I": "",
            "J": "EUR"
        }
    ],
    "PEF": [
        {
            "B": "EDCP",
            "C": "PEENEMUNDE",
            "D": "Peenemunde",
            "E": "Germany",
            "F": "DE",
            "G": "E",
            "H": "",
            "I": "",
            "J": "EUR"
        }
    ],
    "PEG": [
        {
            "B": "LIRZ",
            "C": "PERUGIA",
            "D": "Perugia",
            "E": "Italy",
            "F": "IT",
            "G": "E",
            "H": "",
            "I": "",
            "J": "EUR"
        }
    ],
    "PEI": [
        {
            "B": "SKPE",
            "C": "Matecana",
            "D": "Matecana International",
            "E": "Colombia",
            "F": "CO",
            "G": "T",
            "H": "",
            "I": "",
            "J": "SOA"
        }
    ],
    "PEK": [
        {
            "B": "ZBAA",
            "C": "BEIJING",
            "D": "Beijing",
            "E": "Peoples Republic of China",
            "F": "CN",
            "G": "O",
            "H": "",
            "I": "",
            "J": "SEA"
        }
    ],
    "PEN": [
        {
            "B": "WMKP",
            "C": "Penang International",
            "D": "Penang International",
            "E": "Malaysia",
            "F": "MY",
            "G": "O",
            "H": "",
            "I": "",
            "J": "SEA"
        }
    ],
    "PER": [
        {
            "B": "YPPH",
            "C": "Perth",
            "D": "Perth International",
            "E": "Australia",
            "F": "AU",
            "G": "O",
            "H": "",
            "I": "",
            "J": "SWP"
        }
    ],
    "PEW": [
        {
            "B": "OPPS",
            "C": "Peshawar",
            "D": "Bacha Khan Intl",
            "E": "Pakistan",
            "F": "PK",
            "G": "O",
            "H": "",
            "I": "",
            "J": "SAS"
        }
    ],
    "PEZ": [
        {
            "B": "UWPP",
            "C": "PENZA/TERNOVKA",
            "D": "Penza/Ternovka",
            "E": "Russian Federation",
            "F": "RU",
            "G": "E",
            "H": "",
            "I": "",
            "J": "EUR"
        }
    ],
    "PFB": [
        {
            "B": "SBPF",
            "C": "PASSO FUNDO/LAURO KURTZ",
            "D": "Passo Fundo/Lauro Kurtz",
            "E": "Brazil",
            "F": "BR",
            "G": "T",
            "H": "",
            "I": "",
            "J": "SOA"
        }
    ],
    "PFN": [
        {
            "B": "KPFN",
            "C": "BAY COUNTY",
            "D": "Bay county",
            "E": "USA",
            "F": "US",
            "G": "T",
            "H": "",
            "I": "",
            "J": "NOA"
        }
    ],
    "PFO": [
        {
            "B": "LCPH",
            "C": "PAPHOS",
            "D": "Paphos",
            "E": "Cyprus",
            "F": "CY",
            "G": "E",
            "H": "",
            "I": "",
            "J": "EUR"
        }
    ],
    "PGA": [
        {
            "B": "KPGA",
            "C": "Page, Arizona",
            "D": "Page Municipal",
            "E": "USA",
            "F": "US",
            "G": "T",
            "H": "",
            "I": "",
            "J": "NOA"
        }
    ],
    "PGF": [
        {
            "B": "LFMP",
            "C": "PERPIGNAN",
            "D": "Perpignan",
            "E": "France",
            "F": "FR",
            "G": "E",
            "H": "",
            "I": "",
            "J": "EUR"
        }
    ],
    "PGK": [
        {
            "B": "WIPK",
            "C": "PANGKAL PINANG",
            "D": "Pangkal Pinang/Depati Amir",
            "E": "Indonesia",
            "F": "ID",
            "G": "O",
            "H": "",
            "I": "",
            "J": "SEA"
        }
    ],
    "PGV": [
        {
            "B": "KPGV",
            "C": "Greenville, North Carolina",
            "D": "Pitt-Greenville",
            "E": "USA",
            "F": "US",
            "G": "T",
            "H": "",
            "I": "",
            "J": "NOA"
        }
    ],
    "PGX": [
        {
            "B": "LFBX",
            "C": "PERIGUEUX BASSILLAC",
            "D": "Perigueux",
            "E": "France",
            "F": "FR",
            "G": "E",
            "H": "",
            "I": "",
            "J": "EUR"
        }
    ],
    "PHC": [
        {
            "B": "DNPO",
            "C": "PORT HARCOURT",
            "D": "",
            "E": "Nigeria",
            "F": "",
            "G": "O",
            "H": "",
            "I": "",
            "J": ""
        }
    ],
    "PHE": [
        {
            "B": "YPPD",
            "C": "PORT HEDLAND",
            "D": "Port hedland",
            "E": "Australia",
            "F": "AU",
            "G": "O",
            "H": "",
            "I": "",
            "J": "SWP"
        }
    ],
    "PHF": [
        {
            "B": "KPHF",
            "C": "NEWPORT NEWS",
            "D": "Newport News",
            "E": "USA",
            "F": "US",
            "G": "T",
            "H": "",
            "I": "",
            "J": "NOA"
        }
    ],
    "PHL": [
        {
            "B": "KPHL",
            "C": "PHILADELPHIA INTERNATIONAL",
            "D": "Philadelphia",
            "E": "USA",
            "F": "US",
            "G": "T",
            "H": "",
            "I": "",
            "J": "NOA"
        }
    ],
    "PHW": [
        {
            "B": "FAPH",
            "C": "Phalaborwa",
            "D": "Phalaborwa Arpt",
            "E": "South Africa",
            "F": "ZA",
            "G": "O",
            "H": "",
            "I": "",
            "J": "AFR"
        }
    ],
    "PHX": [
        {
            "B": "KPHX",
            "C": "PHOENIX",
            "D": "Phoenix",
            "E": "USA",
            "F": "US",
            "G": "T",
            "H": "",
            "I": "",
            "J": "NOA"
        }
    ],
    "PIA": [
        {
            "B": "KPIA",
            "C": "Greater Peoria",
            "D": "General Downing International",
            "E": "USA",
            "F": "US",
            "G": "T",
            "H": "",
            "I": "",
            "J": "NOA"
        }
    ],
    "PIH": [
        {
            "B": "KPIH",
            "C": "Pocatello",
            "D": "Pocatello/Regional",
            "E": "USA",
            "F": "US",
            "G": "T",
            "H": "",
            "I": "",
            "J": "NOA"
        }
    ],
    "PIK": [
        {
            "B": "EGPK",
            "C": "GLASGOW PIK",
            "D": "Glasgow PIK",
            "E": "United Kingdom",
            "F": "GB",
            "G": "U",
            "H": "",
            "I": "",
            "J": "EUR"
        }
    ],
    "PIR": [
        {
            "B": "KPIR",
            "C": "PIERRE/MUNI",
            "D": "Pierre/Muni",
            "E": "USA",
            "F": "US",
            "G": "T",
            "H": "",
            "I": "",
            "J": "NOA"
        }
    ],
    "PIS": [
        {
            "B": "LFBI",
            "C": "POITIERS",
            "D": "Poitiers",
            "E": "France",
            "F": "FR",
            "G": "E",
            "H": "",
            "I": "",
            "J": "EUR"
        }
    ],
    "PIT": [
        {
            "B": "KPIT",
            "C": "PITTSBURGH INTERNATIONAL",
            "D": "Pittsburgh",
            "E": "USA",
            "F": "US",
            "G": "T",
            "H": "",
            "I": "",
            "J": "NOA"
        }
    ],
    "PIX": [
        {
            "B": "LPPI",
            "C": "PICO ISLAND ACORES",
            "D": "Pico",
            "E": "Portugal",
            "F": "PT",
            "G": "E",
            "H": "",
            "I": "",
            "J": "EUR"
        }
    ],
    "PKB": [
        {
            "B": "KPKB",
            "C": "Wood County",
            "D": "Mid-Ohio Valley Regional",
            "E": "USA",
            "F": "US",
            "G": "T",
            "H": "",
            "I": "",
            "J": "NOA"
        }
    ],
    "PKH": [
        {
            "B": "LGHL",
            "C": "PORTO KHELI / ALEXION",
            "D": "Alexion",
            "E": "Greece",
            "F": "GR",
            "G": "E",
            "H": "",
            "I": "",
            "J": "EUR"
        }
    ],
    "PKU": [
        {
            "B": "WIBB",
            "C": "PEKANBARU",
            "D": "Pekanbaru/Sultan Syarif Kasim Ii",
            "E": "Indonesia",
            "F": "ID",
            "G": "O",
            "H": "",
            "I": "",
            "J": "SEA"
        }
    ],
    "PKX": [
        {
            "B": "ZBAD",
            "C": "Beijing Daxing",
            "D": "Beijing Daxing",
            "E": "Peoples Republic of China",
            "F": "CN",
            "G": "O",
            "H": "",
            "I": "",
            "J": "SEA"
        }
    ],
    "PKY": [
        {
            "B": "WAOP",
            "C": "Palangkaraya",
            "D": "Tjilik Riwut",
            "E": "Indonesia",
            "F": "ID",
            "G": "O",
            "H": "",
            "I": "",
            "J": "SEA"
        }
    ],
    "PKZ": [
        {
            "B": "VLPS",
            "C": "PAKSE",
            "D": "Pakse",
            "E": "Laos",
            "F": "LA",
            "G": "O",
            "H": "",
            "I": "",
            "J": "SEA"
        }
    ],
    "PLH": [
        {
            "B": "EGHD",
            "C": "PLYMOUTH",
            "D": "Plymouth",
            "E": "United Kingdom",
            "F": "GB",
            "G": "U",
            "H": "",
            "I": "",
            "J": "EUR"
        }
    ],
    "PLN": [
        {
            "B": "KPLN",
            "C": "Emmet County",
            "D": "Regional (Emmet County)",
            "E": "USA",
            "F": "US",
            "G": "T",
            "H": "",
            "I": "",
            "J": "NOA"
        }
    ],
    "PLO": [
        {
            "B": "YPLC",
            "C": "Port Lincoln",
            "D": "Port Lincoln",
            "E": "Australia",
            "F": "AU",
            "G": "O",
            "H": "",
            "I": "",
            "J": "SWP"
        }
    ],
    "PLQ": [
        {
            "B": "EYPA",
            "C": "PALANGA INTERNATIONAL",
            "D": "Palanga",
            "E": "Lithuania",
            "F": "LT",
            "G": "E",
            "H": "",
            "I": "",
            "J": "EUR"
        }
    ],
    "PLS": [
        {
            "B": "MBPV",
            "C": "PROVIDENCIALES",
            "D": "Providenciales",
            "E": "Turks And Caicos Islands",
            "F": "TC",
            "G": "T",
            "H": "",
            "I": "",
            "J": "CAR"
        }
    ],
    "PLV": [
        {
            "B": "",
            "C": "",
            "D": "",
            "E": "UNKNOWN",
            "F": "",
            "G": "A",
            "H": "",
            "I": "",
            "J": ""
        }
    ],
    "PLW": [
        {
            "B": "WAML",
            "C": "PALU/MUTIARA",
            "D": "Palu/Mutiara",
            "E": "Indonesia",
            "F": "ID",
            "G": "O",
            "H": "",
            "I": "",
            "J": "SEA"
        }
    ],
    "PLX": [
        {
            "B": "UASS",
            "C": "Semipalatinsk",
            "D": "Semey",
            "E": "Kazakhstan",
            "F": "KZ",
            "G": "O",
            "H": "",
            "I": "",
            "J": "SEA"
        }
    ],
    "PLZ": [
        {
            "B": "FAPE",
            "C": "Port Elizabeth",
            "D": "Port Elizabeth Arpt",
            "E": "South Africa",
            "F": "ZA",
            "G": "O",
            "H": "",
            "I": "",
            "J": "AFR"
        }
    ],
    "PMC": [
        {
            "B": "SCTE",
            "C": "Tepual",
            "D": "El Tepual",
            "E": "Chile",
            "F": "CL",
            "G": "T",
            "H": "",
            "I": "",
            "J": "SOA"
        }
    ],
    "PMD": [
        {
            "B": "KPMD",
            "C": "PALMDALE AFB",
            "D": "Palmdale",
            "E": "USA",
            "F": "US",
            "G": "T",
            "H": "",
            "I": "",
            "J": "NOA"
        }
    ],
    "PME": [
        {
            "B": "EGVF",
            "C": "PORTSMOUTH - FLEETLANDS",
            "D": "Portsmouth",
            "E": "United Kingdom",
            "F": "GB",
            "G": "U",
            "H": "",
            "I": "",
            "J": "EUR"
        }
    ],
    "PMF": [
        {
            "B": "LIMP",
            "C": "PARMA",
            "D": "Giuseppe Verdi",
            "E": "Italy",
            "F": "IT",
            "G": "E",
            "H": "",
            "I": "",
            "J": "EUR"
        }
    ],
    "PMI": [
        {
            "B": "LEPA",
            "C": "PALMA",
            "D": "Palma",
            "E": "Spain",
            "F": "ES",
            "G": "E",
            "H": "",
            "I": "",
            "J": "EUR"
        }
    ],
    "PMO": [
        {
            "B": "LICJ",
            "C": "PALERMO",
            "D": "Palermo",
            "E": "Italy",
            "F": "IT",
            "G": "E",
            "H": "",
            "I": "",
            "J": "EUR"
        }
    ],
    "PMR": [
        {
            "B": "NZPM",
            "C": "Palmerston North",
            "D": "Palmerston North",
            "E": "New Zealand",
            "F": "NZ",
            "G": "O",
            "H": "",
            "I": "",
            "J": "SWP"
        }
    ],
    "PMV": [
        {
            "B": "SVMG",
            "C": "DelCaribe Gen S Marino",
            "D": "Del Caribe International",
            "E": "Venezuela",
            "F": "VE",
            "G": "T",
            "H": "",
            "I": "",
            "J": "SOA"
        }
    ],
    "PMW": [
        {
            "B": "SBPJ",
            "C": "Palmas",
            "D": "Lysias Rodrigues Airport",
            "E": "Brazil",
            "F": "BR",
            "G": "T",
            "H": "",
            "I": "",
            "J": "SOA"
        }
    ],
    "PNA": [
        {
            "B": "LEPP",
            "C": "PAMPLONA",
            "D": "Pamplona",
            "E": "Spain",
            "F": "ES",
            "G": "E",
            "H": "",
            "I": "",
            "J": "EUR"
        }
    ],
    "PNE": [
        {
            "B": "KPNE",
            "C": "NORTH PHILADELPHIA",
            "D": "North philadelphia",
            "E": "USA",
            "F": "US",
            "G": "T",
            "H": "",
            "I": "",
            "J": "NOA"
        }
    ],
    "PNG": [
        {
            "B": "SSPG",
            "C": "Paranagua",
            "D": "Paranagua Airport",
            "E": "Brazil",
            "F": "BR",
            "G": "T",
            "H": "",
            "I": "",
            "J": "SOA"
        }
    ],
    "PNH": [
        {
            "B": "VDPP",
            "C": "Pochentong",
            "D": "Phnom Penh",
            "E": "Cambodia",
            "F": "KH",
            "G": "O",
            "H": "",
            "I": "",
            "J": "SEA"
        }
    ],
    "PNI": [
        {
            "B": "PTPN",
            "C": "Pohnpei",
            "D": "Pohnpei International",
            "E": "Micronesia (Federated States of)",
            "F": "FM",
            "G": "O",
            "H": "",
            "I": "",
            "J": "SWP"
        }
    ],
    "PNL": [
        {
            "B": "LICG",
            "C": "PANTELLERIA",
            "D": "Pantelleria",
            "E": "Italy",
            "F": "IT",
            "G": "E",
            "H": "",
            "I": "",
            "J": "EUR"
        }
    ],
    "PNQ": [
        {
            "B": "VAPO",
            "C": "PUNE",
            "D": "PUNE AIRPORT",
            "E": "India",
            "F": "IN",
            "G": "O",
            "H": "",
            "I": "",
            "J": "SAS"
        }
    ],
    "PNR": [
        {
            "B": "FCPP",
            "C": "Pointe Noire",
            "D": "Pointe Noire",
            "E": "Congo",
            "F": "CG",
            "G": "O",
            "H": "",
            "I": "",
            "J": "AFR"
        }
    ],
    "PNS": [
        {
            "B": "KPNS",
            "C": "Regional",
            "D": "Pensacola International",
            "E": "USA",
            "F": "US",
            "G": "T",
            "H": "",
            "I": "",
            "J": "NOA"
        }
    ],
    "POA": [
        {
            "B": "SBPA",
            "C": "Salgado Filho",
            "D": "Salgado Filho Intl",
            "E": "Brazil",
            "F": "BR",
            "G": "T",
            "H": "",
            "I": "",
            "J": "SOA"
        }
    ],
    "POL": [
        {
            "B": "FQPB",
            "C": "PEMBA AIRPORT",
            "D": "Pemba",
            "E": "Mozambique",
            "F": "MZ",
            "G": "O",
            "H": "",
            "I": "",
            "J": "AFR"
        }
    ],
    "POM": [
        {
            "B": "AYPY",
            "C": "Jackson Fld",
            "D": "Jackson Field",
            "E": "Papua New Guinea",
            "F": "PG",
            "G": "O",
            "H": "",
            "I": "",
            "J": "SEA"
        }
    ],
    "POP": [
        {
            "B": "MDPP",
            "C": "PUERTO PLATA",
            "D": "Puerto Plata",
            "E": "Dominican Republic",
            "F": "DO",
            "G": "T",
            "H": "",
            "I": "",
            "J": "CAR"
        }
    ],
    "POR": [
        {
            "B": "EFPO",
            "C": "PORI",
            "D": "Pori",
            "E": "Finland",
            "F": "FI",
            "G": "E",
            "H": "",
            "I": "",
            "J": "EUR"
        }
    ],
    "POS": [
        {
            "B": "TTPP",
            "C": "PIARCO TRINIDAD",
            "D": "Piarco",
            "E": "Trinidad And Tobago",
            "F": "TT",
            "G": "T",
            "H": "",
            "I": "",
            "J": "CAR"
        }
    ],
    "POV": [
        {
            "B": "LZPW",
            "C": "PRESOV",
            "D": "Presov",
            "E": "Slovakia",
            "F": "SK",
            "G": "E",
            "H": "",
            "I": "",
            "J": "EUR"
        }
    ],
    "POW": [
        {
            "B": "LJPZ",
            "C": "PORTOROZ",
            "D": "Portoroz",
            "E": "Slovenia",
            "F": "SI",
            "G": "E",
            "H": "",
            "I": "",
            "J": "EUR"
        }
    ],
    "POX": [
        {
            "B": "LFPT",
            "C": "PONTOISE",
            "D": "Pontoise",
            "E": "France",
            "F": "FR",
            "G": "E",
            "H": "",
            "I": "",
            "J": "EUR"
        }
    ],
    "POZ": [
        {
            "B": "EPPO",
            "C": "POZNAN LAWICA",
            "D": "Poznan",
            "E": "Poland",
            "F": "PL",
            "G": "E",
            "H": "",
            "I": "",
            "J": "EUR"
        }
    ],
    "PPB": [
        {
            "B": "SBDN",
            "C": "A. De Barros",
            "D": "Presidente Prudente",
            "E": "Brazil",
            "F": "BR",
            "G": "T",
            "H": "",
            "I": "",
            "J": "SOA"
        }
    ],
    "PPP": [
        {
            "B": "YBPN",
            "C": "PROSERPINE",
            "D": "Proserpine",
            "E": "Australia",
            "F": "AU",
            "G": "O",
            "H": "",
            "I": "",
            "J": "SWP"
        }
    ],
    "PPQ": [
        {
            "B": "NZPP",
            "C": "Paraparaumu",
            "D": "Paraparaumu",
            "E": "New Zealand",
            "F": "NZ",
            "G": "O",
            "H": "",
            "I": "",
            "J": "SWP"
        }
    ],
    "PPS": [
        {
            "B": "RPVP",
            "C": "Puerto Princesa",
            "D": "Puerto Princesa International",
            "E": "Philippines",
            "F": "PH",
            "G": "O",
            "H": "",
            "I": "",
            "J": "SEA"
        }
    ],
    "PPT": [
        {
            "B": "NTAA",
            "C": "Fa'a'ā International",
            "D": "Faaa",
            "E": "French Polynesia",
            "F": "PF",
            "G": "O",
            "H": "",
            "I": "",
            "J": "SWP"
        }
    ],
    "PPW": [
        {
            "B": "EGEP",
            "C": "PAPA WESTRAY",
            "D": "Papa Westray",
            "E": "United Kingdom",
            "F": "GB",
            "G": "U",
            "H": "",
            "I": "",
            "J": "EUR"
        }
    ],
    "PQC": [
        {
            "B": "VVPQ",
            "C": "Duong Dang",
            "D": "Phu Quoc Island International",
            "E": "Vietnam",
            "F": "VN",
            "G": "O",
            "H": "",
            "I": "",
            "J": "SEA"
        }
    ],
    "PQI": [
        {
            "B": "KPQI",
            "C": "Municipal",
            "D": "Northern Maine Regional",
            "E": "USA",
            "F": "US",
            "G": "T",
            "H": "",
            "I": "",
            "J": "NOA"
        }
    ],
    "PQQ": [
        {
            "B": "YPMQ",
            "C": "Port Macquarie",
            "D": "Wauchope",
            "E": "Australia",
            "F": "AU",
            "G": "O",
            "H": "",
            "I": "",
            "J": "SWP"
        }
    ],
    "PRC": [
        {
            "B": "KPRC",
            "C": "Prescott",
            "D": "Ernest A. Love Field",
            "E": "USA",
            "F": "US",
            "G": "T",
            "H": "",
            "I": "",
            "J": "NOA"
        }
    ],
    "PRG": [
        {
            "B": "LKPR",
            "C": "PRAGUE",
            "D": "Prague",
            "E": "Czech Republic",
            "F": "CZ",
            "G": "E",
            "H": "",
            "I": "",
            "J": "EUR"
        }
    ],
    "PRI": [
        {
            "B": "FSPP",
            "C": "Praslin Island",
            "D": "Praslin Island",
            "E": "Seychelles",
            "F": "SC",
            "G": "O",
            "H": "",
            "I": "",
            "J": "AFR"
        }
    ],
    "PRJ": [
        {
            "B": "LIQC",
            "C": "CAPRI",
            "D": "Capri",
            "E": "Italy",
            "F": "IT",
            "G": "E",
            "H": "",
            "I": "",
            "J": "EUR"
        }
    ],
    "PRM": [
        {
            "B": "LPPM",
            "C": "PORTIMAO",
            "D": "Portimao",
            "E": "Portugal",
            "F": "PT",
            "G": "E",
            "H": "",
            "I": "",
            "J": "EUR"
        }
    ],
    "PRN": [
        {
            "B": "LYPR",
            "C": "PRISTINA",
            "D": "Pristina",
            "E": "SERBIA",
            "F": "CS",
            "G": "E",
            "H": "",
            "I": "",
            "J": "EUR"
        }
    ],
    "PRP": [
        {
            "B": "LFKO",
            "C": "PROPRIANO",
            "D": "Propriano",
            "E": "France",
            "F": "FR",
            "G": "E",
            "H": "",
            "I": "",
            "J": "EUR"
        }
    ],
    "PRS": [
        {
            "B": "AGGP",
            "C": "PARASI",
            "D": "Parasi",
            "E": "Solomon Islands",
            "F": "SB",
            "G": "O",
            "H": "",
            "I": "",
            "J": "SWP"
        }
    ],
    "PRV": [
        {
            "B": "LKPO",
            "C": "PREROV",
            "D": "Prerov",
            "E": "Czech Republic",
            "F": "CZ",
            "G": "E",
            "H": "",
            "I": "",
            "J": "EUR"
        }
    ],
    "PRY": [
        {
            "B": "FAWB",
            "C": "PRETORIA",
            "D": "Pretoria",
            "E": "South Africa",
            "F": "ZA",
            "G": "O",
            "H": "",
            "I": "",
            "J": "AFR"
        }
    ],
    "PSA": [
        {
            "B": "LIRP",
            "C": "PISA",
            "D": "Pisa Florence",
            "E": "Italy",
            "F": "IT",
            "G": "E",
            "H": "",
            "I": "",
            "J": "EUR"
        }
    ],
    "PSC": [
        {
            "B": "KPSC",
            "C": "Pasco, Washington",
            "D": "Tri-Cities",
            "E": "USA",
            "F": "US",
            "G": "T",
            "H": "",
            "I": "",
            "J": "NOA"
        }
    ],
    "PSD": [
        {
            "B": "HEPS",
            "C": "PORT SAID",
            "D": "Port Said",
            "E": "Egypt",
            "F": "EG",
            "G": "O",
            "H": "",
            "I": "",
            "J": "MDE"
        }
    ],
    "PSE": [
        {
            "B": "TJPS",
            "C": "Mercedita",
            "D": "Mercedita",
            "E": "Puerto Rico",
            "F": "PR",
            "G": "T",
            "H": "",
            "I": "",
            "J": "NOA"
        }
    ],
    "PSG": [
        {
            "B": "PAPG",
            "C": "PETERSBURG",
            "D": "Petersburg James a Johnson",
            "E": "USA",
            "F": "US",
            "G": "T",
            "H": "",
            "I": "",
            "J": "NOA"
        }
    ],
    "PSH": [
        {
            "B": "EDXO",
            "C": "ST. PETER-ORDING",
            "D": "St Peter Ording",
            "E": "Germany",
            "F": "DE",
            "G": "E",
            "H": "",
            "I": "",
            "J": "EUR"
        }
    ],
    "PSL": [
        {
            "B": "EGPT",
            "C": "PERTH",
            "D": "Perth",
            "E": "United Kingdom",
            "F": "GB",
            "G": "U",
            "H": "",
            "I": "",
            "J": "EUR"
        }
    ],
    "PSM": [
        {
            "B": "KPSM",
            "C": "PORTSMOUTH / PEASE INT",
            "D": "Portsmouth",
            "E": "USA",
            "F": "US",
            "G": "T",
            "H": "",
            "I": "",
            "J": "NOA"
        }
    ],
    "PSO": [
        {
            "B": "SKPS",
            "C": "Cano",
            "D": "Antonio Narino",
            "E": "Colombia",
            "F": "CO",
            "G": "T",
            "H": "",
            "I": "",
            "J": "SOA"
        }
    ],
    "PSP": [
        {
            "B": "KPSP",
            "C": "PALM SPRINGS",
            "D": "PALM SPRINGS INTL AIRPORT",
            "E": "USA",
            "F": "US",
            "G": "T",
            "H": "",
            "I": "",
            "J": "NOA"
        }
    ],
    "PSR": [
        {
            "B": "LIBP",
            "C": "PESCARA",
            "D": "Pescara",
            "E": "Italy",
            "F": "IT",
            "G": "E",
            "H": "",
            "I": "",
            "J": "EUR"
        }
    ],
    "PTG": [
        {
            "B": "FAPI",
            "C": "Pietersburg",
            "D": "Polokwane International",
            "E": "South Africa",
            "F": "ZA",
            "G": "O",
            "H": "",
            "I": "",
            "J": "AFR"
        }
    ],
    "PTK": [
        {
            "B": "KPTK",
            "C": "PONTIAC-OAKLAND",
            "D": "Pontiac-oakland",
            "E": "USA",
            "F": "US",
            "G": "T",
            "H": "",
            "I": "",
            "J": "NOA"
        }
    ],
    "PTP": [
        {
            "B": "TFFR",
            "C": "POINTE A PITRE - LE RAIZET",
            "D": "Pointe a pitre",
            "E": "Guadeloupe",
            "F": "GP",
            "G": "T",
            "H": "",
            "I": "",
            "J": "CAR"
        }
    ],
    "PTY": [
        {
            "B": "MPTO",
            "C": "PANAMA TOCUMEN",
            "D": "Panama",
            "E": "Panama",
            "F": "PA",
            "G": "T",
            "H": "",
            "I": "",
            "J": "CEM"
        }
    ],
    "PUF": [
        {
            "B": "LFBP",
            "C": "PAU / PYRENEES",
            "D": "Pau",
            "E": "France",
            "F": "FR",
            "G": "E",
            "H": "",
            "I": "",
            "J": "EUR"
        }
    ],
    "PUJ": [
        {
            "B": "MDPC",
            "C": "PUNTA CANA",
            "D": "Punta Cana",
            "E": "Dominican Republic",
            "F": "DO",
            "G": "T",
            "H": "",
            "I": "",
            "J": "CAR"
        }
    ],
    "PUQ": [
        {
            "B": "SCCI",
            "C": "Pres Ibanez",
            "D": "C.Ibanez del Campo",
            "E": "Chile",
            "F": "CL",
            "G": "T",
            "H": "",
            "I": "",
            "J": "SOA"
        }
    ],
    "PUS": [
        {
            "B": "RKPK",
            "C": "GIMHAE INTERNATIONAL",
            "D": "Pusan",
            "E": "Republic of Korea",
            "F": "KR",
            "G": "O",
            "H": "",
            "I": "",
            "J": "JAK"
        }
    ],
    "PUW": [
        {
            "B": "KPUW",
            "C": "Pullman, Washington",
            "D": "Moscow Regional",
            "E": "USA",
            "F": "US",
            "G": "T",
            "H": "",
            "I": "",
            "J": "NOA"
        }
    ],
    "PUY": [
        {
            "B": "LDPL",
            "C": "PULA",
            "D": "Pula",
            "E": "Croatia",
            "F": "HR",
            "G": "E",
            "H": "",
            "I": "",
            "J": "EUR"
        }
    ],
    "PVD": [
        {
            "B": "KPVD",
            "C": "PROVIDENCE RHODE ISLAND",
            "D": "Providence",
            "E": "USA",
            "F": "US",
            "G": "T",
            "H": "",
            "I": "",
            "J": "NOA"
        }
    ],
    "PVG": [
        {
            "B": "ZSPD",
            "C": "SHANGHAI PU DONG",
            "D": "Shanghai",
            "E": "Peoples Republic of China",
            "F": "CN",
            "G": "O",
            "H": "",
            "I": "",
            "J": "SEA"
        }
    ],
    "PVH": [
        {
            "B": "SBPV",
            "C": "Belmonte",
            "D": "J.T. de Oliveira Intl",
            "E": "Brazil",
            "F": "BR",
            "G": "T",
            "H": "",
            "I": "",
            "J": "SOA"
        }
    ],
    "PVK": [
        {
            "B": "LGPZ",
            "C": "PREVEZA / AKTION",
            "D": "Aktion",
            "E": "Greece",
            "F": "GR",
            "G": "E",
            "H": "",
            "I": "",
            "J": "EUR"
        }
    ],
    "PVR": [
        {
            "B": "MMPR",
            "C": "PUERTA VALLARTA",
            "D": "Puerto vallarta",
            "E": "Mexico",
            "F": "MX",
            "G": "T",
            "H": "",
            "I": "",
            "J": "CEM"
        }
    ],
    "PWK": [
        {
            "B": "KPWK",
            "C": "CHICAGO/ WHEELING - PAL-WAUKEE",
            "D": "Pal-waukee",
            "E": "USA",
            "F": "US",
            "G": "T",
            "H": "",
            "I": "",
            "J": "NOA"
        }
    ],
    "PWM": [
        {
            "B": "KPWM",
            "C": "PORTLAND INTERNATIONAL JETPORT",
            "D": "Portland",
            "E": "USA",
            "F": "US",
            "G": "T",
            "H": "",
            "I": "",
            "J": "NOA"
        }
    ],
    "PXM": [
        {
            "B": "MMPS",
            "C": "PUERTO ESCONDIDO",
            "D": "Puerto Escondido",
            "E": "Mexico",
            "F": "MX",
            "G": "T",
            "H": "",
            "I": "",
            "J": "CEM"
        }
    ],
    "PXO": [
        {
            "B": "LPPS",
            "C": "PORTO SANTO",
            "D": "Porto Santo",
            "E": "Portugal",
            "F": "PT",
            "G": "E",
            "H": "",
            "I": "",
            "J": "EUR"
        }
    ],
    "PZB": [
        {
            "B": "FAPM",
            "C": "Pietermaritzburg",
            "D": "Pietermaritzburg",
            "E": "South Africa",
            "F": "ZA",
            "G": "O",
            "H": "",
            "I": "",
            "J": "AFR"
        }
    ],
    "PZE": [
        {
            "B": "EGHK",
            "C": "PENZANCE",
            "D": "Penzance",
            "E": "United Kingdom",
            "F": "GB",
            "G": "U",
            "H": "",
            "I": "",
            "J": "EUR"
        }
    ],
    "PZU": [
        {
            "B": "HSPN",
            "C": "Port Sudan",
            "D": "Port Sudan",
            "E": "Sudan",
            "F": "SD",
            "G": "O",
            "H": "",
            "I": "",
            "J": "AFR"
        }
    ],
    "PZY": [
        {
            "B": "LZPP",
            "C": "PIESTANY",
            "D": "Piestany",
            "E": "Slovakia",
            "F": "SK",
            "G": "E",
            "H": "",
            "I": "",
            "J": "EUR"
        }
    ],
    "QAL": [
        {
            "B": "LILA",
            "C": "ALESSANDRIA",
            "D": "Alessandria",
            "E": "Italy",
            "F": "IT",
            "G": "E",
            "H": "",
            "I": "",
            "J": "EUR"
        }
    ],
    "QAM": [
        {
            "B": "LFAY",
            "C": "AMIENS / GLISY",
            "D": "Amiens",
            "E": "France",
            "F": "FR",
            "G": "E",
            "H": "",
            "I": "",
            "J": "EUR"
        }
    ],
    "QBM": [
        {
            "B": "LFHS",
            "C": "CEYREZIAT",
            "D": "Boug-st maurice",
            "E": "France",
            "F": "FR",
            "G": "E",
            "H": "",
            "I": "",
            "J": "EUR"
        }
    ],
    "QBS": [
        {
            "B": "LIPN",
            "C": "VERONA - BOSCOMATICO",
            "D": "Verona",
            "E": "Italy",
            "F": "IT",
            "G": "E",
            "H": "",
            "I": "",
            "J": "EUR"
        }
    ],
    "QCM": [
        {
            "B": "LILY",
            "C": "COMO / IDROSCALO",
            "D": "Como Idroscalo",
            "E": "Italy",
            "F": "IT",
            "G": "E",
            "H": "",
            "I": "",
            "J": "EUR"
        }
    ],
    "QCY": [
        {
            "B": "EGXC",
            "C": "CONINGSBY MIL",
            "D": "Coningsby",
            "E": "United Kingdom",
            "F": "GB",
            "G": "U",
            "H": "",
            "I": "",
            "J": "EUR"
        }
    ],
    "QDU": [
        {
            "B": "",
            "C": "Railway Station Dusseldorf",
            "D": "Duesseldorf-Main Rr",
            "E": "Germany",
            "F": "DE",
            "G": "E",
            "H": "",
            "I": "",
            "J": "EUR"
        }
    ],
    "QEF": [
        {
            "B": "EDFE",
            "C": "EGELSBACH",
            "D": "Egelsbach",
            "E": "Germany",
            "F": "DE",
            "G": "E",
            "H": "",
            "I": "",
            "J": "EUR"
        }
    ],
    "QFB": [
        {
            "B": "EDTF",
            "C": "FREIBURG IM BREISGAU",
            "D": "Freiburg",
            "E": "Germany",
            "F": "DE",
            "G": "E",
            "H": "",
            "I": "",
            "J": "EUR"
        }
    ],
    "QFD": [
        {
            "B": "DAAK",
            "C": "BOUFARIK",
            "D": "Boufarik",
            "E": "Algeria",
            "F": "DZ",
            "G": "O",
            "H": "",
            "I": "",
            "J": "AFR"
        }
    ],
    "QFO": [
        {
            "B": "EGSU",
            "C": "DUXFORD",
            "D": "Duxford",
            "E": "United Kingdom",
            "F": "GB",
            "G": "U",
            "H": "",
            "I": "",
            "J": "EUR"
        }
    ],
    "QFR": [
        {
            "B": "LIRH",
            "C": "FROSINONE",
            "D": "Frosinone",
            "E": "Italy",
            "F": "IT",
            "G": "E",
            "H": "",
            "I": "",
            "J": "EUR"
        }
    ],
    "QGO": [
        {
            "B": "LIPG",
            "C": "GORIZIA",
            "D": "Gorizia",
            "E": "Italy",
            "F": "IT",
            "G": "E",
            "H": "",
            "I": "",
            "J": "EUR"
        }
    ],
    "QGY": [
        {
            "B": "LHPR",
            "C": "GYOR",
            "D": "Gyor",
            "E": "Hungary",
            "F": "HU",
            "G": "E",
            "H": "",
            "I": "",
            "J": "EUR"
        }
    ],
    "QHA": [
        {
            "B": "EBZH",
            "C": "HASSELT",
            "D": "Hasselt",
            "E": "Belgium",
            "F": "BE",
            "G": "E",
            "H": "",
            "I": "",
            "J": "EUR"
        }
    ],
    "QIE": [
        {
            "B": "LFMI",
            "C": "ISTRES - LE TUBE",
            "D": "Istres Le Tube",
            "E": "France",
            "F": "FR",
            "G": "E",
            "H": "",
            "I": "",
            "J": "EUR"
        }
    ],
    "QJS": [
        {
            "B": "EKSA",
            "C": "SAEBY / OTTESTRUP",
            "D": "Ottestrup",
            "E": "Denmark",
            "F": "DK",
            "G": "E",
            "H": "",
            "I": "",
            "J": "EUR"
        }
    ],
    "QKF": [
        {
            "B": "EDLK",
            "C": "KREFELD - EGELSBERG",
            "D": "Krefeld",
            "E": "Germany",
            "F": "DE",
            "G": "E",
            "H": "",
            "I": "",
            "J": "EUR"
        }
    ],
    "QKL": [
        {
            "B": "ETNK",
            "C": "Station",
            "D": "Cologne-Main Rr",
            "E": "Germany",
            "F": "DE",
            "G": "E",
            "H": "",
            "I": "",
            "J": "EUR"
        }
    ],
    "QKQ": [
        {
            "B": "EDCA",
            "C": "ANKLAM",
            "D": "Anklam",
            "E": "Germany",
            "F": "DE",
            "G": "E",
            "H": "",
            "I": "",
            "J": "EUR"
        }
    ],
    "QKT": [
        {
            "B": "",
            "C": "",
            "D": "",
            "E": "UNKNOWN",
            "F": "",
            "G": "A",
            "H": "",
            "I": "",
            "J": ""
        }
    ],
    "QKZ": [
        {
            "B": "EDTZ",
            "C": "KONSTANZ",
            "D": "Konstanz",
            "E": "Germany",
            "F": "DE",
            "G": "E",
            "H": "",
            "I": "",
            "J": "EUR"
        }
    ],
    "QLA": [
        {
            "B": "EGHL",
            "C": "LASHAM",
            "D": "Lasham",
            "E": "United Kingdom",
            "F": "GB",
            "G": "U",
            "H": "",
            "I": "",
            "J": "EUR"
        }
    ],
    "QLG": [
        {
            "B": "EDML",
            "C": "LANDSHUT",
            "D": "Landshut",
            "E": "Germany",
            "F": "DE",
            "G": "E",
            "H": "",
            "I": "",
            "J": "EUR"
        }
    ],
    "QLR": [
        {
            "B": "LPJF",
            "C": "LEIRIA",
            "D": "Leiria",
            "E": "Portugal",
            "F": "PT",
            "G": "E",
            "H": "",
            "I": "",
            "J": "EUR"
        }
    ],
    "QLS": [
        {
            "B": "LSGL",
            "C": "LAUSANNE",
            "D": "Lausanne",
            "E": "Switzerland",
            "F": "CH",
            "G": "E",
            "H": "",
            "I": "",
            "J": "EUR"
        }
    ],
    "QLT": [
        {
            "B": "LIRL",
            "C": "LATINA",
            "D": "Latina",
            "E": "Italy",
            "F": "IT",
            "G": "E",
            "H": "",
            "I": "",
            "J": "EUR"
        }
    ],
    "QMZ": [
        {
            "B": "EDFZ",
            "C": "MAINZ / FINTHEN",
            "D": "Mainz",
            "E": "Germany",
            "F": "DE",
            "G": "E",
            "H": "",
            "I": "",
            "J": "EUR"
        }
    ],
    "QNC": [
        {
            "B": "LSGN",
            "C": "NEUCHATEL",
            "D": "Neuchatel",
            "E": "Switzerland",
            "F": "CH",
            "G": "E",
            "H": "",
            "I": "",
            "J": "EUR"
        }
    ],
    "QNJ": [
        {
            "B": "LFLI",
            "C": "ANNEMASSE",
            "D": "Annemasse",
            "E": "France",
            "F": "FR",
            "G": "E",
            "H": "",
            "I": "",
            "J": "EUR"
        }
    ],
    "QNX": [
        {
            "B": "LFLM",
            "C": "MACON-CHARNAY",
            "D": "Macon Charnay",
            "E": "France",
            "F": "FR",
            "G": "E",
            "H": "",
            "I": "",
            "J": "EUR"
        }
    ],
    "QOB": [
        {
            "B": "EDQF",
            "C": "ANSBACH - PETERSDORF",
            "D": "Ansbach",
            "E": "Germany",
            "F": "DE",
            "G": "E",
            "H": "",
            "I": "",
            "J": "EUR"
        }
    ],
    "QOS": [
        {
            "B": "LIER",
            "C": "ORISTANO FENOSU",
            "D": "Oristano fenosu",
            "E": "Italy",
            "F": "IT",
            "G": "E",
            "H": "",
            "I": "",
            "J": "EUR"
        }
    ],
    "QOW": [
        {
            "B": "DNIM",
            "C": "OWERRI",
            "D": "Owerri",
            "E": "Nigeria",
            "F": "NG",
            "G": "O",
            "H": "",
            "I": "",
            "J": "AFR"
        }
    ],
    "QOX": [
        {
            "B": "ETSM",
            "C": "MEMMINGEN",
            "D": "Memmingen",
            "E": "Germany",
            "F": "DE",
            "G": "E",
            "H": "",
            "I": "",
            "J": "EUR"
        }
    ],
    "QPB": [
        {
            "B": "LIBS",
            "C": "CAMPOBASSO",
            "D": "Campobasso",
            "E": "Italy",
            "F": "IT",
            "G": "E",
            "H": "",
            "I": "",
            "J": "EUR"
        }
    ],
    "QPJ": [
        {
            "B": "LHPP",
            "C": "PECS",
            "D": "Pecs",
            "E": "Hungary",
            "F": "HU",
            "G": "E",
            "H": "",
            "I": "",
            "J": "EUR"
        }
    ],
    "QPO": [
        {
            "B": "LIBZ",
            "C": "POTENZA",
            "D": "Potenza",
            "E": "Italy",
            "F": "IT",
            "G": "E",
            "H": "",
            "I": "",
            "J": "EUR"
        }
    ],
    "QPZ": [
        {
            "B": "LIMS",
            "C": "PIACENZA / S.DAMIANO",
            "D": "Piacenza",
            "E": "Italy",
            "F": "IT",
            "G": "E",
            "H": "",
            "I": "",
            "J": "EUR"
        }
    ],
    "QQP": [
        {
            "B": "",
            "C": "Paddington Railway Station",
            "D": "Paddington Railway Station",
            "E": "United Kingdom",
            "F": "GB",
            "G": "U",
            "H": "",
            "I": "",
            "J": "EUR"
        }
    ],
    "QQQ": [
        {
            "B": "QQQQ",
            "C": "UNKNOWN FOR ARIS",
            "D": "Origin unknown",
            "E": "UNKNOWN",
            "F": "0",
            "G": "D",
            "H": "",
            "I": "",
            "J": "EUR"
        }
    ],
    "QRM": [
        {
            "B": "YNRM",
            "C": "NARROMINE AIRPORT",
            "D": "Narromine Airport",
            "E": "Australia",
            "F": "AU",
            "G": "O",
            "H": "",
            "I": "",
            "J": "SWP"
        }
    ],
    "QRO": [
        {
            "B": "MMQT",
            "C": "Queretaro",
            "D": "Queretaro Intercontinental",
            "E": "Mexico",
            "F": "MX",
            "G": "T",
            "H": "",
            "I": "",
            "J": "CEM"
        }
    ],
    "QRT": [
        {
            "B": "LIQN",
            "C": "RIETI",
            "D": "Rieti",
            "E": "Italy",
            "F": "IT",
            "G": "E",
            "H": "",
            "I": "",
            "J": "EUR"
        }
    ],
    "QRV": [
        {
            "B": "LFQD",
            "C": "ARRAS / ROCLINCOURT",
            "D": "Arras",
            "E": "France",
            "F": "FR",
            "G": "E",
            "H": "",
            "I": "",
            "J": "EUR"
        }
    ],
    "QSA": [
        {
            "B": "LELL",
            "C": "SABADELL",
            "D": "Sabadell",
            "E": "Spain",
            "F": "ES",
            "G": "E",
            "H": "",
            "I": "",
            "J": "EUR"
        }
    ],
    "QSR": [
        {
            "B": "LIRI",
            "C": "SALERNO / PONTECAGNANO",
            "D": "Salerno",
            "E": "Italy",
            "F": "IT",
            "G": "E",
            "H": "",
            "I": "",
            "J": "EUR"
        }
    ],
    "QST": [
        {
            "B": "LTBQ",
            "C": "IZMIT / TOPEL",
            "D": "Izmit",
            "E": "Turkey",
            "F": "TR",
            "G": "E",
            "H": "",
            "I": "",
            "J": "EUR"
        }
    ],
    "QTJ": [
        {
            "B": "LFOR",
            "C": "CHARTRES-CHAMPHOL",
            "D": "Chartres",
            "E": "France",
            "F": "FR",
            "G": "E",
            "H": "",
            "I": "",
            "J": "EUR"
        }
    ],
    "QUG": [
        {
            "B": "EGHR",
            "C": "GOODWOOD",
            "D": "Goodwood/chichester",
            "E": "United Kingdom",
            "F": "GB",
            "G": "U",
            "H": "",
            "I": "",
            "J": "EUR"
        }
    ],
    "QUY": [
        {
            "B": "EGUY",
            "C": "WYTON MIL",
            "D": "Wyton",
            "E": "United Kingdom",
            "F": "GB",
            "G": "U",
            "H": "",
            "I": "",
            "J": "EUR"
        }
    ],
    "QVA": [
        {
            "B": "LILN",
            "C": "VARESE / VENEGONO",
            "D": "Varese",
            "E": "Italy",
            "F": "IT",
            "G": "E",
            "H": "",
            "I": "",
            "J": "EUR"
        }
    ],
    "QVQ": [
        {
            "B": "EDWV",
            "C": "VERDEN-SCHARNHORST",
            "D": "Verden Scharnhorst",
            "E": "Germany",
            "F": "DE",
            "G": "E",
            "H": "",
            "I": "",
            "J": "EUR"
        }
    ],
    "QWR": [
        {
            "B": "EDMQ",
            "C": "DONAUWORTH / GENDERKIN",
            "D": "Donauworth",
            "E": "Germany",
            "F": "DE",
            "G": "E",
            "H": "",
            "I": "",
            "J": "EUR"
        }
    ],
    "QWU": [
        {
            "B": "EDFW",
            "C": "SCHENKENTURM",
            "D": "Schenkenturm",
            "E": "Germany",
            "F": "DE",
            "G": "E",
            "H": "",
            "I": "",
            "J": "EUR"
        }
    ],
    "QXB": [
        {
            "B": "LFMB",
            "C": "AIX-EN-PROVENCE",
            "D": "Aix",
            "E": "France",
            "F": "FR",
            "G": "E",
            "H": "",
            "I": "",
            "J": "EUR"
        }
    ],
    "QXH": [
        {
            "B": "EDAZ",
            "C": "SCHONHAGEN",
            "D": "Schonhagen",
            "E": "Germany",
            "F": "DE",
            "G": "E",
            "H": "",
            "I": "",
            "J": "EUR"
        }
    ],
    "QXL": [
        {
            "B": "EGXE",
            "C": "RAF LEEMING",
            "D": "RAF LEEMING",
            "E": "United Kingdom",
            "F": "GB",
            "G": "U",
            "H": "",
            "I": "",
            "J": "EUR"
        }
    ],
    "QY2": [
        {
            "B": "EHBD",
            "C": "WEERT BUDEL",
            "D": "WEERT BUDEL",
            "E": "Netherlands",
            "F": "NL",
            "G": "E",
            "H": "",
            "I": "",
            "J": "EUR"
        }
    ],
    "QYC": [
        {
            "B": "EHDR",
            "C": "DRACHTEN",
            "D": "Drachten",
            "E": "Netherlands",
            "F": "NL",
            "G": "E",
            "H": "",
            "I": "",
            "J": "EUR"
        }
    ],
    "QYG": [
        {
            "B": "",
            "C": "German railway stations",
            "D": "Germany Railway Service",
            "E": "Germany",
            "F": "DE",
            "G": "E",
            "H": "",
            "I": "",
            "J": "EUR"
        }
    ],
    "QYI": [
        {
            "B": "EHHV",
            "C": "HILVERSUM",
            "D": "Hilversum",
            "E": "Netherlands",
            "F": "NL",
            "G": "E",
            "H": "",
            "I": "",
            "J": "EUR"
        }
    ],
    "QYR": [
        {
            "B": "LFQB",
            "C": "TROYES / BARBEREY",
            "D": "Troyes",
            "E": "France",
            "F": "FR",
            "G": "E",
            "H": "",
            "I": "",
            "J": "EUR"
        }
    ],
    "QZB": [
        {
            "B": "LSEZ",
            "C": "ZERMATT",
            "D": "Zermatt",
            "E": "Switzerland",
            "F": "CH",
            "G": "E",
            "H": "",
            "I": "",
            "J": "EUR"
        }
    ],
    "QZD": [
        {
            "B": "LHUD",
            "C": "SZEGED",
            "D": "Szeged",
            "E": "Hungary",
            "F": "HU",
            "G": "E",
            "H": "",
            "I": "",
            "J": "EUR"
        }
    ],
    "QZO": [
        {
            "B": "LIQB",
            "C": "AREZZO",
            "D": "Arezzo",
            "E": "Italy",
            "F": "IT",
            "G": "E",
            "H": "",
            "I": "",
            "J": "EUR"
        }
    ],
    "R23": [
        {
            "B": "",
            "C": "",
            "D": "",
            "E": "UNKNOWN",
            "F": "",
            "G": "A",
            "H": "",
            "I": "",
            "J": ""
        }
    ],
    "RAE": [
        {
            "B": "OERR",
            "C": "Arar",
            "D": "Arar",
            "E": "Saudi Arabia",
            "F": "SA",
            "G": "O",
            "H": "",
            "I": "",
            "J": "MDE"
        }
    ],
    "RAH": [
        {
            "B": "OERF",
            "C": "Rafha",
            "D": "Rafha",
            "E": "Saudi Arabia",
            "F": "SA",
            "G": "O",
            "H": "",
            "I": "",
            "J": "MDE"
        }
    ],
    "RAI": [
        {
            "B": "GVNP",
            "C": "Francisco Mendes",
            "D": "Praia International",
            "E": "Cape Verde",
            "F": "CV",
            "G": "O",
            "H": "",
            "I": "",
            "J": "AFR"
        }
    ],
    "RAJ": [
        {
            "B": "VARK",
            "C": "Civil",
            "D": "Rajkot",
            "E": "India",
            "F": "IN",
            "G": "O",
            "H": "",
            "I": "",
            "J": "SAS"
        }
    ],
    "RAK": [
        {
            "B": "GMMX",
            "C": "MARRAKECH",
            "D": "Marrakech",
            "E": "Morocco",
            "F": "MA",
            "G": "O",
            "H": "",
            "I": "",
            "J": "AFR"
        }
    ],
    "RAN": [
        {
            "B": "LIDR",
            "C": "RAVENNA",
            "D": "Ravenna",
            "E": "Italy",
            "F": "IT",
            "G": "E",
            "H": "",
            "I": "",
            "J": "EUR"
        }
    ],
    "RAO": [
        {
            "B": "SBRP",
            "C": "Leite Lopes",
            "D": "Leite Lopes",
            "E": "Brazil",
            "F": "BR",
            "G": "T",
            "H": "",
            "I": "",
            "J": "SOA"
        }
    ],
    "RAP": [
        {
            "B": "KRAP",
            "C": "Regional",
            "D": "Rapid City Regional",
            "E": "USA",
            "F": "US",
            "G": "T",
            "H": "",
            "I": "",
            "J": "NOA"
        }
    ],
    "RAR": [
        {
            "B": "NCRG",
            "C": "Rarotonga",
            "D": "Rarotonga Island",
            "E": "Cook Islands",
            "F": "CK",
            "G": "O",
            "H": "",
            "I": "",
            "J": "SWP"
        }
    ],
    "RBA": [
        {
            "B": "GMME",
            "C": "RABAT",
            "D": "Rabat",
            "E": "Morocco",
            "F": "MA",
            "G": "O",
            "H": "",
            "I": "",
            "J": "AFR"
        }
    ],
    "RBM": [
        {
            "B": "EDMS",
            "C": "STRAUBING - WALLMUHLE",
            "D": "Straubing",
            "E": "Germany",
            "F": "DE",
            "G": "E",
            "H": "",
            "I": "",
            "J": "EUR"
        }
    ],
    "RCB": [
        {
            "B": "FARB",
            "C": "Richards Bay",
            "D": "Richards Bay",
            "E": "South Africa",
            "F": "ZA",
            "G": "O",
            "H": "",
            "I": "",
            "J": "AFR"
        }
    ],
    "RCH": [
        {
            "B": "SKRH",
            "C": "RIOHACHA/GUAJIRA",
            "D": "Riohacha/Guajira",
            "E": "Colombia",
            "F": "CO",
            "G": "T",
            "H": "",
            "I": "",
            "J": "SOA"
        }
    ],
    "RCO": [
        {
            "B": "LFDN",
            "C": "ROCHEFORT",
            "D": "Rochefort",
            "E": "France",
            "F": "FR",
            "G": "E",
            "H": "",
            "I": "",
            "J": "EUR"
        }
    ],
    "RCS": [
        {
            "B": "EGTO",
            "C": "ROCHESTER - UK",
            "D": "Rochester",
            "E": "United Kingdom",
            "F": "GB",
            "G": "U",
            "H": "",
            "I": "",
            "J": "EUR"
        }
    ],
    "RDD": [
        {
            "B": "KRDD",
            "C": "Redding",
            "D": "Redding Municipal",
            "E": "USA",
            "F": "US",
            "G": "T",
            "H": "",
            "I": "",
            "J": "NOA"
        }
    ],
    "RDM": [
        {
            "B": "KRDM",
            "C": "Roberts Field",
            "D": "Roberts Field",
            "E": "USA",
            "F": "US",
            "G": "T",
            "H": "",
            "I": "",
            "J": "NOA"
        }
    ],
    "RDO": [
        {
            "B": "EPRA",
            "C": "RADOM",
            "D": "RADOM",
            "E": "Poland",
            "F": "PL",
            "G": "E",
            "H": "",
            "I": "",
            "J": "EUR"
        }
    ],
    "RDU": [
        {
            "B": "KRDU",
            "C": "RALEIGH-DURHAM",
            "D": "Durham",
            "E": "USA",
            "F": "US",
            "G": "T",
            "H": "",
            "I": "",
            "J": "NOA"
        }
    ],
    "RDZ": [
        {
            "B": "LFCR",
            "C": "RODEZ",
            "D": "Rodez",
            "E": "France",
            "F": "FR",
            "G": "E",
            "H": "",
            "I": "",
            "J": "EUR"
        }
    ],
    "REC": [
        {
            "B": "SBRF",
            "C": "RECIFE - GUARARAPES",
            "D": "Recife",
            "E": "Brazil",
            "F": "BR",
            "G": "T",
            "H": "",
            "I": "",
            "J": "SOA"
        }
    ],
    "REG": [
        {
            "B": "LICR",
            "C": "REGGIO CALABRIA",
            "D": "Reggio Calabria",
            "E": "Italy",
            "F": "IT",
            "G": "E",
            "H": "",
            "I": "",
            "J": "EUR"
        }
    ],
    "REK": [
        {
            "B": "BIRK",
            "C": "REYKJAVIK AIRPORT",
            "D": "REYKJAVIK AIRPORT",
            "E": "Iceland",
            "F": "IS",
            "G": "E",
            "H": "",
            "I": "",
            "J": "EUR"
        }
    ],
    "REP": [
        {
            "B": "VDSR",
            "C": "SIEM REAP",
            "D": "Siem reap",
            "E": "Cambodia",
            "F": "KH",
            "G": "O",
            "H": "",
            "I": "",
            "J": "SEA"
        }
    ],
    "REU": [
        {
            "B": "LERS",
            "C": "REUS",
            "D": "Reus Salou",
            "E": "Spain",
            "F": "ES",
            "G": "E",
            "H": "",
            "I": "",
            "J": "EUR"
        }
    ],
    "REX": [
        {
            "B": "MMRX",
            "C": "Gen Lucio Blanco",
            "D": "Lucio Blanco Intl",
            "E": "Mexico",
            "F": "MX",
            "G": "T",
            "H": "",
            "I": "",
            "J": "CEM"
        }
    ],
    "RFD": [
        {
            "B": "KRFD",
            "C": "CHICAGO/ROCKFORD INT",
            "D": "Chicago/rockford int",
            "E": "USA",
            "F": "US",
            "G": "T",
            "H": "",
            "I": "",
            "J": "NOA"
        }
    ],
    "RGN": [
        {
            "B": "VYYY",
            "C": "YANGON- MINGALADON",
            "D": "Yangon",
            "E": "Myanmar",
            "F": "MM",
            "G": "O",
            "H": "",
            "I": "",
            "J": "SEA"
        }
    ],
    "RHE": [
        {
            "B": "LFSR",
            "C": "REIMS",
            "D": "Reims",
            "E": "France",
            "F": "FR",
            "G": "E",
            "H": "",
            "I": "",
            "J": "EUR"
        }
    ],
    "RHO": [
        {
            "B": "LGRP",
            "C": "RHODOS DIAGORAS",
            "D": "Rhodos",
            "E": "Greece",
            "F": "GR",
            "G": "E",
            "H": "",
            "I": "",
            "J": "EUR"
        }
    ],
    "RIA": [
        {
            "B": "SBSM",
            "C": "SANTA MARIA",
            "D": "Santa Maria",
            "E": "Brazil",
            "F": "BR",
            "G": "T",
            "H": "",
            "I": "",
            "J": "SOA"
        }
    ],
    "RIC": [
        {
            "B": "KRIC",
            "C": "RICHMOND INTERNATIONAL",
            "D": "Richmond",
            "E": "USA",
            "F": "US",
            "G": "T",
            "H": "",
            "I": "",
            "J": "NOA"
        }
    ],
    "RIG": [
        {
            "B": "SBRG",
            "C": "RIO GRANDE",
            "D": "Rio grande",
            "E": "Brazil",
            "F": "BR",
            "G": "T",
            "H": "",
            "I": "",
            "J": "SOA"
        }
    ],
    "RIO": [
        {
            "B": "",
            "C": "RIO DE JANEIRO",
            "D": "Rio de Janeiro",
            "E": "Brazil",
            "F": "BR",
            "G": "T",
            "H": "",
            "I": "",
            "J": "SOA"
        }
    ],
    "RIV": [
        {
            "B": "KRIV",
            "C": "RIVERSIDE / MARCH AFB",
            "D": "March global port",
            "E": "USA",
            "F": "US",
            "G": "T",
            "H": "",
            "I": "",
            "J": "NOA"
        }
    ],
    "RIX": [
        {
            "B": "EVRA",
            "C": "RIGA INTERNATIONAL",
            "D": "Riga",
            "E": "Latvia",
            "F": "LV",
            "G": "E",
            "H": "",
            "I": "",
            "J": "EUR"
        }
    ],
    "RIY": [
        {
            "B": "OYRN",
            "C": "RIYAN AIRPORT",
            "D": "RIYAN AIRPORT",
            "E": "Republic of Yemen",
            "F": "YE",
            "G": "O",
            "H": "",
            "I": "",
            "J": "MDE"
        }
    ],
    "RJA": [
        {
            "B": "VORY",
            "C": "Rajahmundry",
            "D": "Rajahmundry Airport",
            "E": "India",
            "F": "IN",
            "G": "O",
            "H": "",
            "I": "",
            "J": "SAS"
        }
    ],
    "RJK": [
        {
            "B": "LDRI",
            "C": "RIJEKA KRK ISLAND",
            "D": "Rijeka",
            "E": "Croatia",
            "F": "HR",
            "G": "E",
            "H": "",
            "I": "",
            "J": "EUR"
        }
    ],
    "RJL": [
        {
            "B": "LELO",
            "C": "LOGRONO",
            "D": "Logrono",
            "E": "Spain",
            "F": "ES",
            "G": "E",
            "H": "",
            "I": "",
            "J": "EUR"
        }
    ],
    "RKD": [
        {
            "B": "KRKD",
            "C": "Rockland, Maine",
            "D": "Knox County Regional",
            "E": "USA",
            "F": "US",
            "G": "T",
            "H": "",
            "I": "",
            "J": "NOA"
        }
    ],
    "RKE": [
        {
            "B": "EKRK",
            "C": "COPENHAGEN ROSKILDE",
            "D": "Roskilde",
            "E": "Denmark",
            "F": "DK",
            "G": "E",
            "H": "",
            "I": "",
            "J": "EUR"
        }
    ],
    "RKS": [
        {
            "B": "KRKS",
            "C": "Sweetwater County",
            "D": "Sweetwater County",
            "E": "USA",
            "F": "US",
            "G": "T",
            "H": "",
            "I": "",
            "J": "NOA"
        }
    ],
    "RKT": [
        {
            "B": "OMRK",
            "C": "RAS AL KHAIMAH",
            "D": "Ras Al Khaimah",
            "E": "United Arab Emirates",
            "F": "",
            "G": "O",
            "H": "",
            "I": "",
            "J": ""
        }
    ],
    "RKV": [
        {
            "B": "BIXX",
            "C": "REYKJAVIK",
            "D": "Reykjavik",
            "E": "Iceland",
            "F": "IS",
            "G": "E",
            "H": "",
            "I": "",
            "J": "EUR"
        }
    ],
    "RLG": [
        {
            "B": "ETNL",
            "C": "ROSTOCK LAAGE",
            "D": "Rostock laage airport",
            "E": "Germany",
            "F": "DE",
            "G": "E",
            "H": "",
            "I": "",
            "J": "EUR"
        }
    ],
    "RMA": [
        {
            "B": "YROM",
            "C": "Roma",
            "D": "Roma",
            "E": "Australia",
            "F": "AU",
            "G": "O",
            "H": "",
            "I": "",
            "J": "SWP"
        }
    ],
    "RME": [
        {
            "B": "KRME",
            "C": "GRIFFISS INTL AIRPORT",
            "D": "Griffiss Intl Airport",
            "E": "USA",
            "F": "US",
            "G": "T",
            "H": "",
            "I": "",
            "J": "NOA"
        }
    ],
    "RMF": [
        {
            "B": "HEMA",
            "C": "Marsa Alam",
            "D": "Marsa Alam International Airport",
            "E": "Egypt",
            "F": "EG",
            "G": "O",
            "H": "",
            "I": "",
            "J": "MDE"
        }
    ],
    "RMI": [
        {
            "B": "LIPR",
            "C": "RIMINI",
            "D": "Rimini",
            "E": "Italy",
            "F": "IT",
            "G": "E",
            "H": "",
            "I": "",
            "J": "EUR"
        }
    ],
    "RMQ": [
        {
            "B": "RCMQ",
            "C": "Taichung",
            "D": "Cingcyuangang",
            "E": "Taiwan, Province of China",
            "F": "TW",
            "G": "O",
            "H": "",
            "I": "",
            "J": "SEA"
        }
    ],
    "RMS": [
        {
            "B": "ETAR",
            "C": "RAMSTEIN",
            "D": "Ramstein",
            "E": "Germany",
            "F": "DE",
            "G": "E",
            "H": "",
            "I": "",
            "J": "EUR"
        }
    ],
    "RMU": [
        {
            "B": "LEMI",
            "C": "Murcia",
            "D": "Murcia",
            "E": "Spain",
            "F": "ES",
            "G": "E",
            "H": "",
            "I": "",
            "J": "EUR"
        }
    ],
    "RNB": [
        {
            "B": "ESDF",
            "C": "RONNEBY",
            "D": "Ronneby",
            "E": "Sweden",
            "F": "SE",
            "G": "E",
            "H": "",
            "I": "",
            "J": "EUR"
        }
    ],
    "RNE": [
        {
            "B": "LFLO",
            "C": "ROANNE",
            "D": "Roanne",
            "E": "France",
            "F": "FR",
            "G": "E",
            "H": "",
            "I": "",
            "J": "EUR"
        }
    ],
    "RNN": [
        {
            "B": "EKRN",
            "C": "BORNHOLM",
            "D": "Bornholm",
            "E": "Denmark",
            "F": "DK",
            "G": "E",
            "H": "",
            "I": "",
            "J": "EUR"
        }
    ],
    "RNO": [
        {
            "B": "KRNO",
            "C": "RENO-TAHOE INTL",
            "D": "RENO–TAHOE INTERNATIONAL AIRPORT",
            "E": "USA",
            "F": "US",
            "G": "T",
            "H": "",
            "I": "",
            "J": "NOA"
        }
    ],
    "RNS": [
        {
            "B": "LFRN",
            "C": "RENNES",
            "D": "Rennes",
            "E": "France",
            "F": "FR",
            "G": "E",
            "H": "",
            "I": "",
            "J": "EUR"
        }
    ],
    "ROA": [
        {
            "B": "KROA",
            "C": "ROANOKE",
            "D": "ROANOKE-BLACKSBURG",
            "E": "USA",
            "F": "US",
            "G": "T",
            "H": "",
            "I": "",
            "J": "NOA"
        }
    ],
    "ROB": [
        {
            "B": "GLRB",
            "C": "MONROVIA ROBERTS INTERNATIONAL",
            "D": "Roberts Int'l",
            "E": "Liberia",
            "F": "LR",
            "G": "O",
            "H": "",
            "I": "",
            "J": "AFR"
        }
    ],
    "ROC": [
        {
            "B": "KROC",
            "C": "ROCHESTER INTERNATIONAL",
            "D": "Rochester",
            "E": "USA",
            "F": "US",
            "G": "T",
            "H": "",
            "I": "",
            "J": "NOA"
        }
    ],
    "ROG": [
        {
            "B": "KROG",
            "C": "ROGERS MUNICIPAL",
            "D": "Rogers",
            "E": "USA",
            "F": "US",
            "G": "T",
            "H": "",
            "I": "",
            "J": "NOA"
        }
    ],
    "ROK": [
        {
            "B": "YBRK",
            "C": "Rockhampton",
            "D": "Rockhampton",
            "E": "Australia",
            "F": "AU",
            "G": "O",
            "H": "",
            "I": "",
            "J": "SWP"
        }
    ],
    "ROR": [
        {
            "B": "PTRO",
            "C": "Airai",
            "D": "Palau International",
            "E": "Palau",
            "F": "PW",
            "G": "O",
            "H": "",
            "I": "",
            "J": "SWP"
        }
    ],
    "ROS": [
        {
            "B": "SAAR",
            "C": "Fisherton",
            "D": "Islas Malvinas",
            "E": "Argentina",
            "F": "AR",
            "G": "T",
            "H": "",
            "I": "",
            "J": "SOA"
        }
    ],
    "ROT": [
        {
            "B": "NZRO",
            "C": "ROTORUA AIRPORT",
            "D": "ROTORUA REGIONAL AIRPORT",
            "E": "New Zealand",
            "F": "NZ",
            "G": "O",
            "H": "",
            "I": "",
            "J": "SWP"
        }
    ],
    "ROU": [
        {
            "B": "LBRS",
            "C": "ROUSSE",
            "D": "Rousse",
            "E": "Bulgaria",
            "F": "BG",
            "G": "E",
            "H": "",
            "I": "",
            "J": "EUR"
        }
    ],
    "ROV": [
        {
            "B": "URRR",
            "C": "ROSTOV-NA-DONU",
            "D": "Rostov",
            "E": "Russian Federation",
            "F": "RU",
            "G": "E",
            "H": "",
            "I": "",
            "J": "EUR"
        }
    ],
    "ROW": [
        {
            "B": "KROW",
            "C": "Industrial",
            "D": "Intl Air Center",
            "E": "USA",
            "F": "US",
            "G": "T",
            "H": "",
            "I": "",
            "J": "NOA"
        }
    ],
    "ROZ": [
        {
            "B": "LERT",
            "C": "Rota Naval Station Airport",
            "D": "Rota",
            "E": "Spain",
            "F": "ES",
            "G": "E",
            "H": "",
            "I": "",
            "J": "EUR"
        }
    ],
    "RPN": [
        {
            "B": "LLIB",
            "C": "ROSH PINA",
            "D": "Rosh Pina",
            "E": "Israel",
            "F": "IL",
            "G": "O",
            "H": "",
            "I": "",
            "J": "MDE"
        }
    ],
    "RPR": [
        {
            "B": "VARP",
            "C": "Raipur",
            "D": "Swami Vivekananda",
            "E": "India",
            "F": "IN",
            "G": "O",
            "H": "",
            "I": "",
            "J": "SAS"
        }
    ],
    "RRG": [
        {
            "B": "FIMR",
            "C": "RODRIGUES",
            "D": "Rodrigues/Plaine Corail Airport",
            "E": "Mauritius",
            "F": "MU",
            "G": "O",
            "H": "",
            "I": "",
            "J": "AFR"
        }
    ],
    "RRS": [
        {
            "B": "ENRO",
            "C": "ROROS",
            "D": "Roros",
            "E": "Norway",
            "F": "NO",
            "G": "E",
            "H": "",
            "I": "",
            "J": "EUR"
        }
    ],
    "RSS": [
        {
            "B": "KRSS",
            "C": "ROSEIRES",
            "D": "Roseires",
            "E": "USA",
            "F": "US",
            "G": "T",
            "H": "",
            "I": "",
            "J": "NOA"
        }
    ],
    "RST": [
        {
            "B": "KRST",
            "C": "ROCHESTER MUNICIPAL",
            "D": "Rochester",
            "E": "USA",
            "F": "US",
            "G": "T",
            "H": "",
            "I": "",
            "J": "NOA"
        }
    ],
    "RSW": [
        {
            "B": "KRSW",
            "C": "FORT MYERS SOUTH WEST FLORIDA",
            "D": "Fort myers",
            "E": "USA",
            "F": "US",
            "G": "T",
            "H": "",
            "I": "",
            "J": "NOA"
        }
    ],
    "RTA": [
        {
            "B": "NFNR",
            "C": "ROTUMA",
            "D": "Rotuma island",
            "E": "Fiji",
            "F": "FJ",
            "G": "O",
            "H": "",
            "I": "Kwajalein",
            "J": "SWP"
        }
    ],
    "RTB": [
        {
            "B": "MHRO",
            "C": "Roatan",
            "D": "Juan Manuel Galvez",
            "E": "Honduras",
            "F": "HN",
            "G": "T",
            "H": "",
            "I": "",
            "J": "CEM"
        }
    ],
    "RTD": [
        {
            "B": "KRTD",
            "C": "ROTUNDA",
            "D": "Rotunda",
            "E": "USA",
            "F": "US",
            "G": "T",
            "H": "",
            "I": "",
            "J": "NOA"
        }
    ],
    "RTF": [
        {
            "B": "XXXX",
            "C": "Return Flight(DO NOT USE)",
            "D": "Return Flight(DO NOT USE)",
            "E": "Republic of Ireland",
            "F": "acl input",
            "G": "D",
            "H": "",
            "I": "",
            "J": "EUR"
        }
    ],
    "RTM": [
        {
            "B": "EHRD",
            "C": "ROTTERDAM",
            "D": "Rotterdam",
            "E": "Netherlands",
            "F": "NL",
            "G": "E",
            "H": "",
            "I": "",
            "J": "EUR"
        }
    ],
    "RUH": [
        {
            "B": "OERK",
            "C": "KING KHALED INTERNATIONAL",
            "D": "Riyadh",
            "E": "Saudi Arabia",
            "F": "SA",
            "G": "O",
            "H": "",
            "I": "",
            "J": "MDE"
        }
    ],
    "RUN": [
        {
            "B": "FMEE",
            "C": "Gillot",
            "D": "Roland Garros",
            "E": "Reunion",
            "F": "RE",
            "G": "O",
            "H": "",
            "I": "",
            "J": "AFR"
        }
    ],
    "RUT": [
        {
            "B": "KRUT",
            "C": "Rutland",
            "D": "Southern Vermont Regional",
            "E": "USA",
            "F": "US",
            "G": "T",
            "H": "",
            "I": "",
            "J": "NOA"
        }
    ],
    "RVD": [
        {
            "B": "SWLC",
            "C": "GENERAL LEITE DE CASTRO",
            "D": "General Leite de Castro",
            "E": "Brazil",
            "F": "BR",
            "G": "T",
            "H": "",
            "I": "",
            "J": "SOA"
        }
    ],
    "RVK": [
        {
            "B": "ENRM",
            "C": "Ryumsjoen Aprt",
            "D": "Roervik",
            "E": "Norway",
            "F": "NO",
            "G": "E",
            "H": "",
            "I": "",
            "J": "EUR"
        }
    ],
    "RVN": [
        {
            "B": "EFRO",
            "C": "ROVANIEMI",
            "D": "North Pole",
            "E": "Finland",
            "F": "FI",
            "G": "E",
            "H": "",
            "I": "",
            "J": "EUR"
        }
    ],
    "RXS": [
        {
            "B": "RPVR",
            "C": "ROXAS CITY",
            "D": "Roxas city",
            "E": "Philippines",
            "F": "PH",
            "G": "O",
            "H": "",
            "I": "",
            "J": "SEA"
        }
    ],
    "RYG": [
        {
            "B": "ENRY",
            "C": "RYGGE",
            "D": "Oslo Rygge",
            "E": "Norway",
            "F": "NO",
            "G": "E",
            "H": "",
            "I": "",
            "J": "EUR"
        }
    ],
    "RYK": [
        {
            "B": "OPRK",
            "C": "Rahim Yar Khan",
            "D": "Shaikh Zayed Intl",
            "E": "Pakistan",
            "F": "PK",
            "G": "O",
            "H": "",
            "I": "",
            "J": "SAS"
        }
    ],
    "RZE": [
        {
            "B": "EPRZ",
            "C": "RZESZOW / JASIONKA",
            "D": "Rzeszow",
            "E": "Poland",
            "F": "PL",
            "G": "E",
            "H": "",
            "I": "",
            "J": "EUR"
        }
    ],
    "RZR": [
        {
            "B": "KRZR",
            "C": "Cleveland Jetport",
            "D": "Cleveland Regional Jetport",
            "E": "USA",
            "F": "US",
            "G": "T",
            "H": "",
            "I": "",
            "J": "NOA"
        }
    ],
    "S01": [
        {
            "B": "ESMG",
            "C": "LJUNGBY / FERINGE",
            "D": "Ljungby",
            "E": "Sweden",
            "F": "SE",
            "G": "E",
            "H": "",
            "I": "",
            "J": "EUR"
        }
    ],
    "S02": [
        {
            "B": "ESKM",
            "C": "MORA",
            "D": "Mora",
            "E": "Sweden",
            "F": "SE",
            "G": "E",
            "H": "",
            "I": "",
            "J": "EUR"
        }
    ],
    "S03": [
        {
            "B": "ESIB",
            "C": "SATENAS",
            "D": "Satenas",
            "E": "Sweden",
            "F": "SE",
            "G": "E",
            "H": "",
            "I": "",
            "J": "EUR"
        }
    ],
    "S05": [
        {
            "B": "",
            "C": "BANDON",
            "D": "Bandon State Airport",
            "E": "USA",
            "F": "",
            "G": "T",
            "H": "",
            "I": "",
            "J": ""
        }
    ],
    "S31": [
        {
            "B": "KLPS",
            "C": "LPS SEAPLANE BASE",
            "D": "LPS SEAPLANE BASE (IATA LPS)",
            "E": "USA",
            "F": "US",
            "G": "T",
            "H": "",
            "I": "",
            "J": "NOA"
        }
    ],
    "SAB": [
        {
            "B": "TNCS",
            "C": "J. Yrausquin",
            "D": "Juancho E. Yrausquin",
            "E": "Netherlands Antilles",
            "F": "AN",
            "G": "T",
            "H": "",
            "I": "",
            "J": "CAR"
        }
    ],
    "SAC": [
        {
            "B": "KSAC",
            "C": "SACRAMENTO EXECUTIVE",
            "D": "Sacramento",
            "E": "USA",
            "F": "US",
            "G": "T",
            "H": "",
            "I": "",
            "J": "NOA"
        }
    ],
    "SAF": [
        {
            "B": "KSAF",
            "C": "Santa Fe, New Mexico",
            "D": "Santa Fe Municipal",
            "E": "USA",
            "F": "US",
            "G": "T",
            "H": "",
            "I": "",
            "J": "NOA"
        }
    ],
    "SAH": [
        {
            "B": "OYSN",
            "C": "SANAA INTERNATIONAL",
            "D": "Sanaa",
            "E": "Republic of Yemen",
            "F": "YE",
            "G": "O",
            "H": "",
            "I": "",
            "J": "MDE"
        }
    ],
    "SAL": [
        {
            "B": "MSSS",
            "C": "ILOPANGO SAN SALVADOR",
            "D": "San salvador",
            "E": "El Salvador",
            "F": "SV",
            "G": "T",
            "H": "",
            "I": "",
            "J": "CEM"
        }
    ],
    "SAN": [
        {
            "B": "KSAN",
            "C": "SAN DIEGO LINDBERGH",
            "D": "San Diego",
            "E": "USA",
            "F": "US",
            "G": "T",
            "H": "",
            "I": "",
            "J": "NOA"
        }
    ],
    "SAP": [
        {
            "B": "MHLM",
            "C": "RAMON VILLEDA MORALES",
            "D": "Honduras",
            "E": "Honduras",
            "F": "HN",
            "G": "T",
            "H": "",
            "I": "",
            "J": "CEM"
        }
    ],
    "SAT": [
        {
            "B": "KSAT",
            "C": "SAN ANTONIO INTERNATIONAL",
            "D": "San antonio",
            "E": "USA",
            "F": "US",
            "G": "T",
            "H": "",
            "I": "",
            "J": "NOA"
        }
    ],
    "SAV": [
        {
            "B": "KSAV",
            "C": "SAVANNAH INTERNATIONAL",
            "D": "Savannah",
            "E": "USA",
            "F": "US",
            "G": "T",
            "H": "",
            "I": "",
            "J": "NOA"
        }
    ],
    "SAW": [
        {
            "B": "LTFJ",
            "C": "ISTANBUL / SABIHA GOKCEN",
            "D": "Istanbul",
            "E": "Turkey",
            "F": "TR",
            "G": "E",
            "H": "",
            "I": "",
            "J": "EUR"
        }
    ],
    "SAY": [
        {
            "B": "LIQS",
            "C": "SIENA",
            "D": "Siena",
            "E": "Italy",
            "F": "IT",
            "G": "E",
            "H": "",
            "I": "",
            "J": "EUR"
        }
    ],
    "SBA": [
        {
            "B": "KSBA",
            "C": "Municipal",
            "D": "Santa Barbara Muni",
            "E": "USA",
            "F": "US",
            "G": "T",
            "H": "",
            "I": "",
            "J": "NOA"
        }
    ],
    "SBD": [
        {
            "B": "KSBD",
            "C": "San Bernardino Intl",
            "D": "San Bernardino International Airport",
            "E": "USA",
            "F": "US",
            "G": "T",
            "H": "",
            "I": "",
            "J": ""
        }
    ],
    "SBH": [
        {
            "B": "TFFJ",
            "C": "St Barthelemy",
            "D": "Gustaf III",
            "E": "Guadeloupe",
            "F": "GP",
            "G": "T",
            "H": "",
            "I": "",
            "J": "CAR"
        }
    ],
    "SBK": [
        {
            "B": "LFRT",
            "C": "SAINT BRIEUC-ARMOR TREMUSON",
            "D": "St brieuc",
            "E": "France",
            "F": "FR",
            "G": "E",
            "H": "",
            "I": "",
            "J": "EUR"
        }
    ],
    "SBN": [
        {
            "B": "KSBN",
            "C": "SOUTH BEND REGIONAL",
            "D": "South bend",
            "E": "USA",
            "F": "US",
            "G": "T",
            "H": "",
            "I": "",
            "J": "NOA"
        }
    ],
    "SBP": [
        {
            "B": "KSBP",
            "C": "COUNTY AIRPORT",
            "D": "County airport",
            "E": "USA",
            "F": "US",
            "G": "T",
            "H": "",
            "I": "",
            "J": "NOA"
        }
    ],
    "SBW": [
        {
            "B": "WBGS",
            "C": "Sibu",
            "D": "Sibu",
            "E": "Malaysia",
            "F": "MY",
            "G": "O",
            "H": "",
            "I": "",
            "J": "SEA"
        }
    ],
    "SBY": [
        {
            "B": "KSBY",
            "C": "Wicomico Regional",
            "D": "Wicomico Regional",
            "E": "USA",
            "F": "US",
            "G": "T",
            "H": "",
            "I": "",
            "J": "NOA"
        }
    ],
    "SBZ": [
        {
            "B": "LRSB",
            "C": "SIBIU",
            "D": "Sibiu",
            "E": "Romania",
            "F": "RO",
            "G": "E",
            "H": "",
            "I": "",
            "J": "EUR"
        }
    ],
    "SCC": [
        {
            "B": "PASC",
            "C": "Deadhorse, Alaska",
            "D": "Deadhorse",
            "E": "USA",
            "F": "US",
            "G": "T",
            "H": "",
            "I": "",
            "J": "NOA"
        }
    ],
    "SCE": [
        {
            "B": "KUNV",
            "C": "Benner",
            "D": "State College",
            "E": "USA",
            "F": "US",
            "G": "T",
            "H": "",
            "I": "",
            "J": "NOA"
        }
    ],
    "SCH": [
        {
            "B": "KSCH",
            "C": "SCHENECTADY COUNTY",
            "D": "Schenectady County",
            "E": "USA",
            "F": "US",
            "G": "T",
            "H": "",
            "I": "",
            "J": "NOA"
        }
    ],
    "SCL": [
        {
            "B": "SCEL",
            "C": "SANTIAGO INTL AIRPORT",
            "D": "SANTIAGO INTERNATIONAL AIRPORT",
            "E": "Chile",
            "F": "CL",
            "G": "T",
            "H": "",
            "I": "",
            "J": "SOA"
        }
    ],
    "SCN": [
        {
            "B": "EDDR",
            "C": "SAARBRUCKEN ENSHEIM",
            "D": "Saarbrucken",
            "E": "Germany",
            "F": "DE",
            "G": "E",
            "H": "",
            "I": "",
            "J": "EUR"
        }
    ],
    "SCO": [
        {
            "B": "UATE",
            "C": "Aktau",
            "D": "Aktau",
            "E": "Kazakhstan",
            "F": "KZ",
            "G": "O",
            "H": "",
            "I": "",
            "J": "SEA"
        }
    ],
    "SCQ": [
        {
            "B": "LEST",
            "C": "SANTIAGO DE COMPOSTE",
            "D": "Santiago",
            "E": "Spain",
            "F": "ES",
            "G": "E",
            "H": "",
            "I": "",
            "J": "EUR"
        }
    ],
    "SCS": [
        {
            "B": "EGPM",
            "C": "SCATSTA",
            "D": "Scatsta",
            "E": "United Kingdom",
            "F": "GB",
            "G": "U",
            "H": "",
            "I": "",
            "J": "EUR"
        }
    ],
    "SCU": [
        {
            "B": "MUCU",
            "C": "SANTIAGO DE CUBA",
            "D": "Santiago De Cuba",
            "E": "Cuba",
            "F": "CU",
            "G": "T",
            "H": "",
            "I": "",
            "J": "CAR"
        }
    ],
    "SCV": [
        {
            "B": "LRSV",
            "C": "SUCEAVA",
            "D": "Suceava",
            "E": "Romania",
            "F": "RO",
            "G": "E",
            "H": "",
            "I": "",
            "J": "EUR"
        }
    ],
    "SCW": [
        {
            "B": "UUYY",
            "C": "Syktyvkar",
            "D": "Syktyvkar",
            "E": "Russian Federation",
            "F": "RU",
            "G": "E",
            "H": "",
            "I": "",
            "J": "EUR"
        }
    ],
    "SCY": [
        {
            "B": "SEST",
            "C": "SAN CRISTÓBAL",
            "D": "San Cristóbal",
            "E": "Ecuador",
            "F": "EC",
            "G": "T",
            "H": "",
            "I": "",
            "J": "SOA"
        }
    ],
    "SDA": [
        {
            "B": "ORBI",
            "C": "SADDAM INTERNATIONAL",
            "D": "Baghdad",
            "E": "Iraq",
            "F": "IQ",
            "G": "O",
            "H": "",
            "I": "",
            "J": "MDE"
        }
    ],
    "SDF": [
        {
            "B": "KSDF",
            "C": "LOUISVILLE - STANDIFORD",
            "D": "Louisville",
            "E": "USA",
            "F": "US",
            "G": "T",
            "H": "",
            "I": "",
            "J": "NOA"
        }
    ],
    "SDH": [
        {
            "B": "MHRS",
            "C": "SANTA ROSA COPAN",
            "D": "Santa rosa copan",
            "E": "Honduras",
            "F": "HN",
            "G": "T",
            "H": "",
            "I": "",
            "J": ""
        }
    ],
    "SDJ": [
        {
            "B": "RJSS",
            "C": "SENDAI",
            "D": "Sendai",
            "E": "Japan",
            "F": "JP",
            "G": "O",
            "H": "",
            "I": "",
            "J": "JAK"
        }
    ],
    "SDK": [
        {
            "B": "WBKS",
            "C": "Sandakan",
            "D": "Sandakan",
            "E": "Malaysia",
            "F": "MY",
            "G": "O",
            "H": "",
            "I": "",
            "J": "SEA"
        }
    ],
    "SDL": [
        {
            "B": "ESNN",
            "C": "SUNDSVALL-TIMRA",
            "D": "SUNDSVALL-TIMRA AIRPORT",
            "E": "Sweden",
            "F": "SE",
            "G": "E",
            "H": "",
            "I": "",
            "J": "EUR"
        }
    ],
    "SDM": [
        {
            "B": "KSDM",
            "C": "SAN DIEGO - BROWN FIELD",
            "D": "San Diego",
            "E": "USA",
            "F": "US",
            "G": "T",
            "H": "",
            "I": "",
            "J": "NOA"
        }
    ],
    "SDN": [
        {
            "B": "ENSD",
            "C": "Sandane",
            "D": "Sandane",
            "E": "Norway",
            "F": "NO",
            "G": "E",
            "H": "",
            "I": "",
            "J": "EUR"
        }
    ],
    "SDQ": [
        {
            "B": "MDSD",
            "C": "SANTO DOMINGO",
            "D": "Santo domingo",
            "E": "Dominican Republic",
            "F": "DO",
            "G": "T",
            "H": "",
            "I": "",
            "J": "CAR"
        }
    ],
    "SDR": [
        {
            "B": "LEXJ",
            "C": "SANTANDER",
            "D": "Santander",
            "E": "Spain",
            "F": "ES",
            "G": "E",
            "H": "",
            "I": "",
            "J": "EUR"
        }
    ],
    "SDU": [
        {
            "B": "SBRJ",
            "C": "Santos Dumont",
            "D": "Santos Dumont",
            "E": "Brazil",
            "F": "BR",
            "G": "T",
            "H": "",
            "I": "",
            "J": "SOA"
        }
    ],
    "SDV": [
        {
            "B": "LLSD",
            "C": "TEL-AVIV - SDE-DOV AIR",
            "D": "Tel Aviv",
            "E": "Israel",
            "F": "IL",
            "G": "O",
            "H": "",
            "I": "",
            "J": "MDE"
        }
    ],
    "SEA": [
        {
            "B": "KSEA",
            "C": "SEATTLE - TACOMA INTERNATIONAL",
            "D": "Seattle",
            "E": "USA",
            "F": "US",
            "G": "T",
            "H": "",
            "I": "",
            "J": "NOA"
        }
    ],
    "SEB": [
        {
            "B": "HLLS",
            "C": "SEBHA",
            "D": "Sebha",
            "E": "Libya",
            "F": "LY",
            "G": "O",
            "H": "",
            "I": "",
            "J": "AFR"
        }
    ],
    "SEN": [
        {
            "B": "EGMC",
            "C": "LONDON - SOUTHEND",
            "D": "London SEN",
            "E": "United Kingdom",
            "F": "GB1",
            "G": "U",
            "H": "",
            "I": "",
            "J": "EUR"
        }
    ],
    "SEZ": [
        {
            "B": "FSIA",
            "C": "SEYCHELLES INT",
            "D": "SEYCHELLES INTERNATIONAL",
            "E": "Seychelles",
            "F": "SC",
            "G": "O",
            "H": "",
            "I": "",
            "J": "AFR"
        }
    ],
    "SFB": [
        {
            "B": "KSFB",
            "C": "ORLANDO SANFORD",
            "D": "Orlando Sanford",
            "E": "USA",
            "F": "US",
            "G": "T",
            "H": "",
            "I": "",
            "J": "NOA"
        }
    ],
    "SFF": [
        {
            "B": "KSFF",
            "C": "SPOKANE - FELTS FIELD",
            "D": "Spokane",
            "E": "USA",
            "F": "US",
            "G": "T",
            "H": "",
            "I": "",
            "J": "NOA"
        }
    ],
    "SFG": [
        {
            "B": "TFFG",
            "C": "L'ESPÉRANCE",
            "D": "L'Espérance",
            "E": "Saint Martin",
            "F": "MF",
            "G": "O",
            "H": "",
            "I": "",
            "J": "CAR"
        }
    ],
    "SFJ": [
        {
            "B": "BGSF",
            "C": "KANGERLUSSUAQ",
            "D": "Kangerlussuaq",
            "E": "Greenland",
            "F": "GL",
            "G": "E",
            "H": "",
            "I": "",
            "J": "EUR"
        }
    ],
    "SFN": [
        {
            "B": "SAFE",
            "C": "Santa Fe",
            "D": "Sauce Viejo",
            "E": "Argentina",
            "F": "AR",
            "G": "T",
            "H": "",
            "I": "",
            "J": "SOA"
        }
    ],
    "SFO": [
        {
            "B": "KSFO",
            "C": "SAN FRANCISCO INTERNATIONAL",
            "D": "San Francisco",
            "E": "USA",
            "F": "US",
            "G": "T",
            "H": "",
            "I": "",
            "J": "NOA"
        }
    ],
    "SFQ": [
        {
            "B": "LTCH",
            "C": "SANLIURFA",
            "D": "Sanliurfa",
            "E": "Turkey",
            "F": "TR",
            "G": "E",
            "H": "",
            "I": "",
            "J": "EUR"
        }
    ],
    "SFT": [
        {
            "B": "ESNS",
            "C": "Skelleftea",
            "D": "Skelleftea",
            "E": "Sweden",
            "F": "SE",
            "G": "E",
            "H": "",
            "I": "",
            "J": "EUR"
        }
    ],
    "SGC": [
        {
            "B": "USRR",
            "C": "SURGUT",
            "D": "Surgut",
            "E": "RUSSIAN FEDERATION",
            "F": "RU",
            "G": "E",
            "H": "",
            "I": "",
            "J": "EUR"
        }
    ],
    "SGD": [
        {
            "B": "EKSB",
            "C": "SONDERBORG",
            "D": "Sonderborg",
            "E": "Denmark",
            "F": "DK",
            "G": "E",
            "H": "",
            "I": "",
            "J": "EUR"
        }
    ],
    "SGE": [
        {
            "B": "EDGS",
            "C": "SIEGERLAND",
            "D": "Siegerland",
            "E": "Germany",
            "F": "DE",
            "G": "E",
            "H": "",
            "I": "",
            "J": "EUR"
        }
    ],
    "SGF": [
        {
            "B": "KSGF",
            "C": "Springfield-Branson Rg",
            "D": "Springfield Branson Natl",
            "E": "USA",
            "F": "US",
            "G": "T",
            "H": "",
            "I": "",
            "J": "NOA"
        }
    ],
    "SGN": [
        {
            "B": "VVTS",
            "C": "HO CHI MINH CITY",
            "D": "Ho chi minh city",
            "E": "Vietnam",
            "F": "VN",
            "G": "O",
            "H": "",
            "I": "",
            "J": "SEA"
        }
    ],
    "SGU": [
        {
            "B": "KSGU",
            "C": "St. George, Utah",
            "D": "St George Municipal",
            "E": "USA",
            "F": "US",
            "G": "T",
            "H": "",
            "I": "",
            "J": "NOA"
        }
    ],
    "SHA": [
        {
            "B": "ZSSS",
            "C": "SHANGHAI HONGQIAO",
            "D": "Shanghai",
            "E": "Peoples Republic of China",
            "F": "CN",
            "G": "O",
            "H": "",
            "I": "",
            "J": "SEA"
        }
    ],
    "SHD": [
        {
            "B": "KSHD",
            "C": "Shenandoah Valley",
            "D": "Shenandoah Valley Rgnl",
            "E": "USA",
            "F": "US",
            "G": "T",
            "H": "",
            "I": "",
            "J": "NOA"
        }
    ],
    "SHE": [
        {
            "B": "ZYSY",
            "C": "Shenyang",
            "D": "Taoxian International",
            "E": "Peoples Republic of China",
            "F": "CN",
            "G": "O",
            "H": "",
            "I": "",
            "J": "SEA"
        }
    ],
    "SHJ": [
        {
            "B": "OMSJ",
            "C": "SHARJAH",
            "D": "Sharjah",
            "E": "United Arab Emirates",
            "F": "AE",
            "G": "O",
            "H": "",
            "I": "",
            "J": "MDE"
        }
    ],
    "SHO": [
        {
            "B": "FDSK",
            "C": "King Mswati III International",
            "D": "King Mswati III Intl",
            "E": "Swaziland",
            "F": "SZ",
            "G": "O",
            "H": "",
            "I": "",
            "J": "AFR"
        }
    ],
    "SHV": [
        {
            "B": "KSHV",
            "C": "SHREVEPORT REGIONAL",
            "D": "Shreveport regional",
            "E": "USA",
            "F": "US",
            "G": "T",
            "H": "",
            "I": "",
            "J": "NOA"
        }
    ],
    "SHW": [
        {
            "B": "OESH",
            "C": "Sharurah",
            "D": "Sharurah",
            "E": "Saudi Arabia",
            "F": "SA",
            "G": "O",
            "H": "",
            "I": "",
            "J": "MDE"
        }
    ],
    "SIC": [
        {
            "B": "LTCM",
            "C": "SINOP",
            "D": "Sinop",
            "E": "Turkey",
            "F": "TR",
            "G": "E",
            "H": "",
            "I": "",
            "J": "EUR"
        }
    ],
    "SID": [
        {
            "B": "GVAC",
            "C": "AMLICAR CABRAI INTERNATIONAL",
            "D": "Cape Verde",
            "E": "Cape Verde",
            "F": "CV",
            "G": "O",
            "H": "",
            "I": "",
            "J": "AFR"
        }
    ],
    "SIE": [
        {
            "B": "LPSI",
            "C": "SINES",
            "D": "Sines",
            "E": "Portugal",
            "F": "PT",
            "G": "E",
            "H": "",
            "I": "",
            "J": "EUR"
        }
    ],
    "SIG": [
        {
            "B": "TJIG",
            "C": "SAN JUAN - ISLA GRANDE",
            "D": "San juan",
            "E": "Puerto Rico",
            "F": "PR",
            "G": "T",
            "H": "",
            "I": "",
            "J": "CAR"
        }
    ],
    "SII": [
        {
            "B": "GMMF",
            "C": "SIDI IFNI",
            "D": "Sidi Ifni",
            "E": "Morocco",
            "F": "MA",
            "G": "O",
            "H": "",
            "I": "",
            "J": "AFR"
        }
    ],
    "SIN": [
        {
            "B": "WSSS",
            "C": "SINGAPORE - CHANGI",
            "D": "Singapore",
            "E": "Singapore",
            "F": "SG",
            "G": "O",
            "H": "",
            "I": "",
            "J": "SEA"
        }
    ],
    "SIP": [
        {
            "B": "UKFF",
            "C": "SIMFEROPOL INTERNATIONAL",
            "D": "Simferopol",
            "E": "Ukraine",
            "F": "UA",
            "G": "E",
            "H": "",
            "I": "",
            "J": "EUR"
        }
    ],
    "SIR": [
        {
            "B": "LSGS",
            "C": "SION",
            "D": "Sion",
            "E": "Switzerland",
            "F": "CH",
            "G": "E",
            "H": "",
            "I": "",
            "J": "EUR"
        }
    ],
    "SIT": [
        {
            "B": "PASI",
            "C": "Sitka",
            "D": "Rocky Gutierrez",
            "E": "USA",
            "F": "US",
            "G": "T",
            "H": "",
            "I": "",
            "J": "NOA"
        }
    ],
    "SJC": [
        {
            "B": "KSJC",
            "C": "SAN JOSE INTERNATIONAL",
            "D": "San Jose",
            "E": "USA",
            "F": "US",
            "G": "T",
            "H": "",
            "I": "",
            "J": "NOA"
        }
    ],
    "SJD": [
        {
            "B": "MMSD",
            "C": "LOS CABOS",
            "D": "Los Cabos Intl",
            "E": "Mexico",
            "F": "MX",
            "G": "T",
            "H": "",
            "I": "",
            "J": "CEM"
        }
    ],
    "SJJ": [
        {
            "B": "LQSA",
            "C": "SARAJEVO",
            "D": "Sarajevo",
            "E": "Bosnia and Herzegovina",
            "F": "BA",
            "G": "E",
            "H": "",
            "I": "",
            "J": "EUR"
        }
    ],
    "SJO": [
        {
            "B": "MROC",
            "C": "SAN JOSE",
            "D": "San Jose",
            "E": "Costa Rica",
            "F": "CR",
            "G": "T",
            "H": "",
            "I": "",
            "J": "CEM"
        }
    ],
    "SJT": [
        {
            "B": "KSJT",
            "C": "Mathis Fld",
            "D": "Mathis Field",
            "E": "USA",
            "F": "US",
            "G": "T",
            "H": "",
            "I": "",
            "J": "NOA"
        }
    ],
    "SJU": [
        {
            "B": "TJSJ",
            "C": "LUIS MUNOZ MARIN INTL",
            "D": "San juan",
            "E": "Puerto Rico",
            "F": "PR",
            "G": "T",
            "H": "",
            "I": "",
            "J": "CAR"
        }
    ],
    "SJZ": [
        {
            "B": "LPSJ",
            "C": "SAO JORGE",
            "D": "Sao Jorge",
            "E": "Portugal",
            "F": "PT",
            "G": "E",
            "H": "",
            "I": "",
            "J": "EUR"
        }
    ],
    "SK1": [
        {
            "B": "LZMC",
            "C": "MALACKY",
            "D": "Malacky",
            "E": "Slovakia",
            "F": "SK",
            "G": "E",
            "H": "",
            "I": "",
            "J": "EUR"
        }
    ],
    "SKA": [
        {
            "B": "KSKA",
            "C": "SPOKANE - FAIRCHILD",
            "D": "Spokane",
            "E": "USA",
            "F": "US",
            "G": "T",
            "H": "",
            "I": "",
            "J": "NOA"
        }
    ],
    "SKB": [
        {
            "B": "TKPK",
            "C": "ST KITTS AND NEVIS",
            "D": "St kitts",
            "E": "St. Kitts And Nevis",
            "F": "KN",
            "G": "T",
            "H": "",
            "I": "",
            "J": "CAR"
        }
    ],
    "SKD": [
        {
            "B": "UTSS",
            "C": "SAMARKAND",
            "D": "Samarkand",
            "E": "Uzbekistan",
            "F": "UZ",
            "G": "O",
            "H": "",
            "I": "",
            "J": "SEA"
        }
    ],
    "SKE": [
        {
            "B": "ENSN",
            "C": "GEITERYGGEN",
            "D": "Skein",
            "E": "Norway",
            "F": "NO",
            "G": "E",
            "H": "",
            "I": "",
            "J": "EUR"
        }
    ],
    "SKF": [
        {
            "B": "",
            "C": "",
            "D": "",
            "E": "UNKNOWN",
            "F": "",
            "G": "A",
            "H": "",
            "I": "",
            "J": ""
        }
    ],
    "SKG": [
        {
            "B": "LGTS",
            "C": "THESSALONIKI",
            "D": "Thessaloniki",
            "E": "Greece",
            "F": "GR",
            "G": "E",
            "H": "",
            "I": "",
            "J": "EUR"
        }
    ],
    "SKL": [
        {
            "B": "G94Z",
            "C": "BROADFORD",
            "D": "Isle of skye",
            "E": "United Kingdom",
            "F": "GB",
            "G": "U",
            "H": "",
            "I": "",
            "J": "EUR"
        }
    ],
    "SKN": [
        {
            "B": "ENSK",
            "C": "Skagen",
            "D": "Skagen",
            "E": "Norway",
            "F": "NO",
            "G": "E",
            "H": "",
            "I": "",
            "J": "EUR"
        }
    ],
    "SKP": [
        {
            "B": "LWSK",
            "C": "SKOPJE",
            "D": "Skopje",
            "E": "FYR Macedonia",
            "F": "MK",
            "G": "E",
            "H": "",
            "I": "",
            "J": "EUR"
        }
    ],
    "SKS": [
        {
            "B": "EKSP",
            "C": "VOJENS",
            "D": "Vojens",
            "E": "Denmark",
            "F": "DK",
            "G": "E",
            "H": "",
            "I": "",
            "J": "EUR"
        }
    ],
    "SKT": [
        {
            "B": "OPST",
            "C": "Sialkot",
            "D": "Sialkot International",
            "E": "Pakistan",
            "F": "PK",
            "G": "O",
            "H": "",
            "I": "",
            "J": "SAS"
        }
    ],
    "SKU": [
        {
            "B": "LGSY",
            "C": "SKIROS",
            "D": "Skiros",
            "E": "Greece",
            "F": "GR",
            "G": "E",
            "H": "",
            "I": "",
            "J": "EUR"
        }
    ],
    "SKX": [
        {
            "B": "UWPS",
            "C": "SARANSK",
            "D": "Saransk",
            "E": "Russian Federation",
            "F": "RU",
            "G": "E",
            "H": "",
            "I": "",
            "J": "EUR"
        }
    ],
    "SLC": [
        {
            "B": "KSLC",
            "C": "SALT LAKE CITY",
            "D": "Salt Lake City",
            "E": "USA",
            "F": "US",
            "G": "T",
            "H": "",
            "I": "",
            "J": "NOA"
        }
    ],
    "SLD": [
        {
            "B": "LZSL",
            "C": "SLIAC",
            "D": "Sliac",
            "E": "Slovakia",
            "F": "SK",
            "G": "E",
            "H": "",
            "I": "",
            "J": "EUR"
        }
    ],
    "SLE": [
        {
            "B": "KSLE",
            "C": "SALEM-MCNARY FIELD",
            "D": "Salem",
            "E": "USA",
            "F": "US",
            "G": "T",
            "H": "",
            "I": "",
            "J": "NOA"
        }
    ],
    "SLI": [
        {
            "B": "FLSW",
            "C": "SOLWEZI",
            "D": "Solwezi",
            "E": "Zambia",
            "F": "ZM",
            "G": "O",
            "H": "",
            "I": "",
            "J": "AFR"
        }
    ],
    "SLL": [
        {
            "B": "OOSA",
            "C": "Salalah",
            "D": "Salalah",
            "E": "Oman",
            "F": "OM",
            "G": "O",
            "H": "",
            "I": "",
            "J": "MDE"
        }
    ],
    "SLM": [
        {
            "B": "LESA",
            "C": "SALAMANCA",
            "D": "Salamanca",
            "E": "Spain",
            "F": "ES",
            "G": "E",
            "H": "",
            "I": "",
            "J": "EUR"
        }
    ],
    "SLN": [
        {
            "B": "KSLN",
            "C": "SALINA/MUNI",
            "D": "Salina/Muni",
            "E": "USA",
            "F": "US",
            "G": "T",
            "H": "",
            "I": "",
            "J": "NOA"
        }
    ],
    "SLP": [
        {
            "B": "MMSP",
            "C": "San Luis Potosi",
            "D": "Ponciano Arriaga Intl",
            "E": "Mexico",
            "F": "MX",
            "G": "T",
            "H": "",
            "I": "",
            "J": "CEM"
        }
    ],
    "SLU": [
        {
            "B": "TLPC",
            "C": "ST LUCIA - VIGIE",
            "D": "St lucia",
            "E": "Saint Lucia",
            "F": "LC",
            "G": "T",
            "H": "",
            "I": "",
            "J": "CAR"
        }
    ],
    "SLW": [
        {
            "B": "MMIO",
            "C": "Saltillo",
            "D": "Plan de Guadalupe Intl",
            "E": "Mexico",
            "F": "MX",
            "G": "T",
            "H": "",
            "I": "",
            "J": "CEM"
        }
    ],
    "SLY": [
        {
            "B": "USDD",
            "C": "SALEKHARD",
            "D": "Salekhard",
            "E": "Russian Federation",
            "F": "RU",
            "G": "E",
            "H": "",
            "I": "",
            "J": "EUR"
        }
    ],
    "SLZ": [
        {
            "B": "SBSL",
            "C": "Mal. Cunha Machado",
            "D": "Cunha Machado Intl",
            "E": "Brazil",
            "F": "BR",
            "G": "T",
            "H": "",
            "I": "",
            "J": "SOA"
        }
    ],
    "SMA": [
        {
            "B": "LPAZ",
            "C": "SANTA MARIA",
            "D": "Santa Maria",
            "E": "Portugal",
            "F": "PT",
            "G": "E",
            "H": "",
            "I": "",
            "J": "EUR"
        }
    ],
    "SMF": [
        {
            "B": "KSMF",
            "C": "SACRAMENTO",
            "D": "Sacramento",
            "E": "USA",
            "F": "US",
            "G": "T",
            "H": "",
            "I": "",
            "J": "NOA"
        }
    ],
    "SMI": [
        {
            "B": "LGSM",
            "C": "SAMOS",
            "D": "Samos",
            "E": "Greece",
            "F": "GR",
            "G": "E",
            "H": "",
            "I": "",
            "J": "EUR"
        }
    ],
    "SMR": [
        {
            "B": "SKSM",
            "C": "Simon Bolivar",
            "D": "Simon Bolivar Intl",
            "E": "Colombia",
            "F": "CO",
            "G": "T",
            "H": "",
            "I": "",
            "J": "SOA"
        }
    ],
    "SMV": [
        {
            "B": "LSZS",
            "C": "ST. MORITZ SAMEDAN",
            "D": "St Moritz",
            "E": "Switzerland",
            "F": "CH",
            "G": "E",
            "H": "",
            "I": "",
            "J": "EUR"
        }
    ],
    "SMX": [
        {
            "B": "KSMX",
            "C": "Public",
            "D": "Santa Maria Public",
            "E": "USA",
            "F": "US",
            "G": "T",
            "H": "",
            "I": "",
            "J": "NOA"
        }
    ],
    "SNA": [
        {
            "B": "KSNA",
            "C": "SANTA ANA - JOHN WAYNE",
            "D": "Santa ana",
            "E": "USA",
            "F": "US",
            "G": "T",
            "H": "",
            "I": "",
            "J": "NOA"
        }
    ],
    "SNN": [
        {
            "B": "EINN",
            "C": "SHANNON",
            "D": "Shannon",
            "E": "Republic of Ireland",
            "F": "IE",
            "G": "D",
            "H": "",
            "I": "",
            "J": "EUR"
        }
    ],
    "SNR": [
        {
            "B": "LFRZ",
            "C": "ST.NAZAIRE MONTOIR",
            "D": "St nazaire",
            "E": "France",
            "F": "FR",
            "G": "E",
            "H": "",
            "I": "",
            "J": "EUR"
        }
    ],
    "SNU": [
        {
            "B": "MUSC",
            "C": "Santa Clara",
            "D": "Abel Santamaria",
            "E": "Cuba",
            "F": "CU",
            "G": "T",
            "H": "",
            "I": "",
            "J": "CAR"
        }
    ],
    "SOB": [
        {
            "B": "LHSM",
            "C": "SAARMELLEEK",
            "D": "Saarmelleek",
            "E": "Hungary",
            "F": "HU",
            "G": "E",
            "H": "",
            "I": "",
            "J": "EUR"
        }
    ],
    "SOC": [
        {
            "B": "WARQ",
            "C": "SOLO/ADI SUMARMO",
            "D": "Solo/Adi Sumarmo",
            "E": "Indonesia",
            "F": "ID",
            "G": "O",
            "H": "",
            "I": "",
            "J": "SEA"
        }
    ],
    "SOF": [
        {
            "B": "LBSF",
            "C": "SOFIA",
            "D": "Sofia",
            "E": "Bulgaria",
            "F": "BG",
            "G": "E",
            "H": "",
            "I": "",
            "J": "EUR"
        }
    ],
    "SOG": [
        {
            "B": "ENSG",
            "C": "Haukasen",
            "D": "Haukasen",
            "E": "Norway",
            "F": "NO",
            "G": "E",
            "H": "",
            "I": "",
            "J": "EUR"
        }
    ],
    "SOJ": [
        {
            "B": "ENSR",
            "C": "Sorkjosen",
            "D": "Sorkjosen",
            "E": "Norway",
            "F": "NO",
            "G": "E",
            "H": "",
            "I": "",
            "J": "EUR"
        }
    ],
    "SOQ": [
        {
            "B": "WASS",
            "C": "Jefman",
            "D": "Domine Eduard Osok",
            "E": "Indonesia",
            "F": "ID",
            "G": "O",
            "H": "",
            "I": "",
            "J": "SEA"
        }
    ],
    "SOU": [
        {
            "B": "EGHI",
            "C": "SOUTHAMPTON",
            "D": "Southampton",
            "E": "United Kingdom",
            "F": "GB",
            "G": "U",
            "H": "",
            "I": "",
            "J": "EUR"
        }
    ],
    "SOY": [
        {
            "B": "EGER",
            "C": "STRONSAY",
            "D": "Stronsay",
            "E": "United Kingdom",
            "F": "GB",
            "G": "U",
            "H": "",
            "I": "",
            "J": "EUR"
        }
    ],
    "SOZ": [
        {
            "B": "LFKS",
            "C": "SOLENZARA",
            "D": "Solenzara",
            "E": "France",
            "F": "FR",
            "G": "E",
            "H": "",
            "I": "",
            "J": "EUR"
        }
    ],
    "SPC": [
        {
            "B": "GCLA",
            "C": "LA PALMA",
            "D": "LA PALMA",
            "E": "Spain",
            "F": "ES",
            "G": "E",
            "H": "",
            "I": "",
            "J": "EUR"
        }
    ],
    "SPI": [
        {
            "B": "KSPI",
            "C": "Capital",
            "D": "A.Lincoln Capital",
            "E": "USA",
            "F": "US",
            "G": "T",
            "H": "",
            "I": "",
            "J": "NOA"
        }
    ],
    "SPJ": [
        {
            "B": "LGSP",
            "C": "SPARTI",
            "D": "Sparti",
            "E": "Greece",
            "F": "GR",
            "G": "E",
            "H": "",
            "I": "",
            "J": "EUR"
        }
    ],
    "SPM": [
        {
            "B": "ETAD",
            "C": "SPANGDAHLEM",
            "D": "Spangdahlem",
            "E": "Germany",
            "F": "DE",
            "G": "E",
            "H": "",
            "I": "",
            "J": "EUR"
        }
    ],
    "SPN": [
        {
            "B": "PGSN",
            "C": "International",
            "D": "Saipan International",
            "E": "Northern Mariana Islands",
            "F": "MP",
            "G": "T",
            "H": "",
            "I": "",
            "J": "NOA"
        }
    ],
    "SPS": [
        {
            "B": "KSPS",
            "C": "Sheppard AFB",
            "D": "Sheppard Afb",
            "E": "USA",
            "F": "US",
            "G": "T",
            "H": "",
            "I": "",
            "J": "NOA"
        }
    ],
    "SPU": [
        {
            "B": "LDSP",
            "C": "SPLIT",
            "D": "Split",
            "E": "Croatia",
            "F": "HR",
            "G": "E",
            "H": "",
            "I": "",
            "J": "EUR"
        }
    ],
    "SQJ": [
        {
            "B": "ZSSM",
            "C": "SANMING SHAXIAN",
            "D": "Sanming Shaxian",
            "E": "Peoples Republic of China",
            "F": "CN",
            "G": "O",
            "H": "",
            "I": "",
            "J": "SEA"
        }
    ],
    "SQZ": [
        {
            "B": "EGXP",
            "C": "SCAMPTON",
            "D": "Scampton",
            "E": "United Kingdom",
            "F": "GB",
            "G": "U",
            "H": "",
            "I": "",
            "J": "EUR"
        }
    ],
    "SRG": [
        {
            "B": "WARS",
            "C": "Achmad Uani",
            "D": "Ahmad Yani",
            "E": "Indonesia",
            "F": "ID",
            "G": "O",
            "H": "",
            "I": "",
            "J": "SEA"
        }
    ],
    "SRP": [
        {
            "B": "ENSO",
            "C": "STORD",
            "D": "Stord",
            "E": "Norway",
            "F": "NO",
            "G": "E",
            "H": "",
            "I": "",
            "J": "EUR"
        }
    ],
    "SRQ": [
        {
            "B": "KSRQ",
            "C": "SARASOTA - BRADENTON",
            "D": "Sarasota",
            "E": "USA",
            "F": "US",
            "G": "T",
            "H": "",
            "I": "",
            "J": "NOA"
        }
    ],
    "SSA": [
        {
            "B": "SBSV",
            "C": "SALVADOR LUIS E MAGALHAES",
            "D": "Salvador",
            "E": "Brazil",
            "F": "BR",
            "G": "T",
            "H": "",
            "I": "",
            "J": "SOA"
        }
    ],
    "SSC": [
        {
            "B": "KSSC",
            "C": "SHAW AIR FORCE BASE",
            "D": "Shaw air force base",
            "E": "USA",
            "F": "US",
            "G": "T",
            "H": "",
            "I": "",
            "J": "NOA"
        }
    ],
    "SSH": [
        {
            "B": "HESH",
            "C": "SHARM EL SHEIKH-OPHIRA",
            "D": "Sharm El Sheikh",
            "E": "Egypt",
            "F": "EG",
            "G": "O",
            "H": "",
            "I": "",
            "J": "MDE"
        }
    ],
    "SSI": [
        {
            "B": "KSSI",
            "C": "MCKINNON AIRPORT",
            "D": "Malcolm mckinnon airport",
            "E": "USA",
            "F": "",
            "G": "T",
            "H": "",
            "I": "",
            "J": ""
        }
    ],
    "SSJ": [
        {
            "B": "ENST",
            "C": "Stokka",
            "D": "Stokka",
            "E": "Norway",
            "F": "NO",
            "G": "E",
            "H": "",
            "I": "",
            "J": "EUR"
        }
    ],
    "SSN": [
        {
            "B": "RKSM",
            "C": "SINCHON RI",
            "D": "Seoul",
            "E": "Republic of Korea",
            "F": "KR",
            "G": "O",
            "H": "",
            "I": "",
            "J": "JAK"
        }
    ],
    "SSX": [
        {
            "B": "LTAQ",
            "C": "SAMSUN",
            "D": "Samsun",
            "E": "Turkey",
            "F": "TR",
            "G": "E",
            "H": "",
            "I": "",
            "J": "EUR"
        }
    ],
    "STA": [
        {
            "B": "EKVJ",
            "C": "STAUNING",
            "D": "Stauning",
            "E": "Denmark",
            "F": "DK",
            "G": "E",
            "H": "",
            "I": "",
            "J": "EUR"
        }
    ],
    "STC": [
        {
            "B": "KSTC",
            "C": "Municipal",
            "D": "St Cloud Regional",
            "E": "USA",
            "F": "US",
            "G": "T",
            "H": "",
            "I": "",
            "J": "NOA"
        }
    ],
    "STG": [
        {
            "B": "PAPB",
            "C": "ST GEORGE ISLAND",
            "D": "St george island",
            "E": "USA",
            "F": "US",
            "G": "T",
            "H": "",
            "I": "",
            "J": "NOA"
        }
    ],
    "STI": [
        {
            "B": "MDST",
            "C": "Municipal",
            "D": "Cibao Intl",
            "E": "Dominican Republic",
            "F": "DO",
            "G": "T",
            "H": "",
            "I": "",
            "J": "CAR"
        }
    ],
    "STL": [
        {
            "B": "KSTL",
            "C": "ST. LOUIS LAMBERT",
            "D": "St louis",
            "E": "USA",
            "F": "US",
            "G": "T",
            "H": "",
            "I": "",
            "J": "NOA"
        }
    ],
    "STM": [
        {
            "B": "SBSN",
            "C": "SANTAREM EDUARDO GOMES",
            "D": "Santarem",
            "E": "Brazil",
            "F": "BR",
            "G": "T",
            "H": "",
            "I": "",
            "J": "SOA"
        }
    ],
    "STN": [
        {
            "B": "EGSS",
            "C": "LONDON - STANSTED",
            "D": "London STN",
            "E": "United Kingdom",
            "F": "GB1",
            "G": "U",
            "H": "",
            "I": "",
            "J": "EUR"
        }
    ],
    "STP": [
        {
            "B": "KSTP",
            "C": "ST PAULS' - HOLMAN FIELD",
            "D": "St pauls",
            "E": "USA",
            "F": "US",
            "G": "T",
            "H": "",
            "I": "",
            "J": "NOA"
        }
    ],
    "STR": [
        {
            "B": "EDDS",
            "C": "STUTTGART",
            "D": "Stuttgart",
            "E": "Germany",
            "F": "DE",
            "G": "E",
            "H": "",
            "I": "",
            "J": "EUR"
        }
    ],
    "STS": [
        {
            "B": "KSTS",
            "C": "Sonoma County",
            "D": "Sonoma County",
            "E": "USA",
            "F": "US",
            "G": "T",
            "H": "",
            "I": "",
            "J": "NOA"
        }
    ],
    "STT": [
        {
            "B": "TIST",
            "C": "HS TRUMAN",
            "D": "St thomas island",
            "E": "Virgin Islands (US)",
            "F": "VI",
            "G": "T",
            "H": "",
            "I": "",
            "J": "CAR"
        }
    ],
    "STV": [
        {
            "B": "VASU",
            "C": "SURAT",
            "D": "Surat",
            "E": "India",
            "F": "IN",
            "G": "O",
            "H": "",
            "I": "",
            "J": "SAS"
        }
    ],
    "STW": [
        {
            "B": "URMT",
            "C": "Stavropol",
            "D": "Stavropol",
            "E": "Russian Federation",
            "F": "RU",
            "G": "E",
            "H": "",
            "I": "",
            "J": "EUR"
        }
    ],
    "STX": [
        {
            "B": "TISX",
            "C": "St Croix I",
            "D": "Henry E. Rohlsen",
            "E": "Virgin Islands (US)",
            "F": "VI",
            "G": "T",
            "H": "",
            "I": "",
            "J": "CAR"
        }
    ],
    "SUA": [
        {
            "B": "KSUA",
            "C": "STUART WITHAM FIELD",
            "D": "Stuart",
            "E": "USA",
            "F": "US",
            "G": "T",
            "H": "",
            "I": "",
            "J": "NOA"
        }
    ],
    "SUB": [
        {
            "B": "WARR",
            "C": "Juanda",
            "D": "Juanda",
            "E": "Indonesia",
            "F": "ID",
            "G": "O",
            "H": "",
            "I": "",
            "J": "SEA"
        }
    ],
    "SUF": [
        {
            "B": "LICA",
            "C": "LAMEZIA TERME",
            "D": "Lamezia Terme",
            "E": "Italy",
            "F": "IT",
            "G": "E",
            "H": "",
            "I": "",
            "J": "EUR"
        }
    ],
    "SUJ": [
        {
            "B": "LRSM",
            "C": "SATU MARE",
            "D": "Satu Mare",
            "E": "Romania",
            "F": "RO",
            "G": "E",
            "H": "",
            "I": "",
            "J": "EUR"
        }
    ],
    "SUN": [
        {
            "B": "KSUN",
            "C": "Hailey, Idaho",
            "D": "Friedman Memorial",
            "E": "USA",
            "F": "US",
            "G": "T",
            "H": "",
            "I": "",
            "J": "NOA"
        }
    ],
    "SUS": [
        {
            "B": "KSUS",
            "C": "SPIRIT OF ST. LOUIS AIRPORT",
            "D": "St. louis",
            "E": "USA",
            "F": "US",
            "G": "T",
            "H": "",
            "I": "",
            "J": "NOA"
        }
    ],
    "SUV": [
        {
            "B": "NFNA",
            "C": "Nausori",
            "D": "Nausori International",
            "E": "Fiji",
            "F": "FJ",
            "G": "O",
            "H": "",
            "I": "",
            "J": "SWP"
        }
    ],
    "SUX": [
        {
            "B": "KSUX",
            "C": "SIOUX CITY",
            "D": "Sioux City",
            "E": "USA",
            "F": "US",
            "G": "T",
            "H": "",
            "I": "",
            "J": "NOA"
        }
    ],
    "SVB": [
        {
            "B": "FMNS",
            "C": "Sambava",
            "D": "Sambava",
            "E": "Madagascar",
            "F": "MG",
            "G": "O",
            "H": "",
            "I": "",
            "J": "AFR"
        }
    ],
    "SVD": [
        {
            "B": "TVSV",
            "C": "ST VINCENT & THE GRENADINES",
            "D": "St vincents",
            "E": "St Vincent and Grenadines",
            "F": "US",
            "G": "T",
            "H": "",
            "I": "",
            "J": "CAR"
        }
    ],
    "SVG": [
        {
            "B": "ENZV",
            "C": "STAVANGER",
            "D": "Stavanger",
            "E": "Norway",
            "F": "NO",
            "G": "E",
            "H": "",
            "I": "",
            "J": "EUR"
        }
    ],
    "SVH": [
        {
            "B": "KSVH",
            "C": "STATESVILLE REGIONAL AIRPORT",
            "D": "Statesville regional airport",
            "E": "USA",
            "F": "US",
            "G": "T",
            "H": "",
            "I": "",
            "J": "NOA"
        }
    ],
    "SVN": [
        {
            "B": "KSVN",
            "C": "SAVANNAH - HUNTER",
            "D": "Savannah",
            "E": "USA",
            "F": "US",
            "G": "T",
            "H": "",
            "I": "",
            "J": "NOA"
        }
    ],
    "SVO": [
        {
            "B": "UUEE",
            "C": "MOSKVA - SHEREMETYEVO",
            "D": "Moscow",
            "E": "Russian Federation",
            "F": "RU",
            "G": "E",
            "H": "",
            "I": "",
            "J": "EUR"
        }
    ],
    "SVQ": [
        {
            "B": "LEZL",
            "C": "SEVILLE",
            "D": "Seville",
            "E": "Spain",
            "F": "ES",
            "G": "E",
            "H": "",
            "I": "",
            "J": "EUR"
        }
    ],
    "SVU": [
        {
            "B": "NFNS",
            "C": "Savusavu",
            "D": "Savusavu",
            "E": "Fiji",
            "F": "FJ",
            "G": "O",
            "H": "",
            "I": "",
            "J": "SWP"
        }
    ],
    "SVW": [
        {
            "B": "PASV",
            "C": "SPARREVOHN AFS",
            "D": "Sparrevohn",
            "E": "USA",
            "F": "US",
            "G": "T",
            "H": "",
            "I": "",
            "J": "NOA"
        }
    ],
    "SVX": [
        {
            "B": "USSS",
            "C": "KOLTSOVO INTERNATION AIRPORT",
            "D": "Koltsovo international airport - yekaterinburg",
            "E": "RUSSIAN FEDERATION",
            "F": "RU",
            "G": "E",
            "H": "",
            "I": "",
            "J": "EUR"
        }
    ],
    "SWA": [
        {
            "B": "ZGOW",
            "C": "Shantou",
            "D": "Jieyang Chaoshan",
            "E": "Peoples Republic of China",
            "F": "CN",
            "G": "O",
            "H": "",
            "I": "",
            "J": "SEA"
        }
    ],
    "SWF": [
        {
            "B": "KSWF",
            "C": "New York SWF",
            "D": "New York SWF",
            "E": "USA",
            "F": "US",
            "G": "T",
            "H": "",
            "I": "",
            "J": "NOA"
        }
    ],
    "SWS": [
        {
            "B": "EGFH",
            "C": "SWANSEA",
            "D": "Swansea",
            "E": "United Kingdom",
            "F": "GB",
            "G": "U",
            "H": "",
            "I": "",
            "J": "EUR"
        }
    ],
    "SXB": [
        {
            "B": "LFST",
            "C": "STRASBOURG",
            "D": "Strasbourg",
            "E": "France",
            "F": "FR",
            "G": "E",
            "H": "",
            "I": "",
            "J": "EUR"
        }
    ],
    "SXF": [
        {
            "B": "EDDB",
            "C": "BERLIN - SCHOENEFELD",
            "D": "Berlin SXF",
            "E": "Germany",
            "F": "DE",
            "G": "E",
            "H": "",
            "I": "",
            "J": "EUR"
        }
    ],
    "SXL": [
        {
            "B": "EISG",
            "C": "SLIGO",
            "D": "Sligo",
            "E": "Republic of Ireland",
            "F": "IE",
            "G": "D",
            "H": "",
            "I": "",
            "J": "EUR"
        }
    ],
    "SXM": [
        {
            "B": "TNCM",
            "C": "SAINT MAARTEN-PRINCESS JULIANA",
            "D": "Saint maarten",
            "E": "Netherlands Antilles",
            "F": "AN",
            "G": "T",
            "H": "",
            "I": "",
            "J": "CAR"
        }
    ],
    "SXR": [
        {
            "B": "VISR",
            "C": "Srinagar",
            "D": "Sheikh ul Alam",
            "E": "India",
            "F": "IN",
            "G": "O",
            "H": "",
            "I": "",
            "J": "SAS"
        }
    ],
    "SXZ": [
        {
            "B": "LTCL",
            "C": "SIIRT",
            "D": "Siirt",
            "E": "Turkey",
            "F": "TR",
            "G": "E",
            "H": "",
            "I": "",
            "J": "EUR"
        }
    ],
    "SYD": [
        {
            "B": "YSSY",
            "C": "SYDNEY KINGSFORD SMITH",
            "D": "Sydney",
            "E": "Australia",
            "F": "AU",
            "G": "O",
            "H": "",
            "I": "",
            "J": "SWP"
        }
    ],
    "SYK": [
        {
            "B": "BIST",
            "C": "STYKKISHOLMUR",
            "D": "Stykkisholmur",
            "E": "Iceland",
            "F": "IS",
            "G": "E",
            "H": "",
            "I": "",
            "J": "EUR"
        }
    ],
    "SYR": [
        {
            "B": "KSYR",
            "C": "SYRACUSE",
            "D": "Syracuse",
            "E": "USA",
            "F": "US",
            "G": "T",
            "H": "",
            "I": "",
            "J": "NOA"
        }
    ],
    "SYT": [
        {
            "B": "LFLN",
            "C": "SAINT-YAN",
            "D": "Saint Yan",
            "E": "France",
            "F": "FR",
            "G": "E",
            "H": "",
            "I": "",
            "J": "EUR"
        }
    ],
    "SYX": [
        {
            "B": "ZJSY",
            "C": "Sanya",
            "D": "Phoenix International",
            "E": "Peoples Republic of China",
            "F": "CN",
            "G": "O",
            "H": "",
            "I": "",
            "J": "SEA"
        }
    ],
    "SYY": [
        {
            "B": "EGPO",
            "C": "STORNOWAY",
            "D": "Stornoway",
            "E": "United Kingdom",
            "F": "GB",
            "G": "U",
            "H": "",
            "I": "",
            "J": "EUR"
        }
    ],
    "SYZ": [
        {
            "B": "OISS",
            "C": "Shiraz",
            "D": "Shahid Dastghaib International",
            "E": "Iran",
            "F": "IR",
            "G": "O",
            "H": "",
            "I": "",
            "J": "MDE"
        }
    ],
    "SZA": [
        {
            "B": "FNSO",
            "C": "SOYO",
            "D": "Soyo",
            "E": "Angola",
            "F": "AO",
            "G": "O",
            "H": "",
            "I": "",
            "J": "AFR"
        }
    ],
    "SZD": [
        {
            "B": "EGSY",
            "C": "SHEFFIELD",
            "D": "Sheffield",
            "E": "United Kingdom",
            "F": "GB",
            "G": "U",
            "H": "",
            "I": "",
            "J": "EUR"
        }
    ],
    "SZF": [
        {
            "B": "LTFH",
            "C": "SAMSUN CARSAMBA AIRPORT",
            "D": "SAMSUN CARSAMBA AIRPORT",
            "E": "Turkey",
            "F": "",
            "G": "E",
            "H": "",
            "I": "",
            "J": ""
        }
    ],
    "SZG": [
        {
            "B": "LOWS",
            "C": "SALZBURG",
            "D": "Salzburg",
            "E": "Austria",
            "F": "AT",
            "G": "E",
            "H": "",
            "I": "",
            "J": "EUR"
        }
    ],
    "SZS": [
        {
            "B": "NZRC",
            "C": "Ryan's Creek",
            "D": "Stewart Island",
            "E": "New Zealand",
            "F": "NZ",
            "G": "O",
            "H": "",
            "I": "",
            "J": "SWP"
        }
    ],
    "SZW": [
        {
            "B": "EDBP",
            "C": "SCHWERIN-PARCHIM",
            "D": "Pinnow",
            "E": "Germany",
            "F": "DE",
            "G": "E",
            "H": "",
            "I": "",
            "J": "EUR"
        }
    ],
    "SZX": [
        {
            "B": "ZGSZ",
            "C": "SHENZHEN",
            "D": "Shenzhen",
            "E": "Peoples Republic of China",
            "F": "CN",
            "G": "O",
            "H": "",
            "I": "",
            "J": "SEA"
        }
    ],
    "SZY": [
        {
            "B": "EPSY",
            "C": "SZYMANY / MAZURY",
            "D": "Szymany",
            "E": "Poland",
            "F": "PL",
            "G": "E",
            "H": "",
            "I": "",
            "J": "EUR"
        }
    ],
    "SZZ": [
        {
            "B": "EPSC",
            "C": "SZCZECIN",
            "D": "Szczecin",
            "E": "Poland",
            "F": "PL",
            "G": "E",
            "H": "",
            "I": "",
            "J": "EUR"
        }
    ],
    "TAB": [
        {
            "B": "TTCP",
            "C": "Tobago",
            "D": "ANR Robinson International",
            "E": "Trinidad And Tobago",
            "F": "TT",
            "G": "T",
            "H": "",
            "I": "",
            "J": "CAR"
        }
    ],
    "TAC": [
        {
            "B": "RPVA",
            "C": "D.Z. Romualdez",
            "D": "D Z Romualdez",
            "E": "Philippines",
            "F": "PH",
            "G": "O",
            "H": "",
            "I": "",
            "J": "SEA"
        }
    ],
    "TAE": [
        {
            "B": "RKTN",
            "C": "DAEGU",
            "D": "Daegu",
            "E": "Republic of Korea",
            "F": "KR",
            "G": "O",
            "H": "",
            "I": "",
            "J": "JAK"
        }
    ],
    "TAG": [
        {
            "B": "RPVT",
            "C": "Tagbilaran",
            "D": "Tagbilaran",
            "E": "Philippines",
            "F": "PH",
            "G": "O",
            "H": "",
            "I": "",
            "J": "SEA"
        }
    ],
    "TAI": [
        {
            "B": "OYTZ",
            "C": "TAIZ",
            "D": "Taiz",
            "E": "Republic of Yemen",
            "F": "YE",
            "G": "O",
            "H": "",
            "I": "",
            "J": "MDE"
        }
    ],
    "TAK": [
        {
            "B": "RJOT",
            "C": "Takamatsu",
            "D": "Takamatsu",
            "E": "Japan",
            "F": "JP",
            "G": "O",
            "H": "",
            "I": "",
            "J": "JAK"
        }
    ],
    "TAM": [
        {
            "B": "MMTM",
            "C": "Gen F Javier Mina",
            "D": "F. Javier Mina Intl",
            "E": "Mexico",
            "F": "MX",
            "G": "T",
            "H": "",
            "I": "",
            "J": "CEM"
        }
    ],
    "TAO": [
        {
            "B": "ZSQD",
            "C": "QINGDOA",
            "D": "Qingdoa",
            "E": "Peoples Republic of China",
            "F": "CN",
            "G": "O",
            "H": "",
            "I": "",
            "J": "SEA"
        }
    ],
    "TAP": [
        {
            "B": "MMTP",
            "C": "International",
            "D": "Tapachula East International",
            "E": "Mexico",
            "F": "MX",
            "G": "T",
            "H": "",
            "I": "",
            "J": "CEM"
        }
    ],
    "TAR": [
        {
            "B": "LIBG",
            "C": "TARANTO / GROTTAGLIE",
            "D": "Taranto",
            "E": "Italy",
            "F": "IT",
            "G": "E",
            "H": "",
            "I": "",
            "J": "EUR"
        }
    ],
    "TAS": [
        {
            "B": "UTTT",
            "C": "TASHKENT YUZHNY",
            "D": "Tashkent",
            "E": "Uzbekistan",
            "F": "UZ",
            "G": "O",
            "H": "",
            "I": "",
            "J": "SEA"
        }
    ],
    "TAT": [
        {
            "B": "LZTT",
            "C": "TATRY / POPRAD",
            "D": "Poprad",
            "E": "Slovakia",
            "F": "SK",
            "G": "E",
            "H": "",
            "I": "",
            "J": "EUR"
        }
    ],
    "TAY": [
        {
            "B": "EETU",
            "C": "TARTU / RAADI",
            "D": "Tartu",
            "E": "Estonia",
            "F": "EE",
            "G": "E",
            "H": "",
            "I": "",
            "J": "EUR"
        }
    ],
    "TBN": [
        {
            "B": "KTBN",
            "C": "Forney AAF",
            "D": "Waynesvl-St Robert Rgn",
            "E": "USA",
            "F": "US",
            "G": "T",
            "H": "",
            "I": "",
            "J": "NOA"
        }
    ],
    "TBS": [
        {
            "B": "UGTB",
            "C": "TBILISI",
            "D": "Tbilisi",
            "E": "Georgia",
            "F": "GE",
            "G": "E",
            "H": "",
            "I": "",
            "J": "EUR"
        }
    ],
    "TBU": [
        {
            "B": "NFTN",
            "C": "Fua'Amotu International",
            "D": "Fua'Amotu",
            "E": "Tonga",
            "F": "TO",
            "G": "O",
            "H": "",
            "I": "",
            "J": "SWP"
        }
    ],
    "TBZ": [
        {
            "B": "OITT",
            "C": "Tabriz",
            "D": "Tabriz International",
            "E": "Iran",
            "F": "IR",
            "G": "O",
            "H": "",
            "I": "",
            "J": "MDE"
        }
    ],
    "TCE": [
        {
            "B": "LRTC",
            "C": "TULCEA",
            "D": "Tulcea",
            "E": "Romania",
            "F": "RO",
            "G": "E",
            "H": "",
            "I": "",
            "J": "EUR"
        }
    ],
    "TCM": [
        {
            "B": "KTCM",
            "C": "McCHORD FIELD AIRPORT",
            "D": "McCHORD FIELD AIRPORT",
            "E": "USA",
            "F": "US",
            "G": "T",
            "H": "",
            "I": "",
            "J": "NOA"
        }
    ],
    "TDX": [
        {
            "B": "VTBV",
            "C": "Trat",
            "D": "Trat Airport",
            "E": "Thailand",
            "F": "TH",
            "G": "O",
            "H": "",
            "I": "",
            "J": "SEA"
        }
    ],
    "TEB": [
        {
            "B": "KTEB",
            "C": "TETERBORO",
            "D": "Teterboro",
            "E": "USA",
            "F": "US",
            "G": "T",
            "H": "",
            "I": "",
            "J": "NOA"
        }
    ],
    "TED": [
        {
            "B": "EKTS",
            "C": "THISTED",
            "D": "Thisted",
            "E": "Denmark",
            "F": "DK",
            "G": "E",
            "H": "",
            "I": "",
            "J": "EUR"
        }
    ],
    "TEQ": [
        {
            "B": "LTBU",
            "C": "TEKIRDAG CORLU",
            "D": "TEKIRDAG CORLU AIRPORT",
            "E": "Turkey",
            "F": "TR",
            "G": "E",
            "H": "",
            "I": "",
            "J": "EUR"
        }
    ],
    "TER": [
        {
            "B": "LPLA",
            "C": "LAJES TECEIRA ACORES",
            "D": "Lajes",
            "E": "Portugal",
            "F": "PT",
            "G": "E",
            "H": "",
            "I": "",
            "J": "EUR"
        }
    ],
    "TET": [
        {
            "B": "FQTT",
            "C": "Tete",
            "D": "Chingozi",
            "E": "Mozambique",
            "F": "MZ",
            "G": "O",
            "H": "",
            "I": "",
            "J": "AFR"
        }
    ],
    "TEV": [
        {
            "B": "LETL",
            "C": "TERUEL AIRPORT",
            "D": "TERUEL AIRPORT",
            "E": "Spain",
            "F": "ES",
            "G": "E",
            "H": "",
            "I": "",
            "J": "EUR"
        }
    ],
    "TFN": [
        {
            "B": "GCXO",
            "C": "TENERIFE NORTE / LOS RODEOS",
            "D": "Tenerife",
            "E": "Spain",
            "F": "ES",
            "G": "E",
            "H": "",
            "I": "",
            "J": "EUR"
        }
    ],
    "TFS": [
        {
            "B": "GCTS",
            "C": "TENERIFE SOUTH",
            "D": "Tenerife",
            "E": "Spain",
            "F": "ES",
            "G": "E",
            "H": "",
            "I": "",
            "J": "EUR"
        }
    ],
    "TGD": [
        {
            "B": "LYPG",
            "C": "PODGORICA",
            "D": "Podgorica",
            "E": "MONTENEGRO",
            "F": "ME",
            "G": "E",
            "H": "",
            "I": "",
            "J": "EUR"
        }
    ],
    "TGG": [
        {
            "B": "WMKN",
            "C": "Sultan Mahmood",
            "D": "Sultan Mahmud",
            "E": "Malaysia",
            "F": "MY",
            "G": "O",
            "H": "",
            "I": "",
            "J": "SEA"
        }
    ],
    "TGM": [
        {
            "B": "LRTM",
            "C": "Targu-Mures",
            "D": "Tirgu Mures",
            "E": "Romania",
            "F": "RO",
            "G": "E",
            "H": "",
            "I": "",
            "J": "EUR"
        }
    ],
    "TGU": [
        {
            "B": "MHTG",
            "C": "TONCONTIN INTL AIRPORT",
            "D": "TONCONTIN INTL AIRPORT",
            "E": "Honduras",
            "F": "HN",
            "G": "T",
            "H": "",
            "I": "",
            "J": "CEM"
        }
    ],
    "TGZ": [
        {
            "B": "MMTG",
            "C": "Llano San Juan",
            "D": "Angel Albino Corzo Intl",
            "E": "Mexico",
            "F": "MX",
            "G": "T",
            "H": "",
            "I": "",
            "J": "CEM"
        }
    ],
    "THE": [
        {
            "B": "SBTE",
            "C": "Teresina",
            "D": "Petronio Portella",
            "E": "Brazil",
            "F": "BR",
            "G": "T",
            "H": "",
            "I": "",
            "J": "SOA"
        }
    ],
    "THF": [
        {
            "B": "EDDI",
            "C": "BERLIN - TEMPELHOF",
            "D": "Berlin",
            "E": "Germany",
            "F": "DE",
            "G": "E",
            "H": "",
            "I": "",
            "J": "EUR"
        }
    ],
    "THN": [
        {
            "B": "ESGT",
            "C": "TROLLHATTAN",
            "D": "Trollhattan",
            "E": "Sweden",
            "F": "SE",
            "G": "E",
            "H": "",
            "I": "",
            "J": "EUR"
        }
    ],
    "THR": [
        {
            "B": "OIII",
            "C": "TEHRAN - MEHRABAD",
            "D": "Tehran",
            "E": "Iran",
            "F": "IR",
            "G": "O",
            "H": "",
            "I": "",
            "J": "MDE"
        }
    ],
    "THS": [
        {
            "B": "VTPO",
            "C": "SUKHOTHAI",
            "D": "Sukhothai",
            "E": "Thailand",
            "F": "TH",
            "G": "O",
            "H": "",
            "I": "",
            "J": "SEA"
        }
    ],
    "TIA": [
        {
            "B": "LATI",
            "C": "TIRANA RINAS",
            "D": "Tirana",
            "E": "Albania",
            "F": "AL",
            "G": "E",
            "H": "",
            "I": "",
            "J": "EUR"
        }
    ],
    "TIF": [
        {
            "B": "OETF",
            "C": "Taif",
            "D": "Taif",
            "E": "Saudi Arabia",
            "F": "SA",
            "G": "O",
            "H": "",
            "I": "",
            "J": "MDE"
        }
    ],
    "TIJ": [
        {
            "B": "MMTJ",
            "C": "TIJUANA",
            "D": "Tijuana",
            "E": "Mexico",
            "F": "MX",
            "G": "T",
            "H": "",
            "I": "",
            "J": "CEM"
        }
    ],
    "TIM": [
        {
            "B": "WAYY",
            "C": "Moses Kilangin",
            "D": "Moses Kilangin",
            "E": "Indonesia",
            "F": "ID",
            "G": "O",
            "H": "",
            "I": "",
            "J": "SEA"
        }
    ],
    "TIP": [
        {
            "B": "HLLT",
            "C": "TRIPOLI INTERNATIONAL",
            "D": "Tripoli",
            "E": "Libya",
            "F": "LY",
            "G": "O",
            "H": "",
            "I": "",
            "J": "AFR"
        }
    ],
    "TIV": [
        {
            "B": "LYTV",
            "C": "TIVAT",
            "D": "Tivat",
            "E": "MONTENEGRO",
            "F": "ME",
            "G": "E",
            "H": "",
            "I": "",
            "J": "EUR"
        }
    ],
    "TJK": [
        {
            "B": "LTAW",
            "C": "TOKAT",
            "D": "Tokat",
            "E": "Turkey",
            "F": "TR",
            "G": "E",
            "H": "",
            "I": "",
            "J": "EUR"
        }
    ],
    "TKG": [
        {
            "B": "WICT",
            "C": "Branti",
            "D": "Radin Inten II",
            "E": "Indonesia",
            "F": "ID",
            "G": "O",
            "H": "",
            "I": "",
            "J": "SEA"
        }
    ],
    "TKI": [
        {
            "B": "KTKI",
            "C": "COLLIN COUNTY REGIONAL",
            "D": "McKinney",
            "E": "USA",
            "F": "US",
            "G": "T",
            "H": "",
            "I": "",
            "J": "NOA"
        }
    ],
    "TKK": [
        {
            "B": "PTKK",
            "C": "CHUUK",
            "D": "Chuuk",
            "E": "Micronesia (Federated States of)",
            "F": "FM",
            "G": "O",
            "H": "",
            "I": "",
            "J": "SWP"
        }
    ],
    "TKS": [
        {
            "B": "RJOS",
            "C": "Tokushima",
            "D": "Tokushima",
            "E": "Japan",
            "F": "JP",
            "G": "O",
            "H": "",
            "I": "",
            "J": "JAK"
        }
    ],
    "TKU": [
        {
            "B": "EFTU",
            "C": "TURKU",
            "D": "Turku",
            "E": "Finland",
            "F": "FI",
            "G": "E",
            "H": "",
            "I": "",
            "J": "EUR"
        }
    ],
    "TLC": [
        {
            "B": "MMTO",
            "C": "ADOLFO LOPEZ MATEOS INT",
            "D": "Toluca",
            "E": "Mexico",
            "F": "MX",
            "G": "T",
            "H": "",
            "I": "",
            "J": "CEM"
        }
    ],
    "TLH": [
        {
            "B": "KTLH",
            "C": "TALLAHASSEE REGIONAL",
            "D": "Tallahassee",
            "E": "USA",
            "F": "US",
            "G": "T",
            "H": "",
            "I": "",
            "J": "NOA"
        }
    ],
    "TLL": [
        {
            "B": "EETN",
            "C": "TALLINN",
            "D": "Tallinn",
            "E": "Estonia",
            "F": "EE",
            "G": "E",
            "H": "",
            "I": "",
            "J": "EUR"
        }
    ],
    "TLM": [
        {
            "B": "DAON",
            "C": "Tlemcen",
            "D": "Zenata",
            "E": "Algeria",
            "F": "DZ",
            "G": "O",
            "H": "",
            "I": "",
            "J": "AFR"
        }
    ],
    "TLN": [
        {
            "B": "LFTH",
            "C": "TOULON",
            "D": "Toulon",
            "E": "France",
            "F": "FR",
            "G": "E",
            "H": "",
            "I": "",
            "J": "EUR"
        }
    ],
    "TLS": [
        {
            "B": "LFBO",
            "C": "TOULOUSE",
            "D": "Toulouse",
            "E": "France",
            "F": "FR",
            "G": "E",
            "H": "",
            "I": "",
            "J": "EUR"
        }
    ],
    "TLV": [
        {
            "B": "LLBG",
            "C": "TEL-AVIV - BEN GURION",
            "D": "Tel Aviv",
            "E": "Israel",
            "F": "IL",
            "G": "O",
            "H": "",
            "I": "",
            "J": "MDE"
        }
    ],
    "TMM": [
        {
            "B": "FMMT",
            "C": "Toamasina",
            "D": "Toamasina",
            "E": "Madagascar",
            "F": "MG",
            "G": "O",
            "H": "",
            "I": "",
            "J": "AFR"
        }
    ],
    "TMP": [
        {
            "B": "EFTP",
            "C": "TAMPERE-PIRKKALA",
            "D": "Tampere",
            "E": "Finland",
            "F": "FI",
            "G": "E",
            "H": "",
            "I": "",
            "J": "EUR"
        }
    ],
    "TMR": [
        {
            "B": "DAAT",
            "C": "AGUENAR",
            "D": "AGUENAR - HADJ BEY AKHAMOK AIRPORT",
            "E": "Algeria",
            "F": "DZ",
            "G": "O",
            "H": "",
            "I": "",
            "J": "AFR"
        }
    ],
    "TMS": [
        {
            "B": "FPST",
            "C": "Sao Tome Is",
            "D": "Sao Tome International Airport",
            "E": "Sao Tome And Principe",
            "F": "ST",
            "G": "O",
            "H": "",
            "I": "",
            "J": "AFR"
        }
    ],
    "TMW": [
        {
            "B": "YSTW",
            "C": "Tamworth",
            "D": "Tamworth",
            "E": "Australia",
            "F": "AU",
            "G": "O",
            "H": "",
            "I": "",
            "J": "SWP"
        }
    ],
    "TNA": [
        {
            "B": "ZSTN",
            "C": "Jinan",
            "D": "Yaoqiang International",
            "E": "Peoples Republic of China",
            "F": "CN",
            "G": "O",
            "H": "",
            "I": "",
            "J": "SEA"
        }
    ],
    "TNF": [
        {
            "B": "LFPN",
            "C": "TOUSSUS-LE-NOBLE",
            "D": "Toussus Le Noble",
            "E": "France",
            "F": "FR",
            "G": "E",
            "H": "",
            "I": "",
            "J": "EUR"
        }
    ],
    "TNG": [
        {
            "B": "GMTT",
            "C": "TANGIER",
            "D": "Tangier",
            "E": "Morocco",
            "F": "MA",
            "G": "O",
            "H": "",
            "I": "",
            "J": "AFR"
        }
    ],
    "TNN": [
        {
            "B": "RCNN",
            "C": "Tainan",
            "D": "Tainan",
            "E": "Taiwan, Province of China",
            "F": "TW",
            "G": "O",
            "H": "",
            "I": "",
            "J": "SEA"
        }
    ],
    "TNR": [
        {
            "B": "FMMI",
            "C": "ANTANARIVO",
            "D": "Antananarivo",
            "E": "Madagascar",
            "F": "MG",
            "G": "O",
            "H": "",
            "I": "",
            "J": "AFR"
        }
    ],
    "TOE": [
        {
            "B": "DTTZ",
            "C": "TOZEUR- NEFTA",
            "D": "Tozeur",
            "E": "Tunisia",
            "F": "TN",
            "G": "O",
            "H": "",
            "I": "",
            "J": "AFR"
        }
    ],
    "TOF": [
        {
            "B": "UNTT",
            "C": "Bogashevo",
            "D": "Tomsk",
            "E": "Russian Federation",
            "F": "RU",
            "G": "E",
            "H": "",
            "I": "",
            "J": "EUR"
        }
    ],
    "TOJ": [
        {
            "B": "LETO",
            "C": "MADRID - TORREJON",
            "D": "Madrid",
            "E": "Spain",
            "F": "ES",
            "G": "E",
            "H": "",
            "I": "",
            "J": "EUR"
        }
    ],
    "TOL": [
        {
            "B": "KTOL",
            "C": "TOLEDO / EXPRESS",
            "D": "Toledo",
            "E": "USA",
            "F": "US",
            "G": "T",
            "H": "",
            "I": "",
            "J": "NOA"
        }
    ],
    "TOS": [
        {
            "B": "ENTC",
            "C": "TROMSO",
            "D": "Tromso",
            "E": "Norway",
            "F": "NO",
            "G": "E",
            "H": "",
            "I": "",
            "J": "EUR"
        }
    ],
    "TOY": [
        {
            "B": "RJNT",
            "C": "Toyama",
            "D": "Toyama",
            "E": "Japan",
            "F": "JP",
            "G": "O",
            "H": "",
            "I": "",
            "J": "JAK"
        }
    ],
    "TPA": [
        {
            "B": "KTPA",
            "C": "TAMPA INTERNATIONAL",
            "D": "Tampa",
            "E": "USA",
            "F": "US",
            "G": "T",
            "H": "",
            "I": "",
            "J": "NOA"
        }
    ],
    "TPC": [
        {
            "B": "SETR",
            "C": "TARAPOA",
            "D": "Tarapoa",
            "E": "Ecuador",
            "F": "EC",
            "G": "T",
            "H": "",
            "I": "",
            "J": "SOA"
        }
    ],
    "TPE": [
        {
            "B": "RCTP",
            "C": "TAIPEI",
            "D": "Taiwan Taoyuan International Airport",
            "E": "Taiwan, Province of China",
            "F": "TW",
            "G": "O",
            "H": "",
            "I": "",
            "J": "SEA"
        }
    ],
    "TPP": [
        {
            "B": "SPST",
            "C": "TARAPOTO",
            "D": "Tarapoto/Cdte. Guillermo Del Castillo Paredes",
            "E": "Peru",
            "F": "PE",
            "G": "T",
            "H": "",
            "I": "",
            "J": "SOA"
        }
    ],
    "TPS": [
        {
            "B": "LICT",
            "C": "TRAPANI",
            "D": "Trapani",
            "E": "Italy",
            "F": "IT",
            "G": "E",
            "H": "",
            "I": "",
            "J": "EUR"
        }
    ],
    "TRC": [
        {
            "B": "MMTC",
            "C": "Torreon",
            "D": "Francisco Sarabia Intl",
            "E": "Mexico",
            "F": "MX",
            "G": "T",
            "H": "",
            "I": "",
            "J": "CEM"
        }
    ],
    "TRD": [
        {
            "B": "ENVA",
            "C": "TRONDHEIM / VAERNES",
            "D": "Trondheim",
            "E": "Norway",
            "F": "NO",
            "G": "E",
            "H": "",
            "I": "",
            "J": "EUR"
        }
    ],
    "TRE": [
        {
            "B": "EGPU",
            "C": "TIREE",
            "D": "Tiree",
            "E": "United Kingdom",
            "F": "GB",
            "G": "U",
            "H": "",
            "I": "",
            "J": "EUR"
        }
    ],
    "TRF": [
        {
            "B": "ENTO",
            "C": "OSLO - SANDEFJORD",
            "D": "Oslo Sandefjor",
            "E": "Norway",
            "F": "NO",
            "G": "E",
            "H": "",
            "I": "",
            "J": "EUR"
        }
    ],
    "TRG": [
        {
            "B": "NZTG",
            "C": "Tauranga",
            "D": "Tauranga",
            "E": "New Zealand",
            "F": "NZ",
            "G": "O",
            "H": "",
            "I": "",
            "J": "SWP"
        }
    ],
    "TRN": [
        {
            "B": "LIMF",
            "C": "TURIN",
            "D": "Turin",
            "E": "Italy",
            "F": "IT",
            "G": "E",
            "H": "",
            "I": "",
            "J": "EUR"
        }
    ],
    "TRS": [
        {
            "B": "LIVT",
            "C": "TRIESTE",
            "D": "Trieste",
            "E": "Italy",
            "F": "IT",
            "G": "E",
            "H": "",
            "I": "",
            "J": "EUR"
        }
    ],
    "TRU": [
        {
            "B": "SPRU",
            "C": "Trujillo",
            "D": "C.Martinez de Pinillos",
            "E": "Peru",
            "F": "PE",
            "G": "T",
            "H": "",
            "I": "",
            "J": "SOA"
        }
    ],
    "TRV": [
        {
            "B": "VOTV",
            "C": "Thiruvananthapuram (Kerala)",
            "D": "Trivandrum International",
            "E": "India",
            "F": "IN",
            "G": "O",
            "H": "",
            "I": "",
            "J": "SAS"
        }
    ],
    "TRW": [
        {
            "B": "NGTA",
            "C": "Bonriki",
            "D": "Bonriki International",
            "E": "Kiribati",
            "F": "KI",
            "G": "O",
            "H": "",
            "I": "",
            "J": "SWP"
        }
    ],
    "TRZ": [
        {
            "B": "VOTR",
            "C": "Tiruchirapalli (Tamil Nadu)",
            "D": "Tiruchchirappalli",
            "E": "India",
            "F": "IN",
            "G": "O",
            "H": "",
            "I": "",
            "J": "SAS"
        }
    ],
    "TSA": [
        {
            "B": "RCSS",
            "C": "Taipei",
            "D": "Songshan",
            "E": "Taiwan, Province of China",
            "F": "TW",
            "G": "O",
            "H": "",
            "I": "",
            "J": "SEA"
        }
    ],
    "TSB": [
        {
            "B": "FYTM",
            "C": "TSUMEB",
            "D": "Tsumeb",
            "E": "Namibia",
            "F": "NA",
            "G": "O",
            "H": "",
            "I": "",
            "J": "AFR"
        }
    ],
    "TSE": [
        {
            "B": "",
            "C": "ASTANA",
            "D": "Astana",
            "E": "Kazakhstan",
            "F": "KZ",
            "G": "O",
            "H": "",
            "I": "",
            "J": "SEA"
        }
    ],
    "TSF": [
        {
            "B": "LIPH",
            "C": "VENICE - TREVISO",
            "D": "Venice TSF",
            "E": "Italy",
            "F": "IT",
            "G": "E",
            "H": "",
            "I": "",
            "J": "EUR"
        }
    ],
    "TSN": [
        {
            "B": "ZBTJ",
            "C": "TIANJIN BINHAI AIRPORT",
            "D": "TIANJIN BINHAI INTERNATIONAL AIRPORT",
            "E": "Peoples Republic of China",
            "F": "CN",
            "G": "O",
            "H": "",
            "I": "",
            "J": "SEA"
        }
    ],
    "TSR": [
        {
            "B": "LRTR",
            "C": "TIMISOARA",
            "D": "Timisoara",
            "E": "Romania",
            "F": "RO",
            "G": "E",
            "H": "",
            "I": "",
            "J": "EUR"
        }
    ],
    "TSV": [
        {
            "B": "YBTL",
            "C": "Townsville",
            "D": "Townsville International",
            "E": "Australia",
            "F": "AU",
            "G": "O",
            "H": "",
            "I": "",
            "J": "SWP"
        }
    ],
    "TTA": [
        {
            "B": "KTTA",
            "C": "SANFORD-LEE COUNTY REGIONAL",
            "D": "Sanford nc",
            "E": "USA",
            "F": "US",
            "G": "T",
            "H": "",
            "I": "",
            "J": "NOA"
        }
    ],
    "TTB": [
        {
            "B": "LIET",
            "C": "TORTOLI",
            "D": "Tortoli",
            "E": "Italy",
            "F": "IT",
            "G": "E",
            "H": "",
            "I": "",
            "J": "EUR"
        }
    ],
    "TTJ": [
        {
            "B": "RJOR",
            "C": "Tottori",
            "D": "Tottori",
            "E": "Japan",
            "F": "JP",
            "G": "O",
            "H": "",
            "I": "",
            "J": "JAK"
        }
    ],
    "TTN": [
        {
            "B": "KTTN",
            "C": "TRENTON- MERCER COUNTY",
            "D": "Trenton",
            "E": "USA",
            "F": "US",
            "G": "T",
            "H": "",
            "I": "",
            "J": "NOA"
        }
    ],
    "TTU": [
        {
            "B": "GMTN",
            "C": "TETOUAN AIRPORT",
            "D": "TETOUAN AIRPORT",
            "E": "Morocco",
            "F": "MA",
            "G": "O",
            "H": "",
            "I": "",
            "J": "AFR"
        }
    ],
    "TUC": [
        {
            "B": "SANT",
            "C": "Benj Matienzo",
            "D": "Benjamin Matienzo",
            "E": "Argentina",
            "F": "AR",
            "G": "T",
            "H": "",
            "I": "",
            "J": "SOA"
        }
    ],
    "TUF": [
        {
            "B": "LFOT",
            "C": "TOURS",
            "D": "Tours",
            "E": "France",
            "F": "FR",
            "G": "E",
            "H": "",
            "I": "",
            "J": "EUR"
        }
    ],
    "TUI": [
        {
            "B": "OETR",
            "C": "Turaif",
            "D": "Turaif",
            "E": "Saudi Arabia",
            "F": "SA",
            "G": "O",
            "H": "",
            "I": "",
            "J": "MDE"
        }
    ],
    "TUL": [
        {
            "B": "KTUL",
            "C": "TULSA INTERNATIONAL",
            "D": "Tulsa",
            "E": "USA",
            "F": "US",
            "G": "T",
            "H": "",
            "I": "",
            "J": "NOA"
        }
    ],
    "TUN": [
        {
            "B": "DTTA",
            "C": "TUNIS",
            "D": "Tunis",
            "E": "Tunisia",
            "F": "TN",
            "G": "O",
            "H": "",
            "I": "",
            "J": "AFR"
        }
    ],
    "TUO": [
        {
            "B": "NZAP",
            "C": "Taupo",
            "D": "Taupo",
            "E": "New Zealand",
            "F": "NZ",
            "G": "O",
            "H": "",
            "I": "",
            "J": "SWP"
        }
    ],
    "TUP": [
        {
            "B": "KTUP",
            "C": "TUPELO REGIONAL",
            "D": "Tupelo regional",
            "E": "USA",
            "F": "US",
            "G": "T",
            "H": "",
            "I": "",
            "J": "NOA"
        }
    ],
    "TUU": [
        {
            "B": "OETB",
            "C": "Tabuk",
            "D": "Tabuk",
            "E": "Saudi Arabia",
            "F": "SA",
            "G": "O",
            "H": "",
            "I": "",
            "J": "MDE"
        }
    ],
    "TVC": [
        {
            "B": "KTVC",
            "C": "Traverse City",
            "D": "Cherry Capital",
            "E": "USA",
            "F": "US",
            "G": "T",
            "H": "",
            "I": "",
            "J": "NOA"
        }
    ],
    "TWF": [
        {
            "B": "KTWF",
            "C": "City County",
            "D": "Magic Valley Regional",
            "E": "USA",
            "F": "US",
            "G": "T",
            "H": "",
            "I": "",
            "J": "NOA"
        }
    ],
    "TWU": [
        {
            "B": "WBKW",
            "C": "Tawau",
            "D": "Tawau",
            "E": "Malaysia",
            "F": "MY",
            "G": "O",
            "H": "",
            "I": "",
            "J": "SEA"
        }
    ],
    "TXK": [
        {
            "B": "KTXK",
            "C": "Municipal",
            "D": "Regional - Webb Field",
            "E": "USA",
            "F": "US",
            "G": "T",
            "H": "",
            "I": "",
            "J": "NOA"
        }
    ],
    "TXL": [
        {
            "B": "EDDT",
            "C": "BERLIN - TEGEL",
            "D": "Berlin",
            "E": "Germany",
            "F": "DE",
            "G": "E",
            "H": "",
            "I": "",
            "J": "EUR"
        }
    ],
    "TYL": [
        {
            "B": "SPYL",
            "C": "TALARA/CAPITAN MONTES",
            "D": "Talara/Capitan Montes",
            "E": "Peru",
            "F": "PE",
            "G": "T",
            "H": "",
            "I": "",
            "J": "SOA"
        }
    ],
    "TYN": [
        {
            "B": "ZBYN",
            "C": "Taiyuan",
            "D": "Wusu International",
            "E": "Peoples Republic of China",
            "F": "CN",
            "G": "O",
            "H": "",
            "I": "",
            "J": "SEA"
        }
    ],
    "TYR": [
        {
            "B": "KTYR",
            "C": "Pounds Field",
            "D": "Pounds Field",
            "E": "USA",
            "F": "US",
            "G": "T",
            "H": "",
            "I": "",
            "J": "NOA"
        }
    ],
    "TZL": [
        {
            "B": "LQTZ",
            "C": "TUZLA INTERNATIONAL",
            "D": "Tuzla",
            "E": "Bosnia and Herzegovina",
            "F": "BA",
            "G": "E",
            "H": "",
            "I": "",
            "J": "EUR"
        }
    ],
    "TZX": [
        {
            "B": "LTCG",
            "C": "TRABZON",
            "D": "Trabzon",
            "E": "Turkey",
            "F": "TR",
            "G": "E",
            "H": "",
            "I": "",
            "J": "EUR"
        }
    ],
    "UAK": [
        {
            "B": "BGBW",
            "C": "NARSSARSSUAQ",
            "D": "Narssarssuaq",
            "E": "Greenland",
            "F": "GL",
            "G": "E",
            "H": "",
            "I": "",
            "J": "EUR"
        }
    ],
    "UBJ": [
        {
            "B": "RJDC",
            "C": "Yamaguchi",
            "D": "Yamaguchi-Ube",
            "E": "Japan",
            "F": "JP",
            "G": "O",
            "H": "",
            "I": "",
            "J": "JAK"
        }
    ],
    "UBP": [
        {
            "B": "VTUU",
            "C": "MUANG UBON",
            "D": "Muang ubon",
            "E": "Thailand",
            "F": "TH",
            "G": "O",
            "H": "",
            "I": "",
            "J": "SEA"
        }
    ],
    "UDE": [
        {
            "B": "EHVK",
            "C": "UDEN / VOLKEL",
            "D": "Uden",
            "E": "Netherlands",
            "F": "NL",
            "G": "E",
            "H": "",
            "I": "",
            "J": "EUR"
        }
    ],
    "UDI": [
        {
            "B": "SBUL",
            "C": "Eduardo Gomes",
            "D": "Cesar Bombonato",
            "E": "Brazil",
            "F": "BR",
            "G": "T",
            "H": "",
            "I": "",
            "J": "SOA"
        }
    ],
    "UDR": [
        {
            "B": "VAUD",
            "C": "Dabok",
            "D": "Maharana Pratap",
            "E": "India",
            "F": "IN",
            "G": "O",
            "H": "",
            "I": "",
            "J": "SAS"
        }
    ],
    "UET": [
        {
            "B": "OPQT",
            "C": "QUETTA",
            "D": "Quetta",
            "E": "Pakistan",
            "F": "PK",
            "G": "O",
            "H": "",
            "I": "",
            "J": "SAS"
        }
    ],
    "UFA": [
        {
            "B": "UWUU",
            "C": "Ufa",
            "D": "Ufa",
            "E": "Russian Federation",
            "F": "RU",
            "G": "E",
            "H": "",
            "I": "",
            "J": "EUR"
        }
    ],
    "UGC": [
        {
            "B": "UTSU",
            "C": "UCHKUDUK",
            "D": "Uchkuduk",
            "E": "Uzbekistan",
            "F": "UZ",
            "G": "O",
            "H": "",
            "I": "",
            "J": "SEA"
        }
    ],
    "UGN": [
        {
            "B": "KUGN",
            "C": "WAUKEGAN",
            "D": "Waukegan",
            "E": "USA",
            "F": "US",
            "G": "T",
            "H": "",
            "I": "",
            "J": "NOA"
        }
    ],
    "UHE": [
        {
            "B": "LKKU",
            "C": "KUNOVICE AIRPORT",
            "D": "Kunovice Airport",
            "E": "Czech Republic",
            "F": "CZ",
            "G": "E",
            "H": "",
            "I": "",
            "J": "EUR"
        }
    ],
    "UIH": [
        {
            "B": "VVPC",
            "C": "PHU CAT",
            "D": "Phu Cat",
            "E": "Vietnam",
            "F": "VN",
            "G": "O",
            "H": "",
            "I": "",
            "J": "SEA"
        }
    ],
    "UIN": [
        {
            "B": "KUIN",
            "C": "QUINCY/MUNI BALDWIN FIELD",
            "D": "Quincy/Muni Baldwin Field",
            "E": "USA",
            "F": "US",
            "G": "T",
            "H": "",
            "I": "",
            "J": "NOA"
        }
    ],
    "UIO": [
        {
            "B": "SEQU",
            "C": "Mariscal Sucr",
            "D": "Mariscal Sucre Intl",
            "E": "Ecuador",
            "F": "EC",
            "G": "T",
            "H": "",
            "I": "",
            "J": "SOA"
        }
    ],
    "UIP": [
        {
            "B": "LFRQ",
            "C": "QUIMPER",
            "D": "Quimper",
            "E": "France",
            "F": "FR",
            "G": "E",
            "H": "",
            "I": "",
            "J": "EUR"
        }
    ],
    "UKB": [
        {
            "B": "RJBE",
            "C": "KOBE",
            "D": "Kobe",
            "E": "Japan",
            "F": "JP",
            "G": "O",
            "H": "",
            "I": "",
            "J": "JAK"
        }
    ],
    "UKK": [
        {
            "B": "UASK",
            "C": "Ust-Kamenogorsk",
            "D": "Ust-Kamenogorsk Airport",
            "E": "Kazakhstan",
            "F": "KZ",
            "G": "O",
            "H": "",
            "I": "",
            "J": "SEA"
        }
    ],
    "ULN": [
        {
            "B": "ZMUB",
            "C": "Buyant Uhaa",
            "D": "Chinggis Khaan Intl",
            "E": "Mongolia",
            "F": "MN",
            "G": "O",
            "H": "",
            "I": "",
            "J": "SEA"
        }
    ],
    "ULV": [
        {
            "B": "UWLL",
            "C": "ULYANOVSK",
            "D": "Ulyanovsk",
            "E": "Russian Federation",
            "F": "RU",
            "G": "E",
            "H": "",
            "I": "",
            "J": "EUR"
        }
    ],
    "ULY": [
        {
            "B": "UWLW",
            "C": "ULYANOVSK/VOSTOCHNY",
            "D": "Ulyanovsk",
            "E": "Russian Federation",
            "F": "RU",
            "G": "E",
            "H": "",
            "I": "",
            "J": "EUR"
        }
    ],
    "UME": [
        {
            "B": "ESNU",
            "C": "UMEA",
            "D": "UMEA",
            "E": "Sweden",
            "F": "",
            "G": "E",
            "H": "",
            "I": "",
            "J": ""
        }
    ],
    "UPG": [
        {
            "B": "WAAA",
            "C": "Hasanudin",
            "D": "Sultan Hasanuddin",
            "E": "Indonesia",
            "F": "ID",
            "G": "O",
            "H": "",
            "I": "",
            "J": "SEA"
        }
    ],
    "URC": [
        {
            "B": "ZWWW",
            "C": "Urumqi",
            "D": "Diwopu International",
            "E": "Peoples Republic of China",
            "F": "CN",
            "G": "O",
            "H": "",
            "I": "",
            "J": "SEA"
        }
    ],
    "URD": [
        {
            "B": "EDQE",
            "C": "BURG FEUERSTEIN",
            "D": "Burg Feuerstein",
            "E": "Germany",
            "F": "DE",
            "G": "E",
            "H": "",
            "I": "",
            "J": "EUR"
        }
    ],
    "URE": [
        {
            "B": "EEKE",
            "C": "KURESSAARE",
            "D": "Kuressaare",
            "E": "Estonia",
            "F": "EE",
            "G": "E",
            "H": "",
            "I": "",
            "J": "EUR"
        }
    ],
    "URO": [
        {
            "B": "LFOP",
            "C": "ROUEN",
            "D": "Rouen",
            "E": "France",
            "F": "FR",
            "G": "E",
            "H": "",
            "I": "",
            "J": "EUR"
        }
    ],
    "URT": [
        {
            "B": "VTSB",
            "C": "SURAT THANI",
            "D": "Surat Thani",
            "E": "Thailand",
            "F": "TH",
            "G": "O",
            "H": "",
            "I": "",
            "J": "SEA"
        }
    ],
    "URY": [
        {
            "B": "OEGT",
            "C": "Gurayat",
            "D": "Gurayat",
            "E": "Saudi Arabia",
            "F": "SA",
            "G": "O",
            "H": "",
            "I": "",
            "J": "MDE"
        }
    ],
    "USH": [
        {
            "B": "SAWH",
            "C": "Islas Malvinas",
            "D": "Malvinas Argentinas",
            "E": "Argentina",
            "F": "AR",
            "G": "T",
            "H": "",
            "I": "",
            "J": "SOA"
        }
    ],
    "USM": [
        {
            "B": "VTSM",
            "C": "Kho Samui (Ko Samui)",
            "D": "Ko Samui",
            "E": "Thailand",
            "F": "TH",
            "G": "O",
            "H": "",
            "I": "",
            "J": "SEA"
        }
    ],
    "USN": [
        {
            "B": "RKPU",
            "C": "ULSAN",
            "D": "Ulsan",
            "E": "Republic of Korea",
            "F": "KR",
            "G": "O",
            "H": "",
            "I": "",
            "J": "JAK"
        }
    ],
    "USS": [
        {
            "B": "MUSS",
            "C": "SANCTI SPIRITUS",
            "D": "Sancti Spiritus",
            "E": "Cuba",
            "F": "CU",
            "G": "T",
            "H": "",
            "I": "",
            "J": "CAR"
        }
    ],
    "UST": [
        {
            "B": "KSGJ",
            "C": "NORTHEAST FLORIDA",
            "D": "NORTHEAST FLORIDA REGIONAL",
            "E": "USA",
            "F": "",
            "G": "T",
            "H": "",
            "I": "",
            "J": "NOA"
        }
    ],
    "UTC": [
        {
            "B": "EHSB",
            "C": "UTRECHT - SOESTERBERG",
            "D": "Utrecht",
            "E": "Netherlands",
            "F": "NL",
            "G": "E",
            "H": "",
            "I": "",
            "J": "EUR"
        }
    ],
    "UTH": [
        {
            "B": "VTUD",
            "C": "Udon Thani",
            "D": "Udon Thani International",
            "E": "Thailand",
            "F": "TH",
            "G": "O",
            "H": "",
            "I": "",
            "J": "SEA"
        }
    ],
    "UTN": [
        {
            "B": "FAUP",
            "C": "Upington",
            "D": "Upington Arpt",
            "E": "South Africa",
            "F": "ZA",
            "G": "O",
            "H": "",
            "I": "",
            "J": "AFR"
        }
    ],
    "UTP": [
        {
            "B": "VTBU",
            "C": "U-TAPAO",
            "D": "U-Tapao",
            "E": "Thailand",
            "F": "TH",
            "G": "O",
            "H": "",
            "I": "",
            "J": "SEA"
        }
    ],
    "UTT": [
        {
            "B": "FAUT",
            "C": "Umtata",
            "D": "K D Matanzima",
            "E": "South Africa",
            "F": "ZA",
            "G": "O",
            "H": "",
            "I": "",
            "J": "AFR"
        }
    ],
    "UUS": [
        {
            "B": "UHSS",
            "C": "Yuzhno-Sakhalinsk",
            "D": "Yuzhno-Sakhalinsk",
            "E": "Russian Federation",
            "F": "RU",
            "G": "E",
            "H": "",
            "I": "",
            "J": "EUR"
        }
    ],
    "UVF": [
        {
            "B": "TLPL",
            "C": "HEWANORRA INTL",
            "D": "HEWANORRA INTL",
            "E": "Saint Lucia",
            "F": "LC",
            "G": "T",
            "H": "",
            "I": "",
            "J": "CAR"
        }
    ],
    "UZC": [
        {
            "B": "LYUZ",
            "C": "UZICE / PONIKVE",
            "D": "Uzice",
            "E": "SERBIA",
            "F": "CS",
            "G": "E",
            "H": "",
            "I": "",
            "J": "EUR"
        }
    ],
    "VAA": [
        {
            "B": "EFVA",
            "C": "VAASA",
            "D": "Vaasa",
            "E": "Finland",
            "F": "FI",
            "G": "E",
            "H": "",
            "I": "",
            "J": "EUR"
        }
    ],
    "VAC": [
        {
            "B": "EDWU",
            "C": "VARRELBUSCH",
            "D": "Varrelbusch",
            "E": "Germany",
            "F": "DE",
            "G": "E",
            "H": "",
            "I": "",
            "J": "EUR"
        }
    ],
    "VAF": [
        {
            "B": "LFLU",
            "C": "VALENCE - CHABEUIL",
            "D": "Valence Chabeuil",
            "E": "France",
            "F": "FR",
            "G": "E",
            "H": "",
            "I": "",
            "J": "EUR"
        }
    ],
    "VAN": [
        {
            "B": "LTCI",
            "C": "VAN",
            "D": "Van",
            "E": "Turkey",
            "F": "TR",
            "G": "E",
            "H": "",
            "I": "",
            "J": "EUR"
        }
    ],
    "VAR": [
        {
            "B": "LBWN",
            "C": "VARNA",
            "D": "Varna",
            "E": "Bulgaria",
            "F": "BG",
            "G": "E",
            "H": "",
            "I": "",
            "J": "EUR"
        }
    ],
    "VAS": [
        {
            "B": "LTAR",
            "C": "SIVAS",
            "D": "Sivas",
            "E": "Turkey",
            "F": "TR",
            "G": "E",
            "H": "",
            "I": "",
            "J": "EUR"
        }
    ],
    "VAV": [
        {
            "B": "NFTV",
            "C": "VAVA'U",
            "D": "Vava'U",
            "E": "Tonga",
            "F": "TO",
            "G": "O",
            "H": "",
            "I": "",
            "J": "SWP"
        }
    ],
    "VBS": [
        {
            "B": "LIPO",
            "C": "BRESCIA MONTICHIARI",
            "D": "Brescia",
            "E": "Italy",
            "F": "IT",
            "G": "E",
            "H": "",
            "I": "",
            "J": "EUR"
        }
    ],
    "VBY": [
        {
            "B": "ESSV",
            "C": "VISBY",
            "D": "Visby",
            "E": "Sweden",
            "F": "SE",
            "G": "E",
            "H": "",
            "I": "",
            "J": "EUR"
        }
    ],
    "VCE": [
        {
            "B": "LIPZ",
            "C": "VENICE - MARCO POLO",
            "D": "Venice",
            "E": "Italy",
            "F": "IT",
            "G": "E",
            "H": "",
            "I": "",
            "J": "EUR"
        }
    ],
    "VCP": [
        {
            "B": "SBKP",
            "C": "SAO PAULO - VIRACOPOS",
            "D": "Sao Paulo",
            "E": "Brazil",
            "F": "BR",
            "G": "T",
            "H": "",
            "I": "",
            "J": "SOA"
        }
    ],
    "VCV": [
        {
            "B": "KVCV",
            "C": "VICTORVILLE",
            "D": "Victorville",
            "E": "USA",
            "F": "US",
            "G": "T",
            "H": "",
            "I": "",
            "J": "NOA"
        }
    ],
    "VDA": [
        {
            "B": "LLOV",
            "C": "OVDA",
            "D": "Ovda",
            "E": "Israel",
            "F": "IL",
            "G": "O",
            "H": "",
            "I": "",
            "J": "MDE"
        }
    ],
    "VDB": [
        {
            "B": "ENFG",
            "C": "FAGERNES",
            "D": "Fagernes",
            "E": "Norway",
            "F": "NO",
            "G": "E",
            "H": "",
            "I": "",
            "J": "EUR"
        }
    ],
    "VDC": [
        {
            "B": "SBQV",
            "C": "VITORIA DA CONQUISTA",
            "D": "Vitoria Da Conquista",
            "E": "Brazil",
            "F": "BR",
            "G": "T",
            "H": "",
            "I": "",
            "J": "SOA"
        }
    ],
    "VDZ": [
        {
            "B": "PAVD",
            "C": "Municipal",
            "D": "Pioneer Field",
            "E": "USA",
            "F": "US",
            "G": "T",
            "H": "",
            "I": "",
            "J": "NOA"
        }
    ],
    "VEL": [
        {
            "B": "KVEL",
            "C": "VERNAL",
            "D": "Vernal",
            "E": "USA",
            "F": "US",
            "G": "T",
            "H": "",
            "I": "",
            "J": "NOA"
        }
    ],
    "VER": [
        {
            "B": "MMVR",
            "C": "LAS BAJADAS",
            "D": "Heriberto Jara Intl",
            "E": "Mexico",
            "F": "MX",
            "G": "T",
            "H": "",
            "I": "",
            "J": "CEM"
        }
    ],
    "VFA": [
        {
            "B": "FVFA",
            "C": "Victoria Falls",
            "D": "Victoria Falls International",
            "E": "Zimbabwe",
            "F": "ZW",
            "G": "O",
            "H": "",
            "I": "",
            "J": "AFR"
        }
    ],
    "VGA": [
        {
            "B": "",
            "C": "",
            "D": "",
            "E": "UNKNOWN",
            "F": "",
            "G": "A",
            "H": "",
            "I": "",
            "J": ""
        }
    ],
    "VGO": [
        {
            "B": "LEVX",
            "C": "VIGO PEINADOR",
            "D": "Vigo",
            "E": "Spain",
            "F": "ES",
            "G": "E",
            "H": "",
            "I": "",
            "J": "EUR"
        }
    ],
    "VHM": [
        {
            "B": "ESNV",
            "C": "Vilhelmina",
            "D": "Vilhelmina",
            "E": "Sweden",
            "F": "SE",
            "G": "E",
            "H": "",
            "I": "",
            "J": "EUR"
        }
    ],
    "VHY": [
        {
            "B": "LFLV",
            "C": "VICHY - CHARMEIL",
            "D": "Vichy",
            "E": "France",
            "F": "FR",
            "G": "E",
            "H": "",
            "I": "",
            "J": "EUR"
        }
    ],
    "VIC": [
        {
            "B": "LIPT",
            "C": "VICENZA",
            "D": "Vicenza",
            "E": "Italy",
            "F": "IT",
            "G": "E",
            "H": "",
            "I": "",
            "J": "EUR"
        }
    ],
    "VIE": [
        {
            "B": "LOWW",
            "C": "VIENNA",
            "D": "Vienna",
            "E": "Austria",
            "F": "AT",
            "G": "E",
            "H": "",
            "I": "",
            "J": "EUR"
        }
    ],
    "VII": [
        {
            "B": "VVVH",
            "C": "VINH",
            "D": "Vinh",
            "E": "Vietnam",
            "F": "VN",
            "G": "O",
            "H": "",
            "I": "",
            "J": "SEA"
        }
    ],
    "VIT": [
        {
            "B": "LEVT",
            "C": "VITORIA",
            "D": "Vitoria",
            "E": "Spain",
            "F": "ES",
            "G": "E",
            "H": "",
            "I": "",
            "J": "EUR"
        }
    ],
    "VIX": [
        {
            "B": "SBVT",
            "C": "Eurico Sales",
            "D": "E. de Aguiar Salles",
            "E": "Brazil",
            "F": "BR",
            "G": "T",
            "H": "",
            "I": "",
            "J": "SOA"
        }
    ],
    "VIY": [
        {
            "B": "LFPV",
            "C": "VILLACOUBLAY",
            "D": "Villacoublay",
            "E": "France",
            "F": "FR",
            "G": "E",
            "H": "",
            "I": "",
            "J": "EUR"
        }
    ],
    "VKO": [
        {
            "B": "UUWW",
            "C": "MOSKVA - VNUKOVO",
            "D": "Moscow",
            "E": "Russian Federation",
            "F": "RU",
            "G": "E",
            "H": "",
            "I": "",
            "J": "EUR"
        }
    ],
    "VLC": [
        {
            "B": "LEVC",
            "C": "VALENCIA",
            "D": "Valencia",
            "E": "Spain",
            "F": "ES",
            "G": "E",
            "H": "",
            "I": "",
            "J": "EUR"
        }
    ],
    "VLD": [
        {
            "B": "KVLD",
            "C": "Regional",
            "D": "Valdosta Regional",
            "E": "USA",
            "F": "US",
            "G": "T",
            "H": "",
            "I": "",
            "J": "NOA"
        }
    ],
    "VLI": [
        {
            "B": "NVVV",
            "C": "Bauerfield",
            "D": "Bauerfield International",
            "E": "Vanuatu",
            "F": "VU",
            "G": "O",
            "H": "",
            "I": "",
            "J": "SWP"
        }
    ],
    "VLL": [
        {
            "B": "LEVD",
            "C": "VALLADOLID",
            "D": "Valladolid",
            "E": "Spain",
            "F": "ES",
            "G": "E",
            "H": "",
            "I": "",
            "J": "EUR"
        }
    ],
    "VLN": [
        {
            "B": "SVVA",
            "C": "VELENCIA",
            "D": "Arturo michelena int",
            "E": "Venezuela",
            "F": "VE",
            "G": "T",
            "H": "",
            "I": "",
            "J": "SOA"
        }
    ],
    "VLY": [
        {
            "B": "EGOV",
            "C": "Anglesey Airport",
            "D": "Anglesey",
            "E": "United Kingdom",
            "F": "GB",
            "G": "U",
            "H": "",
            "I": "",
            "J": "EUR"
        }
    ],
    "VNE": [
        {
            "B": "LFRV",
            "C": "VANNES",
            "D": "Vannes",
            "E": "France",
            "F": "FR",
            "G": "E",
            "H": "",
            "I": "",
            "J": "EUR"
        }
    ],
    "VNO": [
        {
            "B": "EYVI",
            "C": "VILNIUS",
            "D": "Vilnius",
            "E": "Lithuania",
            "F": "LT",
            "G": "E",
            "H": "",
            "I": "",
            "J": "EUR"
        }
    ],
    "VNS": [
        {
            "B": "VIBN",
            "C": "Varanasi",
            "D": "Lal Bahadur Shastri",
            "E": "India",
            "F": "IN",
            "G": "O",
            "H": "",
            "I": "",
            "J": "SAS"
        }
    ],
    "VNX": [
        {
            "B": "FQVL",
            "C": "Vilanculos",
            "D": "Vilankulos",
            "E": "Mozambique",
            "F": "MZ",
            "G": "O",
            "H": "",
            "I": "",
            "J": "AFR"
        }
    ],
    "VNY": [
        {
            "B": "KVNY",
            "C": "VAN NUYS",
            "D": "Van nuys ca",
            "E": "USA",
            "F": "US",
            "G": "T",
            "H": "",
            "I": "",
            "J": "NOA"
        }
    ],
    "VOG": [
        {
            "B": "URWW",
            "C": "VOLGOGRAD",
            "D": "Volgograd",
            "E": "Russian Federation",
            "F": "RU",
            "G": "E",
            "H": "",
            "I": "",
            "J": "EUR"
        }
    ],
    "VOL": [
        {
            "B": "LGVO",
            "C": "VOLOS / NEA ANCHIALOS",
            "D": "Volos",
            "E": "Greece",
            "F": "GR",
            "G": "E",
            "H": "",
            "I": "",
            "J": "EUR"
        }
    ],
    "VOZ": [
        {
            "B": "UUOO",
            "C": "VORONEZH",
            "D": "Chertovitskoye",
            "E": "Russian Federation",
            "F": "RU",
            "G": "E",
            "H": "",
            "I": "",
            "J": "EUR"
        }
    ],
    "VPN": [
        {
            "B": "BIVO",
            "C": "VOPNAFJORDUR",
            "D": "Vopnafjordur",
            "E": "Iceland",
            "F": "IS",
            "G": "E",
            "H": "",
            "I": "",
            "J": "EUR"
        }
    ],
    "VRA": [
        {
            "B": "MUVR",
            "C": "VARADERO",
            "D": "Varadero",
            "E": "Cuba",
            "F": "CU",
            "G": "T",
            "H": "",
            "I": "",
            "J": "CAR"
        }
    ],
    "VRB": [
        {
            "B": "KVRB",
            "C": "VERO BEACH MUNICIPAL",
            "D": "Vero beach",
            "E": "USA",
            "F": "US",
            "G": "T",
            "H": "",
            "I": "",
            "J": "NOA"
        }
    ],
    "VRL": [
        {
            "B": "LPVR",
            "C": "VILA REAL",
            "D": "Vila Real",
            "E": "Portugal",
            "F": "PT",
            "G": "E",
            "H": "",
            "I": "",
            "J": "EUR"
        }
    ],
    "VRN": [
        {
            "B": "LIPX",
            "C": "VERONA - VILLAFRANCA",
            "D": "Verona",
            "E": "Italy",
            "F": "IT",
            "G": "E",
            "H": "",
            "I": "",
            "J": "EUR"
        }
    ],
    "VRO": [
        {
            "B": "MUKW",
            "C": "KAWAMA AIRPORT",
            "D": "KAWAMA AIRPORT",
            "E": "Cuba",
            "F": "CU",
            "G": "T",
            "H": "",
            "I": "",
            "J": "SOA"
        }
    ],
    "VSA": [
        {
            "B": "MMVA",
            "C": "Capitan Carlos Perez",
            "D": "C.Rovirosa Perez Intl",
            "E": "Mexico",
            "F": "MX",
            "G": "T",
            "H": "",
            "I": "",
            "J": "CEM"
        }
    ],
    "VSE": [
        {
            "B": "LPVZ",
            "C": "VISEU",
            "D": "Viseu",
            "E": "Portugal",
            "F": "PT",
            "G": "E",
            "H": "",
            "I": "",
            "J": "EUR"
        }
    ],
    "VST": [
        {
            "B": "ESOW",
            "C": "VASTERAS - HASSLO",
            "D": "Vasteras",
            "E": "Sweden",
            "F": "SE",
            "G": "E",
            "H": "",
            "I": "",
            "J": "EUR"
        }
    ],
    "VTE": [
        {
            "B": "VLVT",
            "C": "Wattay",
            "D": "Wattay Intl",
            "E": "Laos",
            "F": "LA",
            "G": "O",
            "H": "",
            "I": "",
            "J": "SEA"
        }
    ],
    "VTL": [
        {
            "B": "LFSZ",
            "C": "VITTEL - CHAMP DE COUR",
            "D": "Vittel",
            "E": "France",
            "F": "FR",
            "G": "E",
            "H": "",
            "I": "",
            "J": "EUR"
        }
    ],
    "VTZ": [
        {
            "B": "VEVZ",
            "C": "Vishakhapatnam",
            "D": "Vishakhapatnam",
            "E": "India",
            "F": "IN",
            "G": "O",
            "H": "",
            "I": "",
            "J": "SAS"
        }
    ],
    "VUP": [
        {
            "B": "SKVP",
            "C": "VALLEDUPAR/CESAR",
            "D": "Valledupar/Cesar",
            "E": "Colombia",
            "F": "CO",
            "G": "T",
            "H": "",
            "I": "",
            "J": "SOA"
        }
    ],
    "VVI": [
        {
            "B": "SLVR",
            "C": "Viru Viru Intl",
            "D": "Viru Viru International",
            "E": "Bolivia",
            "F": "BO",
            "G": "T",
            "H": "",
            "I": "",
            "J": "SOA"
        }
    ],
    "VVO": [
        {
            "B": "UHWW",
            "C": "Vladivostok",
            "D": "Vladivostok",
            "E": "Russian Federation",
            "F": "RU",
            "G": "E",
            "H": "",
            "I": "",
            "J": "EUR"
        }
    ],
    "VXE": [
        {
            "B": "GVSV",
            "C": "San Pedro",
            "D": "Sao Pedro/Sao Vicente Island",
            "E": "Cape Verde",
            "F": "CV",
            "G": "O",
            "H": "",
            "I": "",
            "J": "AFR"
        }
    ],
    "VXO": [
        {
            "B": "ESMX",
            "C": "VAXJO",
            "D": "Vaxjo",
            "E": "Sweden",
            "F": "SE",
            "G": "E",
            "H": "",
            "I": "",
            "J": "EUR"
        }
    ],
    "WAG": [
        {
            "B": "NZWU",
            "C": "Wanganui",
            "D": "Wanganui",
            "E": "New Zealand",
            "F": "NZ",
            "G": "O",
            "H": "",
            "I": "",
            "J": "SWP"
        }
    ],
    "WAR": [
        {
            "B": "",
            "C": "WARIS AIRPORT",
            "D": "WARIS AIRPORT",
            "E": "Indonesia",
            "F": "ID",
            "G": "O",
            "H": "",
            "I": "",
            "J": "SEA"
        }
    ],
    "WAT": [
        {
            "B": "EIWF",
            "C": "WATERFORD",
            "D": "Waterford",
            "E": "Republic of Ireland",
            "F": "IE",
            "G": "D",
            "H": "",
            "I": "",
            "J": "EUR"
        }
    ],
    "WAW": [
        {
            "B": "EPWA",
            "C": "WARSAW - CHOPIN",
            "D": "Warsaw",
            "E": "Poland",
            "F": "PL",
            "G": "E",
            "H": "",
            "I": "",
            "J": "EUR"
        }
    ],
    "WDH": [
        {
            "B": "",
            "C": "",
            "D": "",
            "E": "UNKNOWN",
            "F": "",
            "G": "A",
            "H": "",
            "I": "",
            "J": ""
        }
    ],
    "WEF": [
        {
            "B": "ZSWF",
            "C": "WEIFANG",
            "D": "Weifang",
            "E": "Peoples Republic of China",
            "F": "CN",
            "G": "O",
            "H": "",
            "I": "",
            "J": "SEA"
        }
    ],
    "WEH": [
        {
            "B": "ZSWH",
            "C": "Weihai",
            "D": "Dashuibo",
            "E": "Peoples Republic of China",
            "F": "CN",
            "G": "O",
            "H": "",
            "I": "",
            "J": "SEA"
        }
    ],
    "WEI": [
        {
            "B": "YBWP",
            "C": "Weipa",
            "D": "Weipa",
            "E": "Australia",
            "F": "AU",
            "G": "O",
            "H": "",
            "I": "",
            "J": "SWP"
        }
    ],
    "WEL": [
        {
            "B": "FAWM",
            "C": "Welkom",
            "D": "Welkom Airport",
            "E": "South Africa",
            "F": "ZA",
            "G": "O",
            "H": "",
            "I": "",
            "J": "AFR"
        }
    ],
    "WGA": [
        {
            "B": "YSWG",
            "C": "Forrest Hill",
            "D": "Wagga Wagga",
            "E": "Australia",
            "F": "AU",
            "G": "O",
            "H": "",
            "I": "",
            "J": "SWP"
        }
    ],
    "WHK": [
        {
            "B": "NZWK",
            "C": "Whakatane",
            "D": "Whakatane",
            "E": "New Zealand",
            "F": "NZ",
            "G": "O",
            "H": "",
            "I": "",
            "J": "SWP"
        }
    ],
    "WHS": [
        {
            "B": "EGEH",
            "C": "WHALSAY",
            "D": "Whalsay",
            "E": "United Kingdom",
            "F": "GB",
            "G": "U",
            "H": "",
            "I": "",
            "J": "EUR"
        }
    ],
    "WIC": [
        {
            "B": "EGPC",
            "C": "WICK",
            "D": "Wick",
            "E": "United Kingdom",
            "F": "GB",
            "G": "U",
            "H": "",
            "I": "",
            "J": "EUR"
        }
    ],
    "WIE": [
        {
            "B": "ETOU",
            "C": "WIESBADEN",
            "D": "Wiesbaden",
            "E": "Germany",
            "F": "DE",
            "G": "E",
            "H": "",
            "I": "",
            "J": "EUR"
        }
    ],
    "WLG": [
        {
            "B": "NZWN",
            "C": "WELLINGTON",
            "D": "Wellington",
            "E": "New Zealand",
            "F": "NZ",
            "G": "O",
            "H": "",
            "I": "",
            "J": "SWP"
        }
    ],
    "WMI": [
        {
            "B": "EPMO",
            "C": "WARSAW - MODLIN",
            "D": "Warsaw",
            "E": "Poland",
            "F": "PL",
            "G": "E",
            "H": "",
            "I": "Europe/Warsaw",
            "J": "EUR"
        }
    ],
    "WNZ": [
        {
            "B": "ZSWZ",
            "C": "Wenzhou",
            "D": "Yongqiang International",
            "E": "Peoples Republic of China",
            "F": "CN",
            "G": "O",
            "H": "",
            "I": "",
            "J": "SEA"
        }
    ],
    "WOE": [
        {
            "B": "EHWO",
            "C": "WOENSDRECHT AIR BASE",
            "D": "BERGEN OP ZOOM / WOENSDRECHT",
            "E": "Netherlands",
            "F": "NL",
            "G": "E",
            "H": "",
            "I": "",
            "J": "EUR"
        }
    ],
    "WOL": [
        {
            "B": "YWOL",
            "C": "WOLLONGONG",
            "D": "Wollongong",
            "E": "Australia",
            "F": "AU",
            "G": "O",
            "H": "",
            "I": "",
            "J": "SWP"
        }
    ],
    "WRE": [
        {
            "B": "NZWR",
            "C": "Whangarei",
            "D": "Whangarei",
            "E": "New Zealand",
            "F": "NZ",
            "G": "O",
            "H": "",
            "I": "",
            "J": "SWP"
        }
    ],
    "WRI": [
        {
            "B": "KWRI",
            "C": "MCGUIRE MIL",
            "D": "Mcguire",
            "E": "USA",
            "F": "US",
            "G": "T",
            "H": "",
            "I": "",
            "J": "NOA"
        }
    ],
    "WRO": [
        {
            "B": "EPWR",
            "C": "WROCLAW",
            "D": "Wroclaw",
            "E": "Poland",
            "F": "PL",
            "G": "E",
            "H": "",
            "I": "",
            "J": "EUR"
        }
    ],
    "WRT": [
        {
            "B": "",
            "C": "WARTON AERODROME",
            "D": "WARTON AERODROME",
            "E": "United Kingdom",
            "F": "GB",
            "G": "U",
            "H": "",
            "I": "",
            "J": "EUR"
        }
    ],
    "WTB": [
        {
            "B": "YBWW",
            "C": "Brisbane West Wellcamp",
            "D": "Brisbane West Wellcamp",
            "E": "Australia",
            "F": "AU",
            "G": "O",
            "H": "",
            "I": "",
            "J": "SWP"
        }
    ],
    "WTN": [
        {
            "B": "EGXW",
            "C": "WADDINGTON",
            "D": "Waddington",
            "E": "United Kingdom",
            "F": "GB",
            "G": "U",
            "H": "",
            "I": "",
            "J": "EUR"
        }
    ],
    "WUA": [
        {
            "B": "ZBUH",
            "C": "Wuhai",
            "D": "Wuhai Airport",
            "E": "Peoples Republic of China",
            "F": "CN",
            "G": "O",
            "H": "",
            "I": "",
            "J": "SEA"
        }
    ],
    "WUH": [
        {
            "B": "",
            "C": "",
            "D": "",
            "E": "UNKNOWN",
            "F": "",
            "G": "A",
            "H": "",
            "I": "",
            "J": ""
        }
    ],
    "WUX": [
        {
            "B": "ZSWX",
            "C": "Wuxi",
            "D": "Sunan Shuofang",
            "E": "Peoples Republic of China",
            "F": "CN",
            "G": "O",
            "H": "",
            "I": "",
            "J": "SEA"
        }
    ],
    "WVB": [
        {
            "B": "FYWB",
            "C": "Rooikop",
            "D": "Walvis Bay Airport",
            "E": "Namibia",
            "F": "NA",
            "G": "O",
            "H": "",
            "I": "",
            "J": "AFR"
        }
    ],
    "WVN": [
        {
            "B": "EDWI",
            "C": "WILHELMSHAVEN",
            "D": "Wilhelmshaven",
            "E": "Germany",
            "F": "DE",
            "G": "E",
            "H": "",
            "I": "",
            "J": "EUR"
        }
    ],
    "WWK": [
        {
            "B": "AYWK",
            "C": "WEWAK",
            "D": "Wewak",
            "E": "Papua New Guinea",
            "F": "PG",
            "G": "O",
            "H": "",
            "I": "",
            "J": "SEA"
        }
    ],
    "XAB": [
        {
            "B": "LFOI",
            "C": "ABBEVILLE",
            "D": "Abbeville",
            "E": "France",
            "F": "FR",
            "G": "E",
            "H": "",
            "I": "",
            "J": "EUR"
        }
    ],
    "XAC": [
        {
            "B": "LFCH",
            "C": "ARCACHON / LA TESTE DE BUCH",
            "D": "Arcachon",
            "E": "France",
            "F": "FR",
            "G": "E",
            "H": "",
            "I": "",
            "J": "EUR"
        }
    ],
    "XAM": [
        {
            "B": "LFEF",
            "C": "AMBOISE / DIERRE",
            "D": "Amboise",
            "E": "France",
            "F": "FR",
            "G": "E",
            "H": "",
            "I": "",
            "J": "EUR"
        }
    ],
    "XAN": [
        {
            "B": "LFOF",
            "C": "ALENCON - VALFRAMBERT",
            "D": "Alencon",
            "E": "France",
            "F": "FR",
            "G": "E",
            "H": "",
            "I": "",
            "J": "EUR"
        }
    ],
    "XAS": [
        {
            "B": "LFMS",
            "C": "ALES-DEAUX",
            "D": "Ales Deaux",
            "E": "France",
            "F": "FR",
            "G": "E",
            "H": "",
            "I": "",
            "J": "EUR"
        }
    ],
    "XAV": [
        {
            "B": "LFKA",
            "C": "ALBERTVILLE",
            "D": "Albertville",
            "E": "France",
            "F": "FR",
            "G": "E",
            "H": "",
            "I": "",
            "J": "EUR"
        }
    ],
    "XBF": [
        {
            "B": "LFHN",
            "C": "BELLEGARDE / VOUVRAY",
            "D": "Bellegarde",
            "E": "France",
            "F": "FR",
            "G": "E",
            "H": "",
            "I": "",
            "J": "EUR"
        }
    ],
    "XBQ": [
        {
            "B": "LFOQ",
            "C": "BLOIS-LE BREUIL",
            "D": "Blois Le Breuil",
            "E": "France",
            "F": "FR",
            "G": "E",
            "H": "",
            "I": "",
            "J": "EUR"
        }
    ],
    "XBV": [
        {
            "B": "LFGF",
            "C": "BEAUNE / CHALLANGES",
            "D": "Beaune",
            "E": "France",
            "F": "FR",
            "G": "E",
            "H": "",
            "I": "",
            "J": "EUR"
        }
    ],
    "XBX": [
        {
            "B": "LFPD",
            "C": "BERNAY-SAINT-MARTIN",
            "D": "Bernay",
            "E": "France",
            "F": "FR",
            "G": "E",
            "H": "",
            "I": "",
            "J": "EUR"
        }
    ],
    "XCB": [
        {
            "B": "LFQI",
            "C": "CAMBRAI",
            "D": "Cambrai",
            "E": "France",
            "F": "FR",
            "G": "E",
            "H": "",
            "I": "",
            "J": "EUR"
        }
    ],
    "XCM": [
        {
            "B": "CYCK",
            "C": "CHATHAM-KENT ONTARIO",
            "D": "CHATHAM-KENT ONTARIO",
            "E": "Canada",
            "F": "CA",
            "G": "T",
            "H": "",
            "I": "",
            "J": "NOA"
        }
    ],
    "XCP": [
        {
            "B": "LFAD",
            "C": "COMPIEGNE / MARGNY",
            "D": "Compiegne",
            "E": "France",
            "F": "FR",
            "G": "E",
            "H": "",
            "I": "",
            "J": "EUR"
        }
    ],
    "XCR": [
        {
            "B": "LFOK",
            "C": "CHALONS-VATRY",
            "D": "CHALONS-VATRY",
            "E": "France",
            "F": "FR",
            "G": "E",
            "H": "",
            "I": "",
            "J": "EUR"
        }
    ],
    "XCW": [
        {
            "B": "LFJA",
            "C": "CHAUMONT SEMOUTIERS",
            "D": "Chaumont",
            "E": "France",
            "F": "FR",
            "G": "E",
            "H": "",
            "I": "",
            "J": "EUR"
        }
    ],
    "XCX": [
        {
            "B": "LFCA",
            "C": "CHATELLERAULT / TARGE",
            "D": "Chatellerault",
            "E": "France",
            "F": "FR",
            "G": "E",
            "H": "",
            "I": "",
            "J": "EUR"
        }
    ],
    "XCY": [
        {
            "B": "LFFH",
            "C": "CHATEAU-THIERRY-BELL",
            "D": "Chateau Thierry Bell",
            "E": "France",
            "F": "FR",
            "G": "E",
            "H": "",
            "I": "",
            "J": "EUR"
        }
    ],
    "XCZ": [
        {
            "B": "LFQV",
            "C": "CHARLEVILLE - MEZIERES",
            "D": "Charleville Mezieres",
            "E": "France",
            "F": "FR",
            "G": "E",
            "H": "",
            "I": "",
            "J": "EUR"
        }
    ],
    "XDA": [
        {
            "B": "LFBY",
            "C": "DAX / SEYRESSE",
            "D": "Seyresse",
            "E": "France",
            "F": "FR",
            "G": "E",
            "H": "",
            "I": "",
            "J": "EUR"
        }
    ],
    "XDK": [
        {
            "B": "LFAK",
            "C": "DUNKERQUE - GHYVELDE",
            "D": "Dunkerque",
            "E": "France",
            "F": "FR",
            "G": "E",
            "H": "",
            "I": "",
            "J": "EUR"
        }
    ],
    "XDS": [
        {
            "B": "",
            "C": "Railway Station Ottawa",
            "D": "Ottawa Railway Station",
            "E": "Canada",
            "F": "CA",
            "G": "T",
            "H": "",
            "I": "",
            "J": "NOA"
        }
    ],
    "XEP": [
        {
            "B": "LFSW",
            "C": "EPERNAY - PLIVOT",
            "D": "Epernay - plivot",
            "E": "France",
            "F": "FR",
            "G": "E",
            "H": "",
            "I": "",
            "J": "EUR"
        }
    ],
    "XER": [
        {
            "B": "",
            "C": "Bus Station Strasbourg",
            "D": "Strasbourg Bus Station",
            "E": "France",
            "F": "FR",
            "G": "E",
            "H": "",
            "I": "",
            "J": "EUR"
        }
    ],
    "XFW": [
        {
            "B": "EDHI",
            "C": "HAMBURG-FINKENWERDER",
            "D": "Finkenwerder",
            "E": "Germany",
            "F": "DE",
            "G": "E",
            "H": "",
            "I": "",
            "J": "EUR"
        }
    ],
    "XGT": [
        {
            "B": "LFCE",
            "C": "GUERET / SAINT-LAURENT",
            "D": "Gueret",
            "E": "France",
            "F": "FR",
            "G": "E",
            "H": "",
            "I": "",
            "J": "EUR"
        }
    ],
    "XHE": [
        {
            "B": "",
            "C": "TOULON-HYERES",
            "D": "TOULON-HYERES",
            "E": "France",
            "F": "FR",
            "G": "E",
            "H": "",
            "I": "",
            "J": "EUR"
        }
    ],
    "XHJ": [
        {
            "B": "",
            "C": "AACHEN CENTRAL STATION",
            "D": "Aachen Central Station",
            "E": "Germany",
            "F": "DE",
            "G": "E",
            "H": "",
            "I": "",
            "J": "EUR"
        }
    ],
    "XHV": [
        {
            "B": "",
            "C": "Bus station Brasov",
            "D": "Bus station Brasov",
            "E": "Romania",
            "F": "RO",
            "G": "E",
            "H": "",
            "I": "",
            "J": "EUR"
        }
    ],
    "XIT": [
        {
            "B": "",
            "C": "LEIPZIG/HALLE RAILWAY",
            "D": "Leipzig/Halle Railway",
            "E": "Germany",
            "F": "DE",
            "G": "E",
            "H": "",
            "I": "",
            "J": "EUR"
        }
    ],
    "XIU": [
        {
            "B": "ZSNJ",
            "C": "NANJING LUKOU",
            "D": "Nanjing Lukou",
            "E": "Peoples Republic of China",
            "F": "CN",
            "G": "O",
            "H": "",
            "I": "",
            "J": "SEA"
        }
    ],
    "XIY": [
        {
            "B": "ZLXY",
            "C": "XIANYANG",
            "D": "Xianyang",
            "E": "Peoples Republic of China",
            "F": "CN",
            "G": "O",
            "H": "",
            "I": "",
            "J": "SEA"
        }
    ],
    "XLE": [
        {
            "B": "LFQL",
            "C": "LENS - BENIFONTAINE",
            "D": "Lens Benifontaine",
            "E": "France",
            "F": "FR",
            "G": "E",
            "H": "",
            "I": "",
            "J": "EUR"
        }
    ],
    "XLG": [
        {
            "B": "LFPL",
            "C": "LOGNES - EMERAINVILLE",
            "D": "Lognes Emerainville",
            "E": "France",
            "F": "FR",
            "G": "E",
            "H": "",
            "I": "",
            "J": "EUR"
        }
    ],
    "XLL": [
        {
            "B": "LFGL",
            "C": "LONS LE SAUNIER",
            "D": "Lons Le Saunier",
            "E": "France",
            "F": "FR",
            "G": "E",
            "H": "",
            "I": "",
            "J": "EUR"
        }
    ],
    "XLN": [
        {
            "B": "LFAF",
            "C": "LAON / CHAMBRY",
            "D": "Chambry",
            "E": "France",
            "F": "FR",
            "G": "E",
            "H": "",
            "I": "",
            "J": "EUR"
        }
    ],
    "XLR": [
        {
            "B": "LFDI",
            "C": "LIBOURNE",
            "D": "Libourne",
            "E": "France",
            "F": "FR",
            "G": "E",
            "H": "",
            "I": "",
            "J": "EUR"
        }
    ],
    "XLW": [
        {
            "B": "EDWD",
            "C": "LEMWERDER",
            "D": "Lemwerder",
            "E": "Germany",
            "F": "DE",
            "G": "E",
            "H": "",
            "I": "",
            "J": "EUR"
        }
    ],
    "XME": [
        {
            "B": "LFQJ",
            "C": "MAUBEUGE / ELESMES",
            "D": "Maubeuge",
            "E": "France",
            "F": "FR",
            "G": "E",
            "H": "",
            "I": "",
            "J": "EUR"
        }
    ],
    "XMF": [
        {
            "B": "LFSM",
            "C": "MONTBELIARD-COURCELL",
            "D": "Montbeliard",
            "E": "France",
            "F": "FR",
            "G": "E",
            "H": "",
            "I": "",
            "J": "EUR"
        }
    ],
    "XMJ": [
        {
            "B": "LFBM",
            "C": "MONT-DE-MARSAN",
            "D": "Mont-de-marsan",
            "E": "France",
            "F": "FR",
            "G": "E",
            "H": "",
            "I": "",
            "J": "EUR"
        }
    ],
    "XMK": [
        {
            "B": "LFLQ",
            "C": "MONTELIMAR - ANCONE",
            "D": "Montelimar",
            "E": "France",
            "F": "FR",
            "G": "E",
            "H": "",
            "I": "",
            "J": "EUR"
        }
    ],
    "XMN": [
        {
            "B": "ZSAM",
            "C": "Xiamen Gaoqi",
            "D": "Gaoqi International",
            "E": "Peoples Republic of China",
            "F": "CN",
            "G": "O",
            "H": "",
            "I": "",
            "J": "SEA"
        }
    ],
    "XMW": [
        {
            "B": "LFDB",
            "C": "MONTAUBAN",
            "D": "Montauban",
            "E": "France",
            "F": "FR",
            "G": "E",
            "H": "",
            "I": "",
            "J": "EUR"
        }
    ],
    "XNA": [
        {
            "B": "KXNA",
            "C": "Northwest Arkansas",
            "D": "Northwest Arkansas",
            "E": "USA",
            "F": "US",
            "G": "T",
            "H": "",
            "I": "",
            "J": "NOA"
        }
    ],
    "XNB": [
        {
            "B": "",
            "C": "Dubai International Airport",
            "D": "Travel Mall EY Bus Stn",
            "E": "United Arab Emirates",
            "F": "AE",
            "G": "O",
            "H": "",
            "I": "",
            "J": "MDE"
        }
    ],
    "XNM": [
        {
            "B": "ZSAM",
            "C": "XIAMEN GAOQI",
            "D": "Xiamen Gaoqi",
            "E": "Peoples Republic of China",
            "F": "CN",
            "G": "O",
            "H": "",
            "I": "",
            "J": "SEA"
        }
    ],
    "XOE": [
        {
            "B": "SSKT",
            "C": "AEROCLUBE SANTA CATARINA",
            "D": "Aeroclube de Santa Catarina",
            "E": "Brazil",
            "F": "BR",
            "G": "T",
            "H": "",
            "I": "",
            "J": "SOA"
        }
    ],
    "XOG": [
        {
            "B": "LFMO",
            "C": "ORANGE",
            "D": "Oristano Fenosu",
            "E": "France",
            "F": "FR",
            "G": "E",
            "H": "",
            "I": "",
            "J": "EUR"
        }
    ],
    "XRN": [
        {
            "B": "LFER",
            "C": "REDON / BAINS-SUR-OUST",
            "D": "Redon",
            "E": "France",
            "F": "FR",
            "G": "E",
            "H": "",
            "I": "",
            "J": "EUR"
        }
    ],
    "XRY": [
        {
            "B": "LEJR",
            "C": "JEREZ DE LA FRONTERA",
            "D": "Jerez",
            "E": "Spain",
            "F": "ES",
            "G": "E",
            "H": "",
            "I": "",
            "J": "EUR"
        }
    ],
    "XSL": [
        {
            "B": "LFDS",
            "C": "SARLAT / DOMME",
            "D": "Sarlat Domme",
            "E": "France",
            "F": "FR",
            "G": "E",
            "H": "",
            "I": "",
            "J": "EUR"
        }
    ],
    "XSP": [
        {
            "B": "WSSL",
            "C": "SINGAPORE - SELETAR",
            "D": "Singapore",
            "E": "Singapore",
            "F": "SG",
            "G": "O",
            "H": "",
            "I": "",
            "J": "SEA"
        }
    ],
    "XSS": [
        {
            "B": "LFJS",
            "C": "SOISSONS-COURMELLES",
            "D": "Soissons",
            "E": "France",
            "F": "FR",
            "G": "E",
            "H": "",
            "I": "",
            "J": "EUR"
        }
    ],
    "XST": [
        {
            "B": "LFXB",
            "C": "SAINTES-THENAC",
            "D": "Saintes Thenac",
            "E": "France",
            "F": "FR",
            "G": "E",
            "H": "",
            "I": "",
            "J": "EUR"
        }
    ],
    "XSV": [
        {
            "B": "LFQY",
            "C": "SAVERNE-STEINBOURG",
            "D": "Saverne Steinbourg",
            "E": "France",
            "F": "FR",
            "G": "E",
            "H": "",
            "I": "",
            "J": "EUR"
        }
    ],
    "XSW": [
        {
            "B": "LFSJ",
            "C": "SEDAN-DOUZY",
            "D": "Sedan Douzy",
            "E": "France",
            "F": "FR",
            "G": "E",
            "H": "",
            "I": "",
            "J": "EUR"
        }
    ],
    "XTH": [
        {
            "B": "LFGV",
            "C": "THIONVILLE / YUTZ",
            "D": "Thionville",
            "E": "France",
            "F": "FR",
            "G": "E",
            "H": "",
            "I": "",
            "J": "EUR"
        }
    ],
    "XUD": [
        {
            "B": "EGTC",
            "C": "CRANFIELD",
            "D": "CRANFIELD",
            "E": "United Kingdom",
            "F": "GB",
            "G": "U",
            "H": "",
            "I": "",
            "J": "EUR"
        }
    ],
    "XVI": [
        {
            "B": "LFHH",
            "C": "VIENNE / REVENTIN",
            "D": "Vienne",
            "E": "France",
            "F": "FR",
            "G": "E",
            "H": "",
            "I": "",
            "J": "EUR"
        }
    ],
    "XVN": [
        {
            "B": "LFGW",
            "C": "VERDUN / ROZELIER",
            "D": "Verdun",
            "E": "France",
            "F": "FR",
            "G": "E",
            "H": "",
            "I": "",
            "J": "EUR"
        }
    ],
    "XVO": [
        {
            "B": "LFQW",
            "C": "VESOUL-FROTEY",
            "D": "Vesoul",
            "E": "France",
            "F": "FR",
            "G": "E",
            "H": "",
            "I": "",
            "J": "EUR"
        }
    ],
    "XVS": [
        {
            "B": "LFAV",
            "C": "VALENCIENNES / DENAIN",
            "D": "Valenciennes",
            "E": "France",
            "F": "FR",
            "G": "E",
            "H": "",
            "I": "",
            "J": "EUR"
        }
    ],
    "XVZ": [
        {
            "B": "LFFV",
            "C": "VIERZON - MEREAU",
            "D": "Vierzon Mereau",
            "E": "France",
            "F": "FR",
            "G": "E",
            "H": "",
            "I": "",
            "J": "EUR"
        }
    ],
    "XWA": [
        {
            "B": "KXWA",
            "C": "WILLISTON BASIN",
            "D": "Williston Basin",
            "E": "USA",
            "F": "US",
            "G": "T",
            "H": "",
            "I": "",
            "J": "NOA"
        }
    ],
    "XXC": [
        {
            "B": "",
            "C": "CASCAIS MUNICIPAL AERODROME",
            "D": "CASCAIS MUNICIPAL AERODROME",
            "E": "Portugal",
            "F": "PT",
            "G": "E",
            "H": "",
            "I": "",
            "J": "EUR"
        }
    ],
    "XXN": [
        {
            "B": "",
            "C": "",
            "D": "",
            "E": "UNKNOWN",
            "F": "",
            "G": "A",
            "H": "",
            "I": "",
            "J": ""
        }
    ],
    "XXX": [
        {
            "B": "XXXX",
            "C": "UNKNOWN PENDING",
            "D": "Mystery Flight",
            "E": "UNKNOWN",
            "F": "0",
            "G": "D",
            "H": "",
            "I": "",
            "J": "EUR"
        }
    ],
    "XYD": [
        {
            "B": "",
            "C": "LYON PART-DIEU RAILWAY",
            "D": "Lyon Part-Dieu Railway",
            "E": "France",
            "F": "FR",
            "G": "E",
            "H": "",
            "I": "",
            "J": "EUR"
        }
    ],
    "XYT": [
        {
            "B": "LFIO",
            "C": "TOULOUSE - MONTAUDRAN",
            "D": "Toulouse",
            "E": "France",
            "F": "FR",
            "G": "E",
            "H": "",
            "I": "",
            "J": "EUR"
        }
    ],
    "XZM": [
        {
            "B": "",
            "C": "HONG KONG MACAU FERRY",
            "D": "Hong Kong Macau Ferry Terminal",
            "E": "Hong Kong",
            "F": "HK",
            "G": "O",
            "H": "",
            "I": "",
            "J": "SEA"
        }
    ],
    "YAK": [
        {
            "B": "PAYA",
            "C": "YAKUTAT",
            "D": "Yakutat",
            "E": "USA",
            "F": "US",
            "G": "T",
            "H": "",
            "I": "",
            "J": "NOA"
        }
    ],
    "YAM": [
        {
            "B": "CYAM",
            "C": "Sault Ste Marie",
            "D": "Sault Ste Marie",
            "E": "Canada",
            "F": "CA",
            "G": "T",
            "H": "",
            "I": "",
            "J": "NOA"
        }
    ],
    "YAW": [
        {
            "B": "CYAW",
            "C": "Shearwater",
            "D": "Shearwater YAW",
            "E": "Canada",
            "F": "",
            "G": "T",
            "H": "",
            "I": "",
            "J": ""
        }
    ],
    "YBC": [
        {
            "B": "CYBC",
            "C": "Baie Comeau",
            "D": "Baie Comeau",
            "E": "Canada",
            "F": "CA",
            "G": "T",
            "H": "",
            "I": "",
            "J": "NOA"
        }
    ],
    "YBL": [
        {
            "B": "CYBL",
            "C": "Campbell River",
            "D": "Campbell River",
            "E": "Canada",
            "F": "CA",
            "G": "T",
            "H": "",
            "I": "",
            "J": "NOA"
        }
    ],
    "YCD": [
        {
            "B": "CYCD",
            "C": "Nanaimo Arpt",
            "D": "Nanaimo Airport",
            "E": "Canada",
            "F": "CA",
            "G": "T",
            "H": "",
            "I": "",
            "J": "NOA"
        }
    ],
    "YCG": [
        {
            "B": "CYCG",
            "C": "Castlegar",
            "D": "West Kootenay Regional",
            "E": "Canada",
            "F": "CA",
            "G": "T",
            "H": "",
            "I": "",
            "J": "NOA"
        }
    ],
    "YDF": [
        {
            "B": "CYDF",
            "C": "DEER LAKE - NEW FOUNDLAND",
            "D": "Deer lake",
            "E": "Canada",
            "F": "CA",
            "G": "T",
            "H": "",
            "I": "",
            "J": "NOA"
        }
    ],
    "YEG": [
        {
            "B": "CYEG",
            "C": "EDMONTON",
            "D": "Edmonton",
            "E": "Canada",
            "F": "CA",
            "G": "T",
            "H": "",
            "I": "",
            "J": "NOA"
        }
    ],
    "YEI": [
        {
            "B": "LTBR",
            "C": "Yenisehir",
            "D": "Yenisehir",
            "E": "Turkey",
            "F": "",
            "G": "E",
            "H": "",
            "I": "",
            "J": ""
        }
    ],
    "YEO": [
        {
            "B": "EGDY",
            "C": "YEOVILTON",
            "D": "Yeovilton",
            "E": "United Kingdom",
            "F": "GB",
            "G": "U",
            "H": "",
            "I": "",
            "J": "EUR"
        }
    ],
    "YEV": [
        {
            "B": "CYEV",
            "C": "INUVIK (MIKE ZUBKO) N.W.T",
            "D": "Inuvik (Mike Zubko) N.W.T.",
            "E": "Canada",
            "F": "CA",
            "G": "T",
            "H": "",
            "I": "",
            "J": "NOA"
        }
    ],
    "YFB": [
        {
            "B": "CYFB",
            "C": "IQALUIT",
            "D": "Iqaluit",
            "E": "Canada",
            "F": "CA",
            "G": "T",
            "H": "",
            "I": "",
            "J": "NOA"
        }
    ],
    "YFC": [
        {
            "B": "CYFC",
            "C": "FEDERICTON",
            "D": "FEDERICTON INTERNATIONAL AIRPORT",
            "E": "Canada",
            "F": "CA",
            "G": "T",
            "H": "",
            "I": "",
            "J": "NOA"
        }
    ],
    "YGJ": [
        {
            "B": "ROYN",
            "C": "Miho",
            "D": "Miho Ab",
            "E": "Japan",
            "F": "JP",
            "G": "O",
            "H": "",
            "I": "",
            "J": "JAK"
        }
    ],
    "YGK": [
        {
            "B": "CYGK",
            "C": "Kingston",
            "D": "Norman Rogers",
            "E": "Canada",
            "F": "CA",
            "G": "T",
            "H": "",
            "I": "",
            "J": "NOA"
        }
    ],
    "YHM": [
        {
            "B": "CYHM",
            "C": "TORONTO - HAMILTON",
            "D": "Toronto YHM",
            "E": "Canada",
            "F": "CA",
            "G": "T",
            "H": "",
            "I": "",
            "J": "NOA"
        }
    ],
    "YHZ": [
        {
            "B": "CYHZ",
            "C": "HALIFAX INTERNATIONAL",
            "D": "Halifax",
            "E": "Canada",
            "F": "CA",
            "G": "T",
            "H": "",
            "I": "",
            "J": "NOA"
        }
    ],
    "YIH": [
        {
            "B": "ZHYC",
            "C": "Yichang",
            "D": "Sanxia",
            "E": "Peoples Republic of China",
            "F": "CN",
            "G": "O",
            "H": "",
            "I": "",
            "J": "SEA"
        }
    ],
    "YIP": [
        {
            "B": "KYIP",
            "C": "DETROIT - WILLOW RUN",
            "D": "Detroit",
            "E": "USA",
            "F": "US",
            "G": "T",
            "H": "",
            "I": "",
            "J": "NOA"
        }
    ],
    "YIW": [
        {
            "B": "ZSYW",
            "C": "Yiwu",
            "D": "Yiwu Airport",
            "E": "Peoples Republic of China",
            "F": "CN",
            "G": "O",
            "H": "",
            "I": "",
            "J": "SEA"
        }
    ],
    "YJT": [
        {
            "B": "CYJT",
            "C": "STEPHENVILLE",
            "D": "Stephenville",
            "E": "Canada",
            "F": "CA",
            "G": "T",
            "H": "",
            "I": "",
            "J": "NOA"
        }
    ],
    "YJW": [
        {
            "B": "",
            "C": "",
            "D": "",
            "E": "UNKNOWN",
            "F": "",
            "G": "A",
            "H": "",
            "I": "",
            "J": ""
        }
    ],
    "YKA": [
        {
            "B": "CYKA",
            "C": "Kamloops",
            "D": "Kamloops",
            "E": "Canada",
            "F": "CA",
            "G": "T",
            "H": "",
            "I": "",
            "J": "NOA"
        }
    ],
    "YKF": [
        {
            "B": "CYKF",
            "C": "KITCHENER-WATERLOO REGIONAL",
            "D": "KITCHENER-WATERLOO REGIONAL AIRPORT",
            "E": "Canada",
            "F": "CA",
            "G": "T",
            "H": "",
            "I": "",
            "J": "NOA"
        }
    ],
    "YKM": [
        {
            "B": "KYKM",
            "C": "Yakima, Washington",
            "D": "Yakima Air Terminal",
            "E": "USA",
            "F": "US",
            "G": "T",
            "H": "",
            "I": "",
            "J": "NOA"
        }
    ],
    "YKZ": [
        {
            "B": "CYKZ",
            "C": "TORONTO - BUTTONVILLE",
            "D": "Toronto",
            "E": "Canada",
            "F": "CA",
            "G": "T",
            "H": "",
            "I": "",
            "J": "NOA"
        }
    ],
    "YLL": [
        {
            "B": "CYLL",
            "C": "LLOYDMINSTER",
            "D": "Lloydminster",
            "E": "Canada",
            "F": "CA",
            "G": "T",
            "H": "",
            "I": "",
            "J": "NOA"
        }
    ],
    "YLW": [
        {
            "B": "CYLW",
            "C": "KELOWNA INTL",
            "D": "KELOWNA INTERNATIONAL AIRPORT",
            "E": "Canada",
            "F": "CA",
            "G": "T",
            "H": "",
            "I": "",
            "J": "NOA"
        }
    ],
    "YMM": [
        {
            "B": "CYMM",
            "C": "Fort Mcmurray",
            "D": "Fort mcmurray",
            "E": "Canada",
            "F": "CA",
            "G": "T",
            "H": "",
            "I": "",
            "J": ""
        }
    ],
    "YMQ": [
        {
            "B": "CYMQ",
            "C": "Metropolitan Area",
            "D": "Montreal",
            "E": "Canada",
            "F": "CA",
            "G": "T",
            "H": "",
            "I": "",
            "J": "NOA"
        }
    ],
    "YMX": [
        {
            "B": "CYMX",
            "C": "MONTREAL MIRABEL",
            "D": "Montreal",
            "E": "Canada",
            "F": "CA",
            "G": "T",
            "H": "",
            "I": "",
            "J": "NOA"
        }
    ],
    "YNB": [
        {
            "B": "OEYN",
            "C": "YANBU AIRPORT",
            "D": "PRINCE ABDUL MOHSIN BIN ABDULAZIZ AIRPORT",
            "E": "Saudi Arabia",
            "F": "SA",
            "G": "O",
            "H": "",
            "I": "",
            "J": "MDE"
        }
    ],
    "YNJ": [
        {
            "B": "ZYYJ",
            "C": "Yanji",
            "D": "Chaoyangchuan",
            "E": "Peoples Republic of China",
            "F": "CN",
            "G": "O",
            "H": "",
            "I": "",
            "J": "SEA"
        }
    ],
    "YNT": [
        {
            "B": "ZSYT",
            "C": "Laishan",
            "D": "Penglai International",
            "E": "Peoples Republic of China",
            "F": "CN",
            "G": "O",
            "H": "",
            "I": "",
            "J": "SEA"
        }
    ],
    "YOW": [
        {
            "B": "CYOW",
            "C": "OTTAWA INTERNATIONAL",
            "D": "Ottawa",
            "E": "Canada",
            "F": "CA",
            "G": "T",
            "H": "",
            "I": "",
            "J": "NOA"
        }
    ],
    "YPQ": [
        {
            "B": "CYPQ",
            "C": "PETERBOROUGH AIRPORT",
            "D": "PETERBOROUGH AIRPORT",
            "E": "Canada",
            "F": "CA",
            "G": "T",
            "H": "",
            "I": "",
            "J": "NOA"
        }
    ],
    "YPR": [
        {
            "B": "CYPR",
            "C": "Digby Island",
            "D": "Prince Rupert Airport",
            "E": "Canada",
            "F": "CA",
            "G": "T",
            "H": "",
            "I": "",
            "J": "NOA"
        }
    ],
    "YQA": [
        {
            "B": "CYQA",
            "C": "MUSKOKA AIRPORT",
            "D": "MUSKOKA AIRPORT",
            "E": "Canada",
            "F": "CA",
            "G": "T",
            "H": "",
            "I": "",
            "J": "NOA"
        }
    ],
    "YQB": [
        {
            "B": "CYQB",
            "C": "QUEBEC",
            "D": "Quebec",
            "E": "Canada",
            "F": "CA",
            "G": "T",
            "H": "",
            "I": "",
            "J": "NOA"
        }
    ],
    "YQF": [
        {
            "B": "CYQF",
            "C": "Red Deer",
            "D": "Red Deer Industrial",
            "E": "Canada",
            "F": "CA",
            "G": "T",
            "H": "",
            "I": "",
            "J": "NOA"
        }
    ],
    "YQL": [
        {
            "B": "CYQL",
            "C": "Lethbridge",
            "D": "Lethbridge County",
            "E": "Canada",
            "F": "CA",
            "G": "T",
            "H": "",
            "I": "",
            "J": "NOA"
        }
    ],
    "YQM": [
        {
            "B": "CYQM",
            "C": "Greater Moncton Roméo LeBlanc Int Airport",
            "D": "Greater Moncton Roméo LeBlanc Int Airport",
            "E": "Canada",
            "F": "",
            "G": "T",
            "H": "",
            "I": "",
            "J": "NOA"
        }
    ],
    "YQQ": [
        {
            "B": "CYQQ",
            "C": "Comox",
            "D": "Comox",
            "E": "Canada",
            "F": "CA",
            "G": "T",
            "H": "",
            "I": "",
            "J": "NOA"
        }
    ],
    "YQR": [
        {
            "B": "CYQR",
            "C": "Regina",
            "D": "Regina International",
            "E": "Canada",
            "F": "CA",
            "G": "T",
            "H": "",
            "I": "",
            "J": "NOA"
        }
    ],
    "YQS": [
        {
            "B": "CYQS",
            "C": "ST.THOMAS",
            "D": "St.thomas municipal airport",
            "E": "Canada",
            "F": "CA",
            "G": "T",
            "H": "",
            "I": "",
            "J": "NOA"
        }
    ],
    "YQT": [
        {
            "B": "CYQT",
            "C": "THUNDER BAY",
            "D": "Thunder bay",
            "E": "Canada",
            "F": "CA",
            "G": "T",
            "H": "",
            "I": "",
            "J": "NOA"
        }
    ],
    "YQU": [
        {
            "B": "CYQU",
            "C": "Grande Prairie",
            "D": "Grande Prairie",
            "E": "Canada",
            "F": "CA",
            "G": "T",
            "H": "",
            "I": "",
            "J": "NOA"
        }
    ],
    "YQX": [
        {
            "B": "CYQX",
            "C": "GANDER",
            "D": "Gander",
            "E": "Canada",
            "F": "CA",
            "G": "T",
            "H": "",
            "I": "",
            "J": "NOA"
        }
    ],
    "YQY": [
        {
            "B": "CYQY",
            "C": "SYDNEY",
            "D": "SYDNEY AIRPORT, NS",
            "E": "Canada",
            "F": "CA",
            "G": "T",
            "H": "",
            "I": "",
            "J": ""
        }
    ],
    "YQZ": [
        {
            "B": "CYQZ",
            "C": "QUESNEL AIRPORT",
            "D": "Quesnel regional airport",
            "E": "Canada",
            "F": "CA",
            "G": "T",
            "H": "",
            "I": "",
            "J": ""
        }
    ],
    "YRL": [
        {
            "B": "CYRL",
            "C": "RED LAKE",
            "D": "Red Lake",
            "E": "Canada",
            "F": "CA",
            "G": "T",
            "H": "",
            "I": "",
            "J": "NOA"
        }
    ],
    "YRQ": [
        {
            "B": "CYRQ",
            "C": "TROIS-RIVIERES",
            "D": "",
            "E": "Canada",
            "F": "CA",
            "G": "T",
            "H": "",
            "I": "",
            "J": "NOA"
        }
    ],
    "YSB": [
        {
            "B": "CYSB",
            "C": "SUDBURY AIRPORT",
            "D": "Sudbury airport",
            "E": "Canada",
            "F": "CA",
            "G": "T",
            "H": "",
            "I": "",
            "J": "NOA"
        }
    ],
    "YSJ": [
        {
            "B": "CYSJ",
            "C": "ST. JOHN",
            "D": "St. john",
            "E": "Canada",
            "F": "CA",
            "G": "T",
            "H": "",
            "I": "",
            "J": "NOA"
        }
    ],
    "YTR": [
        {
            "B": "CYTR",
            "C": "TRENTON",
            "D": "Trenton",
            "E": "Canada",
            "F": "CA",
            "G": "T",
            "H": "",
            "I": "",
            "J": "NOA"
        }
    ],
    "YTS": [
        {
            "B": "CYTS",
            "C": "Timmins",
            "D": "Victor M. Power",
            "E": "Canada",
            "F": "CA",
            "G": "T",
            "H": "",
            "I": "",
            "J": "NOA"
        }
    ],
    "YTZ": [
        {
            "B": "CYTZ",
            "C": "TORONTO - ISLAND",
            "D": "Toronto ytz",
            "E": "Canada",
            "F": "CA",
            "G": "T",
            "H": "",
            "I": "",
            "J": "NOA"
        }
    ],
    "YUL": [
        {
            "B": "CYUL",
            "C": "MONTREAL - DORVAL",
            "D": "Montreal",
            "E": "Canada",
            "F": "CA",
            "G": "T",
            "H": "",
            "I": "",
            "J": "NOA"
        }
    ],
    "YUM": [
        {
            "B": "KNYL",
            "C": "Yuma",
            "D": "Yuma International",
            "E": "USA",
            "F": "US",
            "G": "T",
            "H": "",
            "I": "",
            "J": "NOA"
        }
    ],
    "YUS": [
        {
            "B": "ZLYS",
            "C": "Yushu Batang",
            "D": "Batang",
            "E": "Peoples Republic of China",
            "F": "CN",
            "G": "O",
            "H": "",
            "I": "",
            "J": "SEA"
        }
    ],
    "YUX": [
        {
            "B": "CYUX",
            "C": "HALL BEACH",
            "D": "Hall beach",
            "E": "Canada",
            "F": "CA",
            "G": "T",
            "H": "",
            "I": "",
            "J": "NOA"
        }
    ],
    "YUY": [
        {
            "B": "CYUY",
            "C": "Rouyn",
            "D": "Rouyn-Noranda",
            "E": "Canada",
            "F": "CA",
            "G": "T",
            "H": "",
            "I": "",
            "J": "NOA"
        }
    ],
    "YVO": [
        {
            "B": "CYVO",
            "C": "Val D'Or",
            "D": "Val d'Or",
            "E": "Canada",
            "F": "CA",
            "G": "T",
            "H": "",
            "I": "",
            "J": "NOA"
        }
    ],
    "YVR": [
        {
            "B": "CYVR",
            "C": "VANCOUVER INTERNATIONAL",
            "D": "Vancouver",
            "E": "Canada",
            "F": "CA",
            "G": "T",
            "H": "",
            "I": "",
            "J": "NOA"
        }
    ],
    "YWG": [
        {
            "B": "CYWG",
            "C": "WINNIPEG INTERNATIONAL",
            "D": "Winnipeg",
            "E": "Canada",
            "F": "CA",
            "G": "T",
            "H": "",
            "I": "",
            "J": "NOA"
        }
    ],
    "YWK": [
        {
            "B": "CYWK",
            "C": "Wabush",
            "D": "Wabush",
            "E": "Canada",
            "F": "CA",
            "G": "T",
            "H": "",
            "I": "",
            "J": "NOA"
        }
    ],
    "YWL": [
        {
            "B": "CYWL",
            "C": "Williams Lake",
            "D": "Williams Lake",
            "E": "Canada",
            "F": "CA",
            "G": "T",
            "H": "",
            "I": "",
            "J": "NOA"
        }
    ],
    "YXC": [
        {
            "B": "CYXC",
            "C": "Cranbrook",
            "D": "Canadian Rockies Intl",
            "E": "Canada",
            "F": "CA",
            "G": "T",
            "H": "",
            "I": "",
            "J": "NOA"
        }
    ],
    "YXE": [
        {
            "B": "CYXE",
            "C": "Saskatoon",
            "D": "J.G. Diefenbaker Intl",
            "E": "Canada",
            "F": "CA",
            "G": "T",
            "H": "",
            "I": "",
            "J": "NOA"
        }
    ],
    "YXH": [
        {
            "B": "CYXH",
            "C": "MEDICINE HAT",
            "D": "Medicine Hat",
            "E": "Canada",
            "F": "CA",
            "G": "T",
            "H": "",
            "I": "",
            "J": "NOA"
        }
    ],
    "YXJ": [
        {
            "B": "CYXJ",
            "C": "Fort St John",
            "D": "North Peace",
            "E": "Canada",
            "F": "CA",
            "G": "T",
            "H": "",
            "I": "",
            "J": "NOA"
        }
    ],
    "YXS": [
        {
            "B": "CYXS",
            "C": "PRINCE GEORGE",
            "D": "Prince george",
            "E": "Canada",
            "F": "CA",
            "G": "T",
            "H": "",
            "I": "",
            "J": "NOA"
        }
    ],
    "YXT": [
        {
            "B": "CYXT",
            "C": "Terrace",
            "D": "Northwest Regional",
            "E": "Canada",
            "F": "CA",
            "G": "T",
            "H": "",
            "I": "",
            "J": "NOA"
        }
    ],
    "YXU": [
        {
            "B": "CYXU",
            "C": "LONDON ONTARIO",
            "D": "London - ontario",
            "E": "Canada",
            "F": "CA",
            "G": "T",
            "H": "",
            "I": "",
            "J": "NOA"
        }
    ],
    "YXX": [
        {
            "B": "CYXX",
            "C": "Abbotsford",
            "D": "Abbotsford International",
            "E": "Canada",
            "F": "CA",
            "G": "T",
            "H": "",
            "I": "",
            "J": "NOA"
        }
    ],
    "YYB": [
        {
            "B": "CYYB",
            "C": "North Bay",
            "D": "Jack Garland",
            "E": "Canada",
            "F": "CA",
            "G": "T",
            "H": "",
            "I": "",
            "J": "NOA"
        }
    ],
    "YYC": [
        {
            "B": "CYYC",
            "C": "CALGARY",
            "D": "Calgary",
            "E": "Canada",
            "F": "CA",
            "G": "T",
            "H": "",
            "I": "",
            "J": "NOA"
        }
    ],
    "YYD": [
        {
            "B": "CYYD",
            "C": "Smithers",
            "D": "Smithers",
            "E": "Canada",
            "F": "CA",
            "G": "T",
            "H": "",
            "I": "",
            "J": "NOA"
        }
    ],
    "YYF": [
        {
            "B": "CYYF",
            "C": "Penticton",
            "D": "Penticton",
            "E": "Canada",
            "F": "CA",
            "G": "T",
            "H": "",
            "I": "",
            "J": "NOA"
        }
    ],
    "YYG": [
        {
            "B": "CYYG",
            "C": "Charlottetown",
            "D": "Charlottetown",
            "E": "Canada",
            "F": "CA",
            "G": "T",
            "H": "",
            "I": "",
            "J": "NOA"
        }
    ],
    "YYJ": [
        {
            "B": "CYYJ",
            "C": "VICTORIA INTERNATIONAL",
            "D": "Victoria",
            "E": "Canada",
            "F": "CA",
            "G": "T",
            "H": "",
            "I": "",
            "J": "NOA"
        }
    ],
    "YYR": [
        {
            "B": "CYYR",
            "C": "GOOSE BAY",
            "D": "Goose Bay",
            "E": "Canada",
            "F": "CA",
            "G": "T",
            "H": "",
            "I": "",
            "J": "NOA"
        }
    ],
    "YYT": [
        {
            "B": "CYYT",
            "C": "ST. JOHNS INTERNATIONAL",
            "D": "St Johns",
            "E": "Canada",
            "F": "CA",
            "G": "T",
            "H": "",
            "I": "",
            "J": "NOA"
        }
    ],
    "YYY": [
        {
            "B": "CYYY",
            "C": "Mont Joli",
            "D": "Mont-Joli",
            "E": "Canada",
            "F": "CA",
            "G": "T",
            "H": "",
            "I": "",
            "J": "NOA"
        }
    ],
    "YYZ": [
        {
            "B": "CYYZ",
            "C": "TORONTO - PEARSON",
            "D": "Toronto",
            "E": "Canada",
            "F": "CA",
            "G": "T",
            "H": "",
            "I": "",
            "J": "NOA"
        }
    ],
    "YZD": [
        {
            "B": "CYZD",
            "C": "DOWNSVIEW AIRPORT",
            "D": "DOWNSVIEW AIRPORT",
            "E": "Canada",
            "F": "CA",
            "G": "T",
            "H": "",
            "I": "",
            "J": "NOA"
        }
    ],
    "YZF": [
        {
            "B": "CYZF",
            "C": "YELLOWKNIFE",
            "D": "Yellowknife",
            "E": "Canada",
            "F": "CA",
            "G": "T",
            "H": "",
            "I": "",
            "J": "NOA"
        }
    ],
    "YZP": [
        {
            "B": "CYZP",
            "C": "Sandspit",
            "D": "Sandspit",
            "E": "Canada",
            "F": "CA",
            "G": "T",
            "H": "",
            "I": "",
            "J": "NOA"
        }
    ],
    "YZR": [
        {
            "B": "CYZR",
            "C": "Sarnia",
            "D": "Chris Hadfield",
            "E": "Canada",
            "F": "CA",
            "G": "T",
            "H": "",
            "I": "",
            "J": "NOA"
        }
    ],
    "YZX": [
        {
            "B": "CYZX",
            "C": "GREENWOOD",
            "D": "Greenwood",
            "E": "Canada",
            "F": "CA",
            "G": "T",
            "H": "",
            "I": "",
            "J": "NOA"
        }
    ],
    "YZZ": [
        {
            "B": "",
            "C": "TRAIL",
            "D": "Trail",
            "E": "Canada",
            "F": "CA",
            "G": "T",
            "H": "",
            "I": "",
            "J": "NOA"
        }
    ],
    "ZAA": [
        {
            "B": "CAC3",
            "C": "ALICE ARM/SILVER CITY",
            "D": "ALICE ARM/SILVER CITY WATER AERODROME",
            "E": "Canada",
            "F": "CA",
            "G": "T",
            "H": "",
            "I": "",
            "J": "NOA"
        }
    ],
    "ZAD": [
        {
            "B": "LDZD",
            "C": "ZADAR",
            "D": "Zadar",
            "E": "Croatia",
            "F": "HR",
            "G": "E",
            "H": "",
            "I": "",
            "J": "EUR"
        }
    ],
    "ZAG": [
        {
            "B": "LDZA",
            "C": "ZAGREB",
            "D": "Zagreb",
            "E": "Croatia",
            "F": "HR",
            "G": "E",
            "H": "",
            "I": "",
            "J": "EUR"
        }
    ],
    "ZAL": [
        {
            "B": "SCVD",
            "C": "VALDIVIA/AD PICHOY",
            "D": "Valdivia/Ad Pichoy",
            "E": "Chile",
            "F": "CL",
            "G": "T",
            "H": "",
            "I": "",
            "J": "SOA"
        }
    ],
    "ZAO": [
        {
            "B": "LFCC",
            "C": "CAHORS / LALBENQUE",
            "D": "Cahors",
            "E": "France",
            "F": "FR",
            "G": "E",
            "H": "",
            "I": "",
            "J": "EUR"
        }
    ],
    "ZAQ": [
        {
            "B": "",
            "C": "NUREMBERG RAILWAY",
            "D": "Nuremberg Railway",
            "E": "Germany",
            "F": "DE",
            "G": "E",
            "H": "",
            "I": "",
            "J": "EUR"
        }
    ],
    "ZAV": [
        {
            "B": "LPAV",
            "C": "AVEIRO MIL",
            "D": "Aveiro",
            "E": "Portugal",
            "F": "PT",
            "G": "E",
            "H": "",
            "I": "",
            "J": "EUR"
        }
    ],
    "ZAZ": [
        {
            "B": "LEZG",
            "C": "ZARAGOZA",
            "D": "Zaragoza",
            "E": "Spain",
            "F": "ES",
            "G": "E",
            "H": "",
            "I": "",
            "J": "EUR"
        }
    ],
    "ZBE": [
        {
            "B": "LKZA",
            "C": "ZABREH / DOLNI BE",
            "D": "Zabreh",
            "E": "Czech Republic",
            "F": "CZ",
            "G": "E",
            "H": "",
            "I": "",
            "J": "EUR"
        }
    ],
    "ZBF": [
        {
            "B": "CZBF",
            "C": "Bathurst",
            "D": "Bathurst",
            "E": "Canada",
            "F": "CA",
            "G": "T",
            "H": "",
            "I": "",
            "J": "NOA"
        }
    ],
    "ZCB": [
        {
            "B": "EDFC",
            "C": "ASCHAFFENBURG - GROSSO",
            "D": "Aschaffenburg",
            "E": "Germany",
            "F": "DE",
            "G": "E",
            "H": "",
            "I": "",
            "J": "EUR"
        }
    ],
    "ZCC": [
        {
            "B": "EDTB",
            "C": "BADEN-BADEN / OOS",
            "D": "Baden Baden",
            "E": "Germany",
            "F": "DE",
            "G": "E",
            "H": "",
            "I": "",
            "J": "EUR"
        }
    ],
    "ZCL": [
        {
            "B": "MMZC",
            "C": "La Calera",
            "D": "Leobardo C. Ruiz Intl",
            "E": "Mexico",
            "F": "MX",
            "G": "T",
            "H": "",
            "I": "",
            "J": "CEM"
        }
    ],
    "ZCO": [
        {
            "B": "SCTC",
            "C": "Temuco",
            "D": "La Araucania",
            "E": "Chile",
            "F": "CL",
            "G": "T",
            "H": "",
            "I": "",
            "J": "SOA"
        }
    ],
    "ZCV": [
        {
            "B": "EDLD",
            "C": "DINSLAKEN SCHWARZE",
            "D": "Dinslaken",
            "E": "Germany",
            "F": "DE",
            "G": "E",
            "H": "",
            "I": "",
            "J": "EUR"
        }
    ],
    "ZDK": [
        {
            "B": "LSZP",
            "C": "BIEL-KAPPELEN",
            "D": "Biel Kappelen",
            "E": "Switzerland",
            "F": "CH",
            "G": "E",
            "H": "",
            "I": "",
            "J": "EUR"
        }
    ],
    "ZDN": [
        {
            "B": "LKTB",
            "C": "BRNO BUS STATION",
            "D": "Brno Bus Station",
            "E": "Czech Republic",
            "F": "CZ",
            "G": "E",
            "H": "",
            "I": "",
            "J": "EUR"
        }
    ],
    "ZEE": [
        {
            "B": "EDGF",
            "C": "FULDA - JOSSA",
            "D": "Fulda Jossa",
            "E": "Germany",
            "F": "DE",
            "G": "E",
            "H": "",
            "I": "",
            "J": "EUR"
        }
    ],
    "ZFV": [
        {
            "B": "",
            "C": "Philadelphia Railway Station",
            "D": "Philadelphia 30th Street Rail Station",
            "E": "USA",
            "F": "US",
            "G": "T",
            "H": "",
            "I": "",
            "J": "NOA"
        }
    ],
    "ZGA": [
        {
            "B": "EDAJ",
            "C": "GERA - LEUMNITZ",
            "D": "Gera Leumnitz",
            "E": "Germany",
            "F": "DE",
            "G": "E",
            "H": "",
            "I": "",
            "J": "EUR"
        }
    ],
    "ZGO": [
        {
            "B": "EDEG",
            "C": "GOTHA - OST",
            "D": "Gotha Ost",
            "E": "Germany",
            "F": "DE",
            "G": "E",
            "H": "",
            "I": "",
            "J": "EUR"
        }
    ],
    "ZHA": [
        {
            "B": "ZGZJ",
            "C": "Zhanjiang",
            "D": "Zhanjiang",
            "E": "Peoples Republic of China",
            "F": "CN",
            "G": "O",
            "H": "",
            "I": "",
            "J": "SEA"
        }
    ],
    "ZHH": [
        {
            "B": "LSXO",
            "C": "GOSSAU SG",
            "D": "Gossau Sg",
            "E": "Switzerland",
            "F": "CH",
            "G": "E",
            "H": "",
            "I": "",
            "J": "EUR"
        }
    ],
    "ZHI": [
        {
            "B": "LSZG",
            "C": "GRENCHEN",
            "D": "Grenchen",
            "E": "Switzerland",
            "F": "CH",
            "G": "E",
            "H": "",
            "I": "",
            "J": "EUR"
        }
    ],
    "ZHK": [
        {
            "B": "LSHA",
            "C": "GSTAAD INN GRUND",
            "D": "Gstaad",
            "E": "Switzerland",
            "F": "CH",
            "G": "E",
            "H": "",
            "I": "",
            "J": "EUR"
        }
    ],
    "ZHW": [
        {
            "B": "LSPL",
            "C": "LANGENTHAL",
            "D": "La Cote",
            "E": "Switzerland",
            "F": "CH",
            "G": "E",
            "H": "",
            "I": "",
            "J": "EUR"
        }
    ],
    "ZHY": [
        {
            "B": "ZLZW",
            "C": "Zhongwei Shapotou",
            "D": "Xiangshan Airport",
            "E": "Peoples Republic of China",
            "F": "CN",
            "G": "O",
            "H": "",
            "I": "",
            "J": "SEA"
        }
    ],
    "ZIA": [
        {
            "B": "LIDT",
            "C": "TRENTO / MATTARELLO",
            "D": "Trento",
            "E": "Italy",
            "F": "IT",
            "G": "E",
            "H": "",
            "I": "",
            "J": "EUR"
        }
    ],
    "ZIH": [
        {
            "B": "MMZH",
            "C": "Internacional",
            "D": "Ixtapa/Zihuatanejo International",
            "E": "Mexico",
            "F": "MX",
            "G": "T",
            "H": "",
            "I": "",
            "J": "CEM"
        }
    ],
    "ZIN": [
        {
            "B": "LSXI",
            "C": "INTERLAKEN",
            "D": "Interlaken",
            "E": "Switzerland",
            "F": "CH",
            "G": "E",
            "H": "",
            "I": "",
            "J": "EUR"
        }
    ],
    "ZIR": [
        {
            "B": "EKRD",
            "C": "RANDERS",
            "D": "Randers",
            "E": "Denmark",
            "F": "DK",
            "G": "E",
            "H": "",
            "I": "",
            "J": "EUR"
        }
    ],
    "ZJI": [
        {
            "B": "LSZL",
            "C": "LOCARNO",
            "D": "Locarno",
            "E": "Switzerland",
            "F": "CH",
            "G": "E",
            "H": "",
            "I": "",
            "J": "EUR"
        }
    ],
    "ZJS": [
        {
            "B": "EDBJ",
            "C": "JENA - SCHONGLEINA",
            "D": "Jena",
            "E": "Germany",
            "F": "DE",
            "G": "E",
            "H": "",
            "I": "",
            "J": "EUR"
        }
    ],
    "ZJU": [
        {
            "B": "LSPO",
            "C": "OLTEN",
            "D": "Olten",
            "E": "Switzerland",
            "F": "CH",
            "G": "E",
            "H": "",
            "I": "",
            "J": "EUR"
        }
    ],
    "ZKJ": [
        {
            "B": "LSPF",
            "C": "SCHAFFHAUSEN",
            "D": "Schaffhausen",
            "E": "Switzerland",
            "F": "CH",
            "G": "E",
            "H": "",
            "I": "",
            "J": "EUR"
        }
    ],
    "ZLI": [
        {
            "B": "LSPH",
            "C": "WINTERTHUR",
            "D": "Winterthur",
            "E": "Switzerland",
            "F": "CH",
            "G": "E",
            "H": "",
            "I": "",
            "J": "EUR"
        }
    ],
    "ZLJ": [
        {
            "B": "LSGY",
            "C": "YVERDON LES BAINS",
            "D": "Yverdon",
            "E": "Switzerland",
            "F": "CH",
            "G": "E",
            "H": "",
            "I": "",
            "J": "EUR"
        }
    ],
    "ZLO": [
        {
            "B": "MMZO",
            "C": "Manzanillo",
            "D": "Playa de Oro Intl",
            "E": "Mexico",
            "F": "MX",
            "G": "T",
            "H": "",
            "I": "",
            "J": "CEM"
        }
    ],
    "ZMO": [
        {
            "B": "LIPM",
            "C": "MODENA / MARZAGLIA",
            "D": "Modena",
            "E": "Italy",
            "F": "IT",
            "G": "E",
            "H": "",
            "I": "",
            "J": "EUR"
        }
    ],
    "ZNE": [
        {
            "B": "YNWN",
            "C": "Newman",
            "D": "Newman",
            "E": "Australia",
            "F": "AU",
            "G": "O",
            "H": "",
            "I": "",
            "J": "SWP"
        }
    ],
    "ZNO": [
        {
            "B": "EDVM",
            "C": "HILDESHEIM",
            "D": "Hildesheim",
            "E": "Germany",
            "F": "DE",
            "G": "E",
            "H": "",
            "I": "",
            "J": "EUR"
        }
    ],
    "ZNQ": [
        {
            "B": "EZNQ",
            "C": "INGOLSTADT",
            "D": "Ingolstadt",
            "E": "Germany",
            "F": "DE",
            "G": "E",
            "H": "",
            "I": "",
            "J": "EUR"
        }
    ],
    "ZNS": [
        {
            "B": "EDMK",
            "C": "KEMPTEN - DURACH",
            "D": "Kempten Durach",
            "E": "Germany",
            "F": "DE",
            "G": "E",
            "H": "",
            "I": "",
            "J": "EUR"
        }
    ],
    "ZNV": [
        {
            "B": "EDRK",
            "C": "KOBLENZ - WINNINGEN",
            "D": "Koblenz",
            "E": "Germany",
            "F": "DE",
            "G": "E",
            "H": "",
            "I": "",
            "J": "EUR"
        }
    ],
    "ZNZ": [
        {
            "B": "HTZA",
            "C": "Kisauni",
            "D": "Zanzibar International",
            "E": "Tanzania",
            "F": "TZ",
            "G": "O",
            "H": "",
            "I": "",
            "J": "AFR"
        }
    ],
    "ZOA": [
        {
            "B": "EDKL",
            "C": "LEVERKUSEN",
            "D": "Leverkusen",
            "E": "Germany",
            "F": "DE",
            "G": "E",
            "H": "",
            "I": "",
            "J": "EUR"
        }
    ],
    "ZOI": [
        {
            "B": "EDFN",
            "C": "MARBURG - SCHONSTADT",
            "D": "Marburg",
            "E": "Germany",
            "F": "DE",
            "G": "E",
            "H": "",
            "I": "",
            "J": "EUR"
        }
    ],
    "ZOJ": [
        {
            "B": "EDLM",
            "C": "MARL - LOEMUHLE",
            "D": "Marl Loemuhle",
            "E": "Germany",
            "F": "DE",
            "G": "E",
            "H": "",
            "I": "",
            "J": "EUR"
        }
    ],
    "ZOS": [
        {
            "B": "SCJO",
            "C": "Canal Balo",
            "D": "Canal Bajo",
            "E": "Chile",
            "F": "CL",
            "G": "T",
            "H": "",
            "I": "",
            "J": "SOA"
        }
    ],
    "ZOW": [
        {
            "B": "EDWN",
            "C": "NORDHORN-LINGEN",
            "D": "Nordhorn Lingen",
            "E": "Germany",
            "F": "DE",
            "G": "E",
            "H": "",
            "I": "",
            "J": "EUR"
        }
    ],
    "ZPA": [
        {
            "B": "EDTO",
            "C": "Off-line Pt",
            "D": "Offenburg",
            "E": "Germany",
            "F": "DE",
            "G": "E",
            "H": "",
            "I": "",
            "J": "EUR"
        }
    ],
    "ZPE": [
        {
            "B": "EDWO",
            "C": "OSNABRUCK - ATTERHEIDE",
            "D": "Osnabruck",
            "E": "Germany",
            "F": "DE",
            "G": "E",
            "H": "",
            "I": "",
            "J": "EUR"
        }
    ],
    "ZPG": [
        {
            "B": "EDVP",
            "C": "PEINE-EDDESSE",
            "D": "Peine Eddesse",
            "E": "Germany",
            "F": "DE",
            "G": "E",
            "H": "",
            "I": "",
            "J": "EUR"
        }
    ],
    "ZPI": [
        {
            "B": "EDRP",
            "C": "PIRMASENS",
            "D": "Pirmasens",
            "E": "Germany",
            "F": "DE",
            "G": "E",
            "H": "",
            "I": "",
            "J": "EUR"
        }
    ],
    "ZPT": [
        {
            "B": "EDRJ",
            "C": "SAARLOUIS - DUREN",
            "D": "Saarlouis",
            "E": "Germany",
            "F": "DE",
            "G": "E",
            "H": "",
            "I": "",
            "J": "EUR"
        }
    ],
    "ZPU": [
        {
            "B": "EDVS",
            "C": "SALZGITTER-DRUTTE",
            "D": "Salzgitter",
            "E": "Germany",
            "F": "DE",
            "G": "E",
            "H": "",
            "I": "",
            "J": "EUR"
        }
    ],
    "ZQC": [
        {
            "B": "EDRY",
            "C": "SPEYER",
            "D": "Speyer",
            "E": "Germany",
            "F": "DE",
            "G": "E",
            "H": "",
            "I": "",
            "J": "EUR"
        }
    ],
    "ZQD": [
        {
            "B": "EDHS",
            "C": "STADE",
            "D": "Stade",
            "E": "Germany",
            "F": "DE",
            "G": "E",
            "H": "",
            "I": "",
            "J": "EUR"
        }
    ],
    "ZQN": [
        {
            "B": "NZQN",
            "C": "Frankton",
            "D": "Queenstown",
            "E": "New Zealand",
            "F": "NZ",
            "G": "O",
            "H": "",
            "I": "",
            "J": "SWP"
        }
    ],
    "ZQP": [
        {
            "B": "EDLX",
            "C": "WESEL - ROMERWARDT",
            "D": "Wesel",
            "E": "Germany",
            "F": "DE",
            "G": "E",
            "H": "",
            "I": "",
            "J": "EUR"
        }
    ],
    "ZQV": [
        {
            "B": "EDFV",
            "C": "WORMS",
            "D": "Worms",
            "E": "Germany",
            "F": "DE",
            "G": "E",
            "H": "",
            "I": "",
            "J": "EUR"
        }
    ],
    "ZQW": [
        {
            "B": "EDRZ",
            "C": "ZWEIBRUKEN",
            "D": "Zweibruken",
            "E": "Germany",
            "F": "DE",
            "G": "E",
            "H": "",
            "I": "",
            "J": "EUR"
        }
    ],
    "ZRH": [
        {
            "B": "LSZH",
            "C": "ZURICH",
            "D": "Zurich",
            "E": "Switzerland",
            "F": "CH",
            "G": "E",
            "H": "",
            "I": "",
            "J": "EUR"
        }
    ],
    "ZSE": [
        {
            "B": "FMEP",
            "C": "SAINT PIERRE PIERREFONDS",
            "D": "Reunion island",
            "E": "Reunion",
            "F": "RE",
            "G": "O",
            "H": "",
            "I": "",
            "J": "AFR"
        }
    ],
    "ZSK": [
        {
            "B": "EDCV",
            "C": "PASEWALK - FRANSFELDE",
            "D": "Pasewalk",
            "E": "Germany",
            "F": "DE",
            "G": "E",
            "H": "",
            "I": "",
            "J": "EUR"
        }
    ],
    "ZSN": [
        {
            "B": "EDOV",
            "C": "STENDAL - BORSTEL",
            "D": "Stendal",
            "E": "Germany",
            "F": "DE",
            "G": "E",
            "H": "",
            "I": "",
            "J": "EUR"
        }
    ],
    "ZSO": [
        {
            "B": "EDQS",
            "C": "SUHL - GOLDLAUTER",
            "D": "Suhl Goldlauter",
            "E": "Germany",
            "F": "DE",
            "G": "E",
            "H": "",
            "I": "",
            "J": "EUR"
        }
    ],
    "ZTH": [
        {
            "B": "LGZA",
            "C": "ZAKINTHOS",
            "D": "Zakinthos",
            "E": "Greece",
            "F": "GR",
            "G": "E",
            "H": "",
            "I": "",
            "J": "EUR"
        }
    ],
    "ZTK": [
        {
            "B": "LSPN",
            "C": "TRIENGEN",
            "D": "Triengen",
            "E": "Switzerland",
            "F": "CH",
            "G": "E",
            "H": "",
            "I": "",
            "J": "EUR"
        }
    ],
    "ZTZ": [
        {
            "B": "EDCJ",
            "C": "CHEMNITZ / JAHNSDORF",
            "D": "Chemnitz",
            "E": "Germany",
            "F": "DE",
            "G": "E",
            "H": "",
            "I": "",
            "J": "EUR"
        }
    ],
    "ZUH": [
        {
            "B": "ZGSD",
            "C": "Zhuhai",
            "D": "Sanzao International",
            "E": "Peoples Republic of China",
            "F": "CN",
            "G": "O",
            "H": "",
            "I": "",
            "J": "SEA"
        }
    ],
    "ZVJ": [
        {
            "B": "",
            "C": "Abu Dhabi Bus Station Airport",
            "D": "Abu Dhabi Bus Station Airport",
            "E": "United Arab Emirates",
            "F": "AE",
            "G": "O",
            "H": "",
            "I": "",
            "J": "MDE"
        }
    ],
    "ZWE": [
        {
            "B": "",
            "C": "Railway Station Antwerpen-Centraal",
            "D": "Central Rail Station",
            "E": "Belgium",
            "F": "BE",
            "G": "E",
            "H": "",
            "I": "",
            "J": "EUR"
        }
    ],
    "ZWM": [
        {
            "B": "EDCW",
            "C": "WISMAR - MUGGENBURG",
            "D": "Santa Express",
            "E": "Germany",
            "F": "DE",
            "G": "E",
            "H": "",
            "I": "",
            "J": "EUR"
        }
    ],
    "ZWS": [
        {
            "B": "EZWS",
            "C": "Stuttgart",
            "D": "Stuttgart-Main Rr",
            "E": "Germany",
            "F": "DE",
            "G": "E",
            "H": "",
            "I": "",
            "J": "EUR"
        }
    ],
    "ZYK": [
        {
            "B": "ZGSK",
            "C": "SHEKOU",
            "D": "Shekou",
            "E": "Peoples Republic of China",
            "F": "CN",
            "G": "O",
            "H": "",
            "I": "",
            "J": "SEA"
        }
    ],
    "ZYL": [
        {
            "B": "VGSY",
            "C": "Civil",
            "D": "Osmani International",
            "E": "Bangladesh",
            "F": "BD",
            "G": "O",
            "H": "",
            "I": "",
            "J": "SAS"
        }
    ],
    "ZYR": [
        {
            "B": "",
            "C": "Brussels Midi Railway Station",
            "D": "Brussels Olp",
            "E": "Belgium",
            "F": "BE",
            "G": "E",
            "H": "",
            "I": "",
            "J": "EUR"
        }
    ],
    "ZZF": [
        {
            "B": "ZZZF",
            "C": "MYSTERY FLIGHT",
            "D": "Mystery Flight",
            "E": "Republic of Ireland",
            "F": "0",
            "G": "D",
            "H": "",
            "I": "",
            "J": "EUR"
        }
    ],
    "ZZJ": [
        {
            "B": "ZZZJ",
            "C": "ACL DEFAULT UNKNOWN",
            "D": "Acl unknown",
            "E": "UNKNOWN",
            "F": "0",
            "G": "D",
            "H": "",
            "I": "",
            "J": "EUR"
        }
    ],
    "ZZP": [
        {
            "B": "LYPO",
            "C": "POZAREVAC",
            "D": "Pozarevac",
            "E": "SERBIA",
            "F": "CS",
            "G": "E",
            "H": "",
            "I": "",
            "J": "EUR"
        }
    ],
    "ZZZ": [
        {
            "B": "ZZZZ",
            "C": "UNKNOWN",
            "D": "Unknown",
            "E": "UNKNOWN",
            "F": "IE",
            "G": "D",
            "H": "",
            "I": "",
            "J": "EUR"
        }
    ]
};

export default codes;
