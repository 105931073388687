import React, {useContext} from "react";
import {FlightStore} from "./FlightStore";

export const createStore = () => {
  const store = new FlightStore();
  store.startFetch();
  return store;
};

export const StoreContext = React.createContext({});
export const StoreProvider = StoreContext.Provider;

export const useStore = () => {
  return useContext(StoreContext);
}

