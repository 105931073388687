import React from 'react';
import iata_code from "./iata_code";

import './FlightTable.css'

export function FlightTable(props) {
  function generateRows(rows) {
    return rows.map((row, index) => {
      let checkin = "";
      if(row.type === 'separator') {
        return <tr className="flights-category bg-black color-white" key={row.date}>
          <td colSpan={6}>Flights for {row.date}</td>
        </tr>
      } else if(row.type === 'item') {
        if(row.item.passenger_terminal_code === '2' && row.item.checkin_desk_range != null) {
          const checkinArray = row.item.checkin_desk_range.split(",")
          checkin =
            checkinArray.length > 1
              ? checkinArray[checkinArray.length - 1] + '-' + checkinArray[0]
              : checkinArray[0];
        } else if(row.item.passenger_terminal_code === '1') {
          checkin = row.item.checkin_zone;
        }

        return <tr className="flights-item" key={row.item.id}>
          <td className="color-blue">{row.item.time}</td>
          <td className="flights-col-destination">{iata_code[row.item.port_of_call_iata_code][0]['D']}</td>
          <td className="text-center"><img src={`//pics.avs.io/170/50/${row.item.carrier_iata_code}.png`}/></td>
          <td className="color-blue">{row.item.flight_id}</td>
          <td className="text-center">{row.item.passenger_terminal_code}</td>
          <td className="text-right">{checkin}</td>
        </tr>
      } else {
        return <tr className="flights-item" key={index}><td colSpan={6}>&nbsp;</td></tr>
      }
    })
  }

  const {rows, page, totalPage} = props;

  return (
    <>
      <table className="flights-table">
        <thead>
          <tr className="color-black bg-green">
            <th className="flights-col-time">Time</th>
            <th>Destination</th>
            <th className="flights-col-flighticon">&nbsp;</th>
            <th className="flights-col-flightid">Flight</th>
            <th className="flights-col-terminal text-center">Terminal</th>
            <th className="flights-col-checkin text-right">Check-in</th>
          </tr>
        </thead>
        <tbody>
        {generateRows(rows)}
        </tbody>
      </table>
      <div className="table-footer bg-green text-right">
        &nbsp; Page {page} of {totalPage} &nbsp;
      </div>
    </>
  )
}
