import React, {Component} from 'react';

class Header extends Component {
    constructor(props) {
        super(props);
        this.state = {
            date: new Date()
        }
    }

    componentDidMount() {
        this.interval = setInterval(() => {
            this.setState({
                date: new Date()
            })
        }, 1000);
    }

    componentWillUnmount() {
        clearInterval(this.interval);
    }

    _formatDate(date) {
        let minute = date.getMinutes();
        let hour = date.getHours();

        if(hour < 10) {
            hour = '0' + hour;
        }

        if(minute < 10) {
            minute = '0' + minute;
        }

        return `${hour}:${minute}`;
    }

    render() {
        const {date} = this.state;
        const time = this._formatDate(date);

        return (
            <section className="header bg-dark-green color-white">
              <div className="row">
                <div className="col auto">
                  Departures Check-in Information
                </div>
                <div className="col">
                  {time}
                </div>
              </div>
            </section>
        )
    }
}

export default Header;
