import React from 'react';
import {DisplayStatus} from "../DisplayStatus";
import Logo from "../Logo";
import './Footer.css'

export function Footer(props) {
  return (
    <div className="footer bg-dark-green color-white">
      <DisplayStatus/>
      <div className="logo-wrapper text-right"><Logo/></div>
    </div>
  )
}


