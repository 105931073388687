import React from 'react';
import logo from '../../assets/DA Logo White.png';

function Logo() {
    return (
        <img style={{width: '250pt'}} src={logo} alt='Logo'/>
    )
}

export default Logo;
