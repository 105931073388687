import React from 'react';
import Header from './components/Header';
import { Footer } from './components/Footer';
import { FlightTable } from "./components/FlightTable";
import "../slideTransition.css";
import { useStore } from "../store";

function Dashboard(props) {
  const store = useStore();
  const { activePage } = props;
  const { totalPageNum, pages } = store;

  return (
    <div className="page" style={{display: 'flex', flexDirection: 'column', minHeight: '100vh'}}>
      <Header />
      <FlightTable
        totalPage = {totalPageNum}
        page={activePage}
        rows={pages[activePage - 1] ?? []}
      />
      <Footer/>
    </div>
  )
}

export default Dashboard;
